.HeadingDescriptionHighlight {
	&-heading {
		text-align: center;

		.Heading {
			&-title {
				margin-top: 0;
				margin-bottom: 20px;
			}

			&-text {
				margin-top: 0;
				margin-bottom: 0;

				&:lang(ja) {
					font-size: 1rem;
				}

				@include viewport(large) {
					@include typography(b1);
				}
			}
		}
	}
}
