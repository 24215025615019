.SimpleList {
	@mixin SimpleList-item-visible() {
		height: auto;
		opacity: 1;
		padding-bottom: 16px;
		padding-top: 16px;

		&::after {
			opacity: 0;
			z-index: -1;
		}

		@include viewport(medium) {
			height: auto;
			opacity: 1;
		}
	}

	@mixin SimpleList-item-hinted() {
		opacity: 0.75;
		transition: opacity 200ms ease-out;

		&::after {
			bottom: 0;
			content: '';
			display: block;
			opacity: 1;
			position: absolute;
			top: 0;
			transform-origin: bottom;
			transform: scaleY(1);
			transition: transform 200ms ease-out;
			transition-delay: 125ms;
			width: 100%;
			z-index: 1;

			.SimpleList--dark &::after {
				@include gradient2transparent(rgba(0, 0, 0, 1), 100%, 0, 65%);
			}
		}
	}

	text-align: center;

	&-list {
		position: relative;
		list-style: none;
		padding: 0;
		margin: 0;
		overflow: hidden;
		transition: max-height 250ms ease-out;
		transition-delay: 0;

		&.is-expanded {
			transition-delay: 100ms;
		}
	}

	&-item {
		position: relative;
		text-align: center;

		@include SimpleList-item-visible;

		@include viewport(medium) {
			&:nth-child(odd) {
				clear: both;
			}
		}
	}

	&--js &-item {
		&.is-hintedSmall {
			@include SimpleList-item-hinted;

			@include viewport(medium) {
				opacity: 1;

				&::after {
					display: none;
				}
			}
		}

		&.is-hintedMedium {
			@include viewport(medium) {
				@include SimpleList-item-hinted;
			}
		}

		&.is-hiddenSmall {
			opacity: 0;
			transition: opacity 200ms;

			@include viewport(medium) {
				opacity: 1;
			}
		}

		&.is-hiddenMedium {
			opacity: 0;
			transition: opacity 200ms;
		}

		&.u-lightHint::after {
			@include gradient2transparent(rgba(255, 255, 255, 1), 100%, 0, 65%);
		}

		&.u-darkHint::after {
			@include gradient2transparent(rgba(0, 0, 0, 1), 100%, 0, 65%);
		}
	}

	&--js .is-expanded &-item {
		&.is-hintedSmall {
			opacity: 1;

			&::after {
				transform: scaleY(0);
				transition-delay: 0;
			}
		}

		&.is-hintedMedium {
			@include viewport(medium) {
				opacity: 1;

				&::after {
					transform: scaleY(0);
					transition-delay: 0;
				}
			}
		}

		&.is-hiddenSmall {
			opacity: 1;
		}

		&.is-hiddenMedium {
			opacity: 1;
		}
	}

	&-toggle {
		display: none;
	}

	&--js &-toggle {
		display: inline-block;
		position: relative;
		margin-top: 35px;

		@include viewport(medium) {
			margin-top: 55px;
		}

		&.is-hiddenMedium {
			display: inline-block;

			@include viewport(medium) {
				display: none;
			}
		}

		.toggle-open,
		.toggle-close {
			position: relative;
			display: block;
			text-align: center;
			padding-right: 10px;

			@include rtl {
				padding-right: 0;
				padding-left: 10px;
				width: 150px;
			}

			&::before {
				position: absolute;
				width: 10px;
				height: 10px;
				right: 0;
				line-height: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.toggle-open::before {
			@include icon('icon-plus_sign');
		}

		.toggle-close {
			display: none;

			&::before {
				@include icon('icon-less_sign');

				@include rtl {
					right: -15px;
					position: relative;
				}
			}
		}
	}

	&-toggle.is-expanded {
		.toggle-close {
			display: block;
		}

		.toggle-open {
			display: none;
		}
	}

	&-heading {
		margin-bottom: 24px;

		@include viewport(medium) {
			margin-bottom: 44px;
		}

		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			left: calc(50% - 20px);
			border-top: 2px solid;
			width: 40px;
		}

		.Heading-title {
			padding-bottom: 30px;
			margin: 0 auto;

			@include viewport(medium) {
				letter-spacing: em(4, 16);
				line-height: em(21, 16);
			}
		}
	}
}
