.ReservationBar {
	&-container {
		text-align: center;
		padding: 40px 0;

		@include viewport(medium) {
			padding: 42px 0;
		}

		@include viewport(large) {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 50px 80px 60px;
		}

		@include viewport(xlarge) {
			padding: 50px 130px 60px;
		}
	}

	&--light {
		background-color: $black;
		color: $white;
	}

	&--dark {
		background-color: $white;
		color: $black;
	}

	&-title {
		display: block;
		font-size: rem(18, 16);
		line-height: em(27, 18);
		margin-bottom: 40px;
		margin-left: auto;
		margin-right: auto;
		width: percentage(8/12);

		@include viewport(medium) {
			width: 100%;
		}

		&::after {
			content: '';
			display: block;
			height: 1px;
			margin: 0 auto;
			position: relative;
			top: 19px;
			width: 40px;
		}

		.ReservationBar--light &::after {
			background-color: $gray5;
		}

		.ReservationBar--dark &::after {
			background-color: $black;
		}

		@include viewport(large) {
			font-size: rem(21, 16);
			margin-bottom: 60px;

			&::after {
				top: 30px;
			}
		}
	}

	&-phone {
		display: table;
		margin: 0 auto 30px;

		.ReservationBar--light & {
			@include link('underlined', 'dark');
		}

		.ReservationBar--dark & {
			@include link('underlined', 'light');
		}

		@include viewport(large) {
			display: block;
			margin: 0;
		}
	}

	&-ctaLink {
		display: table;
		margin: 0 auto 30px;

		@include viewport(large) {
			display: block;
			margin: 0;
		}
	}

	&-ctaButton {
		@include viewport(large) {
			display: block;
			margin: 0;
		}
	}
}
