.PrivateJetContactForm {
	&-Container.ContactForm {
		padding-top: 0;
	}
	&-Heading {
		text-align: center;
		margin: 40px 0;
		@include viewport(medium) {
			margin: 70px 0;
		}
		.Heading-title {
			margin-top: 0;
			margin-bottom: 15px;
			@include viewport(large) {
				margin-bottom: 20px;
			}
		}
		.Heading-text {
			color: var(--color-text-primary, #1f1f1f);
		}
	}

	.RadioButtons-button {
		margin: 0;
		&:not(:last-child) {
			margin-right: 30px;
		}
	}

	.ContactForm {
		padding-bottom: 0;
		&-SubmitContainer {
			margin-bottom: 27px;
		}
		&-SubmitCTA {
			min-width: 95px;
			@include viewport(medium) {
				min-width: 188px;
			}
		}
	}

	&-BrochureRequests {
		.PrivateJetContactForm-SubText {
			@include viewport(large) {
				margin-bottom: 33px;
			}
		}
		.Checkbox {
			margin-top: 2px;
			margin-bottom: 11px;
		}
	}
	&-Year {
		.PrivateJetContactForm-SubText {
			margin-top: 2px;
			margin-bottom: 5px;

			@include viewport(medium) {
				margin-top: 7px;
			}
		}
	}
	&-LegalDisclaimer {
		margin-bottom: 27px;
	}
	&-Legal,
	.LegalAgreement {
		a {
			color: inherit;
			white-space: nowrap;
		}
	}
	&-Legal-email,
	.LegalAgreement {
		a {
			text-decoration: inherit;
		}
	}
}
