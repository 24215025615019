@function em($pixels, $context) {
	@return #{$pixels/$context}em;
}

@function rem($pixels, $context) {
	$remSize: $pixels / $context;

	@return #{$remSize}rem;
}

@mixin serifRegular() {
	font-family: $serifFont;
	font-weight: 400;
	font-style: normal;
}

@mixin serifItalic() {
	font-family: $serifFont;
	font-weight: 400;
	font-style: italic;
}

@mixin sansThin() {
	font-family: $sansFont;
	font-weight: 200;
	font-style: normal;
}

@mixin sansBold() {
	font-family: $sansFont;
	font-weight: 700;
	font-style: normal;
}

@mixin sansRegular() {
	font-family: $sansFont;
	font-weight: 400;
	font-style: normal;
}

@mixin typography($size) {
	@if $size == h1 {
		@include sansThin;
		font-size: rem(28, 16);
		letter-spacing: em(6, 28);
		line-height: em(42, 28);
		text-transform: uppercase;

		@include viewport(medium) {
			font-size: rem(32, 16);
			letter-spacing: em(5, 32);
			line-height: em(47, 32);
		}

		@include viewport(large) {
			font-size: rem(44, 16);
			letter-spacing: em(6, 44);
			line-height: em(62, 44);
		}

		@include viewport(xlarge) {
			font-size: rem(72, 16);
			letter-spacing: em(10, 72);
			line-height: em(100, 72);
		}

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
		}
	}

	@if $size == h2 {
		@include sansThin;
		font-size: rem(24, 16);
		letter-spacing: em(4, 24);
		line-height: em(31, 24);
		text-transform: uppercase;

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
		}
	}

	@if $size == h3 {
		@include sansBold;
		font-size: rem(16, 16);
		letter-spacing: em(4, 16);
		line-height: em(21, 16);
		text-transform: uppercase;

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
		}
	}

	@if $size == h4 {
		@include serifItalic;
		font-size: rem(18, 16);
		letter-spacing: em(0.5, 18);
		line-height: em(27, 18);

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
		}
	}

	@if $size == b1 {
		@include serifRegular;
		font-size: rem(22, 16);
		letter-spacing: em(0.5, 22);
		line-height: em(33, 22);

		@include i18nFonts;
		@include wls {
			font-family: 'Work Sans';
			font-size: rem(18, 16);
			line-height: em(27, 18);
			letter-spacing: em(0.5, 18);
		}
	}

	@if $size == b2 {
		@include serifRegular;
		font-size: rem(18, 16);
		letter-spacing: em(0.5, 18);
		line-height: em(27, 18);

		@include i18nFonts;

		&:lang(ja) {
			font-size: 1rem;
		}
		@include wls {
			font-family: 'Work Sans';
			font-size: rem(14, 16);
			line-height: em(27, 14);
			letter-spacing: em(0.5, 14);
		}
	}

	@if $size == b3 {
		@include serifRegular;
		font-size: rem(15, 16);
		letter-spacing: em(0.5, 15);
		line-height: em(25, 15);

		@include i18nFonts;
		@include wls {
			font-family: 'Work Sans';
			font-size: rem(11, 16);
			line-height: em(25, 11);
			letter-spacing: em(0.5, 11);
		}
	}

	@if $size == q1 {
		@include serifItalic;
		font-size: rem(32, 16);
		letter-spacing: em(-0.5, 32);
		line-height: 1.5em;

		@include viewport(medium) {
			font-size: rem(36, 16);
			letter-spacing: em(-0.5, 36);
		}

		@include viewport(large) {
			font-size: rem(48, 16);
			letter-spacing: em(-0.5, 48);
		}

		@include viewport(xlarge) {
			font-size: rem(76, 16);
			letter-spacing: em(-0.5, 76);
		}

		@include i18nFonts;
	}

	@if $size == q2 {
		@include serifItalic;
		font-size: rem(21, 16);
		letter-spacing: em(0.5, 21);
		line-height: 1.5em;

		@include i18nFonts;
	}

	@if $size == c1 {
		@include sansBold;
		font-size: rem(12, 16);
		letter-spacing: em(3, 12);
		line-height: em(16, 12);
		text-transform: uppercase;

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
			font-size: rem(16, 16);
			line-height: em(27, 16);
			letter-spacing: em(1, 16);
		}
	}

	@if $size == c2 {
		@include sansRegular;
		font-size: rem(12, 16);
		letter-spacing: em(3, 12);
		line-height: em(18, 12);
		text-transform: uppercase;

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
			font-size: rem(16, 16);
			line-height: em(27, 16);
			letter-spacing: em(1, 16);
		}
	}

	@if $size == c3 {
		@include sansBold;
		font-size: rem(10, 16);
		letter-spacing: em(3, 10);
		line-height: em(13, 10);
		text-transform: uppercase;

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
		}
	}

	@if $size == c4 {
		@include sansRegular;
		font-size: rem(10, 16);
		letter-spacing: em(3, 10);
		line-height: em(13, 10);
		text-transform: uppercase;

		@include i18nFonts;
		@include wls {
			font-family: 'EB Garamond';
			text-transform: none;
		}
	}

	@if $size == cc1 {
		@include sansBold;
		font-size: rem(12, 16);
		letter-spacing: em(1, 12);
		line-height: em(16, 12);

		@include i18nFonts;
	}

	@if $size == cc2 {
		@include sansRegular;
		font-size: rem(12, 16);
		letter-spacing: em(1, 12);
		line-height: em(18, 12);

		@include i18nFonts;
	}

	@if $size == cc3 {
		@include sansBold;
		font-size: rem(10, 16);
		letter-spacing: em(1, 10);
		line-height: em(13, 10);

		@include i18nFonts;
	}

	@if $size == cc4 {
		@include sansRegular;
		font-size: rem(10, 16);
		letter-spacing: em(1, 10);
		line-height: em(13, 10);

		@include i18nFonts;
	}
}

// TODO: Give this a better name or move to headline

@mixin headingBorder() {
	position: relative;

	&::after {
		background-color: currentColor;
		content: '';
		display: block;
		height: 2px;
		margin: 15px auto 10px;
		width: 40px;
	}
}

@mixin headingBorder-2() {
	@include headingBorder();
	position: static;
	margin: 0;

	&::after {
		margin: 15px auto;
	}
}
