$navigationBreakpointMax: 1099px;

.Variant {
	display: none;

	&-prevButton,
	&-nextButton {
		left: calc(50% + -540px);
		right: auto;
		position: relative;
		top: 430px;
		z-index: 2;
		position: absolute;
		&::after {
			background: #000;
			opacity: 0.5;
			border-radius: 50%;
			display: inline-block;
			width: 63px;
			height: 63px;
			content: '';
			position: absolute;
			top: -7px;
			left: -7px;
			z-index: -1;
			line-height: 48px;
		}
	}

	&-nextButton {
		right: calc(50% + -540px);
		left: auto;
	}

	.Hero {
		&-headingContainer {
			top: 25px;
			bottom: auto;
		}
	}

	&--transition {
		.Variant-prevButton,
		.Variant-nextButton {
			z-index: 0; // layer buttons underneath slides while transitioning
		}
	}
}

.VariantCard {
	background-color: white;
	top: 90px;
	padding: 50px;
	height: auto;
	overflow: hidden;
	height: 590px;
	margin: 75px;

	.ImageAndCta {
		display: flex;
		height: 490px;
	}
	.Image-picture {
		width: 400px;
		height: 490px;
		position: relative;
	}

	.CtaBlock {
		margin-left: 50px;
		height: 490px;
		width: 350px;
		position: relative;

		&-title {
			@include typography(h3);
		}

		&-cta {
			position: absolute;
			bottom: 0;
		}
	}
}
