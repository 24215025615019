.LandscapeCard {
	&.LandscapeCardWithCta {
		.LandscapeCard-contents {
			gap: 30px;
		}
	}
	&-cta {
		text-decoration: none;
	}
}
