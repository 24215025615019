.Bubble {
	padding: 60px 0;
	@include viewport(medium) {
		padding: 70px 0;
	}
	&-title-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 40px;
		@include viewport(medium) {
			margin-bottom: 50px;
		}
	}

	&--light {
		color: $black;
		background-color: $gray4;
	}

	&--dark {
		color: $white;
		background-color: $gray1;
	}

	&-content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&-title {
		text-align: center;
		margin: 0;
	}

	&-cta-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;
		@include viewport(medium) {
			margin-top: 70px;
		}
	}

	&-item {
		max-width: 210px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		flex-basis: calc(50% - 45px);
		@include viewport(medium) {
			flex-basis: calc(50% - 90px);
		}
		@include viewport(large) {
			flex-basis: calc(25% - 30px);
			max-width: none;
		}

		&-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 40px 45px;
			justify-content: center;
			padding: 0;

			@include viewport(medium) {
				gap: 50px 90px;
			}
			@include viewport(large) {
				gap: 50px 30px;

				&.max3 {
					max-width: 75%;
					margin: auto;
					.Bubble-item {
						flex-basis: calc(33.333333% - 30px);
					}
				}
			}
		}

		&-text {
			text-align: center;
		}

		&-image {
			line-height: 0;
			& .ImageCircle-img {
				width: 100%;
				aspect-ratio: 1;
			}
		}
	}
}
