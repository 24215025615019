.VideoItem {
	box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);

	.BodySection--dark & {
		box-shadow: 0 0 8px 4px rgba(255, 255, 255, 0.2);
	}

	width: 100%;

	&:not(:last-child) {
		@include component-spacing($small: 20px, $medium: 20px, $large: 20px, $xlarge: 20px);
	}

	&-wrapper {
		position: relative;
	}

	&-row {
		padding: 4%;

		@include viewport(large) {
			padding: 16px 60px 16px 16px;
		}

		@include viewport(xlarge) {
			padding-right: 100px;
		}

		@include rtl {
			@include viewport(large) {
				padding: 16px 16px 16px 60px;
			}

			@include viewport(xlarge) {
				padding-left: 100px;
			}
		}

		margin-left: 0;
		margin-right: 0;

		&-col {
			padding: 0;
		}
	}

	&-content {
		padding-right: 4%;

		@include viewport(large) {
			position: relative;
			padding-left: 30px;
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: space-between;
		}

		@include rtl {
			@include viewport(large) {
				padding-left: 0;
				padding-right: 30px;
			}
		}
	}

	&-videoOverlay {
		display: block;

		font-size: 0;
		margin-bottom: 32px;

		@include viewport(large) {
			margin-bottom: 0;
		}

		img {
			display: block;
		}

		.VideoPlaceholderImage-button {
			width: 50px;
			height: 50px;
			&::before {
				font-size: 50px;
			}

			@include viewport(large) {
				width: 40px;
				height: 40px;
				&::before {
					font-size: 40px;
				}
			}

			@include viewport(xlarge) {
				width: 55px;
				height: 55px;
				&::before {
					font-size: 55px;
				}
			}
		}
	}

	&-title {
		display: flex;
		// vertically center title content
		span {
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
		&-subtitle {
			margin-left: 2px;
			letter-spacing: 3px;
		}
	}

	&-wrapper {
		padding: 0;
	}

	&-description {
		display: block;
		text-align: left;
		margin-top: 16px;

		@include viewport(large) {
			margin-top: 20px;
		}

		@include viewport(xlarge) {
			margin-top: 24px;
		}

		@include rtl {
			text-align: right;
		}
	}
}
