.IconsList {
	background-color: $gray4;
	color: $black;
	padding: 50px 30px;

	&--dark {
		background-color: $black;
		color: $white;

		.IconsList-IconText-line {
			background-color: $white;
		}
		.IconsList-IconText-subText {
			color: $white;
		}
	}

	&--light {
		.IconsList-IconText-line {
			background-color: #7d7d7d;
		}
		.IconsList-IconText-subText {
			color: $black;
		}
	}

	&-mobile.IconsList-iconsContainer {
		@include viewport(medium) {
			display: none;
			align-items: flex-start;
		}
		.IconsList-IconText-line {
			display: none;
		}
	}

	&-desktop.IconsList-titleContainer {
		display: none;

		@include viewport(medium) {
			display: flex;
			align-items: flex-start;
		}
	}

	@include viewport(medium) {
		padding: 50px 0;
	}

	@include viewport(large) {
		padding: 80px 0;
	}

	&-titleContainer {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		flex-direction: row;
		justify-content: space-between;
	}

	&-title {
		text-align: center;
		margin: 0;
		margin-bottom: 20px;
		margin-bottom: 0;
		text-align: left;
	}

	&-cta {
		text-align: right;
		flex-shrink: 0;

		.CTA {
			display: block;
			font-size: 10px;
		}
	}

	&-iconsContainer {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include viewport(medium) {
			flex-direction: row;
		}
	}

	&-topPart {
		margin-bottom: 8px;
	}
}
