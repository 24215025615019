$mobileBreakpoint: 701px;
$tabletBreakpoint: 1275px;

.AfricaTravelTime {
	@media screen and (min-width: $mobileBreakpoint) {
		opacity: 0;
		transition: opacity 0.5s;
	}

	&--initialized {
		opacity: 1;
	}

	@media screen and (min-width: $tabletBreakpoint) {
		background-image: url('/alt/fshr/design3/images/africa-travel-time/map-background.jpg');
		background-size: cover;
	}

	&-mapContainer {
		position: relative;
		background-image: url('/alt/fshr/design3/images/africa-travel-time/map-background.jpg');
		background-size: cover;

		@media screen and (min-width: $tabletBreakpoint) {
			background-image: none;
		}
		opacity: 0;
		transition: opacity 0.5s;
		&--initialized {
			opacity: 1;
		}
	}

	&-container {
		position: relative;
		max-width: 1530px;
		margin-left: auto;
		margin-right: auto;
	}

	&-map {
		width: 100%;
		position: relative;

		@media screen and (min-width: $tabletBreakpoint) {
			width: 90%;
		}

		svg {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;

			@media screen and (min-width: $tabletBreakpoint) {
				width: 65%;
				max-height: calc(100vh - 60px);
			}
		}

		&--desktop {
			display: none;

			@media screen and (min-width: $mobileBreakpoint) {
				display: block;
			}
		}

		&--mobile {
			display: flex; //seems to fix height issue

			@media screen and (min-width: $mobileBreakpoint) {
				display: none;
			}
		}
	}

	&-pin {
		pointer-events: none;

		@media screen and (min-width: $mobileBreakpoint) {
			cursor: pointer;
			pointer-events: all;

			opacity: 0;
			transform: translateY(-50px);
			transition: opacity 1s, transform 1s;

			&.AfricaTravelTime--animate {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	&-flightTime {
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.2s;

		&.AfricaTravelTime--animate {
			opacity: 1;
		}
	}

	&-instructions {
		opacity: 0;
		transition: opacity 0.5s;

		&.AfricaTravelTime--animate {
			opacity: 1;
			transition: opacity 1s;
		}

		.AfricaTravelTime-blur {
			filter: blur(13px);
		}
	}

	&-textContainer {
		margin: auto;
		width: 100%;
		max-width: calc(100% - 20px);
		text-align: center;

		@media screen and (min-width: $mobileBreakpoint) {
			max-width: calc(100% - 44px);
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		@media screen and (min-width: $tabletBreakpoint) {
			display: block;
			position: absolute;
			top: 50%;
			right: 20px;
			margin: 0;
			max-width: 460px;
			transform: translateY(-50%);
			text-align: left;
			color: $black;
		}

		@media screen and (min-width: 1400px) {
			right: 50px;
		}

		@media screen and (min-width: 1460px) {
			right: 80px;
		}

		@media screen and (min-width: 1480px) {
			right: 100px;
		}
	}

	&-text {
		@include serifRegular();
		font-size: 18px;
		line-height: 1.5em;
		letter-spacing: 0.5px;

		@media screen and (min-width: 700px) {
			font-size: 22px;
		}
	}

	&-legend {
		display: none;

		&--private {
			color: rgb(8, 95, 98);
		}
		&-icon {
			vertical-align: middle;
		}

		@media screen and (min-width: $tabletBreakpoint) {
			margin: 0 auto 20px;
			display: flex;
			flex-direction: column;
			text-align: left;
		}

		&--mobile {
			opacity: 0;
			transition: opacity 0.5s;

			&.AfricaTravelTime--animate {
				opacity: 1;
			}
			color: $black;
			position: absolute;
			top: 3%;
			left: 3%;
			background-color: rgba(255, 255, 255, 0.7);
			margin: 0;
			display: flex;
			flex-direction: column;
			text-align: left;
			padding: 0.3em 0.8em;
			font-size: 0.625rem;
			line-height: 1em;
			z-index: 1;

			@media screen and (min-width: $mobileBreakpoint) {
				line-height: 1.5em;
			}

			@media screen and (min-width: $tabletBreakpoint) {
				display: none;
			}
		}
	}

	&-propertySelector {
		padding: 20px 0px 0px;
		line-height: 0;

		@media screen and (min-width: $mobileBreakpoint) {
			display: none;
		}

		position: relative;
		width: 100%;
		border: none;
		background: transparent;
		font-size: 10px;
		line-height: 13px;
		letter-spacing: 3px;
		appearance: none;

		&--dark {
			color: $white;
			background-color: $black;
		}

		&Container {
			position: relative;
			flex-direction: row;
			width: 100%;
			height: 60px;
			padding: 0px 24px;
			display: flex;
			gap: 30px;
			overflow-x: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
			scrollbar-width: none;

			border-top: 1px solid #d3d3d3;

			@include rtl {
				&::after {
					left: 7px;
					right: auto;
				}
			}
		}

		&-tab {
			flex: 1 1 0px;
			align-self: center;
			text-align: center;
			display: inline-block;
			background: none;
			border: none;
			font-weight: 400;
			height: 100%;

			&--dark {
				@include grayHover($theme: 'dark');
				color: $white;
			}

			&--light {
				@include grayHover($theme: 'light');
				color: $gray1;
			}

			// This psuedo-element makes the inactive tab text take up as much space
			// as the active (bold) text will to avoid layout shifting
			&::before {
				display: block;
				content: attr(title);
				font-weight: bold;
				height: 0;
				overflow: hidden;
				visibility: hidden;
			}

			position: relative;

			&-active-tab {
				font-weight: bold;

				&::after {
					position: absolute;
					left: 0;
					bottom: 0px;
					height: 4px;
					width: 100%;
					content: '';

					@include rtl {
						left: auto;
						right: 0;
					}
					.AfricaTravelTime-propertySelectorContainer--light & {
						background-color: black;
					}
					.AfricaTravelTime-propertySelectorContainer--dark & {
						background-color: white;
					}
				}
			}
		}
	}
}
