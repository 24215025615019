$transition: 0.5s linear;

.AnimatedCardCarouselBackground {
	@include viewport(medium) {
		position: relative;

		&--slide {
			height: 100%;
			background-color: transparent;

			&.animated {
				transition: width $transition, margin $transition, height $transition;
			}

			&.CenterFocusCarousel--slide {
				width: 350px;
				margin: 0 15px;
				&.is-selected {
					width: 400px;
					height: 100%;
					margin-left: -10px;

					@include rtl {
						margin-right: -10px;
					}

					.AnimatedCardCarouselBackground-Card {
						height: 100%;
						margin-top: 0;

						&--active {
							opacity: 1;
						}

						&--inactive {
							opacity: 0;
						}

						margin: 0;
						opacity: 1;
						box-shadow: 0 0 30px 0 $black;
						border: none;

						&-CTA {
							pointer-events: all;
						}
					}
				}
			}
		}

		&-Card {
			&.AnimatedCardCarousel-Card {
				height: calc(100% - 90px);
				border-color: $white;
				background-color: transparent;
				opacity: 1;
			}

			position: relative;
			background-color: $white;
			color: $black;
			text-align: center;
			height: 100%;
			border: 1px solid $gray3;
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: hidden;
			margin-top: 45px;

			transition: height $transition, margin $transition;

			&--active {
				position: absolute;
				opacity: 0;
				height: 100%;
				min-height: 626px;
				background-color: $white;
				transition: opacity $transition;
				display: flex;
				flex-direction: column;

				.AnimatedCardCarouselBackground-Card-title {
					margin: 40px 25px;
				}

				&-noDescription {
					min-height: 520px;

					.AnimatedCardCarouselBackground-Card {
						&-CTA {
							margin-top: 0;
							margin-bottom: 27.5px;
						}
					}
				}
			}

			&--inactive {
				position: absolute;
				opacity: 1;
				padding: 0 30px;
				transition: opacity $transition;
				color: $white;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				pointer-events: none;

				.AnimatedCardCarouselBackground-Card-subheading {
					padding-bottom: 40px;
				}

				.AnimatedCardCarouselBackground-Card-text {
					display: none;
				}
			}

			&-title {
				margin: 40px 0;
			}

			&-subheading {
				margin: 0;
				padding-bottom: 10px;
			}

			&-noSubheading {
				margin: 0;
			}

			&-text {
				margin: 0;
				height: 108px;
			}

			&-body {
				padding: 30px 25px 50px;
				width: 400px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				&--mobile {
					justify-content: center;
				}
			}

			&-CTA {
				display: inline-block;
				margin: 30px auto 0;
				min-width: 130px;
				pointer-events: none;

				@include viewport(large) {
					min-width: 150px;
				}
			}

			&-MobileLink {
				text-decoration: none;
			}
		}
	}

	.CenterFocusCarousel-mobile {
		.AnimatedCardCarousel {
			&-Card {
				border-color: $gray1;
			}
		}
	}
}
