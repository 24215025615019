.AutomotiveChart.OverlayPage {
	.StandardHero-heading {
		.Heading-eyebrow {
			@include serifItalic;
		}

		.Heading-title {
			margin-top: 0;
			margin-bottom: 50px;

			@include typography(h1);

			@include viewport(large) {
				font-size: 44px;
			}
		}

		.Heading-text {
			margin: auto;
			max-width: 545px;

			@include typography(b1);
		}
	}
}
