.TitleAndDescription {
	padding-left: 15px;
	padding-right: 15px;
	.heading-3 {
		margin: 0;
	}

	@include viewport(medium) {
		padding-left: 60px;
		padding-right: 60px;
	}

	@include viewport(large) {
		padding-left: 90px;
		padding-right: 90px;
		h2 {
			margin-bottom: 30px;
		}
	}

	@include viewport(xlarge) {
		padding-left: 120px;
		padding-right: 120px;
	}
}

.description {
	margin-top: 15px;
	@include viewport(large) {
		margin-top: 30px;
	}
}
