.ImageGrid {
	@include section-spacing;

	&--light {
		background-color: white;
		color: black;
	}

	&--dark {
		background-color: black;
		color: white;
	}

	&-ItemWrap {
		display: flex;
		justify-content: center;

		@include viewport(large) {
			flex-wrap: nowrap;
		}

		@include viewport(small) {
			flex-wrap: wrap;
		}
	}

	&-Item {
		@include viewport(large) {
			padding: 10px;
		}

		@include viewport(small) {
			padding: 15px;
		}
		width: 30%;
		display: inline-block;
		flex-basis: 33%;
		text-align: center;

		&-Text {
			font-size: 12px;
			font-family: $sansFont;
			font-weight: bold;
			letter-spacing: 3px;
			line-height: 16px;
		}
	}
}
