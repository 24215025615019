.MapAndFloorplan {
	display: none;

	&--dark {
		.MapAndFloorplan {
			&-buttons {
				border-color: $white;
			}
			&-button {
				@include grayHover($theme: 'dark');
				color: $white;
				&--active {
					&:hover,
					&:active,
					&:focus {
						color: $white;
					}

					&::after {
						background-color: $white;
					}
				}
			}
			&-content {
				@include viewport(large) {
					border-bottom: 1px solid $white;
					border-left: 1px solid $white;
					border-right: 1px solid $white;
				}
			}
			&-skip {
				&:focus {
					color: $white;
				}
			}
			&-FloorPlan {
				border-color: $white;
			}
		}
	}

	&--light {
		.MapAndFloorplan {
			&-buttons {
				border-color: $black;
			}
			&-button {
				@include grayHover($theme: 'light');
				color: $black;
				&--active {
					&:hover,
					&:active,
					&:focus {
						color: $black;
					}

					&::after {
						background-color: $black;
					}
				}
			}
			&-content {
				@include viewport(large) {
					border-bottom: 1px solid $black;
					border-left: 1px solid $black;
					border-right: 1px solid $black;
				}
			}
			&-skip {
				&:focus {
					color: $black;
				}
			}
			&-FloorPlan {
				border-color: $black;
			}
		}
	}

	&-title,
	&-text {
		text-align: center;
	}

	&-text {
		@include viewport(medium) {
			@include typography(b1);
		}
	}

	&-heading {
		margin-bottom: 40px;

		@include viewport(medium) {
			margin-bottom: 60px;
		}
	}

	&-buttonsTab {
		display: none;

		@include viewport(large) {
			display: block;
		}
	}

	&-buttons {
		border: 1px solid;
		height: 80px;
	}

	&-buttonsWrap {
		display: flex;
		max-width: 640px;
		margin: 0 auto;
		height: 100%;
		justify-content: space-around;
	}

	&-content {
		background-color: $white;
	}

	&-dropdownContainer {
		@include viewport(large) {
			display: none;
		}
	}

	&-dropdown {
		height: 80px;
		padding: 22px 20px;
		position: relative;
		border-top: 1px solid $separatorLight;

		&::after {
			@include icon('icon-caret');
			position: absolute;
			top: 27px;
			right: 20px;
			font-size: 5px;
			pointer-events: none;
		}

		@include rtl {
			&::after {
				left: 20px;
				right: auto;
			}
		}
	}

	&-select {
		display: block;
		width: 100%;
		background: transparent;
		border: none;
		appearance: none;
		padding-top: 4px;
	}

	&-buttonContainer {
		display: flex;
	}

	&-button {
		text-align: center;
		background: none;
		border: none;
		padding: 0;
		position: relative;

		&--active {
			font-weight: bold;

			&::after {
				content: '';
				height: 4px;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}

	&-content {
		@include viewport(large) {
			border-bottom: 1px solid $black;
			border-left: 1px solid $black;
			border-right: 1px solid $black;
		}
	}

	&-virtualTour {
		position: relative;
	}

	&-virtualTour360 {
		position: relative;
	}

	&-view360 {
		position: relative;
	}

	&-virtualTourImage {
		&::after {
			content: '';
			position: relative;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
		}
	}

	&-view360Image {
		&::after {
			content: '';
			position: relative;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
		}
	}

	&-virtualTourCta {
		position: absolute;
		bottom: 50%;
		right: 50%;
		transform: translate(50%, 50%);
	}

	&-view360Cta {
		position: absolute;
		bottom: 50%;
		right: 50%;
		transform: translate(50%, 50%);
	}

	&-Map {
		&--fixedHeight {
			.EmbeddedMap-map {
				height: 457px;
				width: 100%;

				@include viewport(medium) {
					height: 388px;
				}

				@include viewport(large) {
					height: 523px;
				}

				@include viewport(xlarge) {
					height: 861px;
				}
			}
		}
	}

	&-skipLink {
		position: relative;
		height: 15px;
	}

	&-skip {
		position: absolute;
		top: -1000px;
		left: -1000px;
		width: 1px;
		height: 1px;
		overflow: hidden;

		&:focus {
			left: 0;
			top: 0;
			width: auto;
			height: auto;
			overflow: visible;
		}
	}

	&-FloorPlan {
		height: 377px;
		border: 2px solid;
		position: relative;
		overflow: hidden;

		@include viewport(medium) {
			height: 403px;
		}

		@include viewport(large) {
			height: 522px;
			border: none;
		}

		@include viewport(xlarge) {
			height: 859px;
		}
	}

	&-floorPlanWrapper {
		width: 100%;
		height: 100%;
		overflow: hidden;

		&--zoomed {
			cursor: grab;

			&:active {
				cursor: grabbing;
			}
		}
	}

	&-floorPlanSelectWrapper {
		background-color: $black;
		border-radius: 5px;
		color: $white;
		position: absolute;
		top: 10px;
		right: 10px;
		height: 38px;

		&::after {
			@include icon('icon-caret');
			position: absolute;
			top: 6px;
			right: 15px;
			font-size: 5px;
			pointer-events: none;
		}

		@include viewport(medium) {
			top: 30px;
			right: 30px;
		}

		@include rtl {
			top: 10px;
			right: unset;
			left: 10px;

			&::after {
				left: 15px;
				right: unset;
			}

			@include viewport(medium) {
				top: 30px;
				left: 30px;
			}
		}
	}

	&-floorPlanSelect {
		padding: 10px 47px 12px 15px;
		border: none;
		border-radius: 5px;
		background-color: transparent;
		color: $white;
		text-align: left;
		appearance: none;

		&::-ms-expand {
			display: none;
		}

		option {
			color: $black;
		}

		@include rtl {
			padding: 10px 15px 12px 47px;
		}
	}

	&-floorPlanContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
		display: flex;
	}

	&-floorPlanImage {
		max-height: 80%;
		max-width: 80%;
		pointer-events: none;
		user-select: none;
		display: none;
		margin: auto;
	}

	&-floorPlanButtons {
		position: absolute;
		bottom: 10px;
		right: 10px;
		color: $white;
		background-color: $black;
		box-shadow: rgba($black, 0.3) 0 1px 4px -1px;

		@include viewport(medium) {
			bottom: 30px;
			right: 30px;
		}

		@include rtl {
			right: unset;
			left: 30px;
		}
	}

	&-floorPlanZoomIn,
	&-floorPlanZoomOut {
		border-radius: 2px;
		padding: 13px 11px;
		background-color: transparent;
		position: relative;
		border: none;
		width: 18px;
		height: 18px;
		display: block;
		box-sizing: content-box;

		&::before,
		&::after {
			background-color: $white;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';
		}

		&::after {
			height: 4px;
			width: 18px;
		}
	}

	&-floorPlanZoomIn {
		&::before {
			width: 4px;
			height: 18px;
		}
	}

	&-floorPlanButtonsDivider {
		display: block;
		width: 28px;
		height: 1px;
		margin: auto;
		background-color: $white;
	}
}
