div.svelte-modal {
	z-index: $overlay-z-index;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	div.backdrop {
		background-color: rgba(0, 0, 0, 0.4);
		position: absolute;
		width: 100%;
		height: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
	}
	div.content-wrapper {
		z-index: $overlay-z-index + 1;
		background-color: white;
		padding: 1rem;
		width: 100%;
		height: 100%;
		overflow: auto;
		margin: 0;
		@include viewport(medium) {
			margin: 0 72px;
			height: calc(100% - 200px);
		}
		@include viewport(large) {
			margin: 0 195px;
			height: calc(100% - 240px);
		}
	}
}
div.svelte-modal:not(:focus-within) {
	transition: opacity 0.1ms;
	opacity: 0.99;
}
