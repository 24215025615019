@mixin nonOverflowedTable($columns: 1) {
	.Table {
		&-gradient {
			display: none;
		}

		&-table {
			margin-right: 0;
			margin-left: 0;
			display: table;
			table-layout: auto;
			width: 100%;
		}

		&-cell {
			width: percentage(1 / $columns);
			max-width: none;
			min-width: 0;
		}
	}
}

.Table {
	&-table {
		border-collapse: collapse;
		table-layout: fixed;
		margin-right: 30px;
		// Necessary for adding a right margin to the scrollable table
		display: inline-block;
		vertical-align: top;

		.BodySection--dark & {
			color: $white;
		}

		.BodySection--light & {
			color: $black;
		}

		@include rtl {
			margin-right: 0;
			margin-left: 30px;
		}
	}

	&-wrapper {
		overflow-x: auto;
		overflow-y: hidden;
	}

	&-gradient {
		height: 100%;
		width: 30px;
		position: absolute;
		right: 0;
		top: 0;
		display: block;

		.BodySection--light & {
			background: linear-gradient(to right, transparentize($white, 1) 0, $white 100%);
		}

		.BodySection--dark & {
			background: linear-gradient(to right, transparentize($black, 1) 0, $black 100%);
		}

		@include rtl {
			right: auto;
			left: 0;
			transform: rotate(180deg);
		}

		&-wrapper {
			position: relative;
		}
	}

	&-cell {
		text-transform: uppercase;
		border: 1px solid currentColor;
		padding: 30px 20px;
		text-align: left;
		// Necessary, to override the table layout algorithm
		// and make columns the same width
		min-width: 240px;
		max-width: 240px;

		@include rtl {
			text-align: right;
		}
	}

	// Max columns modifiers
	@include viewport(small) {
		&-1cols {
			@include nonOverflowedTable(1);
		}
	}

	// Cell width is 240px on overflowed tables. If we get to 450,
	// make two-column tables take 100% of the container width.
	@include media('460px') {
		&-2cols {
			@include nonOverflowedTable(2);
		}
	}

	@include viewport(medium) {
		&-3cols {
			@include nonOverflowedTable(3);
		}
	}

	@include viewport(large) {
		// Inside the component container the table can only hold 3 columns
		:not(.ComponentContainer) &-4cols {
			@include nonOverflowedTable(4);
		}
	}

	@include viewport(xlarge) {
		// Inside the component container the table can only hold 3 columns
		:not(.ComponentContainer) &-5cols {
			@include nonOverflowedTable(5);
		}
		:not(.ComponentContainer) &-6cols {
			@include nonOverflowedTable(6);
		}
	}

	&-footnote {
		margin: 30px 0 0;

		@include serifItalic;
		font-style: italic;
	}
}
