.GreatGroupValues {
	.StandardHero {
		@include viewport(large) {
			padding-top: 120px;
		}

		&-title {
			max-width: 615px;
		}

		.StandardHero-heading {
			.Heading-title {
				margin-top: 0;
				margin-bottom: 15px;

				@include typography(h1);

				@include viewport(large) {
					font-size: 48px;
				}
			}

			.Heading-text {
				margin: auto;
				max-width: 555px;

				@include typography(b1);
			}
		}
	}
}
