.ParagraphGroup {
	&-heading {
		margin-bottom: 10px;

		&-title {
			font-size: 2rem;
			letter-spacing: 0.03125em;
			line-height: 0.96875em;
			font-weight: 400;
			text-transform: none;
		}
	}
	&-paragraph {
		margin-bottom: 30px;
	}
}
