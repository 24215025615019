.RandomMultiImagePanel {
	&-multiImagePanel {
		display: none;

		&--visible {
			display: block;
		}
	}

	.BodySection & > .SpacedComponent {
		margin-bottom: 0;
	}
}
