@mixin rtl {
	// Used to fix node-sass version issue
	#{'&'}:lang(ar) {
		@content;
	}
}

@mixin i18nFonts() {
	&:lang(ar) {
		font-family: $cairo;
		-webkit-font-smoothing: auto;
		letter-spacing: 0;
	}

	&:lang(ru) {
		font-family: $openSans;
		-webkit-font-smoothing: auto;
	}

	&:lang(ko-KR) {
		font-family: $nanum;
		-webkit-font-smoothing: auto;
	}

	&:lang(zh) {
		font-family: $notoSansSC;
		-webkit-font-smoothing: auto;
	}

	&:lang(zh-TW) {
		font-family: $notoSans;
		-webkit-font-smoothing: auto;
	}

	&:lang(ja) {
		font-family: $notoSansJP;
		-webkit-font-smoothing: auto;
	}
}
