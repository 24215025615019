.VideoList {
	&-heading {
		.Heading-title {
			@include component-spacing;

			text-align: center;
			margin-top: 0;
		}
	}

	&-filter {
		margin: 0 auto 40px;

		@include viewport(medium) {
			margin: 0 auto 60px;
		}

		@include viewport(large) {
			margin: 0 auto 70px;
		}

		@include viewport(xlarge) {
			margin: 0 auto 90px;
		}

		&-select {
			width: 100%;
			background: transparent;
			border: none;
			display: block;
			appearance: none;
			padding-top: 5px;
		}

		&-button {
			@include grayHover($theme: 'light');
			width: 25%;
			align-self: center;
			text-align: center;
			display: inline-block;
			background: none;
			border: none;
			color: $black;
			font-weight: 400;

			@include viewport(medium) {
				margin-top: 25px;
			}

			@include viewport(large) {
				margin-top: 30px;
			}

			@include viewport(xlarge) {
				margin-top: 40px;
			}

			&--is-active {
				font-weight: bold;

				&:hover,
				&:active,
				&:focus {
					color: $black;
				}
			}
		}
	}

	&-dropdownContainer {
		@include viewport(large) {
			display: none;
		}
	}

	&-dropdown {
		position: relative;
		height: 80px;
		padding: 22px 0;
		border-top: 1px solid $separatorLight;
		border-bottom: 1px solid $separatorLight;
	}

	&-types {
		display: block;

		&-select {
			position: relative;

			&::after {
				@include icon('icon-caret');
				pointer-events: none;
				position: absolute;
				top: 7px;
				right: 7px;
				font-size: 5px;
			}

			@include rtl {
				&::after {
					left: 7px;
					right: auto;
				}
			}

			@include viewport(medium) {
				&::after {
					right: 15px;
				}

				@include rtl {
					&::after {
						left: 15px;
						right: auto;
					}
				}
			}
		}

		&-buttons {
			display: none;
		}

		@include viewport(large) {
			&-buttons {
				display: block;
				border-top: 1px solid;
				border-bottom: 1px solid;
				padding-top: 0;

				@include viewport(medium) {
					padding-bottom: 25px;
				}

				@include viewport(large) {
					padding-bottom: 30px;
				}

				@include viewport(xlarge) {
					padding-bottom: 40px;
				}

				&-wrap {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
				}
			}

			&-select {
				display: none;
			}
		}
	}
}
