.HeadingAndList {
	&-heading {
		.Heading-title {
			@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);

			text-align: center;
			margin-top: 0;
		}
	}

	.TableList {
		&-item {
			display: block;
			padding: 30px 0 55px;

			@include viewport(medium) {
				padding-bottom: 35px;
			}

			@include viewport(large) {
				padding-bottom: 55px;
			}

			@include viewport(xlarge) {
				padding-bottom: 75px;
			}

			&-title {
				margin-bottom: 5px;

				@include viewport(medium) {
					margin-bottom: 15px;
				}
			}

			&-description {
				margin-left: 0;
				text-align: left;

				@include rtl {
					text-align: right;
				}

				&-head:not(:last-child) {
					margin-bottom: 15px;

					@include viewport(xlarge) {
						margin-bottom: 33px;
					}
				}

				&-cta {
					margin-top: 0;
					text-align: left;

					@include rtl {
						text-align: right;
					}
				}
			}
		}
	}
}
