.Attribution {
	display: block;
	font-style: normal;
	text-align: center;

	.Attribution-author {
		margin-bottom: 20px;

		@include viewport(medium) {
			margin-bottom: 10px;
		}
	}

	.Attribution-role {
		@include viewport(large) {
			&::before {
				content: '';
				display: inline-block;
				width: 10px;
			}
		}
	}
}

// Image attribution
.Image + .Attribution,
.Image + figcaption .Attribution {
	//  20px from the image to the top of the text.
	//  20px minus half the difference of Attribution height and the icon
	margin-top: 20px - ((28px - 13px) / 2);
	text-align: left;

	@include rtl {
		text-align: right;
	}

	.Attribution-author {
		@include typography(c4);
	}

	// Icon
	&::before {
		font-size: rem(13, 16);
		vertical-align: middle;
	}
}
