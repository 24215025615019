.DestinationCardGrid {
	position: relative;

	&-header {
		margin-top: 40px;

		@include viewport(medium) {
			margin-top: 60px;
		}

		@include viewport(large) {
			margin-top: 70px;
		}

		@include viewport(xlarge) {
			margin-top: 90px;
		}

		&Title {
			margin-top: 0;
			margin-bottom: 0;
			text-align: center;
		}
	}

	&-filterBarContainer {
		height: 65px;
		margin-top: 30px;
		margin-bottom: 55px;

		@include viewport(medium) {
			height: 75px;
			margin-top: 30px;
		}

		@include viewport(large) {
			margin-top: 50px;
		}

		@include viewport(xlarge) {
			margin-top: 90px;
			margin-bottom: 75px;
		}
	}

	&-filterBar {
		padding-top: 20px;
		width: 100%;
		background-color: $white;
		z-index: 1;

		@include viewport(medium) {
			padding-top: 30px;
		}

		// The positioning with the navbar will be done using javascript
		// and transform: translate, because it has better performance.
		&.is-stick {
			box-shadow: 0 4px 7px $white, 0 9px 11px $white;
			position: fixed;
			top: 0;
			transition: transform 0.5s;
		}

		&.is-stickAtBottom {
			box-shadow: none;
			bottom: 0;
			top: auto;
			position: absolute;
			// Needed to overwrite the js functionality
			transform: translateY(0) !important;

			@include viewport(medium) {
				bottom: 20px;
			}

			@include viewport(large) {
				bottom: -55px;
			}

			@include viewport(xlarge) {
				bottom: -100px;
			}
		}
	}

	&-filterBarContent {
		align-items: center;
		border-top: 1px solid $gray3;
		display: flex;
		justify-content: space-between;
		padding-top: 15px;
		padding-bottom: 10px;
	}

	&-filterBarInlineLabel {
		display: inline-block;

		&.is-hidden {
			display: none;
		}
	}

	&-filterBarLabel {
		font-size: 14px;
		color: $gray2;
		font-style: italic;

		&.is-hidden {
			display: none;
		}
	}

	&-filterBarLabelText {
		text-transform: capitalize;

		&.is-hidden {
			display: none;
		}
	}

	&-container {
		// Remove margin-bottom of the last row
		margin-bottom: -60px;

		@include viewport(medium) {
			display: flex;
			flex-wrap: wrap;
			// Remove margin-bottom of the last row
			margin-bottom: -120px;
		}

		@include viewport(large) {
			// Remove margin-bottom of the last row
			margin-bottom: -60px;
		}

		&::before {
			// Make sure Safari renders the last element of the first row
			content: none;
		}
	}

	&-item {
		margin: 0 auto 60px;
		width: get-columns-fixed-unit(10, small);

		&.is-hidden {
			display: none;
		}

		@include viewport(medium) {
			display: flex;
			width: get-columns-fixed-unit(6, medium);
			margin: 0 15px 120px;
		}

		@include viewport(large) {
			width: get-columns-fixed-unit(4, large);
			margin-bottom: 60px;
		}

		@include viewport(xlarge) {
			width: get-columns-fixed-unit(3, xlarge);
		}
	}

	&-overlayTitle {
		margin-top: 10px;
		margin-bottom: 0;
		padding-bottom: 40px;

		@include viewport(xlarge) {
			padding-bottom: 70px;
		}
	}

	&-CTA {
		display: block;

		&:not(:last-child) {
			margin-right: 35px;

			@include rtl {
				margin-right: 0;
				margin-left: 35px;
			}
		}
	}

	&-overlayBottomBar {
		align-items: center;
		background-color: $white;
		bottom: 0;
		box-shadow: 0 -1px 8px rgba($black, 0.3);
		display: flex;
		justify-content: space-between;
		left: 50%;
		max-width: $containerWidth-xl;
		transform: translateX(-50%);
		padding: 10px 15px;
		position: fixed;
		width: 100%;

		&--dark {
			background-color: $black;
		}

		@include viewport(medium) {
			box-shadow: none;
			justify-content: flex-end;
			padding: 30px;
		}
	}

	&-overlayContainer {
		height: calc(100vh - 59px);
		overflow-y: auto;
		padding: 55px 15px 0;

		@include viewport(medium) {
			height: calc(100vh - 99px);
		}

		@include viewport(xlarge) {
			padding-top: 90px;
		}

		&::before {
			background-color: $white;
			box-shadow: 0 0 7px 4px $white, 0 0 15px $white;
			content: '';
			display: block;
			height: 55px;
			position: fixed;
			width: 100%;
			top: 0;
			z-index: 1;

			@include viewport(medium) {
				right: 0;
				width: 55px;
			}
		}

		&--dark {
			&::before {
				background-color: $black;
				box-shadow: 0 0 7px 4px $black, 0 0 15px $black;
			}
		}
	}

	&-filtersTitle {
		display: none;
		margin: 0;

		@include viewport(medium) {
			display: block;
		}
	}

	&-filtersTitleFragment {
		display: inline-block;
	}

	&-accordion {
		margin-left: 0;
		margin-right: 0;

		@include viewport(medium) {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	&-accordionItem {
		line-height: 1;
		padding: 0;
		border: none;
		margin: 0;

		@include viewport(medium) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&-accordionHeading {
		width: 100%;
		border-top: 1px solid $gray3;

		@include viewport(medium) {
			border-top: 0;
			margin-bottom: 25px;
		}

		@include viewport(xlarge) {
			margin-bottom: 40px;
		}
	}

	&-accordionCTA {
		background: transparent;
		border: 0;

		&:hover {
			color: $black;
		}

		&--dark {
			color: $white;
		}

		@include viewport(medium) {
			display: none;
		}
	}

	&-accordionItemTitle {
		font-size: 18px;

		@include serifItalic;
		font-style: italic;
		letter-spacing: 1px;
		text-transform: none;
		line-height: 1.4;
		padding-top: 35px;
		padding-bottom: 30px;

		&::after {
			font-size: 11px;
		}
	}

	&-accordionItemRegion {
		padding-bottom: 40px;

		@include viewport(medium) {
			overflow: visible;
		}
	}

	&-checkbox {
		&:not(:last-child) {
			margin-bottom: 30px;

			@include viewport(xlarge) {
				margin-bottom: 40px;
			}
		}

		.Checkbox-label {
			@include sansRegular;
			font-size: 12px;
			text-transform: uppercase;
			line-height: 1.6;
			letter-spacing: 3px;
		}

		.Checkbox-input:checked ~ .Checkbox-label {
			font-weight: bold;
		}
	}
}

.DestinationCard {
	position: relative;
	background-color: $gray4;
	flex-grow: 1;

	&-image {
		margin-bottom: 30px;
	}

	&-textBox {
		text-align: center;
		padding: 0 10px;
	}

	&-description {
		position: relative;
		margin-bottom: 0;
		margin-top: 30px;
		padding: 0 15px 160px;

		&::before {
			content: '';
			position: absolute;
			border-top: 1px solid $black;
			left: calc(50% - 15px);
			width: 30px;
			top: -18px;
		}

		@include viewport(medium) {
			padding-bottom: 137px;
		}

		@include viewport(large) {
			padding-bottom: 157px;
		}

		@include viewport(xlarge) {
			padding-bottom: 197px;
		}
	}

	&-offerCta {
		position: absolute;
		bottom: 30px;
		transform: translateX(-50%);
		left: 50%;

		@include viewport(medium) {
			bottom: 40px;
		}

		@include viewport(xlarge) {
			bottom: 70px;
		}

		.DestinationCard--specific & {
			background-color: $gray4;

			&:hover,
			&:focus {
				background-color: $black;
			}

			@include viewport(medium) {
				bottom: 30px;
			}

			@include viewport(xlarge) {
				bottom: 60px;
			}
		}
	}
}
