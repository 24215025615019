@font-face {
	font-family: 'Monotype Garamond';
	src: url('/alt/fshr/design3/fonts/2facc835-78c9-4456-b5dc-807374ccbaa4.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/e9de9cfc-1efd-400f-a2d6-e934b42180ee.woff') format('woff'),
		url('/alt/fshr/design3/fonts/639a7000-1f6f-4e79-968c-03640a20f45f.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Monotype Garamond';
	src: url('/alt/fshr/design3/fonts/b25db2e7-6a38-4599-9f7a-9672078d6278.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/2169b5c6-dae9-4259-85d0-044dee7be22e.woff') format('woff'),
		url('/alt/fshr/design3/fonts/27a7d804-99f3-4851-b7e0-c4c8d85abca2.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Neue Helvetica';
	src: url('/alt/fshr/design3/fonts/18fbcb9a-96e4-4820-8f60-312aadce6d35.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/e9722702-4fb8-436a-9342-c5f4f5c3a75d.woff') format('woff'),
		url('/alt/fshr/design3/fonts/0cadd069-b191-4897-b28a-a7df7a06a582.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Helvetica';
	src: url('/alt/fshr/design3/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff') format('woff'),
		url('/alt/fshr/design3/fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Helvetica';
	src: url('/alt/fshr/design3/fonts/f350f87a-2ea2-408b-98bb-580827d1a634.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/0552ce48-950c-471f-b843-1afac814d259.woff') format('woff'),
		url('/alt/fshr/design3/fonts/df2262d6-48cc-478c-aed1-5ce7421b4cf4.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Cairo';
	src: url('/alt/fshr/design3/fonts/Cairo-Bold.eot');
	src: url('/alt/fshr/design3/fonts/Cairo-Bold.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/Cairo-Bold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/Cairo-Bold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/Cairo-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Cairo';
	src: url('/alt/fshr/design3/fonts/Cairo-Regular.eot');
	src: url('/alt/fshr/design3/fonts/Cairo-Regular.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/Cairo-Regular.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/Cairo-Regular.woff') format('woff'),
		url('/alt/fshr/design3/fonts/Cairo-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Cairo';
	src: url('/alt/fshr/design3/fonts/Cairo-ExtraLight.eot');
	src: url('/alt/fshr/design3/fonts/Cairo-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/Cairo-ExtraLight.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/Cairo-ExtraLight.woff') format('woff'),
		url('/alt/fshr/design3/fonts/Cairo-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Cairo';
	src: url('/alt/fshr/design3/fonts/Cairo-Black.eot');
	src: url('/alt/fshr/design3/fonts/Cairo-Black.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/Cairo-Black.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/Cairo-Black.woff') format('woff'),
		url('/alt/fshr/design3/fonts/Cairo-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Cairo';
	src: url('/alt/fshr/design3/fonts/Cairo-SemiBold.eot');
	src: url('/alt/fshr/design3/fonts/Cairo-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/Cairo-SemiBold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/Cairo-SemiBold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/Cairo-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Cairo';
	src: url('/alt/fshr/design3/fonts/Cairo-Light.eot');
	src: url('/alt/fshr/design3/fonts/Cairo-Light.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/Cairo-Light.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/Cairo-Light.woff') format('woff'),
		url('/alt/fshr/design3/fonts/Cairo-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/alt/fshr/design3/fonts/OpenSans-Light.eot');
	src: url('/alt/fshr/design3/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/OpenSans-Light.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/OpenSans-Light.woff') format('woff'),
		url('/alt/fshr/design3/fonts/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/alt/fshr/design3/fonts/OpenSans-Bold.eot');
	src: url('/alt/fshr/design3/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/OpenSans-Bold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/OpenSans-Bold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/OpenSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/alt/fshr/design3/fonts/OpenSans-Regular.eot');
	src: url('/alt/fshr/design3/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/OpenSans-Regular.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/OpenSans-Regular.woff') format('woff'),
		url('/alt/fshr/design3/fonts/OpenSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/alt/fshr/design3/fonts/OpenSans-Italic.eot');
	src: url('/alt/fshr/design3/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/OpenSans-Italic.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/OpenSans-Italic.woff') format('woff'),
		url('/alt/fshr/design3/fonts/OpenSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

// Nanum font for Korean
@font-face {
	font-family: 'NanumGothic';
	src: url('/alt/fshr/design3/fonts/NanumGothic.eot');
	src: url('/alt/fshr/design3/fonts/NanumGothic.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/NanumGothic.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NanumGothic.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NanumGothic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NanumGothic';
	src: url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.eot');
	src: url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'NanumGothic';
	src: url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.eot');
	src: url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NanumGothic-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

// noto_sans for Traditional Chinese

@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: 300;
	src: url('/alt/fshr/design3/fonts/NotoSansTC-Light.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansTC-Light.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansTC-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: normal;
	src: url('/alt/fshr/design3/fonts/NotoSansTC-Regular.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansTC-Regular.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansTC-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans TC';
	font-style: normal;
	font-weight: bold;
	src: url('/alt/fshr/design3/fonts/NotoSansTC-Bold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansTC-Bold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansTC-Bold.otf') format('opentype');
}

// Simplified Chinese
// noto_sans for Simplified Chinese
@font-face {
	font-family: 'Noto Sans SC';
	font-style: normal;
	font-weight: 300;
	src: url('/alt/fshr/design3/fonts/NotoSansSC-Light.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansSC-Light.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansSC-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans SC';
	font-style: normal;
	font-weight: normal;
	src: url('/alt/fshr/design3/fonts/NotoSansSC-Regular.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansSC-Regular.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansSC-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans SC';
	font-style: normal;
	font-weight: bold;
	src: url('/alt/fshr/design3/fonts/NotoSansSC-Bold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansSC-Bold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansSC-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: 300;
	src: url('/alt/fshr/design3/fonts/NotoSansJP-Light.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansJP-Light.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansJP-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: normal;
	src: url('/alt/fshr/design3/fonts/NotoSansJP-Regular.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansJP-Regular.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansJP-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Noto Sans Japanese';
	font-style: normal;
	font-weight: bold;
	src: url('/alt/fshr/design3/fonts/NotoSansJP-Bold.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/NotoSansJP-Bold.woff') format('woff'),
		url('/alt/fshr/design3/fonts/NotoSansJP-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Saol Display';
	src: url('/alt/fshr/design3/fonts/SaolDisplay-Light.eot');
	src: url('/alt/fshr/design3/fonts/SaolDisplay-Light.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/SaolDisplay-Light.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/SaolDisplay-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Saol Display';
	src: url('/alt/fshr/design3/fonts/SaolDisplay-LightItalic.eot');
	src: url('/alt/fshr/design3/fonts/SaolDisplay-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/SaolDisplay-LightItalic.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/SaolDisplay-LightItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Saol Standard';
	src: url('/alt/fshr/design3/fonts/SaolStandard-Light.eot');
	src: url('/alt/fshr/design3/fonts/SaolStandard-Light.eot?#iefix') format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/SaolStandard-Light.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/SaolStandard-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Saol Standard';
	src: url('/alt/fshr/design3/fonts/SaolStandard-LightItalic.eot');
	src: url('/alt/fshr/design3/fonts/SaolStandard-LightItalic.eot?#iefix')
			format('embedded-opentype'),
		url('/alt/fshr/design3/fonts/SaolStandard-LightItalic.woff2') format('woff2'),
		url('/alt/fshr/design3/fonts/SaolStandard-LightItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'EB Garamond';
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7e8QL99U6g.woff2)
		format('woff2');
}
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGUmQSNjdsmc35JDF1K5GR1SDk.woff2)
		format('woff2');
}
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/ebgaramond/v26/SlGUmQSNjdsmc35JDF1K5GR1SDk.woff2)
		format('woff2');
}
@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 200;
	src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2)
		format('woff2');
}
@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2)
		format('woff2');
}
@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2)
		format('woff2');
}

$typographyStyles: 'h1', 'h2', 'h3', 'h4', 'b1', 'b2', 'b3', 'q1', 'q2', 'c1', 'c2', 'c3', 'c4',
	'cc1', 'cc2', 'cc3', 'cc4';

@each $style in $typographyStyles {
	.ty-#{$style} {
		@include typography($style);
	}
}
