.FullBleedGallery {
	position: relative;
	overflow: hidden;

	&-gallery {
		position: absolute;
		right: 15px;
		top: 15px;

		@include rtl {
			right: unset;
			left: 15px;
		}

		@include viewport(medium) {
			right: 30px;
			top: 30px;

			@include rtl {
				right: unset;
				left: 30px;
			}
		}

		@include viewport(large) {
			right: 40px;
			top: 40px;

			@include rtl {
				right: unset;
				left: 40px;
			}
		}
	}

	&-backgroundImage {
		position: relative;
		display: block;
		width: 100%;
		height: calc(100vw * (4 / 3));
		position: relative;

		@include viewport(medium) {
			height: calc(100vw * (1000 / 1920));
			margin-bottom: 0;
		}
	}
}
