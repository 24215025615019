.travelAdvisories {
	width: calc(100vw - 30px);
	margin: auto;
	min-height: 850px;
	@include viewport(medium) {
		width: 690px;
	}
	@include viewport(large) {
		width: 960px;
	}
	.accordion-body {
		border-top: 1px solid $gray3;
	}
	.travelAdvisoriesSvelte {
		min-height: 500px;
	}
	.travelAdvisoriesSvelte .travelAdvisoriesComponent {
		min-height: 500px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);

		.travelAdvisoriesSearchArea {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 60px 20px;
			@include viewport(medium) {
				padding: 50px 30px;
			}
			@include viewport(large) {
				padding: 50px 80px;
			}
			.heading {
				text-align: center;
			}
			.searchOptions {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				@include viewport(medium) {
					column-gap: 30px;
				}
				.searchOption {
					flex-basis: 100%;
					@include viewport(medium) {
						flex-basis: calc(50% - 15px);
					}
				}
			}
		}
		hr {
			margin-top: 0;
			margin-bottom: 30px;
			border: 1px solid #cacaca;
			width: 100%;
		}
	}

	.RadioButtons {
		display: flex;
		gap: 10px 60px;
		justify-content: center;
		.RadioButtons-button:not(:last-child) {
			margin: 0;
		}
	}
	&-title {
		text-align: center;
		margin-bottom: 30px;
		letter-spacing: em(4, 32);

		@include viewport(large) {
			font-size: rem(32, 16);
			line-height: em(47, 32);
		}
	}
	.travel-checkbox {
		align-self: flex-start;
	}
	.viewRegulations.CTA {
		width: 100%;
		@include viewport(medium) {
			width: 368px;
		}
	}
	.CTA[disabled] {
		opacity: 0.7;
	}
	&-notice {
		display: flex;
		padding-top: 35px;
		flex-direction: column;
		&-top {
			display: flex;
			justify-content: space-between;
		}
		&-important {
			order: 0;
			@include rtl {
				order: 1;
			}
		}
		&-powered {
			order: 1;
			@include rtl {
				order: 0;
			}
		}
		a {
			color: black;
		}
	}
	.LoadingIndicator {
		background: transparent;
	}
	.modal-header {
		display: flex;
		position: relative;
		button {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			align-self: flex-start;
		}
		.header-text {
			width: 100%;
			text-align: center;
			h1 {
				font-size: 28px;
				line-height: 42px;
				letter-spacing: 4px;
			}
			h3 {
				font-size: 16px;
				line-height: 21px;
				letter-spacing: 4px;
			}
		}
	}
	.checkVaccinated {
		background-color: $gray4;
		border: 1px solid $gray3;
		margin-top: 10px;
		margin-bottom: 20px;
		.Accordion-header {
			button.Accordion {
				background-color: $gray4;
				border: none;
				justify-content: start;

				.label {
					@include typography(b3);
					background-color: $gray4;
					text-transform: none;
					font-size: 18px;
					margin-top: 14px;
					margin-bottom: 14px;
					margin-left: 25px;
					margin-right: 10px;
					width: fit-content;
					white-space: normal;
					@include viewport(medium) {
						display: inline;
					}
				}
			}
			span.icon {
				margin-top: 14px;
				margin-bottom: 14px;
				margin-left: 20px;
			}
		}
	}
	.pagination .PaginationComponent {
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		padding: 0 60px 25px;
		@include viewport(medium) {
			padding: 0 50px 30px;
		}
		@include viewport(large) {
			padding: 0 50px 70px;
		}
		.pagination-filters {
			display: flex;
			gap: 10px;
			padding-top: 30px;
			padding-bottom: 30px;
			justify-content: space-between;
			margin-left: 10px;
			margin-right: 10px;
			flex-wrap: wrap;
			@include viewport(medium) {
				gap: 20px;
				margin-left: -20px;
				margin-right: -20px;
				flex-wrap: nowrap;
			}
			@include viewport(large) {
				gap: 30px;
			}
			.pagination-filter {
				font-weight: 400;
				font-size: 12px;
				flex-basis: 100%;
				@include viewport(medium) {
					flex-basis: auto;
				}
				&.active {
					font-weight: 700;
				}
			}
		}
		.pagination-nav {
			margin-top: 35px;
		}
		.items {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			@include viewport(medium) {
				justify-content: space-between;
			}
			gap: 30px;
		}
		hr {
			margin-left: -50px;
			margin-right: -50px;
			width: calc(100% + 100px);
		}
	}
	.IndicatorColor {
		display: inline-block;
		height: 12px;
		width: 12px;
		min-width: 12px;
	}

	.IndicatorColor--sm {
		height: 8px;
		width: 8px;
		min-width: 8px;
	}
	&-noDataNoticeText {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 50px;
		width: 100%;
		text-align: center;
		max-width: 400px;
		@include viewport(medium) {
			@include typography(b2);
			max-width: 500px;
		}
		@include viewport(large) {
			@include typography(b1);
			max-width: 600px;
		}
	}
}
