.FeaturedRentals {
	&-rentals {
		position: relative;
		overflow: hidden;

		@include viewport(medium) {
			display: flex;
			flex-wrap: wrap;
		}

		&::before {
			content: none;
		}

		&--noTruncated {
			// Remove the margin of the last row
			margin-bottom: -60px;
		}
	}

	&-ctaMoreWrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 350px;

		&::before {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				to bottom,
				transparentize($white, 1) 0,
				transparentize($white, 0) 85%,
				$white 100%
			);
		}

		.BodySection--dark & {
			&::before {
				background: linear-gradient(
					to bottom,
					transparentize($black, 1) 0,
					transparentize($black, 0) 85%,
					$black 100%
				);
			}
		}

		&--hidden {
			display: none;
		}
	}

	&-ctaBtn {
		position: absolute;
		height: 39px;
		bottom: 5px;
		right: 50%;
		transform: translateX(50%);

		&--more {
			position: relative;
			width: 100%;
			margin-right: 15px;

			&::after {
				@include icon('icon-plus_sign');
				font-size: 8px;
				position: absolute;
				padding-left: 7px;
			}

			@include rtl {
				margin-right: 0;
				margin-left: 15px;

				&::after {
					padding-left: 0;
					padding-right: 7px;
				}
			}
		}
	}

	&-heading {
		text-align: center;
	}

	&-headingTitle {
		margin-top: 0;
		margin-bottom: 40px;

		@include viewport(medium) {
			margin-bottom: 60px;
		}
	}

	&-rentalCard {
		position: relative;
		margin-bottom: 60px;

		@include viewport(medium) {
			padding-bottom: 15px;
		}
	}

	&-rentalProperty {
		margin-top: 0;
		margin-bottom: 10px;
	}

	&-rentalName {
		margin-top: 0;
		margin-bottom: 20px;
	}

	&-rentalDescription {
		margin-top: 0;
		margin-bottom: 20px;
	}

	&-rentalCta {
		display: inline-block;

		@include viewport(medium) {
			position: absolute;
			bottom: 0;
		}
	}

	&-rentalCarousel {
		position: relative;
		margin-bottom: 20px;

		@include viewport(large) {
			&:hover {
				.FeaturedRentals {
					&-prevButton,
					&-nextButton {
						transform: translateX(0);
					}
				}
			}
		}
	}

	&-rentalCarouselList {
		overflow: hidden;
	}

	&-rentalItemCarousel {
		position: relative;
		overflow: hidden;
		width: 100%;

		.FeaturedRentals-rentalImage::after {
			content: '';
			position: relative;
			background: linear-gradient(to bottom, transparent 54%, $black 110%);
			width: 102%;
			top: 2px;
			left: -3px;
		}

		@include rtl {
			.FeaturedRentals-rentalImage::after {
				left: 4px;
			}
		}
	}

	&-rentalItem360 {
		.FeaturedRentals-rentalImage::after {
			content: '';
			position: relative;
			background: rgba(0, 0, 0, 0.45);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		@include rtl {
			.FeaturedRentals-rentalImage::after {
				left: 0;
			}
		}
	}

	&-rental360Cta {
		position: absolute;
		bottom: 50%;
		right: 50%;
		transform: translate(50%, 50%);
		border-color: $white;
	}

	&-rentalButtons {
		display: none;
		position: absolute;
		overflow: hidden;
		padding: 10px;
		width: 100%;
		bottom: 0;

		@include viewport(large) {
			display: block;
		}
	}

	&-prevButton,
	&-nextButton {
		border: none;
		background-color: transparent;
		transition: transform 0.3s ease;
	}

	&-prevButton {
		position: relative;
		float: left;
		padding-top: 5px;
		padding-left: 5px;
		z-index: 1;
		transform: translateX(-125%);

		@include rtl {
			float: right;
			padding-right: 5px;
			padding-left: inherit;
			transform: translateX(125%);
		}
	}

	&-nextButton {
		position: relative;
		float: right;
		padding-top: 5px;
		padding-right: 5px;
		transform: translateX(125%);

		@include rtl {
			float: left;
			padding-left: 5px;
			padding-right: inherit;
			transform: translateX(-125%);
		}
	}

	&-prevButtonIcon {
		&::before {
			color: $white;

			@include icon('icon-chevron_left');
		}

		@include rtl {
			&::before {
				@include icon('icon-chevron_right');
			}
		}
	}

	&-nextButtonIcon {
		&::before {
			color: $white;

			@include icon('icon-chevron_right');
		}

		@include rtl {
			&::before {
				@include icon('icon-chevron_left');
			}
		}
	}

	&--arrowTabActive {
		transform: translateX(0);

		// The fix is necessary that the specificity in RTL
		// work correctly for the animation of the carousel arrows.
		@include rtl {
			transform: translateX(0);
		}
	}
}
