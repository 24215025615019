.HeadingAndImage {
	&-heading {
		@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);
		text-align: center;

		.Heading-title {
			margin-bottom: 40px;
			position: relative;

			&::after {
				@include viewport(medium) {
					left: calc(50% - (40px / 2));
					width: 40px;
				}

				border-bottom: 2px solid $white;
				bottom: -20px;
				content: '';
				left: calc(50% - (30px / 2));
				position: absolute;
				width: 30px;
			}
		}
	}

	&-image {
		.Image {
			display: block;
			font-size: 0;
		}
	}

	.VideoOverlay {
		.Gallery-button {
			margin-top: 20px;

			@include viewport(xlarge) {
				margin-top: 30px;
			}
		}
	}
}
