.HideSelectStatic {
	.CardCarousel-item .ProductCard-react-ssr-rendered {
		width: 100%;
	}
}

.SquareImageCard {
	width: 100%;
	background-color: white;
	font-family: 'Helvetica Neue', helvetica, sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 22.4px */
	letter-spacing: 0.48px;
	position: relative;

	&-Details {
		display: flex;
		padding-top: 13px;
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
		align-self: stretch;
	}

	.BodySection--dark & {
		background-color: black;
	}

	&-link {
		text-decoration: none;
		color: var(--color-text-secondary, #4d4d4d);
	}

	&-image {
		position: relative;
		left: 0px;
		top: 0px;
		overflow: hidden;
	}

	&-image img {
		transition: 0.5s all ease-in-out;
	}

	&-image:hover img {
		transform: scale(1.1);
	}

	&-eyebrowContainer {
		margin-top: 20px;
		line-height: 0;
	}

	&-featured-tag {
		color: var(--color-text-primary, #1f1f1f);
		font-feature-settings: 'clig' off, 'liga' off;
		position: absolute;
		z-index: 8;
		left: 4px;
		top: 4px;
		background: var(--color-surface-default, #fff);
		/* blur/heavy-30 */
		backdrop-filter: blur(15px);
		padding: var(--spacing-040, 4px) var(--spacing-060, 6px);
		@include viewport(medium) {
			left: 6px;
			top: 6px;
			padding: var(--container-padding-x-small, 6px) var(--container-padding-small, 10px);
		}
	}

	&-title {
		/* Inside auto layout */
		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 0;

		&.has-eyebrows {
			margin-top: 10px;
		}
		&.sm-gap {
			margin-bottom: 0px;
		}
	}

	&-icon-subtitleListItem,
	&-icon-eyebrowListItem {
		display: inline-flex;
		margin: 0 10px 0 0;

		@include rtl {
			margin: 0 0 0 10px;
		}
	}

	&-icon-eyebrowListItem {
		.TallImageCard-icon {
			font-size: 18px;
			line-height: 12px;

			@include viewport(large) {
				line-height: 16px;
			}
		}
	}

	&-icon {
		position: relative;
		margin: 0 8px 0 0;

		@include rtl {
			margin: 0 0 0 8px;
		}

		&-subtitle {
			font-size: 14px;
			line-height: 18px;
			padding-top: 3px;
			max-width: 95%;

			/* identical to box height, or 129% */
			letter-spacing: 1.5px;

			/* 00. Grayscale/Black */
			color: #000000;

			/* Inside auto layout */
			flex: none;
			order: 1;
			flex-grow: 0;

			.BodySection--dark & {
				color: white;
			}
		}

		&-eyebrow {
			font-size: 10px;
			line-height: 12px;
			max-width: 95%;

			@include viewport(large) {
				font-size: 12px;
				line-height: 16px;
			}

			/* identical to box height, or 129% */
			letter-spacing: 3px;

			/* 00. Grayscale/Black */
			color: #000000;

			/* Inside auto layout */
			flex: none;
			order: 1;
			flex-grow: 0;

			.BodySection--dark & {
				color: white;
			}
		}
	}

	&-description {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%; /* 22.4px */
		letter-spacing: 0.48px;

		/* Inside auto layout */
		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;

		.BodySection--dark & {
			color: white;
		}
	}

	&-callout {
		/* Inside auto layout */
		flex: none;
		order: 0;
		flex-grow: 0;

		color: var(--color-text-secondary, #4d4d4d);
	}

	&-qualifier,
	&-note {
		color: var(--color-text-secondary, #4d4d4d);

		/* Inside auto layout */
		flex: none;
		order: 1;
		flex-grow: 0;
	}

	&-CTA {
		margin-top: 15px;
		display: inline-block;
	}

	&-cardsContainer {
		display: block;
	}
	&-icon {
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		letter-spacing: 0.5px;
	}

	.availMessage {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 33px;
		background: var(--color-img-overlay-60, rgba(3, 3, 3, 0.6));
		backdrop-filter: blur(15px);
		padding: 6px 4px;
		color: var(--color-text-on-image-primary, #fff);

		&__message {
			font-size: 14px;
		}
	}

	.Image-ratioContainer {
		aspect-ratio: 1/1;
		&::after {
			padding-bottom: calc(100% / 0.8);
		}

		.Image-img {
			object-fit: cover;
		}
	}
}
