.WarningModal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	display: none;
	z-index: 10;

	@include viewport(medium) {
		overflow: auto;
	}

	&--opened {
		pointer-events: initial;
	}

	&-closeCTA {
		position: absolute;
		top: 0;
		right: 0;
		width: 20px;
		height: 20px;
		margin-top: 20px;
		margin-right: 20px;
		transition: color 0.3s;

		&::before {
			line-height: initial;
		}

		@include viewport(medium) {
			width: 14px;
			height: 14px;

			&::before {
				font-size: 14px;
			}
		}

		@include rtl {
			left: 0;
			right: auto;
			margin-left: 20px;
			margin-right: 0;
		}
	}

	&-background {
		background-color: rgba($black, 0.7);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&-content {
		background-color: $white;
		color: $black;
		height: 100%;
		text-align: center;
		padding: 76px 15px 0;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;

		@include viewport(medium) {
			height: auto;
			top: 120px;
			margin: 0 40px;
			padding: 70px 60px;
		}

		@include viewport(large) {
			margin: 0 130px;
			padding: 70px 80px;
		}

		@include viewport(xlarge) {
			margin: 0 455px;
			padding: 70px 100px;
		}

		&-withImage {
			padding: 0;
			overflow-y: scroll;
			width: 100%;
			@include viewport(medium) {
				top: 0;
				margin: 0;
			}
			@include viewport(large) {
				top: 120px;
				width: 930px;
				height: 600px;
				margin: auto;
				left: 50%;
				transform: translateX(-50%);
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				overflow-y: auto;
			}

			&::after {
				content: '';
				display: block;
				height: 85px;
				position: fixed;
				bottom: 0;
				width: 100%;
				background: linear-gradient(0deg, $white 18.49%, rgba(255, 255, 255, 0) 98.62%);

				@include viewport(large) {
					content: none;
				}
			}

			.WarningModal {
				&-textContent {
					margin-top: calc(9 / 16 * 100vw);
					padding: 30px 15px 85px;
					display: flex;
					flex-direction: column;

					@include viewport(large) {
						width: 57%;
						margin-top: 0;
						padding: 65px 40px 26px;
					}
				}

				&-landscapeImage {
					display: block;
					position: fixed;
					z-index: 1;
					&::after {
						position: relative;
						background: linear-gradient(180deg, $black 0%, rgba(0, 0, 0, 0) 39.34%);
					}
					@include viewport(large) {
						display: none;
					}
				}

				&-portraitImage {
					display: none;
					@include viewport(large) {
						display: block;
						width: 43%;
					}
				}

				&-textContainer {
					margin-bottom: 0;
					padding: 0;

					@include viewport(large) {
						overflow-y: auto;
					}
				}

				&-title {
					&::after {
						width: 100px;
						margin-bottom: 30px;
					}
				}

				&-headline {
					margin: 0 15px;
					@include viewport(large) {
						margin: 0 60px;
					}
				}

				&-cta {
					margin: 20px auto 30px;
					border: 1px solid $black;
				}

				&-text {
					font-size: rem(14, 16);
					letter-spacing: em(0.5, 14);
					line-height: em(21, 14);
				}

				&-closeCTA {
					position: fixed;
					z-index: 2;

					width: 20px;
					height: 20px;
					margin-top: 26px;
					margin-right: 26px;
					&::before {
						font-size: 20px;
						color: $white;
					}

					@include viewport(large) {
						position: absolute;
						&::before {
							color: $black;
						}
					}
				}
			}
		}
	}

	&-title {
		display: inline-block;

		&::after {
			background-color: currentColor;
			content: '';
			display: block;
			width: 30px;
			height: 1px;
			margin: 20px auto;
		}
	}

	&-textContainer {
		position: relative;
		overflow: hidden;
		margin-bottom: 5vh;

		&--scrollable {
			&::after {
				@include gradient2transparent($color: $white, $height: 83px);
				bottom: 0;
				content: '';
				display: block;
				position: absolute;
			}
		}

		@include viewport(medium) {
			margin-bottom: 50px;
		}
	}

	&-text {
		margin-top: 0;
		margin-bottom: 0;

		&--scrollable {
			padding-bottom: 50px;
			overflow-y: scroll;
		}
	}

	&-icon {
		display: inline-block;
		font-size: 42px;
		line-height: 42px;
		margin-bottom: 15px;
		width: 100%;
	}

	&-eyebrow {
		margin-bottom: 5px;
	}

	&-headline {
		margin-bottom: 20px;
	}

	&-cta {
		margin: auto;
	}

	&-content-nestedList {
		padding: 70px 40px 60px;
		text-align: left;
		overflow-y: auto;
		height: auto;
		max-height: 95%;
		width: 100%;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);

		@include rtl {
			text-align: right;
		}

		@include viewport(medium) {
			width: 375px;
			margin: auto;
		}

		@include viewport(large) {
			width: 520px;
			margin: auto;
		}

		& .WarningModal-closeCTA {
			margin-right: 40px;

			@include rtl {
				margin-left: 40px;
				margin-right: 0;
			}
		}

		& .WarningModal-title {
			&::after {
				display: none;
			}

			@include rtl {
				text-align: right;
			}
		}

		& .WarningModal-listitem-container {
			&::before {
				background-color: rgb(0, 0, 0, 0.2);
				width: 100%;
				content: '';
				display: block;
				height: 1px;
				margin: 20px auto;
			}
		}

		& .WarningModal-nested-container {
			text-align: left;
			padding: 20px 0;
			display: block;

			@include rtl {
				text-align: right;
			}
		}

		& .WarningModal-nested-label {
			display: block;
			font-weight: 400;
			width: 50%;
			float: left;

			@include rtl {
				float: right;
			}
		}

		& .WarningModal-nested-value {
			display: block;
			width: 50%;
			text-align: right;
			float: left;

			@include rtl {
				text-align: left;
				float: right;
			}
		}
	}
}
