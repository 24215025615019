$MobileTabs-img-margin-bottom: 30px;
$MobileTabs-img-spacing: (
	small: 15px,
	medium: 20px,
	large: 30px,
);
$MobileTabs-cols: (
	small: 10,
	medium: 10,
	large: 12,
);
$MobileTabs-breakpoint: 375px;
$MobileTabs-phone-width--mobile: 273px;
$MobileTabs-phone-height--mobile: 556px;
$MobileTabs-phone-width--desktop: 335px;
$MobileTabs-phone-height--desktop: 697px;

.MobileTabs {
	position: relative;
	height: 810px;
	background-color: $white;
	background-size: cover;
	background-position: center;
	transition: background-image 1s ease-in;
	overflow: hidden;

	&-title {
		margin-bottom: 50px;
		text-align: center;

		@include media($MobileTabs-breakpoint) {
			margin-bottom: 60px;
		}

		.Heading-title {
			margin: 0 auto;
			margin-bottom: 30px;
			font-size: 32px;
			line-height: 39px;
			letter-spacing: 0.59px;
			font-weight: 100;
			text-transform: none;
		}

		.Heading-subtitle {
			margin-top: 32px;
		}
	}

	&-list {
		position: absolute;
		top: calc(50% + 50px);
		margin: 0;
		padding: 0;
		width: 100%;
		transform: translateY(-50%);
		list-style-type: none;

		&:lang(ja) {
			font-size: 1rem;
		}
	}

	&-phone {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		margin: auto;
		width: $MobileTabs-phone-width--mobile;
		height: $MobileTabs-phone-height--mobile;
		transform: translateY(-50%);
		pointer-events: none;
		box-shadow: 0 3px 45px 0 rgba(0, 0, 0, 0.12);

		@include media($MobileTabs-breakpoint) {
			width: $MobileTabs-phone-width--desktop;
			height: $MobileTabs-phone-height--desktop;
		}
	}

	&-phoneDiv {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		height: 710px;
		width: 347px;
		border-radius: 37px;
		box-shadow: inset 7px 6px 3px 2px #212121, inset 8px 7px 3px 9px rgba(103, 103, 103, 0.6),
			inset 0 0 2px 4px #1c1c1c, 0 0 1px 2px rgba(0, 0, 0, 0.3);
		overflow: hidden;
		pointer-events: none;
	}

	&-cutout {
		position: relative;
		margin: 0 auto;
		width: 100%;
		height: 810px;
		overflow: hidden;
		pointer-events: none;

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 0;
			left: 0;
			margin: auto;
			width: $MobileTabs-phone-width--mobile;
			height: $MobileTabs-phone-height--mobile;
			transform: translateY(-50%);
			border-radius: 35px;
			box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.63);

			@include media($MobileTabs-breakpoint) {
				width: $MobileTabs-phone-width--desktop - 2px;
				height: $MobileTabs-phone-height--desktop - 2px;
			}
		}
	}

	&-button {
		position: absolute;
		bottom: 0;
		right: 0;
	}
}
