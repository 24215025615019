.Accordion {
	&-item {
		border-top: 1px solid $black;
		padding: 32px 0 25px;
		position: relative;

		.BodySection--dark & {
			border-top: 1px solid $white;
		}

		&-button {
			@include grayHover('light', 'after');
			margin: 0;
			padding: 0;
			border: none;
			background-color: transparent;
			text-align: left;
			width: 100%;

			@include rtl {
				text-align: right;
			}

			.BodySection--dark & {
				@include grayHover('dark', 'after');
				color: $white;
			}
		}

		&-region {
			margin: 0;
			overflow: hidden;
		}

		&-title {
			display: flex;
			justify-content: space-between;
			margin: 0 0 7px;

			&::after {
				@include icon('icon-less_sign');
				font-size: 12px;
				display: block;
			}
		}

		&-description {
			margin: 18px 0 0;

			@include viewport(medium) {
				margin-bottom: 10px;
				padding-right: 40px;

				@include rtl {
					padding-right: 0;
					padding-left: 40px;
				}
			}

			@include viewport(large) {
				padding-right: 80px;

				@include rtl {
					padding-right: 0;
					padding-left: 80px;
				}
			}

			@include viewport(xlarge) {
				margin-bottom: 30px;
				padding-right: 130px;

				@include rtl {
					padding-right: 0;
					padding-left: 130px;
				}
			}
		}

		&--collapsed {
			.Accordion-item-title::after {
				@include icon('icon-plus_sign');
				font-size: 12px;
				position: relative;
				top: 2px;
			}
		}
	}
}
