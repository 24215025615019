.QuoteAndImage {
	&-image {
		margin-bottom: 60px;

		@include viewport(medium) {
			margin-bottom: 0;

			&--right {
				order: 2;
			}
		}
	}

	&-cta {
		padding-top: 46px;

		&--small {
			.BodySection--dark & {
				@include borderedButton($white, $black, $white);

				&:focus,
				&:hover {
					@include borderedButton($black, $white, $white);
				}
			}
		}
	}

	&-quote {
		text-align: center;

		.QuoteAndImage--withImage & {
			@include viewport(medium) {
				padding: 0 48px;
			}

			@include viewport(large) {
				padding: 0 16px;
			}
		}

		.Quote {
			&-text {
				margin: 0 0 30px;

				.BodySection--light & {
					@include quote(block, 'light');
				}

				.BodySection--dark & {
					@include quote(block, 'dark');
				}

				.QuoteAndImage--withImage & {
					line-height: 1.5em;
					font-size: 2rem;

					&::before {
						font-size: 20px;
						margin-bottom: 20px;
					}

					&::after {
						font-size: 20px;
						margin-top: 20px;
					}
				}
			}
		}

		.Attribution-author {
			display: block;
			margin-bottom: 6px;
		}

		.Attribution-role {
			display: block;

			&::before {
				content: none;
			}
		}
	}

	&-container {
		@include viewport(medium) {
			display: flex;
			align-items: center;
			flex-direction: row;
		}
	}
}
