.ImageAndTable {
	&-container {
		&--dark {
			background-color: $black;
			color: $white;
		}

		&--light {
			background-color: $white;
			color: $black;
		}
	}

	&-image {
		@include viewport(medium) {
			margin-bottom: 65px;
		}

		@include viewport(large) {
			margin-bottom: 0;
		}

		margin-bottom: 60px;

		.Attribution {
			text-align: left;

			@include rtl {
				text-align: right;
			}

			&-author {
				@include typography(c4);
			}
		}

		.Image {
			display: block;
			font-size: 0;
			line-height: 0;
			margin-bottom: 20px;

			img {
				width: 100%;
			}
		}
	}

	&-table {
		@include viewport(medium) {
			padding-bottom: 80px;
		}

		@include viewport(large) {
			padding-bottom: 0;
		}

		.TableList {
			&-items {
				margin: 0;

				.BodySection--light & {
					background-color: $white;
					color: $black;
				}
			}

			&-item {
				padding-bottom: 30px;

				@include viewport(xlarge) {
					padding-bottom: 40px;
				}
			}

			&-container {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&-ctas {
		@include column(10);
		@include push(1);
		display: flex;
		flex-direction: row;
		padding-top: 50px;
		text-align: center;

		@include viewport(medium) {
			@include column-reset();
		}

		@include viewport(large) {
			padding-top: 60px;
		}

		.CTA {
			flex-grow: 1;
			width: 0;

			&:not(:last-of-type) {
				margin-right: 15px;

				@include rtl {
					margin-left: 15px;
					margin-right: 0;

					@include viewport(medium) {
						margin-left: 30px;
					}
				}

				@include viewport(medium) {
					margin-right: 30px;
				}
			}
		}
	}
}
