.DetailedList {
	margin: 0 auto;

	&-title .Heading-title {
		margin: 0;
		text-align: center;

		@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);
	}

	&-term {
		border-top: 1px solid;
		padding: 30px 0 20px;

		.CTA {
			display: flex;
			justify-content: space-between;

			.BodySection--dark & {
				@include grayHover('dark');
				color: $white;
			}
		}
	}

	&-item {
		margin-top: 0;
		margin-bottom: 40px;

		@include viewport(medium) {
			margin-bottom: 30px;
		}

		@include viewport(large) {
			margin-bottom: 60px;
		}
	}

	&-definition {
		margin: 0;
		padding-right: 14px;

		@include viewport(medium) {
			padding-right: 20px;
		}

		@include rtl {
			padding-right: 0;
			padding-left: 14px;

			@include viewport(medium) {
				padding-left: 20px;
			}
		}

		&-text {
			margin: 0;
		}
	}
}
