.ArticlesListing {
	&-ArticleCard {
		margin-top: 80px;
		margin-bottom: 10px;

		.ImageAndCTABlockWithEyebrows {
			&-textWrapper {
				margin-top: 20px;

				.CtaBlock {
					margin-top: 20px;
					.CtaBlock-title {
						@include typography(b1);
						font-size: 1.5rem;
						letter-spacing: 0.025em;
						font-weight: 600px;
					}
				}
			}

			&-eyebrowWrapper {
				display: flex;
				align-items: baseline;
				flex-wrap: wrap;
			}

			&-eyebrow {
				@include typography(c1);
			}

			&-secondaryEyebrow {
				@include typography(c1);
				font-weight: 400;
			}

			&-bullet {
				@include typography(c2);
				margin-left: 10px;
				margin-right: 10px;
			}
		}
	}

	.PaginatedFilter-paginationContainer {
		margin-bottom: 80px;
	}

	.PaginatedFilter-pagination-navigation {
		border-top: 1px $black solid;
		padding-top: 80px;
		margin-top: 0;
	}
}
