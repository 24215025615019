.cardpanel-container {
	padding-left: 0;
	padding-right: 0;
}

.CardCarousel {
	&-heading {
		@include component-spacing($small: 20px, $medium: 40px, $large: 20px, $xlarge: 40px);
		text-align: center;

		.Heading-title {
			margin: 0;
			margin-bottom: 20px;
		}

		.Heading-text {
			margin-top: 0;
			margin-bottom: 20px;
			@include mediaRange($breakpoints-md, calc($breakpoints-lg - 1px)) {
				margin-bottom: 0;
			}
		}
	}

	&-item {
		width: calc(#{get-columns-vw(10, string)});
		margin: 0 7.5px;
		display: flex; // Used to align all column heights
		background-color: $gray4;

		@include viewport(medium) {
			width: get-columns-fixed-unit(6, medium);
			margin: 0 15px;
		}

		@include viewport(large) {
			width: get-columns-fixed-unit(4, large);
		}

		@include viewport(xlarge) {
			width: get-columns-fixed-unit(4, xlarge);
		}
	}

	&-parent {
		overflow: hidden;
	}

	&-row {
		&.flickity-enabled:focus {
			outline: revert;
		}

		position: relative;

		.flickity-viewport {
			left: calc(#{get-columns-vw(1, string)} + 15px);

			@include viewport(medium) {
				left: 0;
			}
		}

		.flickity-prev-next-button::before {
			content: '';
			position: absolute;
			top: 50%;
			height: 100%;
			right: 50%;
			transform: translateX(50%);
		}

		.flickity-prev-next-button.previous {
			@include grayHover('light');
			display: none;

			&::before {
				@include icon('icon-chevron_left');
			}

			@include rtl {
				&::before {
					@include icon('icon-chevron_right');
				}
			}

			@include viewport(medium) {
				display: block;
				left: -30px;
				transform: translateX(50%);

				@include rtl {
					left: auto;
					right: 0;
				}
			}

			@include viewport(large) {
				left: -30px;

				@include rtl {
					left: auto;
					right: 0;
				}
			}

			.BodySection--dark & {
				@include grayHover('dark');
				color: $white;
			}
		}

		.flickity-prev-next-button.next {
			@include grayHover('light');
			display: none;

			&::before {
				@include icon('icon-chevron_right');
			}

			@include rtl {
				&::before {
					@include icon('icon-chevron_left');
				}
			}

			@include viewport(medium) {
				display: block;
				right: 0;
				transform: translateX(50%);

				@include rtl {
					right: auto;
					left: -30px;
				}
			}

			@include viewport(large) {
				left: auto;
				right: 0;

				@include rtl {
					left: -30px;
					right: auto;
				}
			}

			.BodySection--dark & {
				@include grayHover('dark');
				color: $white;
			}
		}
	}

	&-card {
		text-align: center;
		display: flex; // Used to align column heights
		width: 100%;

		.Card-container {
			background-color: $gray4;
			width: 100%;

			.BodySection--dark & {
				background-color: $gray1;
			}
		}

		.Card-heading {
			color: $black;

			.Heading-title {
				@include headingBorder();
			}

			.BodySection--dark & {
				color: $white;
			}
		}

		.Card-body {
			padding: 18px 15px 60px;

			@include viewport(xlarge) {
				padding-top: 36px;
				padding-bottom: 130px;
			}

			.BodySection--dark & {
				background-color: $gray1;
			}
		}

		.Card-cta {
			display: inline-block;
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);

			.BodySection--dark & {
				@include grayHover('dark');
				color: $white;
				border-color: $white;
			}
		}
	}
}
