$buttonTypes: 'primary', 'secondary';
$buttonThemes: 'dark', 'light', 'greenPink', 'greenGreen', 'blueGreen', 'pinkRed', 'lightBlueGreen',
	'black', 'white', 'yellowOrange';

@each $type in $buttonTypes {
	@each $theme in $buttonThemes {
		.Button--#{$type}--#{$theme} {
			@include button($type, $theme);
		}
	}
}

$linkTypes: 'underlined', 'labeledPlay';

@each $type in $linkTypes {
	@each $theme in $buttonThemes {
		.Button--#{$type}--#{$theme} {
			@include link($type, $theme);
		}
	}
}

$chevronTypes: 'nextIcon', 'prevIcon';

@each $type in $chevronTypes {
	@each $theme in $buttonThemes {
		.Button--#{$type}--#{$theme} {
			@include chevron(if($type == 'nextIcon', 'right', 'left'), $theme);
		}
	}
}

$playButtonStates: 'play', 'pause';

@each $state in $playButtonStates {
	@each $theme in $buttonThemes {
		.Button--#{$state}Icon--#{$theme} {
			@include playButton($state, $theme);
		}
	}
}

$buttonIcons: 'close';

@each $icon in $buttonIcons {
	@each $theme in $buttonThemes {
		.Button--#{$icon}--#{$theme} {
			@include iconButton($icon: 'icon-close', $theme: $theme);
		}
	}
}

.Button--secondary--dark {
	height: 40px;
	&:disabled {
		cursor: default;
		background-color: $bhover;
		border-color: $bhover;

		&:hover {
			color: $white;
			background-color: $bhover;
			border-color: $bhover;
		}
	}
}
.button {
	@extend .labels-button-2;

	text-align: center;

	&-filled {
		border-radius: 5px;
		background: var(--color-button-primary-default, #fff);
		color: var(--color-button-primary-inverse, #000);
		padding: 10px 20px;

		&:hover {
			border-radius: 5px;
			background: var(--color-button-primary-hover, #e6e6e6);
		}

		&:focus {
			border-radius: 5px;
			background: var(--color-button-primary-pressed, #d8d8d8);
		}

		&:disabled {
			border-radius: 5px;
			background: var(--color-button-primary-disabled-default, #7d7d7d);
		}

		&-loading {
			border-radius: 5px;
			background: var(--color-button-primary-disabled-default, #7d7d7d);
			padding: 10px 20px;
		}
		&-image {
			border-radius: 5px;
			padding: 10px 20px;
			background: var(--color-button-on-image-primary-default, #fff);
			color: var(--color-button-on-image-primary-inverse-default, #000);
			&:hover {
				border-radius: 5px;
				background: var(--color-button-on-image-primary-hover, #e6e6e6);
			}

			&:focus {
				border-radius: 5px;
				background: var(--color-button-on-image-primary-pressed, #d8d8d8);
			}

			&:disabled {
				border-radius: 5px;
				background: var(--color-button-on-image-primary-disabled-default, #7d7d7d);
			}
		}
	}
	&-outline {
		padding: 10px 20px;
		border-radius: 5px;
		border: 1px solid var(--color-button-primary-default, #fff);

		&:hover {
			border-radius: 5px;
			border: 1px solid var(--color-button-primary-hover, #e6e6e6);
			background: var(--color-button-primary-ghost-hover, rgba(255, 255, 255, 0.04));
		}

		&:focus {
			border-radius: 5px;
			border: 1px solid var(--color-button-primary-pressed, #d8d8d8);
			background: var(--color-button-primary-ghost-pressed, rgba(255, 255, 255, 0.08));
		}

		&:disabled {
			border-radius: 5px;
			border: 1px solid var(--color-button-primary-disabled-default, #7d7d7d);
		}

		&-loading {
			padding: 10px 20px;
			border-radius: 5px;
			border: 1px solid var(--color-button-primary-disabled-default, #7d7d7d);
		}
	}
	&-ghost {
		padding: 10px 20px;
		border-radius: 5px;

		&:hover {
			border-radius: 5px;
			background: var(--color-button-primary-ghost-hover, rgba(255, 255, 255, 0.04));
		}

		&:focus {
			border-radius: 5px;
			background: var(--color-button-primary-ghost-pressed, rgba(255, 255, 255, 0.08));
		}
	}

	&-text {
		background: none;
		text-decoration: underline;
		text-underline-offset: 6px;

		&:hover {
			color: var(--color-button-primary-hover, #e6e6e6);
		}
		&:focus {
			color: var(--color-button-primary-pressed, #d8d8d8);
		}
		&:disabled {
			color: var(--color-button-primary-disabled-default, #7d7d7d);
		}
	}
	&-text-icon {
		display: flex;
		align-items: center;
		gap: 10px;
		background: none;

		&:hover {
			color: var(--color-button-primary-hover, #e6e6e6);
		}
		&:focus {
			color: var(--color-button-primary-pressed, #d8d8d8);
		}
		&:disabled {
			color: var(--color-button-primary-disabled-default, #7d7d7d);
		}
	}
	&-ghost,
	&-outline,
	&-outline-loading,
	&-filled,
	&-filled-loading,
	&-filled-image,
	&-text,
	&-text-icon {
		@extend .button;
	}
}

.divider {
	height: 1px;
	flex: 1 0 0;
	background: var(--color-border-default, rgba(255, 255, 255, 0.45));
	&-subtle {
		background: var(--color-border-subtle-default, rgba(255, 255, 255, 0.15));
		@extend .divider;
	}
}

.box-outline {
	flex: 1 0 0;
	align-self: stretch;
	border: 1px solid var(--color-border-default, rgba(255, 255, 255, 0.45));
	&-subtle {
		border: 1px solid var(--color-border-subtle-default, rgba(255, 255, 255, 0.15));
		@extend .box-outline;
	}
}
