.WorldwideSalesOffice {
	&.OverlayPage {
		.StandardHero-heading {
			.Heading-eyebrow {
				@include serifItalic;
			}

			.Heading-title {
				@include typography(h1);
				margin-top: 0;
				margin-bottom: 50px;

				@include viewport(large) {
					font-size: 44px;
				}
			}

			.Heading-text {
				@include typography(b1);
				margin: auto;
				max-width: 545px;
			}
		}
	}
}
