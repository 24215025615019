.EliteGuestWelcomeForm {
	.ContactForm {
		padding-top: 0;
		padding-bottom: 0;

		.CTA--primary--light {
			@include rtl {
				float: left;
			}
		}

		.ContactForm-SubmitCTA:disabled {
			background: $gray2;
			cursor: default;
			border: 1px solid $gray2;
		}

		.ContactForm-SubmitCTA:disabled:hover {
			color: $white;
		}
	}
}
