.RfpForm {
	.ContactForm {
		padding-top: 0;

		.RfpHeader {
			margin-bottom: 2em;
		}

		.RfpHeader-nomargin {
			margin-bottom: 0;
		}

		.CTA-light-on-light {
			border-color: $black;
		}

		.FunctionRooms {
			&-add {
				.icon {
					padding-left: 10px;
					padding-top: 1px;
				}
			}

			&-add-hide {
				display: none;
			}

			&-remove {
				text-align: center;
				margin-top: 15px;
				width: 100%;
				color: $gray2;

				&:hover {
					color: $black;
				}
			}

			&-additional {
				display: none;
			}

			&-additional-show {
				display: block;
			}
		}

		.ContactForm-SubmitCTA:disabled {
			background: $gray2;
			cursor: default;
			border: 1px solid $gray2;
		}

		.ContactForm-SubmitCTA:disabled:hover {
			color: $white;
		}
	}
}
