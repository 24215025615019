.ReactAccordion {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&-itemContainer {
		background: white;
		margin-bottom: 5px;
		width: 100%;
	}

	&-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		cursor: pointer;
		border-top: 1px solid #d8d8d8;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		@include viewport(large) {
			padding-bottom: 5px;
		}
		&.no-border {
			border: 0;
		}
		p {
			margin: 0;
		}
	}

	&-plusSign {
		width: 16px;
		height: 16px;
		font-size: 16px;
	}

	&-content {
		max-height: 0;
		overflow: hidden;
	}

	&-content.ReactAccordion-show {
		height: auto;
		max-height: none;
		padding-bottom: 10px;
	}
}
