.HotelCapacityCharts {
	@include viewport(large) {
		margin: 0 40px;
	}

	.Chart {
		margin: 0;
	}

	.ComponentAccordion {
		.Accordion-item-title {
			font-style: italic;
			font-weight: normal;
			text-transform: none;
		}
	}

	&-unitSelectWrapper {
		width: 136px;
		margin-left: auto;
		margin-bottom: 42px;

		select {
			background: none;
			border: none;
			line-height: 39px;
			height: 39px;
			width: 100%;
			padding-right: 15px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;

			@include viewport(medium) {
				margin-top: 10px;
			}

			@include rtl {
				padding-right: 0;
				padding-left: 15px;
			}

			option {
				font-size: 0.75rem;
			}
		}

		&::after {
			right: 0;
		}

		@include rtl {
			&::after {
				left: 0;
			}
		}

		@include viewport(medium) {
			&::after {
				top: 15px;
			}
		}
	}

	.container {
		@include viewport(large) {
			max-width: $containerWidth-xl;
		}
	}

	.Accordion {
		&-item {
			dt {
				@include viewport(large) {
					padding: 0 5px;
				}
			}
		}
	}
}
