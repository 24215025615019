.PaginationComponent {
	position: relative;
	.pagination-nav {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
	}
	.option {
		font-size: 12px;
		font-weight: bolder;
		padding: 6px 8px;
		text-align: center;
		@include viewport(medium) {
			margin: 0 2px;
		}
		@include viewport(large) {
			margin: 0 5px;
		}
	}
	.number {
		&.active {
			background: #f4f4f4;
			color: #000;
			outline: 0.5px solid black;
		}
	}

	.symbol {
		font-size: 12px;
	}
}
