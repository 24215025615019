.GlobalWeddings {
	&-propertyPicker {
		background-color: $gray4;

		@include viewport(medium) {
			max-width: none;
			position: relative;
			right: 0;
			transform: none;
			padding: 35px 30px 45px;
		}

		@include viewport(large) {
			max-width: 740px;
			position: absolute;
			right: 50%;
			bottom: 0;
			transform: translate(50%);
			width: 100%;

			@include rtl {
				right: auto;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.PropertyPicker {
			&-Form {
				margin-bottom: 0;
			}

			&-DropdownContainer {
				&::after {
					right: 12px;

					@include viewport(medium) {
						top: 12px;
					}
				}

				@include rtl {
					&::after {
						left: 12px;
						right: auto;
					}
				}
			}

			&-DropdownContainer,
			&-CTAContainer {
				@include viewport(medium) {
					height: 50px;
				}
			}

			&-Dropdown {
				background-color: $white;
				padding: 0 20px;
				line-height: 45px;

				@include viewport(medium) {
					height: 100%;
				}
			}

			&-CTA {
				@include viewport(medium) {
					min-width: 123px;
					height: 100%;
				}

				@include viewport(xlarge) {
					font-size: 10px;
					padding: 12px 20px;
				}
			}
		}
	}
}
