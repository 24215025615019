$transition: 0.5s linear;

.AnimatedCardCarousel {
	&--slide {
		width: 290px;
		margin: 0 7.5px;

		@include viewport(medium) {
			margin: 0 15px;
			margin-top: 60px;
		}

		&.animated {
			transition: width $transition, margin $transition;
		}

		&.is-selected {
			@include viewport(medium) {
				width: 330px;
				margin-left: -5px;
				margin-top: 0;

				@include rtl {
					margin-right: -5px;
				}

				.AnimatedCardCarousel-Card {
					margin: 0;
					opacity: 1;
					&--light {
						box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.25);
						border: none;
					}

					&-image {
						padding-top: 56.24%;
					}

					&-heading {
						&--title {
							@include typography(h3);
							&::after {
								opacity: 1;
								margin: auto;
								margin-top: 20px;
							}
							margin: 0 30px;
						}
						&--eyebrow {
							margin: 0 30px;
							margin-bottom: 5px;
						}
					}
					&-description {
						opacity: 1;
					}

					&-CTAContainer {
						opacity: 1;
					}
				}
			}

			@include viewport(large) {
				width: 370px;
				margin-left: -25px;

				@include rtl {
					margin-right: -25px;
				}
			}
		}
	}

	&-Card {
		overflow: hidden;
		background-color: $white;
		color: $black;

		&--animate {
			transition: all $transition;

			.AnimatedCardCarousel-Card {
				&-image {
					transition: all $transition;
				}
				&-heading {
					&--title {
						transition: all $transition;
						&::after {
							transition: opacity $transition, margin $transition;
						}
					}
				}
				&-body {
					transition: all $transition;
				}
				&-description {
					transition: all $transition;
				}

				&-CTAContainer {
					transition: all $transition;
				}
			}
		}

		text-align: center;
		min-height: 427.5px;
		height: 100%;
		border: 1px solid $gray3;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include viewport(medium) {
			opacity: 0.9;
		}

		&--dark {
			border: 1px solid $gray2;
		}

		&-image {
			padding-top: 125%;
			width: 100%;

			@include viewport(medium) {
				width: 330px;
			}

			@include viewport(large) {
				width: 370px;
			}
		}

		&-body {
			@include viewport(medium) {
				width: 330px;
			}
			height: calc(100% - 184.45px);

			@include viewport(large) {
				width: 370px;
				height: calc(100% - 208.08px);
			}

			&--mobile {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		&-heading {
			&--title {
				&--hasBorder {
					@include headingBorder();
					margin-bottom: 0;
				}
				opacity: 1;
				margin: 0 20px;
				padding: 25px 0;

				@include viewport(medium) {
					margin: 0 40px;
				}

				@include viewport(large) {
					margin: 0 60px;
				}
				&::after {
					margin: 0 auto;

					@include viewport(medium) {
						opacity: 0;
					}
				}
			}
			&--eyebrow {
				padding-top: 25px;
				margin: 0 20px;
				margin-bottom: 5px;

				@include viewport(medium) {
					margin: 0 40px;
					margin-bottom: 5px;
				}

				@include viewport(large) {
					margin: 0 60px;
					margin-bottom: 5px;
				}

				& + .AnimatedCardCarousel-Card-heading--title {
					padding-top: 0;
				}
			}
		}

		&-description {
			@include viewport(medium) {
				opacity: 0;
			}
			margin: 0 30px;
			overflow: hidden;
		}

		&-CTA {
			&Container {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 30px;
				margin-top: 10px;

				@include viewport(medium) {
					opacity: 0;
				}
			}

			min-width: 130px;
			flex: 2 1 0;

			@include viewport(large) {
				min-width: 150px;
			}

			&--button {
				margin: 10px 0;
				& + & {
					margin-left: 10px;

					@include rtl {
						margin-left: 0;
						margin-right: 10px;
					}
				}
				&--primary {
					width: 100%;
					flex: auto;
				}
			}

			&--link {
				margin-top: 10px;
				text-align: center;
				border: none;
				padding-right: 5px;
				& + & {
					border-left: 1px solid $gray2;
					padding-left: 5px;
					padding-right: 0;

					@include rtl {
						border-left: none;
						border-right: 1px solid $gray2;
					}
				}
			}
		}

		&-MobileLink {
			text-decoration: none;
		}
	}

	.AnimatedCardCarousel-container--stacked {
		.AnimatedCardCarousel-Card {
			margin-bottom: 20px;
			min-height: 0;
			&-image {
				padding-top: 56.24%;
				margin-bottom: 30px;
			}

			&-heading {
				&--title {
					@include typography(h3);
					&::after {
						opacity: 1;
						margin: auto;
						margin-top: 20px;
					}
					margin: 0 30px;
					padding-top: 0;
				}
				&--eyebrow {
					margin: 0 30px;
					margin-bottom: 5px;
					padding-top: 0;
				}
			}

			&-CTA {
				&Container {
					width: 310px;
					margin: 10px auto 30px;
				}
			}
		}
	}

	.IndicatorColor {
		position: static;
		border-radius: 50%;
		width: 8px;
		height: 8px;
		flex: none;
		flex-grow: 0;
		margin-right: 8px;

		@include rtl {
			margin-right: 0;
			margin-left: 8px;
		}
	}

	.IndicatorBackground {
		position: absolute;
		display: flex;
		width: 100%;
		padding: 10px 36px;
		background: rgba(0, 0, 0, 0.6);
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(10px);
		align-items: center;
		align-content: center;
		justify-content: center;
		border-left: 1px solid $gray3;
		border-right: 1px solid $gray3;

		@include viewport(medium) {
			border: none;
		}

		&--stacked {
			width: calc(100% - 30px);
			border: none;
		}
	}

	.IndicatorMessage {
		position: static;
		text-align: center;
		color: $white;

		&-subtext {
			color: $white;
			text-align: center;
		}

		&-container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}
