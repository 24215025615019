.Overlay_caw {
	display: flex;
	& > .container {
		margin: auto;
	}

	&_wrap {
		display: flex;
	}

	&_contents {
		display: flex;
		flex-direction: column;
		margin-right: 50px;
		max-width: 65%;
	}

	&_title {
		position: relative;
	}

	&_title,
	&_body {
		margin: 5px;
	}

	&_button {
		align-items: center;
		border-style: solid;
		border-width: 1px;
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		position: relative;
		font-weight: bold;
		justify-content: center;
		min-width: 130px;
		max-width: 160px;
		padding: 12px 20px;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		color: $black;
		border-radius: 0;
		text-decoration: none;
		// text-transform: uppercase;
		transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;

		&:hover {
			color: $white;
			background: $black;
			border-color: $black;
		}
	}
}
