/*colors*/
$neutral-1700: #030303;
$neutral-1600: #0f0f0f;
$neutral-1500: #1f1f1f;
$neutral-1400: #2d2d2d;
$neutral-1300: #3d3d3d;
$neutral-1200: #4d4d4d;
$neutral-1100: #5c5c5c;
$neutral-1000: #6b6b6b;
$neutral-900: #7d7d7d;
$neutral-800: #8c8c8c;
$neutral-700: #9c9c9c;
$neutral-600: #ababab;
$neutral-500: #bababa;
$neutral-400: #c9c9c9;
$neutral-300: #d8d8d8;
$neutral-200: #e6e6e6;
$neutral-100: #f5f5f5;
$neutral-0: #ffffff;

$red-1200: #310202;
$red-1100: #530404;
$red-1000: #7a0505;
$red-900: #9d0707;
$red-800: #c40808;
$red-700: #ea0a0a;
$red-600: #f63232;
$red-500: #f86262;
$red-400: #fa8f8f;
$red-300: #fcb1b1;
$red-200: #fdd3d3;
$red-100: #fef0f0;

$yellow-1200: #2c1b02;
$yellow-1100: #452a02;
$yellow-1000: #673f04;
$yellow-900: #855205;
$yellow-800: #9e6106;
$yellow-700: #c57907;
$yellow-600: #e28b08;
$yellow-500: #f69b13;
$yellow-400: #f8ad3d;
$yellow-300: #f9bc61;
$yellow-200: #fcdcab;
$yellow-100: #fef3e1;

$green-1200: #071d0e;
$green-1100: #0c3119;
$green-1000: #134e27;
$green-900: #185e2f;
$green-800: #1c6e38;
$green-700: #218242;
$green-600: #26974c;
$green-500: #2aa654;
$green-400: #31c462;
$green-300: #70db94;
$green-200: #adebc2;
$green-100: #def7e7;

$blue-1200: #05111f;
$blue-1100: #091d35;
$blue-1000: #0e3158;
$blue-900: #15467f;
$blue-800: #1a579e;
$blue-700: #1f6bc1;
$blue-600: #3a87df;
$blue-500: #5c9ce5;
$blue-400: #84b4eb;
$blue-300: #a7caf1;
$blue-200: #c2daf5;
$blue-100: #e9f2fb;

$eci-green-1200: #0a2424;
$eci-green-1100: #113c3c;
$eci-green-1000: #175252;
$eci-green-900: #34605d;
$eci-green-800: #417770;
$eci-green-700: #519488;
$eci-green-600: #6bae9e;
$eci-green-500: #85bcad;
$eci-green-400: #a3cdbf;
$eci-green-300: #bfdcd1;
$eci-green-200: #d1e6de;
$eci-green-100: #f2f8f6;

$eci-lime-green-1200: #1f2207;
$eci-lime-green-1100: #373b0d;
$eci-lime-green-1000: #4a5011;
$eci-lime-green-900: #5a6015;
$eci-lime-green-800: #6e7519;
$eci-lime-green-700: #8d9721;
$eci-lime-green-600: #acb828;
$eci-lime-green-500: #cbd747;
$eci-lime-green-400: #d6df6c;
$eci-lime-green-300: #e1e896;
$eci-lime-green-200: #ebf0b8;
$eci-lime-green-100: #f2f5d1;

$eci-powder-blue-1200: #051d29;
$eci-powder-blue-1100: #082d40;
$eci-powder-blue-1000: #0b415b;
$eci-powder-blue-900: #0f587c;
$eci-powder-blue-800: #1476a4;
$eci-powder-blue-700: #188dc4;
$eci-powder-blue-600: #2eabe6;
$eci-powder-blue-500: #57bceb;
$eci-powder-blue-400: #80cef0;
$eci-powder-blue-300: #a4dcf4;
$eci-powder-blue-200: #bfe7f7;
$eci-powder-blue-100: #dff3fb;

$eci-burnt-orange-1200: #270c06;
$eci-burnt-orange-1100: #4b170c;
$eci-burnt-orange-1000: #722412;
$eci-burnt-orange-900: #9a3019;
$eci-burnt-orange-800: #b83a1e;
$eci-burnt-orange-700: #dc4827;
$eci-burnt-orange-600: #e05b3e;
$eci-burnt-orange-500: #e67d65;
$eci-burnt-orange-400: #eb9784;
$eci-burnt-orange-300: #f1b8ac;
$eci-burnt-orange-200: #f8dad3;
$eci-burnt-orange-100: #fdf4f2;

$eci-pink-1200: #280615;
$eci-pink-1100: #4b0c28;
$eci-pink-1000: #661037;
$eci-pink-900: #8d164c;
$eci-pink-800: #b51c62;
$eci-pink-700: #dd2277;
$eci-pink-600: #e2458d;
$eci-pink-500: #e869a3;
$eci-pink-400: #ed8cb8;
$eci-pink-300: #f3b4d1;
$eci-pink-200: #f9d7e7;
$eci-pink-100: #fdf2f7;
