.AccountDelete {
	&Form {
		padding-top: 0;
	}

	&-initial-text,
	&-confirmation-text {
		margin-bottom: 20px;
		p {
			margin: 0;
		}
	}

	&-signin,
	&-deletion {
		text-align: center;

		&-cta {
			text-align: center;
			margin-bottom: 20px;

			@include viewport(large) {
				margin-top: 30px;
			}
		}
	}
	.ContactForm-SubmitCTA {
		float: none;
	}
	margin: 0 12px;

	@include viewport(small) {
		margin: auto;
		margin-top: 70px;
		margin-bottom: 70px;
		width: 325px;
	}

	@include viewport(medium) {
		//tablet
		margin: auto;
		margin-top: 74px;
		margin-bottom: 74px;
		width: 450px;
	}

	@include viewport(large) {
		//desktop
		margin: auto;
		margin-top: 94px;
		margin-bottom: 94px;
		width: 700px;
	}

	&.hidden,
	.hidden {
		display: none;
	}
}
