.BodySection {
	@include section-spacing;
	&--not-first {
		padding-top: 0;
	}

	.SpacedComponent:not(:last-child) {
		@include component-spacing;
	}

	&--light {
		background-color: $white;
		color: $black;
	}

	&--dark {
		background-color: $black;
		color: $white;
	}

	&--light + &--light {
		padding-top: 0;
	}

	&--dark + &--dark {
		padding-top: 0;
	}
}
.pin-spacer {
	.BodySection.tabbed:focus-within {
		z-index: 3;
	}
}
