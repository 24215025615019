.CompareChart {
	.Chart-row--header,
	tbody > .Chart-row:nth-child(even) {
		background-color: $white;
	}
	table {
		margin: 0 auto;
	}
	.Chart-row {
		border-bottom: 1px solid $gray3;
		display: flex;
		&:last-child {
			&:not(.Chart-row--header) {
				border-bottom: none;
				.ChartCell {
					padding-top: 0;
				}
			}
		}

		&:nth-child(7) {
			border-bottom: none;
		}
	}
	.ChartCell {
		font-size: 1.125rem;
		line-height: 1.5em;
		border-left: none;
		min-width: 228px;
		width: 228px;

		@include viewport(xlarge) {
			min-width: 277px;
			width: 277px;
		}
		&:first-child {
			font-weight: 700;
			font-size: 0.75rem;
			letter-spacing: 0.25em;
			line-height: 1.333333333em;
			text-transform: uppercase;
			font-family: $sansFont;
			min-width: 246px;
			width: 246px;
			align-self: center;
			text-align: left;

			@include viewport(xlarge) {
				min-width: 295px;
				width: 295px;
			}
		}
		&:nth-child(even) {
			background-color: $gray4;
		}

		&--header-text {
			font-weight: 700;
			font-size: 0.75rem;
			letter-spacing: 0.25em;
			line-height: 1.333333333em;
			text-transform: uppercase;
			font-family: $sansFont;
		}

		&--cta {
			.CTA {
				width: 100%;
			}
		}

		&-image {
			img {
				width: 89px;
			}
		}
	}
}
