.Experiences {
	// Needed to reduce grid columns for overflow carousel on mobile
	&-carouselcontainer {
		padding-left: 0;
		padding-right: 0;
		overflow: hidden;
	}

	.ImageCtaCollage {
		margin-bottom: 60px;

		&::after {
			content: 'flickity';
			display: none;

			@include viewport(medium) {
				content: '';
			}
		}

		@include viewport(medium) {
			margin-bottom: 0;
		}

		.flickity-viewport {
			left: calc(#{get-columns-vw(1, string)} + 30px);
		}

		.Experience {
			@include clearfix;
		}

		.ImageCtaCollage-item {
			@include clearfix;
			width: calc(#{get-columns-vw(10, string)} + 15px);

			@include viewport(medium) {
				width: 100%;
				margin-bottom: 60px;
			}

			@include viewport(large) {
				margin-bottom: 70px;
			}

			@include viewport(xlarge) {
				margin-bottom: 90px;
			}

			&:not(.ImageCtaCollage-item-primary) {
				@include viewport(medium) {
					// Every .ImageCtaCollage-item but the first
					margin-bottom: 50px;
					.Experience-picture {
						@include column(7);

						@include rtl {
							float: right;
						}
					}

					.Experience-cta {
						@include column(5, medium);
						padding-left: 45px;

						@include rtl {
							padding-left: 15px;
							padding-right: 45px;
						}

						.CtaBlock-text {
							padding-right: 15px;
							padding-left: 0;

							@include rtl {
								padding-right: 0;
								padding-left: 15px;
							}
						}
					}
				}

				@include viewport(xlarge) {
					margin-bottom: 70px;
				}
			}

			&-primary {
				margin-left: calc(#{get-columns-vw(1, string)} + 30px);

				@include rtl {
					margin-left: 0;
					margin-right: calc(#{get-columns-vw(1, string)} + 30px);

					@include viewport(medium) {
						margin-right: 0;
					}
				}

				@include viewport(medium) {
					margin-left: 0;
				}
			}

			.Experience-picture {
				@include column(12);
				margin-bottom: 30px;
			}

			.Experience-cta {
				@include column(12);
				padding-left: 30px;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					display: block;
					height: 100%;
					width: 1px;
					left: 7.5px;
					background: $white;

					@include viewport(medium) {
						left: 15px;
					}
				}

				.BodySection--light & {
					&::before {
						background: $black;
					}
				}

				@include rtl {
					padding-left: 7.5px;
					padding-right: 30px;

					&::before {
						left: auto;
						right: 7.5px;

						@include viewport(medium) {
							right: 15px;
						}
					}
				}

				@include viewport(medium) {
					@include column(7, medium);
					@include push(0);
					padding-left: 45px;

					@include rtl {
						float: right;
						padding-left: 15px;
						padding-right: 45px;
					}
				}
			}

			// This applies to every .ImageCtaCollage-item that has the text block
			// located to the left side of the images, and to the right side in RTL.
			&.ImageCtaCollage-item--left:not(.ImageCtaCollage-item-primary) .Experience {
				@include viewport(medium) {
					.Experience-picture {
						float: right;

						@include rtl {
							float: left;
						}
					}

					.Experience-cta {
						text-align: right;
						padding-left: 15px;
						padding-right: 45px;

						@include viewport(medium) {
							&::before {
								left: auto;
								right: 15px;
							}

							@include rtl {
								text-align: left;
								padding-right: 15px;
								padding-left: 45px;

								&::before {
									right: auto;
									left: 15px;
								}
							}

							.CtaBlock-text {
								padding-left: 15px;
								padding-right: 0;

								@include rtl {
									padding-left: 0;
									padding-right: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
}
