.VideoHero {
	&-heading-noSub .Heading-title::after {
		display: none;
	}

	.ThirdPartyVideo {
		position: relative;
		z-index: 1;
	}

	.VideoPlaceholderImage-button {
		z-index: 2;
	}

	&--playing {
		.VideoPlaceholderImage-button {
			display: none;
		}
	}

	&-heading {
		.Heading {
			text-align: center;
			width: 100%;

			&-eyebrow {
				@include typography(h4);
			}

			&-title {
				@include typography(h1);
				margin: 10px 0 (25px - $separator-height);

				&::after {
					background-color: $black;
					content: '';
					display: block;
					height: $separator-height;
					margin: 0 auto;
					position: relative;
					top: (10px - $separator-height);
					width: 40px;
				}

				@include viewport(xlarge) {
					margin: 10px 0 (32px - $separator-height);
				}
			}

			&-subtitle {
				@include typography(c2);
			}
		}

		&--mobile {
			margin-top: 20px;

			@include viewport(large) {
				display: none;
			}

			.Heading {
				color: $black;

				&-title::after {
					background-color: $black;
				}
			}

			&--dark {
				.Heading {
					color: $white;
				}
			}
		}

		&--desktop {
			display: none;

			@include viewport(large) {
				display: block;
			}

			.Heading {
				color: $white;

				&-title::after {
					background-color: $white;
				}
			}
		}
	}

	&-hero {
		position: relative;
		height: calc(100vw * (9 / 16));

		&::after {
			@include gradient2transparent($height: 60%);
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
			pointer-events: none;

			@include viewport(medium) {
				@include gradient2transparent($height: 70%);
			}

			@include viewport(large) {
				@include gradient2transparent($height: 60%);
			}
		}

		.Image-ratioContainer {
			width: auto;
			height: 100%;

			.Image-img {
				width: auto;
				min-width: 100%;
				min-height: 100%;
			}
		}
	}

	&-heading-noTitle {
		display: none;
	}
}
