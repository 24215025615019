.InformationCards {
	&-heading {
		.Heading-title {
			text-align: center;
			margin-top: 0;
			margin-bottom: 30px;
			padding: 0 15px;

			@include viewport(medium) {
				margin-bottom: 50px;
			}
		}
	}

	&-Container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		align-items: stretch;
		color: black;
	}

	&-Card--text {
		width: 100%;
	}

	.RichText {
		ul,
		ol {
			padding-left: 0px;
			@include rtl {
				padding-right: 0px;
			}
		}

		ul li {
			&::before {
				display: none;
			}

			list-style-type: disc;
			list-style-position: inside;
		}

		ol li {
			list-style-position: inside;
		}
	}
}
