.TableList {
	&-heading {
		.Heading-title {
			@include component-spacing($small: 60px, $medium: 60px, $large: 80px, $xlarge: 80px);

			text-align: center;
			margin-top: 0;
		}
	}

	&-item {
		@include viewport(medium) {
			display: flex;
			padding-bottom: 42px;
		}

		@include viewport(large) {
			padding-bottom: 40px;
		}

		@include viewport(xlarge) {
			padding-bottom: 50px;
		}

		&-description {
			@include viewport(medium) {
				margin-left: auto;
				padding-top: 0;
				text-align: right;

				@include rtl {
					margin-left: 0;
					margin-right: auto;
					text-align: left;
				}
			}

			&-cta {
				@include viewport(medium) {
					text-align: right;

					@include rtl {
						text-align: left;
					}
				}

				&.nestedList {
					margin-top: 10px !important;
				}

				.BodySection--light & {
					.CTA {
						@include grayHover('light');
						color: $black;
					}
				}
			}
		}
	}

	&-finePrint {
		text-align: center;
		padding-top: 20px;

		@include viewport(medium) {
			padding-top: 40px;
		}
		@include viewport(xlarge) {
			padding-top: 50px;
		}
	}
}
