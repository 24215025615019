.WideGridFilter {
	&-bar {
		padding-bottom: 0;

		@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
			padding: 0;
			padding-top: 25px;
			max-width: 100%;
		}

		&-row {
			@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
				overflow: scroll;
				-ms-overflow-style: none;
				scrollbar-width: none;
				&::-webkit-scrollbar {
					display: none;
				}
			}
			@include mediaRange($breakpoints-md, calc($breakpoints-lg - 1px)) {
				padding-left: calc((100% - ($wideGridContainerWidth-md + 120px)) / 2);

				@include rtl {
					padding-left: 0;
					padding-right: calc((100% - ($wideGridContainerWidth-md + 120px)) / 2);
				}
			}
		}

		&-filter-container {
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			gap: 10px;
			padding-top: 0;
			padding-bottom: 25px;
			padding-left: 15px;
			padding-right: 15px;
			@include viewport(medium) {
				padding-left: 60px;
				padding-right: 60px;
				justify-content: flex-start;
			}

			@include viewport(large) {
				padding-left: 0;
				padding-right: 0;
				justify-content: center;
				flex-wrap: wrap;
			}
		}

		&-filterButton,
		&-filterDropdown {
			&-container {
				order: 0;
			}
			&-text {
				text-wrap: nowrap;
			}

			&-button {
				max-height: 40px;
				padding: 10px 15px;
				display: flex;
				align-items: center;
				gap: 5px;
				border: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));

				&--active {
					background: var(--color-surface-raised-subtle, #f5f5f5);
					border: 1px solid var(--color-border-strong-default, rgba(77, 77, 77, 0.95));
				}
			}

			&-icon {
				font-size: 14px;
				display: flex;
				margin-right: 5px;
				@include rtl {
					margin-left: 5px;
					margin-right: 0;
				}
			}
		}

		&-resultCount {
			&-row {
				@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
					max-width: 834px;
					margin: auto;
				}
			}
			&-container {
				margin-left: 15px;
				margin-right: 15px;
				@include viewport(medium) {
					margin-left: 60px;
					margin-right: 60px;
					padding-bottom: 25px;
				}
				@include viewport(large) {
					margin-left: 0;
					margin-right: 0;
				}
				padding: 15px 0 25px;
				border-top: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
				display: flex;
				justify-content: flex-end;
			}
		}

		&-allFilters {
			&-container {
				@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
					order: -1;
				}
			}

			&-button {
				&.WideGridFilter-bar-filterButton-button--active {
					.WideGridFilter-bar-allFilters-numFilters {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 20px;
						height: 20px;
						border-radius: 50px;
						background: var(--color-surface-inverse, #0f0f0f);
						& span {
							color: var(--color-text-inverse-primary, #e6e6e6);
							letter-spacing: unset;
						}
					}
				}
			}

			&-numFilters {
				display: none;
			}
		}

		&-filterDropdown {
			&-container {
				position: relative;

				&.surface-sticky {
					box-shadow: 0px 10px 25px 0px rgba(3, 3, 3, 0.2);
				}
			}
			&-button {
				gap: 10px;
				&--selected {
					background: var(--color-surface-raised-subtle, #f5f5f5);
					border: 1px solid var(--color-border-strong-default, rgba(77, 77, 77, 0.95));
					.WideGridFilter-filter-checkboxGroup-dropdown-carot {
						border-top: none;
						border-bottom: 7px solid var(--color-text-primary, #1f1f1f);
					}

					& + .WideGridFilter-bar-filterDropdown-options-container {
						display: block;
					}
				}
			}

			&-options {
				overflow-y: scroll;
				padding: 0 20px;
				-ms-overflow-style: none;
				scrollbar-width: none;
				&::-webkit-scrollbar {
					display: none;
				}
				@include viewport(large) {
					max-height: 500px;
					padding-top: 0;
				}

				&-background {
					@include viewport(large) {
						display: none;
					}
				}

				&-content {
					border-radius: 20px 20px 0px 0px;
					background: var(--color-surface-sticky, #fff);
					box-shadow: 0px 10px 25px 0px rgba(3, 3, 3, 0.2);
					max-height: 80%;
					bottom: 0;
					position: absolute;
					width: 100%;
					display: flex;
					flex-direction: column;
					@include viewport(large) {
						position: relative;
						border-radius: unset;
					}
				}

				&-heading {
					text-align: center;
					width: 100%;
					display: flex;
					justify-content: center;
					border-bottom: 1px solid
						var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
					@include viewport(large) {
						display: none;
					}

					&-title {
						margin: 15px;
						overflow: hidden;
						color: var(--color-text-primary, #1f1f1f);
						text-align: center;
						font-feature-settings: 'clig' off, 'liga' off;
						text-overflow: ellipsis;
						font-weight: 600;
					}
				}

				&-closeButton {
					@include viewport(large) {
						display: none;
					}
				}

				&-container {
					display: none;
					position: fixed;
					z-index: 10;
					width: 100%;
					height: 100%;
					overflow: hidden;
					bottom: 0;
					left: 0;
					background: transparent;
					@include viewport(large) {
						position: absolute;
						bottom: unset;
						left: unset;
						height: auto;
						width: 315px;
						max-height: 585px;
						z-index: 2;
					}

					.WideGridFilter {
						&-filter {
							&-checkboxGroup {
								&-checkbox {
									flex-basis: 100% !important;
								}
							}
						}
					}
					.WideGridFilter-modal-section-checkboxGroup-container {
						flex-direction: column;
					}
				}
				&-footer {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					padding: 15px 20px 20px;
					border-top: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
				}
			}
		}
	}

	&-item {
		&--hide {
			display: none;
			&--sm {
				@include mediaRange($breakpoints-sm, calc($breakpoints-md - 1px)) {
					display: none;
				}
			}

			&--md {
				@include mediaRange($breakpoints-md, calc($breakpoints-lg - 1px)) {
					display: none;
				}
			}

			&--lg {
				@include mediaRange($breakpoints-lg, calc($breakpoints-xl - 1px)) {
					display: none;
				}
			}

			&--xl {
				@include media($breakpoints-xl) {
					display: none;
				}
			}
		}
		&--filtered {
			display: none !important;
		}
	}

	&-button {
		display: flex;
		height: 40px;
		padding: 10px 20px;
		justify-content: center;
		align-items: center;
		gap: 10px;

		&-container {
			display: flex;
			justify-content: center;
			margin-top: 40px;
			text-align: center;
		}
	}

	&-noResults {
		&-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 10px;
			align-items: center;
		}
		&-heading {
			margin: 0;
		}
		&-body {
			margin-bottom: 15px;
			color: var(--color-text-secondary, #4d4d4d);
		}
	}

	& .WarningModal {
		&-content {
			position: absolute;
			height: auto;
			max-height: 80%;
			overflow-x: hidden;
			overflow-y: auto;
			padding: 0;
			position: absolute;
			text-align: left;
			top: unset;
			bottom: 0;
			width: 100%;
			margin: auto;
			border-radius: 20px 20px 0px 0px;
			background: transparent;

			@include viewport(medium) {
				max-height: 55%;
			}

			@include viewport(large) {
				width: 690px;
				top: 50%;
				bottom: unset;
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				left: 50%;
				max-height: 95%;
				border-radius: unset;
			}
		}

		&-closeCTA {
			width: 16px;
			height: 16px;
			top: 15px;
			right: 20px;
			margin: 0;
			line-height: 0;

			@include rtl {
				left: 20px;
				right: 0;
			}

			&::before {
				font-size: 16px;
				color: var(--color-text-primary, #1f1f1f);
			}
		}
	}

	&-modal {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		border-radius: 0;

		&-heading {
			display: flex;
			justify-content: center;
			border-bottom: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
			&-title {
				margin: 15px;
				overflow: hidden;
				color: var(--color-text-primary, #1f1f1f);
				text-align: center;
				font-feature-settings: 'clig' off, 'liga' off;
				text-overflow: ellipsis;
				font-weight: 600;
			}
		}

		&-body {
			overflow-x: hidden;
			overflow-y: auto;
		}

		&-section {
			margin: 0 30px;
			&:not(:last-child) {
				border-bottom: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
			}
			&-itemContainer {
				display: flex;
				padding: 20px 0 15px;
				justify-content: flex-start;
				gap: 0 20px;
				flex-wrap: wrap;

				@include viewport(medium) {
					padding: 30px 0 20px;
				}
			}

			&-heading {
				flex-basis: 100%;
				width: 100%;

				& + .WideGridFilter-modal-section-checkboxGroup {
					border: none;
					padding-top: 15px;
				}
			}

			&-title {
				margin: 0;
				margin-bottom: 15px;
			}

			&-viewMoreButton {
				width: 100%;
				padding-top: 15px;
			}

			&-checkboxGroup {
				&-container {
					padding-top: 19px;
					gap: 0 20px;

					& .CheckboxGroup-checkbox {
						padding-left: 30px;
						margin: 0;
						@include rtl {
							padding-left: 0;
							padding-right: 30px;
						}
					}
				}
				&:last-child {
					padding-bottom: 15px;
				}
			}
		}

		&-footer {
			display: flex;
			padding: 15px 30px 30px 30px;
			justify-content: space-between;
			align-items: center;
			align-self: stretch;
			border-top: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
		}
	}

	&-filter {
		&-checkbox,
		&-checkboxGroup {
			margin: 0;
			&-label {
				color: var(--color-text-primary, #1f1f1f);
			}

			& .Checkbox-decoration {
				margin-top: 0;
			}
		}
		&-checkbox {
			padding: 15px 0;
			flex-basis: 100%;
			@include viewport(medium) {
				flex-basis: calc(50% - 10px);
			}
		}
		&-checkboxGroup {
			padding: 19px 0;
			flex-basis: 100%;
			border-top: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
			&:first-child {
				border: none;
				padding-top: 15px;
			}
			&-checkbox {
				padding: 0;
				margin: 0;
			}
			&-dropdown {
				display: flex;
				justify-content: space-between;
				align-items: center;
				&-carot {
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-top: 7px solid var(--color-text-primary, #1f1f1f);
					height: 0;
					width: 0;
					cursor: pointer;
				}
				&-carot-touchpoint {
					width: 20px;
					height: 20px;
					display: flex;
					justify-content: end;
					align-items: center;
					cursor: pointer;
				}
			}
			&--open {
				& .WideGridFilter-filter-checkboxGroup-dropdown-carot {
					border-top: none;
					border-bottom: 7px solid var(--color-text-primary, #1f1f1f);
				}
			}
		}
	}

	& .button-text {
		border: none;
	}

	& .surface-overlay {
		@include viewport(large) {
			border-radius: 0;
		}
	}
}
