.ThreeColumnTextItems {
	&-Container {
		background-color: $gray4;
		padding-top: 40px;
		padding-bottom: 60px;
		max-width: 1070px;
		margin: auto;
		@include viewport(xlarge) {
			max-width: 1670px;
		}
	}

	&-Title {
		text-align: center;
		margin-top: 0;
		margin-bottom: 40px;
	}

	&-Line {
		display: block;
		border-bottom: 1px solid $gray3;
		margin-bottom: 10px;
	}

	&-Item {
		text-align: left;
		margin-top: 30px;

		@include rtl {
			text-align: right;
		}

		&-Icon {
			font-size: 40px;
			line-height: 40px;
		}

		.Heading {
			margin-top: 10px;
		}
	}
}
