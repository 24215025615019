.Quote--large {
	text-align: center;

	.Quote {
		&-text {
			margin: 0 0 30px;

			@include viewport(xlarge) {
				margin-bottom: 40px;
			}
		}
		&-picture {
			position: relative;
			margin: auto;
			margin-bottom: 1rem;
			width: 100%;
			max-width: 135px;

			img {
				width: 100%;
				max-width: 100%;
			}

			.Image {
				position: relative;

				@include viewport(medium) {
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	&--light {
		.Quote-text {
			@include quote(block, 'light');
		}
	}

	&--dark {
		.Quote-text {
			@include quote(block, 'dark');
		}
	}

	.Attribution-author,
	.Attribution-role {
		@include viewport(small) {
			display: block;
		}

		@include viewport(medium) {
			display: inline;
		}
	}
}
