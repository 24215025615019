.SummaryTab {
	&--dark {
		color: $white;
	}

	&-container {
		display: flex;
		justify-content: center;
		flex-direction: row;

		flex-wrap: wrap;
		gap: 40px 15px;
		padding: 0 37.5px;

		@include viewport(medium) {
			gap: 30px 0;
			padding: 0;
		}

		@include viewport(large) {
			flex-wrap: nowrap;
		}
	}
}
