.CardGrid {
	.CardGridCard {
		&:not(:last-child) {
			margin-bottom: 60px;

			@include viewport(large) {
				margin-bottom: 70px;
			}

			@include viewport(xlarge) {
				margin-bottom: 90px;
			}
		}

		// Remove margin for last row
		@include viewport(medium) {
			&:nth-last-child(2):nth-child(odd) {
				margin-bottom: 0;
			}
		}

		&-image {
			display: block;
			margin-bottom: 27px;

			@include viewport(medium) {
				margin-bottom: 10px;
			}

			@include viewport(xlarge) {
				margin-bottom: 20px;
			}
		}

		&-text {
			margin-top: 7px;
			margin-bottom: 0;
		}
	}
}
