.AutomotiveChart {
	&-chart {
		margin-bottom: 32px;

		.ChartCell-text {
			a {
				color: $black;
				text-decoration: none;
			}
		}

		.ChartCell-cta {
			@include typography(b2);
			font-size: 15px;
			text-transform: none;
		}

		.Chart-table {
			width: 100%;
		}
	}
}
