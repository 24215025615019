.HomepageHero {
	.Hero-headingContainer {
		width: auto;
		bottom: 40px;

		@include viewport(medium) {
			bottom: 75px;
		}

		@include viewport(large) {
			bottom: 80px;
		}

		@include viewport(xlarge) {
			bottom: 100px;
		}

		.Heading-title {
			font-family: 'Helvetica Neue Light', 'Neue Helvetica', Helvetica, sans-serif;
			white-space: nowrap;
			font-size: 20px;
			letter-spacing: 3.43px;
			line-height: 24px;

			@include viewport(medium) {
				font-size: 32px;
				letter-spacing: 4px;
				line-height: 52px;
			}

			@include viewport(large) {
				font-size: 40px;
				letter-spacing: 6px;
				line-height: 52px;
			}

			@include viewport(xlarge) {
				font-size: 48px;
				letter-spacing: 6px;
				line-height: 52px;
			}

			br {
				@include viewport(medium) {
					display: none;
				}
			}

			.Small-Newline {
				display: block;

				@include viewport(medium) {
					display: inline;
					&::before {
						content: ' ';
					}
				}
			}
		}

		.Heading-eyebrow {
			font-family: 'Adobe Carlson', 'Monotype Garamond', Garamond, serif;
			font-size: 20px;
			letter-spacing: 0.56px;
			line-height: 24px;

			@include viewport(medium) {
				font-size: 24px;
				letter-spacing: 0.67px;
				line-height: 27px;
			}
		}
	}

	.BackgroundImage::after {
		height: 55%;

		@include viewport(large) {
			height: 45%;
		}
	}

	.Hero + .InlineCaw {
		margin: -60px 80px 0; // prevent overlap with video pause button
		position: relative;
		z-index: 2;

		@media only screen and (max-width: 1160px) {
			display: none;
		}

		@media only screen and (min-width: 1161px) and (max-width: 1265px) {
			display: block;
		}
	}

	@media screen and (min-width: 1701px) {
		.caw-comp__wrapper .App-wrapper__row .container {
			max-width: 930px;
		}
	}

	@media only screen and (min-width: 961px) {
		.caw-comp__wrapper {
			.ToggleCount {
				max-width: 187px;
			}
		}
	}
}
