/* https://css-tricks.com/snippets/sass/strip-unit-function/  */
/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

// Get a prop from layout-setup for a specific viewport
@function get-viewport-prop($prop, $viewport: small) {
	@return map-get(map-get($_layout-setup, $viewport), $prop);
}

// Get gutter for a specific viewport
@function get-gutter($viewport: small) {
	@return get-viewport-prop(gutter, $viewport);
}

// Get columns width (+ inner gutters)
@function get-columns-fixed-unit($size: 1, $viewport: small) {
	$gutter: get-gutter($viewport);

	@if $viewport == 'small' {
		@return percentage($size/$_total-columns);
	} @else {
		$container-width: get-viewport-prop(container, $viewport);

		@return (($container-width + $gutter) * $size * 0.083333333333) - $gutter;
	}
}

@function get-columns-vw($size: 1, $string: false) {
	$gutter: get-gutter(small);

	@if $string {
		@return '(((100vw - 15px) * #{$size * 0.083333333333}) - 15px)';
	}

	@return calc(((100vw - 15px) * #{$size * 0.083333333333}) - 15px);
}

@mixin stroke($colour: red, $width: 1px, $type: solid) {
	border: $width $type $colour;
}

@function map-deep-get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}

	@return $map;
}

@mixin ie() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}
