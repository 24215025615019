.PrivateJetJourneyCards {
	display: flex;
	flex-direction: column;
	gap: 40px;
	@include viewport(large) {
		gap: 70px;
	}

	& .PrivateJetJourneyCard {
		display: flex;
		flex-direction: column;
		gap: 15px;

		@include viewport(large) {
			flex-direction: row;
			gap: 30px;
			&:nth-child(even) {
				flex-direction: row-reverse;
			}
		}

		& .labels-label {
			color: var(--color-text-heading, #030303);
			font-weight: bold;
		}

		&-image {
			flex-basis: 58%;
		}

		&-content {
			flex-basis: calc(42% - 30px);
		}

		&-title {
			margin: 0 0 20px 0;

			@include viewport(large) {
				margin: 0 0 30px 0;
			}
		}

		&-info {
			display: flex;
			flex-direction: row;
			gap: 30px;
			justify-content: flex-start;
			margin-bottom: 20px;

			&-item {
				flex-basis: calc(50% - 15px);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
		}

		&-description {
			border-top: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
			padding-top: 20px;
			margin-bottom: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			line-clamp: 3;
			-webkit-line-clamp: 3;
		}

		&-featuredDestinations {
			border-top: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
			padding-top: 20px;
			display: flex;
			flex-direction: column;
			gap: 15px;
			margin-bottom: 20px;

			@include viewport(large) {
				margin-bottom: 30px;
			}
		}

		&-featuredDestination {
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;

			&-image {
				width: 88.889px;
				min-width: 88.889px;
			}

			&-text {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				gap: 4px;
			}
		}

		&-cta {
			display: flex;
			& .button-filled {
				line-height: 20px;
				text-decoration: none;
				width: 100%;
				@include viewport(large) {
					width: unset;
				}
			}
		}
	}
}
