.toggle {
	display: flex;
	flex: 1;
	justify-content: space-between;
	position: relative;
	margin-bottom: 25px;

	&:last-child {
		margin-bottom: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include media($navigationBreakpoint) {
		margin-bottom: 0;
	}

	&.component-toggle {
		display: block;
		position: relative;
		.toggle__label {
			position: absolute;
			left: 0;
			transform: translateY(-22px);
			letter-spacing: 2.05px;
			@include rtl {
				left: inherit;
			}
		}
	}

	&__label {
		display: inline-block;

		.toggle--error & {
			color: $error-red;
		}
	}

	&__button-icon,
	&__icon {
		width: 50%;
		position: absolute;
		top: 25%;
		left: 26%;

		@include media($navigationBreakpoint) {
			top: 23%;
			left: 22.3%;
		}

		@include ie {
			top: 0;
		}
	}

	&__button {
		position: relative;
		$diameter: 30px;
		width: $diameter;
		height: $diameter;
		display: inline-block;
		border: 1px solid $black;
		border-radius: 50%;
		opacity: 1;
		transition: all 0.5s;

		@include ie {
			top: 6px;
		}

		.toggle--error & {
			fill: $error-red;
			border: 1px solid $error-red;
		}

		@include media($navigationBreakpoint) {
			$diameter: 20px;
			width: $diameter;
			height: $diameter;
		}

		& [class^='icon-'] {
			transition: fill 0.5s;
		}

		&:hover:not(:disabled),
		&:active:not(:disabled) {
			background: $black;

			.toggle--error & {
				background: $error-red;
			}

			& [class^='icon-'] {
				fill: $white;
			}
		}

		&:disabled {
			border: 1px solid $gray2;
			cursor: default;

			& [class^='icon-'] {
				fill: $gray2;
			}

			&:hover {
				background: transparent;
			}
		}
	}

	&__controls {
		align-self: center;
		display: flex;
		align-items: center;

		@include ie {
			display: block;
		}
	}

	&__count {
		display: inline-block;
		text-align: center;
		font-weight: bold;
		min-width: 40px;
		margin-left: 3px;
		position: relative;
		top: -2px;

		.toggle--error & {
			color: $error-red;
		}

		@include media($navigationBreakpoint) {
			top: auto;
		}
	}
}
.caw-comp__wrapper,
.caw-popup__wrapper {
	.ToggleCount {
		border-bottom: none;

		&.adult-has-age-desc {
			.toggle:first-child {
				.toggle__label {
					margin-top: -11px;
					white-space: pre-wrap;
					@include media(1210px) {
						//adult still needs to wrap at lower screen sizes
						margin-top: 0;
						white-space: nowrap;
						&:lang(de),
						&:lang(ru) {
							margin-top: -11px;
							white-space: pre-wrap;
						}
					}
				}
			}
		}

		&.child-has-age-desc {
			.toggle:last-child {
				.toggle__label {
					margin-top: -11px;
					white-space: pre-wrap;
				}
			}
		}
	}
}
.ToggleCount {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	border-bottom: 1px solid $black;

	@include media($navigationBreakpoint) {
		padding-bottom: 0px;
	}
}

.caw-popup__wrapper {
	.ToggleCount {
		padding-top: 30px;
		max-width: none;
		&.adult-has-age-desc.child-has-age-desc {
			padding-top: 42px;
		}
	}
}

.count-enter {
	opacity: 0.1;
}

.count-enter.count-enter-active {
	opacity: 1;
	transition: opacity 0.8s;
}

.count-leave {
	opacity: 0;
}

.count-leave.count-leave-active {
	display: none;
	opacity: 1;
	transition: opacity 0.8s;
}
