.PageFooter {
	background: $black;
	color: $white;
	padding: 80px 0 45px;

	&-logo {
		text-align: center;
		margin-bottom: 60px;

		@include viewport(medium) {
			margin-bottom: 105px;
		}

		img {
			max-width: 100%;
		}
	}

	&-lists {
		@include viewport(medium) {
			margin-bottom: 30px;
		}

		@include viewport(large) {
			margin-bottom: 26px;
		}
	}

	&-listTitle {
		margin: 0 0 18px;
	}

	&-list {
		margin-bottom: 60px;
		font-size: rem(10, 16);
	}

	&-listItem {
		margin-bottom: 20px;
		line-height: 2em;

		.CTA {
			font-size: rem(10, 16);
		}
	}

	&-social {
		@include clearfix;

		display: table;
		margin-bottom: -20px;

		li {
			display: table-cell;
			vertical-align: middle;
			padding: 0 $gutter_small;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			@include rtl {
				&:first-child {
					padding-left: $gutter_small;
					padding-right: 0;
				}

				&:last-child {
					padding-right: $gutter_small;
					padding-left: 0;
				}
			}
		}
	}

	&-socialProperty {
		text-decoration: none;
		position: relative;

		&--twitter {
			@include iconButton(before, 'icon-twitter', 'dark');
			font-size: 15px;
		}

		&--facebook {
			@include iconButton(before, 'icon-facebook', 'dark');
			font-size: 16.5px;
		}

		&--instagram {
			@include iconButton(before, 'icon-instagram', 'dark');
			font-size: 15px;
		}

		&--youtube {
			@include iconButton(before, 'icon-youtube', 'dark');
			font-size: 14px;
		}
		&--pinterest {
			@include iconButton(before, 'icon-pinterest', 'dark');
			font-size: 14px;
		}
	}

	hr {
		margin: 20px 0;
		border-color: $gray5;
	}

	&-lang {
		position: relative;

		.LanguageToggle {
			margin-bottom: 30px;
		}

		.LanguageToggle-select {
			font-size: rem(10, 16);
			max-width: 110px;
		}

		@include viewport(medium) {
			.LanguageToggle {
				position: absolute;
				right: 0;
				top: -54px;

				@include rtl {
					right: auto;
					left: 0;
				}
			}
		}
	}

	&-copyright,
	&-legalList {
		@include clearfix;
	}

	&-legal {
		@include clearfix;
	}

	&-legalItem {
		float: left;
		font-size: rem(10, 16);
		position: relative;
		text-transform: none;

		&:not(:last-child)::after {
			content: '•';
			display: inline-block;
			width: 14px;
			text-align: center;
			margin-right: 6px;
		}

		@include rtl {
			float: none;
			float: right;
		}
	}

	&-legalLink {
		font-size: rem(10, 16);
		color: $white;
		transition: color 0.3s ease-out;
		text-decoration: none;
		text-transform: none;
		letter-spacing: rem(1.25, 16);

		&:link,
		&:visited {
			color: $white;
		}

		&:focus,
		&:hover {
			color: $gray2;
		}
	}

	&-copyright {
		@include clearfix;

		letter-spacing: em(1.25, 10);
		color: $gray2;
		text-transform: none;
		letter-spacing: rem(1.25, 16);
		line-height: 1.5em;

		a {
			&:link,
			&:visited {
				color: $gray2;
			}

			&:focus,
			&:hover {
				color: $white;
			}
		}
	}
}
