.ReactCarousel {
	display: flex;
	width: 100%;
	flex-direction: column;
	.ReactCarousel-carousel {
		display: flex;
		gap: 4px;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 100%;
		.ReactCarousel-items {
			display: flex;
			position: relative;
			overflow-x: scroll;
			scroll-snap-type: x mandatory;
			scroll-behavior: smooth;
			scrollbar-width: none;
			.ReactCarousel-item {
				box-sizing: content-box;
				display: flex;
				flex: none;
				scroll-snap-align: start;
				position: relative;
				width: 100%;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			&::-webkit-scrollbar {
				display: none;
			}
		}
		.ReactCarousel-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			max-width: 100%;
			position: relative;
			.ReactCarousel-dots {
				display: flex;
				width: 100%;
				justify-content: center;
				.ReactCarousel-dot {
					font-size: 0;
					width: 7px;
					height: 7px;
					border: 1px solid black;
					background: transparent;
					border-radius: 50%;
					margin: 0 5px;
					&.active {
						background: black;
						border-color: black;
					}
				}
			}
			.ReactCarousel-overlap-nav {
				z-index: 2;
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
				background: white;
				@include rtl {
					left: 0;
					right: auto;
				}
			}
		}
	}
}
