.InformationCards-Card {
	margin: 15px 0;
	&--container {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		background: $gray4;
		height: 100%;
		padding: 30px 20px;

		@include viewport(medium) {
			padding: 30px;
		}

		@include viewport(large) {
			padding: 40px;
		}
	}

	&--icon {
		.icon {
			font-size: 42px;
			width: 42px;
			line-height: 42px;
		}
		margin-bottom: 20px;
	}

	&--title {
		margin-bottom: 24px;
	}

	&--text {
		p {
			margin: 0;
		}
	}

	&--viewMore {
		&--hidden {
			display: none;
		}
		margin-top: 15px;
	}

	&.col-md-12 {
		.InformationCards-Card--container {
			@include viewport(medium) {
				padding: 40px 83px;
			}

			@include viewport(large) {
				padding: 40px 161.5px;
			}
		}
	}

	.WarningModal {
		&-cta {
			display: none;
		}
	}
}
