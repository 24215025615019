.WgTabContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	&-container {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		gap: 25px;

		@include media($breakpoints-md) {
			gap: 30px;
		}
	}

	&-heading {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 30px;
		align-self: stretch;

		@include media($breakpoints-lg) {
			gap: 40px;
		}
	}

	&-title {
		align-self: stretch;

		&-text {
			margin: 0;
		}
	}

	&-tabs {
		display: flex;
		align-items: center;
		gap: 30px;
		flex: 1 0 0;
		overflow-x: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		@include media($breakpoints-lg) {
			gap: 40px;
		}

		.WgTabContainer-tabs-arrow-left,
		.WgTabContainer-tabs-arrow-right {
			display: none;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
		}

		.WgTabContainer-tabs-arrow-left {
			left: 0;
		}

		.WgTabContainer-tabs-arrow-right {
			right: 0;
		}

		&-container {
			display: flex;
			align-items: center;
			gap: 20px;
			align-self: stretch;
			overflow-x: scroll;
			position: relative;
			overflow: hidden;

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&-tab {
		color: var(--color-text-disabled, #6b6b6b);
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		flex-shrink: 0;
		border: none;

		&--active {
			color: var(--color-text-primary, #1f1f1f);
			border-bottom: 1px solid;
		}
	}

	&-pages {
		width: 100%;
	}

	&-page {
		display: none;

		&--active {
			display: block;
		}
	}
}
