.LWCLanding {
	.Text-Box {
		&-title {
			font-size: rem(28, 16);
			@include viewport(medium) {
				font-size: rem(32, 16);
			}
		}
		&-text {
			font-size: rem(18, 16);
		}
	}

	.ComponentAccordion-title {
		font-size: rem(28, 16);
		@include viewport(medium) {
			font-size: rem(32, 16);
		}
	}
}
