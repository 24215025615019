.PrivateJetTripCard {
	&-link {
		text-decoration: initial;
		color: initial;
	}
	&-title {
		font-weight: 700;
		margin: 15px 0 5px;
	}
	&-icon-subtitle {
		&-container {
			display: flex;
			gap: 10px;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 10px;
		}
	}
	&-icon {
		width: 16px;
		line-height: 18px;
		color: var(--color-text-heading, #030303);
		&::before {
			vertical-align: top;
		}
	}

	&-destinations {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;
	}

	&-destination {
		text-wrap: nowrap;
		padding-right: 8px;
		border-right: 0.5px solid var(--color-border-default, #ffffff73);

		&:last-child {
			border-right: none;
		}
	}

	.labels-caption {
		font-size: 12px;
	}
	.SquareImageCard {
		&-featured-tag {
			z-index: 1;
		}
		&-image:hover img {
			@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
				transform: none;
			}
		}
	}
}
