.BottomNavigation {
	border: 1px solid $black;
	display: flex;
	width: 100%;

	&--dark {
		background-color: $black;
		border: 1px solid $white;
		color: $white;
	}

	&-column {
		display: flex;
		flex: 1;
		padding: 20px 15px 22px;
		width: 100%;

		@include viewport(medium) {
			align-items: center;
			padding: 18px 40px;
		}

		@include viewport(large) {
			align-items: center;
			padding: 18px 46px;
		}
	}

	&-column--right {
		border-left: 1px solid $black;
		justify-content: flex-end;
		text-align: right;

		.BottomNavigation--dark & {
			border-left: 1px solid $white;
		}
	}

	&-column-content {
		line-height: 0;
		padding-top: 16px;
		position: relative;
		width: 100%;

		@include viewport(medium) {
			padding-top: 0;
		}
	}

	&-icon {
		line-height: 1;
		position: absolute;
		top: 0;

		@include viewport(medium) {
			left: auto;
			position: relative;
			right: auto;
			top: auto;
		}
	}

	&-left-arrow {
		left: 0;
		padding-right: 10px;

		&::before {
			@include icon('icon-arrow_left');
		}
	}

	&-right-arrow {
		padding-left: 10px;
		right: 0;

		&::before {
			@include icon('icon-arrow_right');
		}
	}

	&-link {
		@include grayHover('light');
		color: $black;
		text-decoration: none;

		.BottomNavigation--dark & {
			@include grayHover('dark');
			color: $white;
		}

		@include viewport(medium) {
			align-items: center;
			display: inline-flex;
		}
	}
}
