.Checkbox {
	display: flex;
	position: relative;

	&-input {
		clip: rect(1px 1px 1px 1px);
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(1px);
		display: block;
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}

	&-decoration {
		display: block;
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		border: 1px solid $black;
		position: relative;
		cursor: pointer;
		margin-right: 16px;
		margin-top: em(2.5, 16);
		line-height: 1;
		transition: background-color 0.3s;

		@include rtl {
			margin-right: 0;
			margin-left: 16px;
		}

		.Checkbox--dark & {
			border-color: $white;
		}

		.Checkbox-input:checked ~ & {
			background-color: $black;

			.Checkbox--dark & {
				background-color: $white;
			}
		}

		.Checkbox-input:disabled ~ & {
			opacity: 0.4;
		}

		.Checkbox-input:focus ~ & {
			outline: 1px solid $black;
			outline: 3px auto -webkit-focus-ring-color;
		}

		.Checkbox-input.formElement-field--invalid ~ & {
			border-color: $error-red;
		}

		&::before {
			// Check mark image
			@include icon('icon-checkmark_simple');
			position: absolute;
			font-size: 12px;
			top: 3px;
			left: 3px;
			opacity: 0;
			color: $white;
			speak: none;
			transition: opacity 0.3s;

			.Checkbox--dark & {
				color: $black;
			}

			.Checkbox-input:checked ~ & {
				opacity: 1;
			}
		}

		.Checkbox--large & {
			width: 25px;
			height: 25px;

			&::before {
				font-size: 16px;
			}
		}
	}

	&-label {
		text-align: left;

		@include rtl {
			text-align: right;
		}

		.Checkbox-input:disabled ~ & {
			opacity: 0.4;
		}
	}

	&-icon {
		margin-right: 10px;
		&::before {
			line-height: 0;
			display: block;
		}
	}

	&--errorLabel {
		opacity: 0 !important;
		pointer-events: none;
	}

	&.checkbox-token {
		& .Checkbox {
			&-label {
				color: var(--color-text-primary, #1f1f1f);
			}

			&-decoration {
				margin-top: 0;
				border-color: var(--color-text-primary, #1f1f1f);
			}

			&-input {
				&:checked ~ .Checkbox-decoration {
					background-color: var(--color-text-primary, #1f1f1f);
					&::before {
						color: var(--color-text-inverse-primary, #1f1f1f);
					}
				}
				&:focus ~ .Checkbox-decoration {
					outline-color: var(--color-text-primary, #1f1f1f);
				}
			}

			&-icon {
				color: var(--color-text-primary, #1f1f1f);
				&::before {
					line-height: inherit;
				}
			}
		}
	}
}
