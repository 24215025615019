.ContactUsGlobal {
	.StandardHero {
		&-title {
			padding-bottom: 50px;

			@include viewport(medium) {
				padding-bottom: 80px;
			}

			@include viewport(large) {
				padding-bottom: 100px;
			}

			@include viewport(xlarge) {
				padding-bottom: 140px;
			}
		}
	}

	.ContactTable {
		margin-bottom: 40px;

		@include viewport(xlarge) {
			margin-bottom: 70px;
		}

		&-title {
			@include typography(c4);
			@include sansThin;
			letter-spacing: 2px;
			line-height: 16px;
			margin-top: 0;
			margin-bottom: 20px;

			@include viewport(medium) {
				margin-bottom: 30px;
			}
		}
	}
}
