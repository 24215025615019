.LWCHotelStatus-Card {
	display: flex;
	flex-direction: column;

	padding: 0 7.5px;

	@include viewport(medium) {
		padding: 0;
		flex-direction: row;
		align-items: center;
	}

	&-Column {
		@include viewport(medium) {
			margin: 20px 0;
		}
	}

	&-Property {
		&-Container {
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			@include viewport(medium) {
				padding-right: 30px;

				@include rtl {
					padding-right: 0;
					padding-left: 30px;
				}
				width: 270px;
			}

			@include viewport(large) {
				width: 400px;
			}
		}
		&-Name {
			padding-top: 4.5px;
			margin-bottom: 10px;
			margin-right: 30px;

			@include rtl {
				margin-left: 30px;
				margin-right: 0;
			}
			position: relative;
			text-decoration: underline;
			text-underline-offset: 2px;

			@include viewport(medium) {
				margin-bottom: 16px;
			}

			&::after {
				position: absolute;
				padding-left: 12px;

				@include rtl {
					padding-left: 0;
					padding-right: 12px;
				}
			}
		}
		&-Status {
			padding-bottom: 10px;

			@include viewport(medium) {
				padding-bottom: 4.5px;
			}
		}
	}

	&-Icons-Container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-right: 1px solid $gray3;
		border-left: 1px solid $gray3;

		height: 32px;
		margin-top: 0;
		margin-bottom: 14px;

		@include viewport(medium) {
			justify-content: flex-start;
			flex-wrap: wrap;
			width: 204px;
			height: 60px;
			margin: auto 0;
		}

		@include viewport(large) {
			flex-wrap: nowrap;
			width: 334px;
		}
	}

	&-Icon {
		font-size: 18px;
		line-height: 18px;
		width: 18px;

		@include viewport(large) {
			font-size: 24px;
			line-height: 24px;
			width: 24px;
		}
		&-Container {
			display: flex;
			padding-left: 13px;
			padding-right: 7px;

			@include rtl {
				padding-left: 7px;
				padding-right: 13px;
			}

			&:first-child {
				padding-left: 26px;

				@include rtl {
					padding-left: 7px;
					padding-right: 26px;
				}
			}
			&:last-child {
				padding-right: 18px;

				@include rtl {
					padding-right: 13px;
					padding-left: 18px;
				}
			}

			@include viewport(medium) {
				justify-content: flex-start;
				padding-left: 15px;
				padding-right: 9px;

				@include rtl {
					padding-left: 9px;
					padding-right: 15px;
				}

				height: 24px;
				&:first-child {
					padding-left: 15px;

					@include rtl {
						padding-left: 9px;
						padding-right: 15px;
					}
				}
				&:last-child {
					padding-right: 15px;

					@include rtl {
						padding-left: 15px;
						padding-right: 9px;
					}
				}
			}

			&--empty {
				@include viewport(medium) {
					display: none;
				}

				@include viewport(large) {
					display: flex;
				}
			}
		}
	}

	&-Booking-Container {
		display: flex;
		margin-bottom: 20px;

		@include viewport(medium) {
			align-items: center;
			justify-content: center;
			margin-left: 30px;

			@include rtl {
				margin-left: 0;
				margin-right: 30px;
			}
		}
	}

	&-CheckRates {
		padding: 7.5px 10px;

		@include viewport(medium) {
			padding: 7.5px 20px;
		}
	}
}
