.Error {
	background-color: #e00202;
	padding-top: 7px;
	margin-bottom: 40px;

	&-text {
		color: $white;

		a {
			color: $white;
			text-decoration: none;
			position: relative;
			display: inline-block;

			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: 5px;
				height: 1px;
				left: 0;
				width: 100%;
				border-bottom: 1px solid $white;
			}

			&:hover {
				text-decoration: none;
			}
		}

		button {
			display: inline-block;
			appearance: none;
			border-radius: 0;
			@include link('underlined', 'dark');
		}

		&__description {
			display: block;

			@include media($navigationBreakpoint) {
				display: inline-block;
				margin-right: 28px;

				@include rtl {
					margin-left: 28px;
					margin-right: 0;
				}
			}
		}
	}
}
