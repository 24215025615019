.HideSelectStatic {
	.SignatureCard-textContent-container {
		&.DynamicProduct {
			display: block;
		}

		&.StaticProduct {
			display: none;
		}
	}
}
