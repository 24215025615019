.HoverCardGrid-GenericCard {
	position: relative;
	color: $white;
	margin-bottom: 15px;
	overflow: hidden;

	@include viewport(medium) {
		margin-bottom: 30px;
	}

	&--dark {
		border: 1px solid $gray1;
	}

	&-image {
		padding-top: 125%;

		&::after {
			@include gradient2transparent($height: 70%, $color: rgba(0, 0, 0, 1));
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
		}
	}

	&-overlay {
		display: flex;
		flex-direction: column;
		z-index: 2;
		position: absolute;
		bottom: 30px;
		left: 30px;
		margin-right: 30px;
		pointer-events: none;
		overflow-wrap: anywhere;

		@include viewport(large) {
			bottom: 20px;
			left: 20px;
			margin-right: 20px;
		}
	}

	&-title {
		margin: 0;
		margin-bottom: 10px;
		line-height: 1.5em;
	}
	&-description {
		margin: 0;
		height: 0;
		line-height: 1.5em;
		transition: all 0.3s ease-out;
		overflow: hidden;
		&--show {
			height: auto;
			margin-bottom: 20px;
		}
	}
	&-subtext {
		margin: 0;
	}
	&-cta {
		margin-right: auto;

		@include rtl {
			margin-right: 0;
			margin-left: auto;
		}

		&::after {
			display: none;
		}
	}

	&:hover,
	&:focus,
	&:focus-within {
		@include viewport(large) {
			.HoverCardGrid-GenericCard-image::after {
				height: 100%;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%);
				backdrop-filter: blur(13px);
			}
			.HoverCardGrid-GenericCard-cta {
				font-weight: bold;
				&::after {
					display: inline-block;
				}
			}
		}
	}
}
