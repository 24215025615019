.RichText {
	@include rtl {
		direction: rtl;
	}

	&--light {
		color: $black;
	}

	&--dark {
		color: $white;
	}

	h1 {
		@include typography(h1);
	}

	h2 {
		@include typography(h2);
	}

	h3 {
		@include typography(h3);
	}

	h4 {
		@include typography(h4);
	}

	h5 {
		@include typography(c1);
	}

	h6 {
		@include typography(c3);
	}

	[style*='text-align: right'] {
		@include rtl {
			text-align: left !important;
		}
	}

	[style*='text-align: left'] {
		@include rtl {
			text-align: right !important;
		}
	}

	a {
		line-height: 1em;
		display: inline-block;
		background: none;
		cursor: pointer;
		border: 0;
		padding: 0;
		text-decoration: none;
		color: inherit;
		border-bottom: 1px solid;
		font-weight: bold;
		transition: color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;

		&:visited {
			color: inherit;
		}
		&:focus,
		&:hover {
			border-color: $whover;
			color: $whover;
		}

		[class*='--dark'] & {
			&:focus,
			&:hover {
				border-color: $bhover;
				color: $bhover;
			}
		}
	}

	ul,
	ol {
		padding-left: 40px;

		@include rtl {
			padding-right: 40px;
			padding-left: 0;
		}
	}

	li {
		padding-bottom: em(5.5, 18);
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}

	ul li {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: -17px;
			top: 8px;
			border: 2px solid;
			border-radius: 10px;
		}

		@include rtl {
			&::before {
				left: auto;
				right: -17px;
			}
		}
	}

	blockquote {
		p {
			font-style: italic;
			padding-left: 30px;
			border-left: 1px solid $gray2;

			@include rtl {
				padding-left: 0;
				border-left: none;

				padding-right: 30px;
				border-right: 1px solid $gray2;
			}
		}
	}
}
