.PrivateJetFilter {
	&
		.WideGridFilter-bar-filterDropdown-container
		+ .WideGridFilter-bar-filterButton-container:not(.WideGridFilter-bar-allFilters-container) {
		padding-left: 15px;
		border-left: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
		margin-left: 5px;

		@include rtl {
			padding-left: unset;
			border-left: none;
			margin-left: unset;
			padding-right: 15px;
			border-right: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
			margin-right: 5px;
		}
	}
	&
		.WideGridFilter-bar-filterDropdown-container
		+ .WideGridFilter-bar-filterButton-container.WideGridFilter-bar-allFilters-container {
		@include viewport(large) {
			padding-left: 15px;
			border-left: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
			margin-left: 5px;

			@include rtl {
				padding-left: unset;
				border-left: none;
				margin-left: unset;
				padding-right: 15px;
				border-right: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
				margin-right: 5px;
			}
		}
	}
}
