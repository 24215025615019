.LWCHotelStatus-TableHeading {
	display: flex !important;
	position: relative;
	margin-bottom: -1px;
	&-Item {
		padding: 26px 0;
		text-align: left;
		z-index: 1;
		display: none;

		@include rtl {
			text-align: right;
		}

		@include viewport(medium) {
			display: block;
		}

		@include viewport(large) {
			@include typography(c1);
		}
	}

	&--property {
		width: 270px;

		@include viewport(large) {
			width: 400px;
		}
	}

	&--amenities {
		width: 204px;

		@include viewport(large) {
			width: 334px;
		}
	}

	&--book {
		margin-left: 30px;

		@include rtl {
			margin-left: 0;
			margin-right: 30px;
		}
	}

	&--mobile {
		display: block;
		text-align: center;
		margin: auto;

		@include viewport(medium) {
			display: none;
		}
	}

	&--background {
		height: 100%;
		width: 100vw;
		position: absolute;
		background-color: $gray4;
		border-top: 1px solid #989898;
		border-bottom: 1px solid #989898;
		left: 50%;
		transform: translateX(-50%);
		max-width: calc(100vw - 30px);

		@include viewport(medium) {
			max-width: 690px;
		}

		@include viewport(large) {
			max-width: 1040px;
		}
	}
}
