.IconTextLinks-link {
	&--Wrapper {
		display: flex;
		width: 100%;
	}
	&--column {
		display: flex;
		flex-wrap: wrap;
	}
	&--container {
		display: flex;
		flex-direction: row;
		text-decoration: none;
		margin-bottom: 24px;
		padding: 21px;
		text-align: left;
		align-items: center;
		height: 100%;
		width: 100%;

		@include rtl {
			text-align: right;
		}

		&--dark {
			border: 0.5px solid change-color($gray3, $alpha: 0.1);
			background-color: $gray1;
			color: $white;
			&:hover,
			&:focus {
				@include viewport(medium) {
					background-color: $gray5;
					box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
				}
			}
		}

		&--light {
			border: 0.5px solid #d8d8d8;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
			background-color: $white;
			color: $black;
			&:hover,
			&:focus {
				@include viewport(medium) {
					background-color: #f5f5f5;
					box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
				}
			}
		}
	}

	&--textContainer {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
	}
	&--icon {
		font-size: 30px;
		margin-right: 17px;

		@include rtl {
			margin-left: 17px;
			margin-right: 0;
		}
	}

	&--subText {
		font-size: 15px;
		letter-spacing: 0.5px;
	}
	&--text {
		word-break: normal;
		letter-spacing: 3px;
		width: 100%;

		@include viewport(large) {
			font-size: 12px;
			word-break: normal;
		}

		&::after {
			@include icon('icon-arrow_right');
			padding-left: 5px;
			display: inline-block;
			font-size: 12px;
			vertical-align: middle;
		}

		@include rtl {
			&::after {
				@include icon('icon-arrow_left');
				padding-right: 5px;
				padding-left: 0;
				display: inline-block;
				font-size: 12px;
				vertical-align: middle;
			}
		}
	}

	&--Horizontal {
		@include viewport(medium) {
			.IconTextLinks-link--container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			.IconTextLinks-link--icon {
				margin-bottom: 21px;
			}
		}

		@include viewport(large) {
			.IconTextLinks-link--container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}
			.IconTextLinks-link--icon {
				margin-bottom: 21px;
			}
		}
	}
}
