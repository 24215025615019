$InlineCarousel-img-margin-bottom: 30px;
$InlineCarousel-img-spacing: (
	small: 15px,
	medium: 20px,
	large: 30px,
);
$InlineCarousel-cols: (
	small: 10,
	medium: 10,
	large: 12,
);

.InlineCarousel {
	&-title {
		margin-bottom: 30px;
		text-align: center;

		.Heading-title {
			margin: 0 auto;
			padding: 0;
			@include viewport(medium) {
				padding: 0 15px;
			}
		}
	}

	&-list {
		list-style-type: none;
		margin: 0;
		padding: 0;

		&:lang(ja) {
			font-size: 1rem;
		}

		.CarouselSlide {
			margin-bottom: 80px;
			width: 100%;

			&:lang(ja) {
				font-size: 1rem;
			}

			.ImageAndCta {
				.Image {
					margin-bottom: $InlineCarousel-img-margin-bottom;
					opacity: 1;
					transition: opacity 0.5s;
				}

				&:lang(ja) {
					font-size: 1rem;
				}

				.CtaBlock {
					text-align: left;

					@include viewport(medium) {
						width: 50%;
					}

					@include rtl {
						text-align: right;
					}

					&-title {
						@include typography(c1);
					}

					&-text {
						@include typography(b2);
						margin: 10px 0 0;

						&:lang(ja) {
							font-size: 1rem;
						}
					}

					&-cta {
						display: inline-block;
						margin-top: 10px;
						&:lang(zh-TW),
						&:lang(zh) {
							font-size: 1.125rem;
						}

						@include viewport(medium) {
							margin-top: 30px;
						}
					}
				}
			}

			&.is-selected {
				.CarouselSlide--grip {
					position: absolute;
					left: 0;
					width: calc(50% - 55px);
					height: 62%;
					top: 0;
					bottom: 130px;
					z-index: 3;

					&::before {
						position: absolute;
						display: block;
						content: '';
						width: 50%;
						height: 74%;
						top: 0;
						left: 100%;
					}

					+ .CarouselSlide--grip {
						left: auto;
						right: 0;

						&::before {
							content: '';
							height: 12%;
							top: auto;
							bottom: 0;
							right: 100%;
							left: auto;
						}
					}
				}
			}

			&-video {
				position: relative;
				margin-bottom: $InlineCarousel-img-margin-bottom;

				.ThirdPartyVideo {
					margin-top: $InlineCarousel-img-margin-bottom;
				}
			}
		}
	}

	&-cta {
		&:lang(zh-TW),
		&:lang(zh) {
			font-size: 1.125rem;
			line-height: 0.9em;

			/* fixes issue with bottom border being covered on hover */
			padding-top: 11px;
			padding-bottom: 11px;
		}

		&-wrapper {
			text-align: center;
			margin-top: 30px;
			width: 100%;

			@include viewport(medium) {
				right: 50%;
				margin-top: 0;
				position: absolute;
				text-align: right;
				transform: translateX(50%);

				@include rtl {
					text-align: left;
				}
			}
		}
	}
}

@function getSlideSpace($viewport: small, $extraGutter: true, $multiplier: 1) {
	$gutterMultiplier: if($extraGutter, 1.5, 1);

	@if $viewport == small {
		// prettier-ignore
		@return calc(#{$multiplier} * (#{get-columns-vw(map-get($InlineCarousel-cols, small), true)} + (#{map-get($InlineCarousel-img-spacing, small) * $gutterMultiplier})));
	} @else {
		// prettier-ignore
		@return $multiplier * (get-columns-fixed-unit(map-get($InlineCarousel-cols, $viewport), $viewport) + (map-get($InlineCarousel-img-spacing, $viewport) * $gutterMultiplier));
	}
}

@mixin slideSpace($position: 'right') {
	@if $position == 'right' {
		@include viewport(small) {
			margin-right: getslidespace();
		}

		@include viewport(medium) {
			margin-right: getslidespace($viewport: medium);
		}

		@include viewport(large) {
			margin-right: getslidespace($viewport: large);
		}
	} @else {
		@include viewport(small) {
			margin-left: getslidespace();
		}

		@include viewport(medium) {
			margin-left: getslidespace($viewport: medium);
		}

		@include viewport(large) {
			margin-left: getslidespace($viewport: large);
		}
	}
}

@mixin spaceBtwSlides {
	@include viewport(small) {
		margin: 0 map-get($InlineCarousel-img-spacing, small) / 2;
	}

	@include viewport(medium) {
		margin: 0 map-get($InlineCarousel-img-spacing, medium) / 2;
	}

	@include viewport(large) {
		margin: 0 map-get($InlineCarousel-img-spacing, large) / 2;
	}
}

.InlineCarousel--js {
	overflow: hidden;
	position: relative;

	&.InlineCarousel--transition {
		.InlineCarousel-button {
			opacity: 0;
			transition: none;
		}
	}

	/* Chevrons */
	.InlineCarousel-button {
		display: block;
		position: absolute;
		opacity: 1;
		text-align: center;
		top: 0;
		transition: opacity 0.5s ease-out;
		z-index: 1;

		&[hidden] {
			display: none;
		}

		.touch & {
			display: none;
		}

		@include viewport(small) {
			height: calc(
				(#{get-columns-vw(map-get($InlineCarousel-cols, small), true)}) * (9 / 16)
			);
			line-height: calc(
				(#{get-columns-vw(map-get($InlineCarousel-cols, small), true)}) * (9 / 16)
			);
			margin: 0 calc((#{get-columns-vw(map-get($InlineCarousel-cols, small), true)}) / 2);
			width: map-get($InlineCarousel-img-spacing, small);
		}

		@include viewport(medium) {
			height: (
				get-columns-fixed-unit(map-get($InlineCarousel-cols, medium), medium) * (9/16)
			);
			line-height: (
				get-columns-fixed-unit(map-get($InlineCarousel-cols, medium), medium) * (9/16)
			);
			margin: 0 (get-columns-fixed-unit(map-get($InlineCarousel-cols, medium), medium) / 2);
			width: map-get($InlineCarousel-img-spacing, medium);
		}

		@include viewport(large) {
			height: (get-columns-fixed-unit(map-get($InlineCarousel-cols, large), large) * (9/16));
			line-height: (
				get-columns-fixed-unit(map-get($InlineCarousel-cols, large), large) * (9/16)
			);
			margin: 0 (get-columns-fixed-unit(map-get($InlineCarousel-cols, large), large) / 2);
			width: map-get($InlineCarousel-img-spacing, large);
		}
	}

	.InlineCarousel-prevButton {
		right: 50%;

		@include rtl {
			left: 50%;
			right: auto;
		}

		&:hover,
		&:focus {
			& ~ .InlineCarousel-list .CarouselSlide--prev {
				.Image {
					opacity: 0.5;
					transition: opacity 0.5s;
				}
			}
		}
	}

	.InlineCarousel-nextButton {
		left: 50%;

		@include rtl {
			left: auto;
			right: 50%;
		}

		&:hover,
		&:focus {
			& ~ .InlineCarousel-list .CarouselSlide--next {
				.Image {
					opacity: 0.5;
					transition: opacity 0.5s;
				}
			}
		}
	}

	.InlineCarousel-list {
		position: relative;

		&:lang(ja) {
			font-size: 1rem;
		}

		.CarouselSlide {
			opacity: 1;
			width: get-columns-vw(map-get($InlineCarousel-cols, small));
			transition: opacity 0.5s;

			&:lang(ja) {
				font-size: 1rem;
			}

			@include viewport(medium) {
				width: get-columns-fixed-unit(map-get($InlineCarousel-cols, medium), medium);
			}

			@include viewport(large) {
				width: get-columns-fixed-unit(map-get($InlineCarousel-cols, large), large);
			}

			/* Add spacing between slides */
			@include spaceBtwSlides;

			.Image {
				display: block;
			}

			.ImageAndCta .CtaBlock {
				margin: 0;
			}

			&.is-selected {
				.CtaBlock {
					opacity: 1;
					transition: opacity 1s ease-in;
				}

				.Image {
					opacity: 1;
					transition: opacity 1s ease-in;
				}
			}

			&:not(.is-selected) {
				cursor: pointer;

				.Image {
					opacity: 0.6;
					transition: opacity 1s ease-out;

					&:hover {
						opacity: 0.5;
						transition: opacity 0.5s;
					}
				}

				.CtaBlock {
					opacity: 0;
					transition: opacity 1s ease-out;
				}
			}
		}
	}

	/* prettier-ignore */
	.InlineCarousel-cta-wrapper {
		@include viewport(medium) {
			top: (($InlineCarousel-img-margin-bottom * 2) + get-columns-fixed-unit(map-get($InlineCarousel-cols, medium), medium) * (9/16));
			width: get-columns-fixed-unit(map-get($InlineCarousel-cols, medium), medium);
		}

		@include viewport(large) {
			top: (($InlineCarousel-img-margin-bottom * 2) + get-columns-fixed-unit(map-get($InlineCarousel-cols, large), large) * (9/16));
			width: get-columns-fixed-unit(map-get($InlineCarousel-cols, large), large);
		}
	}
}

.InlineCarousel--single {
	position: relative;

	.CarouselSlide {
		margin-bottom: 0;

		&:lang(ja) {
			font-size: 1rem;
		}
	}

	.InlineCarousel-button {
		display: none;
	}

	.InlineCarousel-cta-wrapper {
		transform: none;

		@include viewport(medium) {
			top: (($InlineCarousel-img-margin-bottom * 2) + $containerWidth-md * (9/16));
			right: 15px;

			@include rtl {
				right: auto;
				left: 15px;
			}
		}

		@include viewport(large) {
			top: (($InlineCarousel-img-margin-bottom * 2) + $containerWidth-lg * (9/16));
		}

		@include viewport(xlarge) {
			top: (($InlineCarousel-img-margin-bottom * 2) + $containerWidth-xl * (9/16));
		}
	}
}

//Themes
.InlineCarousel {
	&--dark {
		.Heading-title {
			color: $white;
		}
	}

	&--light {
		.Heading-title {
			color: $black;
		}
	}

	@mixin lllCarousel($color) {
		.Heading {
			margin-bottom: -10px;

			@include viewport(medium) {
				margin-bottom: 10px;
			}

			@include viewport(large) {
				margin-bottom: 0;
			}

			&-title {
				color: $color;
				font-family: Saol Display;
				font-weight: 300;
				letter-spacing: 0px;
				font-size: 40px;
				line-height: 35px;

				@include viewport(medium) {
					font-size: 60px;
					line-height: 60px;
				}

				@include viewport(large) {
					line-height: 55px;
				}

				i {
					margin-right: 3px;
					@include viewport(medium) {
						margin-right: 5px;
					}
					@include rtl {
						margin-right: 0;
						margin-left: 3px;
						@include viewport(medium) {
							margin-left: 5px;
						}
					}
				}
			}
		}
	}

	&--greenPink {
		@include lllCarousel($lll-pink);
	}
	&--greenGreen {
		@include lllCarousel($lll-green);
	}
	&--blueGreen {
		@include lllCarousel($lll-lightgreen);
	}
	&--pinkRed {
		@include lllCarousel($lll-red);
	}
	&--lightBlueGreen {
		@include lllCarousel($lll-green);
	}
	&--yellowOrange {
		@include lllCarousel($lll-orange);
	}
	&--white {
		@include lllCarousel($black);
	}
	&--black {
		@include lllCarousel($white);
	}
	&--lightOrange {
		@include lllCarousel($lll-lightorange);
	}
	&--yellow {
		@include lllCarousel($lll-yellow);
	}
	&--lightYellow {
		@include lllCarousel($lll-lightyellow);
	}
	&--pastelGreen {
		@include lllCarousel($lll-pastelgreen);
	}
	&--blue {
		@include lllCarousel($lll-blue);
	}
	&--lightBlue {
		@include lllCarousel($lll-lightblue);
	}
}
