/**
 * 1. Make the dialog container, and its child overlay spread across
 *    the entire window.
 */
.Dialog,
.Dialog-overlay {
	position: fixed; /* 1 */
	top: 0; /* 1 */
	right: 0; /* 1 */
	bottom: 0; /* 1 */
	left: 0; /* 1 */
}

/**
  * 1. Make sure the dialog container and all its descendants sits on
  *    top of the rest of the page.
  * 2. Make the dialog container a flex container to easily center the
  *    dialog.
  */
.Dialog {
	z-index: 999; /* 1 */
	display: flex; /* 2 */
	/**
  * 1. Make sure the dialog container and all its descendants are not
  *    visible and not focusable when it is hidden.
  */
	&.hidden {
		visibility: hidden;
		z-index: -1;
	}
	&[aria-hidden='true'] {
		.Dialog-wrapper {
			transform: translateY(100%);
		}
		@include viewport(large) {
			display: none;
		}
	}
	//Use the dialogminvh set in Dialog.tsx
	max-height: calc(var(--dialogminvh, 1vh) * 100);
	/**
  * 1. Make the overlay look like an overlay.
  */
	.Dialog-overlay {
		background-color: rgba(0, 0, 0, 0.5); /* 1 */
		@include viewport(large) {
			background-color: rgba(43, 46, 56, 0.9); /* 1 */
		}
		display: flex;
	}
}

/**
  * 1. Position the dialog in the page.
  * 2. Make sure the dialog sits on top of the overlay.
  * 3. Make sure the dialog has an opaque background.
  */
.Dialog-wrapper {
	margin-top: auto; /* 1 */
	z-index: 999; /* 2 */
	position: relative; /* 2 */
	background-color: white; /* 3 */
	width: 100vw;
	height: 100%;
	border-radius: 15px 15px 0px 0px;
	transform: translateY(0%);
	transition: transform 0.5s;
	@include viewport(large) {
		width: 690px;
		margin: auto; /* 1 */
		max-height: 100%;
		overflow: auto;
		border-radius: 0;
		transition: none;
	}
	.Dialog-closebutton {
		position: absolute;
		right: 25px;
		top: 35px;
		border: 0;
		padding: 0;
		background-color: transparent;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		z-index: 9999;
		overflow: visible;
		@include rtl {
			left: 35px;
			right: auto;
			@include viewport(large) {
				left: 45px;
			}
		}
		@include viewport(large) {
			right: 45px;
		}
	}
	.Dialog-content {
		display: flex;
		gap: 30px;
		flex-direction: column;
		height: 100%;
		padding: 75px 0 50px 0;
		@include viewport(large) {
			padding: 75px 40px 50px 40px;
			height: auto;
			overflow: auto;
			max-height: 100%;
		}
		.Dialog-content-title {
			@include typography(c1);
			margin: 0 20px;
			&::after {
				content: '';
				display: block;
				position: relative;
				top: 30px;
				width: 100%;
				height: 1px;
				left: 0;
				border-bottom: 1px solid #d8d8d8;
			}
			@include viewport(large) {
				margin: 0;
				border: none;
			}
		}
		.Dialog-content-content {
			width: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			padding: 0 20px;
			@include viewport(large) {
				padding: 0;
			}
		}
		.Dialog-divider {
			width: 100%;
			height: 0;
			border-bottom: 1px solid $gray3;
		}

		.Dialog-cta {
			margin-top: auto;
		}
		&-oneItemContainer {
			display: flex;
			flex-direction: column;
			border-top: 1px solid #d8d8d8;
		}
		&-oneItemTitle {
			padding-top: 30px;
			padding-bottom: 20px;
		}
	}
	//don't use the default title for display
	.Dialog-title {
		display: none;
	}
}
.Dialog-wrapper-95 {
	height: 95%;
}
