.PrivateJetTripDetail {
	.CarouselSlide--emptyCTABlock {
		.ImageAndCta .Image {
			margin-bottom: 0;
		}
		.CtaBlock--empty {
			display: none;
		}
	}
}
