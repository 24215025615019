.DiningContactBarWithLegalModal {
	.WarningModal-content {
		padding: 0;
		text-align: left;
		overflow-y: auto;
		height: auto;
		max-height: 95%;
		width: 100%;
		left: 50%;
		position: absolute;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		overflow-x: hidden;

		@include viewport(medium) {
			margin: auto;
		}

		@include viewport(large) {
			width: 830px;
		}
	}
}
