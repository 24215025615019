.ImageAndText {
	&-picture {
		display: block;
		font-size: 0;
		line-height: 0;
		margin-bottom: 40px;

		@include viewport(medium) {
			margin-bottom: 0;
			margin-right: 30px;
			flex: 1;

			@include rtl {
				margin-right: 0;
				margin-left: 30px;
			}
		}

		.Image-picture {
			top: 50%;
			height: auto;
			transform: translateY(-50%);
		}

		img {
			width: 100%;
		}
	}

	&-primaryHeading {
		.Heading-title {
			@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);

			text-align: center;
			margin-top: 0;
		}
	}

	&-secondaryHeading {
		display: flex;
		flex-direction: column;
	}

	&-container {
		@include viewport(medium) {
			display: flex;
			flex-direction: row;
		}
	}

	&-text {
		text-align: left;

		@include rtl {
			text-align: right;
		}

		@include viewport(medium) {
			flex: 1;
		}

		.Heading {
			&-title {
				position: relative;
				margin-top: 0;
				margin-bottom: 27px;

				@include viewport(large) {
					margin-bottom: 42px;
				}

				&::after {
					border-bottom: 2px solid currentColor;
					bottom: -15px;
					content: '';
					left: 0;
					position: absolute;
					width: 40px;

					@include viewport(large) {
						bottom: -20px;
					}

					@include viewport(xlarge) {
						width: 53px;
					}
				}

				@include rtl {
					&::after {
						left: inherit;
						right: 0;
					}
				}
			}

			&-subtitle {
				@include typography(c2);

				display: block;
				margin-bottom: 40px;

				&:nth-last-of-type(2) {
					margin-bottom: 0;
				}
			}

			&-text {
				@include typography(b2);

				order: 1;
				margin: 0;

				&:lang(ja) {
					font-size: 1rem;
				}
			}

			&-quote {
				@include typography(q2);

				.Quote-text {
					margin-top: 0;
					margin-bottom: 20px;

					@include viewport(medium) {
						margin-bottom: 30px;
					}

					@include viewport(large) {
						margin-bottom: 40px;
					}
				}
			}
		}
	}

	&--right-aligned {
		.ImageAndText {
			&-picture {
				@include viewport(medium) {
					margin-right: 0;
					margin-left: 30px;

					@include rtl {
						margin-right: 30px;
						margin-left: 0;
					}
				}
			}

			&-container {
				@include viewport(medium) {
					flex-direction: row-reverse;
				}
			}

			&-text {
				@include viewport(medium) {
					text-align: right;

					@include rtl {
						text-align: left;
					}

					.Heading {
						&-title {
							&::after {
								left: inherit;
								right: 0;
							}

							@include rtl {
								&::after {
									left: 0;
									right: inherit;
								}
							}
						}
					}
				}
			}
		}
	}
}
