.textIconsList {
	&-title {
		display: flex;
		justify-content: center;
		text-transform: uppercase;
		width: 100%;
		padding-bottom: 40px;
		margin: 0;
	}
	.icon {
		font-size: 56px;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		height: 56px;
		padding-right: 24px;

		@include rtl {
			padding-right: 0px;
			padding-left: 24px;
		}
		@include viewport(large) {
			justify-content: center;
			padding-right: 0px;
			@include rtl {
				padding-left: 0px;
			}
		}
	}

	&-container {
		display: flex;
		flex-direction: column;

		@include viewport(large) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	&-textIcons-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding-bottom: 30px;

		@include viewport(large) {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: column;
			padding-right: 30px;
			flex-basis: 33%;
			padding-bottom: 60px;

			@include rtl {
				padding-right: 0px;
				padding-left: 30px;
			}
		}
	}

	&-container.textIconsList-container--4 .textIconsList-textIcons-container {
		@include viewport(large) {
			flex-basis: 25%;
		}
	}
	&-titleDescriptionContainer {
		@include viewport(large) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
	&-iconDescription {
		@include viewport(large) {
			text-align: center;
		}
	}
	&-iconTitle {
		padding-bottom: 10px;
	}
}
