.PropertyCard {
	padding: 6px 1rem 1rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background-color: $white;
	color: $black;
	margin: auto;
	width: 261px;
	border: 1px solid #d8d8d8;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;

	h3 {
		font-size: 12px;
		text-align: left;
	}

	.IndicatorColor {
		min-width: 12px;
		min-height: 12px;
	}

	h3::after {
		content: '';
		position: absolute;
		height: 1px;
		width: 100%;
		position: absolute;
		background-color: #d8d8d8;
		top: 100%;
		left: 0;
	}

	.IconContainer {
		display: flex;
		flex-direction: column;
		row-gap: 15px;
		padding-top: 10px;
	}

	.TitleContainer {
		position: relative;
		display: flex;
		column-gap: 10px;
		flex-direction: row;
		align-items: center;
	}
	.PropertyCard-CTA {
		cursor: pointer;
		height: 30px;
		margin-top: 20px;
		width: 100%;
		.CTA {
			height: 30px;
			width: 100%;
		}
	}

	.icon {
		color: $black;
		font-size: 15px;
		display: flex;
		align-items: center;
		gap: 10px;
		&.hideMobile {
			display: none;
			@include viewport(medium) {
				display: flex;
			}
		}
		&:before {
			font-size: 25px;
		}
	}
}
