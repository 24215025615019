/* prettier-ignore */
$_quote-types: (
	type1: '\00AB' '\00BB',
	type2: '\201E' '\201C',
	type3: '\27EA' '\27EB',
	type4: '\23A1' '\23A6',
	type5: '\201C' '\201D'
);
$_internationalization-quotes: (
	ar: type1,
	ru: type1,
	pt: type1,
	it: type1,
	fr: type1,
	de: type2,
	ko: type3,
	ja: type4,
	en: type5,
	es: type5,
	pt-br: type5,
);

@each $language, $charType in $_internationalization-quotes {
	:lang(#{$language}) {
		quotes: map-get($_quote-types, $charType);
	}
}

@mixin quote($display: inline, $theme: 'light') {
	@if $display == block {
		@include typography(q1);
		@include quotationIcons($position: 'after', $type: 'close', $theme: $theme);
		@include quotationIcons($position: 'before', $type: 'open', $theme: $theme);

		&::before {
			@include viewport(small) {
				margin-bottom: 15px;
			}

			@include viewport(large) {
				margin-bottom: 20px;
			}

			@include viewport(xlarge) {
				margin-bottom: 30px;
			}
		}

		&::after {
			@include viewport(small) {
				margin-top: 15px;
			}

			@include viewport(large) {
				margin-top: 20px;
			}

			@include viewport(xlarge) {
				margin-top: 30px;
			}
		}
	}

	@if $display == inline {
		@include typography(q2);

		&::before {
			content: open-quote;
		}

		&::after {
			content: close-quote;
		}
	}

	&::before,
	&::after {
		display: $display; // This is to use the quotes as block or inline character
	}
}
