.MeetingsAndEvents {
	.BodySection--dark {
		.CardCarousel-card {
			.Card {
				&-container {
					background-color: $gray4;
				}

				&-body {
					background-color: $gray4;
				}

				&-heading {
					color: $black;
				}

				&-cta {
					color: $black;
					border-color: $black;

					&:hover {
						color: $bhover;
						border-color: $bhover;
					}
				}
			}
		}
	}
}
