@import '../navigation/variables';

.EliteGuestCard {
	position: relative;
	max-width: 310px;
	margin: auto;
	margin-top: 35px;
	margin-bottom: 50px;
	padding: 35px 20px 50px;
	background-color: $white;
	color: $black;
	text-align: center;
	box-shadow: 0 1px 30px 4px rgba(0, 0, 0, 0.12);
	z-index: 1;

	.Navigation--hasHero + .Hero + & {
		transform: translateY(-60px);
	}

	@include media($navigationBreakpoint) {
		max-width: 100%;
		padding: 25px 30px;
		margin-bottom: 0;
		box-shadow: 0 10px 10px rgba($black, 0.3);
	}

	.a {
		padding: 0;
		margin: 0;
	}

	&-ctas {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include media($navigationBreakpoint) {
			align-items: flex-start;
		}

		&-email,
		&-phone {
			display: block;
			word-break: break-all;

			@include media($navigationBreakpoint) {
				display: inline-flex;
				margin-bottom: 0;
			}

			a {
				font-size: 10px;
				letter-spacing: 2.5px;
			}

			&::before {
				vertical-align: middle;
				width: 30px;
				height: 30px;
				margin: auto;
				font-size: 20px;

				@include viewport(small) {
					display: block;
					padding-bottom: 5px;
				}

				@include media($navigationBreakpoint) {
					margin-right: 10px;
					padding-bottom: 0;
				}
			}
		}

		&-email {
			word-break: break-all;

			&::before {
				@include icon('icon-mail');
				font-size: 16px;
			}
		}

		&-phone {
			margin-bottom: 30px;

			@include media($navigationBreakpoint) {
				margin-bottom: 12px;
			}

			&::before {
				@include icon('icon-call_alt_inverted');
				font-size: 20px;
			}
		}
	}

	.Hero ~ & {
		margin-bottom: 0;

		@include media($navigationBreakpoint) {
			display: none;
		}
	}

	a {
		outline: none;
		color: $black;
	}

	&-logo {
		margin: 0;
		margin-bottom: 15px;
		color: $black;
		font-size: 40px;
		line-height: 0;
		font-weight: bold;

		&::before {
			@include icon('icon-tree');
			display: inline-block;
			line-height: 1;
			color: $black;
		}
	}

	&-textContainer {
		@media (min-width: $navigationBreakpoint) {
			margin-right: 75px;
		}
	}

	&-title {
		display: none;
		text-align: left;
		margin-top: 0;

		@include typography(h3);

		@media (min-width: 1100px) {
			display: block;
		}
	}

	&-text {
		margin: 0 auto 30px;
		max-width: 325px;

		@include media($navigationBreakpoint) {
			margin-bottom: 0;
		}
	}

	&-email {
		font-family: $sansFont;

		@include link('underlined', 'dark');
		width: fit-content;
		margin: auto;

		@include viewport(small) {
			font-size: 2.3vw;
		}

		@include viewport(medium) {
			font-size: 1.2vw;
		}

		@include viewport(large) {
			font-size: 0.85vw;
		}
	}

	&-phone {
		font-family: $sansFont;

		@include link('underlined', 'dark');
		min-width: 118px;
		width: fit-content;
		margin: auto;

		@include viewport(small) {
			font-size: 2.3vw;
		}

		@include viewport(medium) {
			font-size: 1.2vw;
		}

		@include viewport(large) {
			font-size: 0.85vw;
		}
	}

	.cawComponent & {
		display: flex;
		margin: auto;
		padding: 0;
		width: 100%;
		height: 100%;
		max-width: 1440px;
		color: black;
		background: none;
		box-shadow: none;
		justify-content: space-between;

		@include viewport(medium) {
			transform: translateY(0);
		}

		a {
			color: black;
			margin-left: 0;
			white-space: nowrap;

			@include typography(cc1);
		}

		.EliteGuestCard-logo {
			display: none;
		}

		.EliteGuestCard-text {
			max-width: 100%;
			text-align: left;
			padding-right: 20px;
		}

		.cawFieldsContainer {
			margin: auto;
		}

		.PPCHero & {
			flex-direction: column;
		}
	}
}

.Navigation
	.NavigationCAW
	.caw-comp__wrapper
	.App-wrapper
	.container
	.row
	.cawComponent.horizontal.hasDestinationPicker.hasEliteGuestComponent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 20px;
	height: auto;
	min-height: 130px;
	z-index: 0;

	.Navigation.has-Secondary & {
		min-height: 135px;
		padding-top: 20px;
		height: auto;
	}

	.cawFieldsContainer {
		@media (min-width: $navigationBreakpoint) {
			padding-left: 147px;
			padding-right: 25px;
		}
	}
}

.Navigation.Navigation--moreLinkExpanded,
.Navigation.Navigation--moreLinkClicked {
	.NavigationCAW
		.caw-comp__wrapper
		.App-wrapper
		.container
		.row
		.cawComponent.horizontal.hasDestinationPicker.hasEliteGuestComponent {
		z-index: -1;
	}
}
