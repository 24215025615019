.LinksList {
	&-links {
		margin-top: 30px;
		margin-bottom: 5px;
		column-gap: 30px;

		@include viewport(medium) {
			column-count: 2;
		}

		@include viewport(large) {
			column-count: 3;
		}

		@include viewport(xlarge) {
			column-count: 4;
		}
	}

	&-link {
		border-bottom: 0;
		display: inline-block;
		line-height: 1.5;
		margin-bottom: 25px;
		text-decoration: underline;
		vertical-align: top;

		@include viewport(medium) {
			max-width: 270px;
		}
	}
}
