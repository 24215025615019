.IndicatorColor {
	border-radius: 50%;
	width: 6px;
	height: 6px;
	&--red {
		background-color: #ff1e1e;
	}

	&--green {
		background-color: #2ad062;
	}

	&--orange {
		background-color: #df943c;
	}
	&--gray {
		background-color: $gray2;
	}
}
