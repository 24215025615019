.Thumbnails {
	padding-left: 15px;
	padding-right: 15px;

	@include viewport(medium) {
		padding-left: 60px;
		padding-right: 60px;
	}

	@include viewport(large) {
		padding-left: 90px;
		padding-right: 90px;
	}

	@include viewport(xlarge) {
		padding-left: 120px;
		padding-right: 120px;
	}
	.Heading-title {
		margin-bottom: 40px;
		@include viewport(medium) {
			margin-bottom: 30px;
		}
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		gap: 30px;
		@include viewport(large) {
			flex-direction: row;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 30px 30px;
		}
	}
}
