.ImageAndCTABlockWithEyebrows {
	&-imageWrapper {
		position: relative;
	}

	&-playButton {
		position: absolute;
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		color: $white;
		font-size: 3rem;
		z-index: 2;
		cursor: pointer;
		transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;

		&:hover {
			color: $bhover;
		}
	}

	&-galleryOverlay {
		.Gallery-button {
			display: none;
		}
	}
}
