// gutter variables
$gutter_small: get-gutter(small);
$gutter_medium: get-gutter(medium);
$gutter_large: get-gutter(large);
$gutter_xlarge: get-gutter(xlarge);

@mixin clearfix() {
	&::before,
	&::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}

// Should be used as default top and bottom spacing on sections
@mixin section-spacing($small: 40px, $medium: 60px, $large: 70px, $xlarge: 90px) {
	padding-bottom: $small;
	padding-top: $small;

	@include viewport(medium) {
		padding-bottom: $medium;
		padding-top: $medium;
	}

	@include viewport(large) {
		padding-bottom: $large;
		padding-top: $large;
	}

	@include viewport(xlarge) {
		padding-bottom: $xlarge;
		padding-top: $xlarge;
	}
}

// Should be used as default bottom spacing on components
@mixin component-spacing($small: 40px, $medium: 60px, $large: 70px, $xlarge: 90px) {
	margin-bottom: $small;

	@include viewport(medium) {
		margin-bottom: $medium;
	}

	@include viewport(large) {
		margin-bottom: $large;
	}

	@include viewport(xlarge) {
		margin-bottom: $xlarge;
	}
}

// Bootstrap-esque Grid System

@mixin gridBuild($className) {
	@for $i from 1 through $_total-columns {
		.col-#{$className}-#{$i} {
			width: #{8.3333333333% * $i};
		}

		.offset-#{$className}-#{$i} {
			margin-left: #{8.3333333333% * $i};

			@include rtl {
				margin-left: 0;
				margin-right: #{8.3333333333% * $i};
			}
		}
	}
}

@mixin _gridBPContent($containerWidth, $className) {
	// We use max-width, so that the container adapts to its container
	// eg. ComponentContainer or components that host components
	.container {
		max-width: $containerWidth;
	}

	.hide-#{$className} {
		display: none;
	}

	.offset-#{$className}-start {
		margin-left: 0;

		@include rtl {
			margin-right: 0;
		}
	}

	@include gridBuild($className);
}

@mixin gridBP($size, $className, $bpSize: null, $_layout-setup: $_layout-setup) {
	$containerWidth: map-deep-get($_layout-setup, $size, 'container');

	@if $bpSize {
		@media screen and (min-width: $bpSize) {
			@include _gridBPContent($containerWidth, $className);
		}
	} @else {
		@include _gridBPContent($containerWidth, $className);
	}
}

@mixin rowVCenter($size) {
	$className: map-deep-get($_layout-setup, $size, shortname);

	.row-#{$className}-vcenter {
		@include viewport($size) {
			display: flex;
			align-items: center;
		}
	}

	.row-#{$className}-vreset {
		@include viewport($size) {
			display: block;

			[class*='col-'] {
				float: left;
				display: block;
			}
		}
	}
}

// Helper mixin to create column widths without a class
// @param $column   Number of columns to span
// @param $size     Viewport to target specifically
//                  (Used when padding inheritance will cascade)
@mixin column($columns: 12, $size: null) {
	float: left;
	width: #{8.3333333333% * $columns};

	@if not $size {
		padding-left: $gutter_small / 2;
		padding-right: $gutter_small / 2;

		@include viewport(medium) {
			padding-left: $gutter_medium / 2;
			padding-right: $gutter_medium / 2;
		}
	} @else {
		$gutterWidth: map-deep-get($_layout-setup, $size, gutter);
		padding-left: $gutterWidth / 2;
		padding-right: $gutterWidth / 2;
	}
}

@mixin column-reset {
	float: none;
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	margin-left: auto;
}

@mixin push($columns) {
	margin-left: #{8.3333333333% * $columns};
}

// Mixin to handle rows to be cleared
// when certain columns content are too tall.
@mixin row-first-child($col-type, $clear-type) {
	.col-#{$col-type}- {
		&1:nth-child(12n + 1),
		&2:nth-child(6n + 1),
		&3:nth-child(4n + 1),
		&4:nth-child(3n + 1),
		&6:nth-child(odd) {
			clear: $clear-type;
		}
	}
}

//Begin new wide grid styles
/*
<div class="wide-grid-container">
	<div class="wide-grid-row-[breakPoint]-[colsPerRow]">
		<div class="wide-grid-item-[breakPoint]-[colWidth]">
			...
		</div>
	</div>
</div>

breakPoint options: sm, md, lg, xl
	sm must always be included, larger breakpoints used to override columns per row (row) and column widths (item)
colsPerRow options: 1-12
	describes how many columns per row for the given breakpoint
colWidth options: 1-12
	descibes how many columns the current item will take up

Ex "wide-grid-row-sm-2 wide-grid-row-lg-3"
	row will be 2 items for sm, md breakpoints
	row will be 3 items for lg, xl breakpoints

Ex "wide-grid-item-sm-1 wide-grid-item-xl-2"
	this item will be 1 column width for sm, md, lg breakpoints
	this item will be 2 column widths for xl breakpoint
*/

$_wide-grid_bp_map: (
	'sm': small,
	'md': medium,
	'lg': large,
	'xl': xlarge,
);

@function buildWideGridRowClassList() {
	$commaClassList: '';
	@each $bpName, $bpValue in $_wide-grid_bp_map {
		@for $i from 1 through $_total-columns {
			@if $commaClassList == '' {
				$commaClassList: '.wide-grid-row-#{$bpName}-#{$i}';
			} @else {
				$commaClassList: append(
					$commaClassList,
					'.wide-grid-row-#{$bpName}-#{$i}',
					'comma'
				);
			}
		}
	}
	@return $commaClassList;
}

#{buildWideGridRowClassList()} {
	@include viewport(small) {
		gap: 20px 15px;
		--colGap: 15px;
	}
	@include viewport(medium) {
		gap: 25px 20px;
		--colGap: 20px;
	}
	@include viewport(large) {
		gap: 30px;
		--colGap: 30px;
	}
}

.wide-grid {
	&-container {
		margin: auto;
		padding: 0 15px;
		max-width: calc($wideGridContainerWidth-sm + 30px);

		@include viewport(medium) {
			padding-left: 60px;
			padding-right: 60px;
			max-width: calc($wideGridContainerWidth-md + 120px);
		}

		@include viewport(large) {
			padding-left: 90px;
			padding-right: 90px;
			max-width: calc($wideGridContainerWidth-lg + 180px);
		}

		@include viewport(xlarge) {
			padding-left: 120px;
			padding-right: 120px;
			max-width: calc($wideGridContainerWidth-xl + 240px);
		}
	}

	@each $bpName, $bpValue in $_wide-grid_bp_map {
		@include viewport($bpValue) {
			@for $c from 1 through $_total-columns {
				&-row-#{$bpName}-#{$c} {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					flex-flow: wrap;
					--colsPerRow: #{$c};
				}
				&-item-#{$bpName}-#{$c} {
					position: relative;
					flex-basis: calc(
						100% /
							(var(--colsPerRow) / $c) -
							(var(--colGap) / var(--colsPerRow) * (var(--colsPerRow) - $c))
					);
				}
			}
		}
	}
}
