.LandscapeCard {
	width: 100%;
	height: 100%;

	&-link {
		text-decoration: none;
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: var(--container-padding-large, 20px);

		@include viewport(medium) {
			flex-direction: row;
		}

		@include viewport(large) {
			gap: var(--container-padding-x-large, 30px);
		}
	}

	&-image {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		gap: 20px;

		@include viewport(medium) {
			flex: 1 0 0;
		}
	}

	&-contents {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: var(--container-padding-medium, 15px);
		align-self: stretch;

		@include viewport(medium) {
			justify-content: center;
			flex: 1 0 0;
		}

		@include viewport(large) {
			gap: 30px;
		}
	}

	&-title {
		display: flex;
		align-items: baseline;
		gap: var(--container-padding-small, 10px);
		align-self: stretch;

		@include viewport(large) {
			gap: var(--container-padding-medium, 15px);
		}
	}

	&-arrow {
		color: var(--color-text-heading, #fff);
		font-size: 13px;

		@include viewport(large) {
			font-size: 18px;
		}
	}

	&-desciption {
		align-self: stretch;
	}
}
