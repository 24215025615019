.CenteredFeatureCollage {
	&-heading {
		@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);

		.Heading-title {
			margin-top: 0;
			text-align: center;
		}
	}

	&-items {
		margin: 0;
		padding: 0;

		.ImageCtaCollage-item {
			list-style: none;

			&:not(:last-of-type) {
				@include component-spacing(
					$small: 60px,
					$medium: 80px,
					$large: 40px,
					$xlarge: 80px
				);
			}

			@include viewport(large) {
				padding-bottom: 60px;
			}

			.ImageAndCta {
				@include viewport(large) {
					display: flex;
					flex-direction: row;
				}

				&-picture {
					margin: 0 0 40px;

					@include viewport(medium) {
						margin-bottom: 60px;
					}

					@include viewport(large) {
						width: calc(50% - 15px);
						position: relative;
						margin-bottom: 0;
					}

					@include viewport(xlarge) {
						width: calc(50% - 27.5px);
					}

					.Attribution {
						@include viewport(large) {
							position: absolute;
						}

						@include viewport(xlarge) {
							position: static;
						}
					}
				}

				&-cta {
					position: relative;

					@include viewport(large) {
						flex-grow: 0;
						flex-shrink: 0;
						flex-basis: calc(50% - 45px);
						padding-bottom: 60px;
					}

					@include viewport(large) {
						flex-grow: 0;
						flex-shrink: 0;
						flex-basis: calc(50% - 82.5px);
					}
				}
			}

			.Image {
				font-size: 0;
				line-height: 0;
			}

			.CtaBlock {
				@include viewport(medium) {
					position: relative;
				}

				&-title {
					@include typography(h3);

					&::after {
						border-bottom: 2px solid currentColor;
						bottom: -15px;
						content: '';
						left: 0;
						position: absolute;
						width: 40px;

						@include viewport(medium) {
							bottom: -20px;
						}

						@include viewport(xlarge) {
							bottom: -30px;
							border-bottom-width: 3px;
						}
					}

					@include rtl {
						&::after {
							left: inherit;
							right: 0;
						}
					}

					@include viewport(medium) {
						width: get-columns-fixed-unit(6, medium);
						margin-bottom: 42px;
					}

					@include viewport(large) {
						width: 100%;

						&::after {
							content: none;
						}
					}

					margin-bottom: 35px;
					position: relative;
					display: inline-block;
				}

				&-text {
					@include viewport(medium) {
						margin-bottom: 35px;
						width: get-columns-fixed-unit(6, medium);
					}

					@include viewport(large) {
						width: 100%;
					}

					@include typography(b2);

					margin: 0 0 30px;
				}

				&-cta {
					@include viewport(xlarge) {
						margin-top: 60px;
						min-width: 200px;
					}
				}
			}

			&--left {
				.ImageAndCta {
					@include viewport(large) {
						flex-direction: row;
					}

					&-picture {
						@include viewport(large) {
							margin-right: 15px;
						}

						@include viewport(xlarge) {
							margin-right: 27.5px;
						}

						@include rtl {
							@include viewport(large) {
								margin-right: 0;
								margin-left: 15px;
							}

							@include viewport(xlarge) {
								margin-left: 27.5px;
							}
						}
					}

					&-cta {
						@include viewport(large) {
							text-align: left;
							padding-left: 45px;

							&::before {
								content: '';
								position: absolute;
								display: block;
								height: 75%;
								width: 1px;
								left: 15px;
								background: currentColor;
							}
						}

						@include viewport(xlarge) {
							padding-left: 82.5px;

							&::before {
								height: 50%;
								left: 27.5px;
							}
						}

						@include rtl {
							text-align: right;

							@include viewport(large) {
								padding-left: 0;
								padding-right: 45px;
							}

							@include viewport(xlarge) {
								padding-left: 82.5px;
							}
						}

						.CtaBlock {
							&-title {
								@include viewport(large) {
									&::after {
										right: inherit;
										left: 0;
									}

									@include rtl {
										&::after {
											right: 0;
											left: inherit;
										}
									}
								}
							}

							&-cta {
								@include viewport(large) {
									left: 0;

									@include rtl {
										left: inherit;
										right: 0;
									}
								}
							}
						}
					}
				}
			}

			&--right {
				.ImageAndCta {
					@include viewport(large) {
						flex-direction: row-reverse;
					}

					&-picture {
						@include viewport(large) {
							margin-right: 0;
							margin-left: 15px;
						}

						@include viewport(xlarge) {
							margin-left: 27.5px;
						}

						@include rtl {
							@include viewport(large) {
								margin-left: 0;
								margin-right: 15px;
							}

							@include viewport(xlarge) {
								margin-right: 27.5px;
							}
						}
					}

					&-cta {
						@include viewport(large) {
							text-align: right;
							padding-left: 0;
							padding-right: 45px;

							&::before {
								content: '';
								position: absolute;
								display: block;
								height: 75%;
								width: 1px;
								right: 15px;
								background: currentColor;
							}
						}

						@include viewport(xlarge) {
							padding-right: 82.5px;

							&::before {
								height: 50%;
								right: 27.5px;
							}
						}

						@include rtl {
							text-align: left;

							@include viewport(large) {
								padding-left: 0;
								padding-right: 45px;
							}

							@include viewport(xlarge) {
								padding-right: 82.5px;
							}
						}
					}
				}
			}
		}
	}
}
