.IconsList-IconText {
	position: relative;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 10px;

	@include viewport(medium) {
		flex-direction: column;
		margin: 0 10px;

		&:first-child {
			margin-left: 0;

			@include rtl {
				margin-right: 0;
				margin-left: 10px;
			}
		}
		&:last-child {
			margin-right: 0;

			@include rtl {
				margin-right: 10px;
				margin-left: 0;
			}
		}
	}

	@for $i from 1 through 7 {
		&--#{$i} {
			@include viewport(medium) {
				width: calc(100% / #{$i});
			}
		}
	}

	&-icon {
		margin-right: 7px;
		height: 24px;
		font-size: 24px;
		min-width: 24px;
		text-align: center;

		@include rtl {
			margin-left: 7px;
			margin-right: 0;
		}

		@include viewport(medium) {
			width: 100%;
			font-size: 32px;
			height: 32px;
			margin-bottom: 7px;
			margin-right: 0;

			@include rtl {
				margin-left: 7px;
			}
		}

		&--large {
			@include viewport(large) {
				font-size: 40px;
				height: 40px;
				margin-bottom: 10px;
			}
		}
	}

	&-text {
		text-align: left;

		@include viewport(medium) {
			text-align: center;
			width: 100%;
		}
	}

	&-HasSubText {
		margin-left: 15px;

		@include rtl {
			margin-left: 0;
			margin-right: 15px;
		}

		@include viewport(medium) {
			margin-left: 0;

			@include rtl {
				margin-right: 0;
			}
		}
	}

	&--IconsItem {
		display: flex;
		flex-wrap: wrap;
	}

	&-line {
		background-color: $white;
		text-align: center;
		width: 50px;
		height: 1px;
		margin: 20px auto;
		margin-top: 0;

		&--dark {
			background-color: $black;
		}

		&--light {
			background-color: $white;
		}
	}
	&-subText {
		color: $white;
		margin-top: 0;
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-left: 46px;
		margin-bottom: 24px;
		width: 100%;

		@include rtl {
			margin-left: 0;
			margin-right: 46px;
			text-align: right;
		}
		&--dark {
			color: $black;
		}

		&--light {
			color: $white;
		}

		@include viewport(medium) {
			text-align: center;
			margin-left: 0;
			margin-bottom: 0;
			width: auto;

			@include rtl {
				margin-right: 0;
				text-align: center;
			}
		}
	}

	.IndicatorColor {
		position: absolute;
		transform: translate(-50%, 0);
		left: 10%;
		top: 0;

		@include rtl {
			left: 0;
			right: 10%;
		}

		@include viewport(medium) {
			transform: translate(-50%, 0%);
			left: calc(50% + 19px);
			top: -6px;

			@include rtl {
				left: 0;
				right: calc(50% + 19px);
			}
		}

		@include viewport(large) {
			transform: translate(-50%, -50%);
			left: calc(50% + 19px);
			top: calc(50% - 29px);

			@include rtl {
				left: 0;
				right: calc(50% + 19px);
			}
		}
	}
}

.IconsList-bottomPart {
	.IconsList-IconText {
		@include viewport(medium) {
			overflow: hidden;
		}
	}
}
