.AlignableImageCTA {
	.ImageAndCTABlockWithEyebrows {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		text-align: center;

		&-imageWrapper {
			width: 100%;
		}

		&-textWrapper {
			width: 100%;

			.CtaBlock-title {
				@include typography(h1);
				margin-bottom: 20px;
				margin-top: 10px;
			}

			.CtaBlock-text {
				@include typography(b1);
				margin-bottom: 20px;
			}
		}

		&-eyebrow {
			@include typography(c4);
			font-weight: bold;
		}

		&-bullet {
			@include typography(c4);
		}

		&-secondaryEyebrow {
			@include typography(c4);
		}

		&-playButton {
			color: $white;
		}

		@include viewport(large) {
			&--center {
				justify-content: center;
				flex-direction: column;
				.ImageAndCTABlockWithEyebrows-imageWrapper {
					padding-bottom: 30px;
				}
			}

			&--left {
				justify-content: flex-start;
				flex-direction: row;
				.ImageAndCTABlockWithEyebrows-imageWrapper {
					width: 50%;
				}
				.ImageAndCTABlockWithEyebrows-textWrapper {
					width: calc(50% - 30px);
					margin-left: 30px;
				}
			}

			&--right {
				justify-content: flex-end;
				flex-direction: row-reverse;
				.ImageAndCTABlockWithEyebrows-imageWrapper {
					width: 50%;
				}
				.ImageAndCTABlockWithEyebrows-textWrapper {
					width: calc(50% - 30px);
					margin-right: 30px;
				}
			}
		}
	}
}
