.ImageAndTextPanel {
	&-title {
		@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);
		text-align: center;
	}

	&-item {
		margin-bottom: 80px;

		@include viewport(medium) {
			margin-bottom: 100px;
		}

		@include viewport(xlarge) {
			margin-bottom: 120px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&-itemContent {
		@include viewport(medium) {
			display: flex;
			flex-direction: row;
			align-items: center;

			.ImageAndTextPanel-item:nth-child(2n) & {
				flex-direction: row-reverse;
				justify-content: flex-end;
			}
		}
	}

	.ImageAndTextPanel--withAttribution {
		// Adds extra padding when the Collage Item has an Attribution element
		@include viewport(medium) {
			padding-bottom: 40px;
		}

		.Collage-figure {
			margin-bottom: 100px;

			@include viewport(medium) {
				margin-bottom: 0;
			}
		}
	}

	.Collage-figure {
		position: relative;
		margin: 0 0 60px;

		@include viewport(medium) {
			margin: 0;
		}
	}

	.Collage-figcaption {
		position: absolute;
		bottom: -40px;
	}

	&-textPanel {
		@include viewport(medium) {
			padding: 0 25px;
		}

		@include viewport(large) {
			padding: 0;
		}

		@include viewport(xlarge) {
			padding: 0 100px;
		}
	}

	&-Heading {
		text-align: center;

		.Heading-title {
			@include headingBorder();
		}

		.Heading-text {
			margin-top: 20px;
			margin-bottom: 30px;

			@include viewport(medium) {
				margin-bottom: 40px;
			}

			&:lang(ja) {
				font-size: 1rem;
			}
		}
	}

	&-CTA {
		display: block;
		text-align: center;

		.BodySection--dark & .CTA {
			@include borderedButton($black, $white, $white);

			&:focus,
			&:hover {
				@include borderedButton($white, $black, $white);
			}
		}
	}
}
