$navigationBreakpoint: 1100px;
$navigationTextBreakpoint: 1240px;
$navigationHeight-sm: 60px;
$navigationHeight-lg-expanded: 76px;
$navigationHeight-lg-collapsed: 96px;
$secondaryNavHeight: 55px;
$navigationTopBarHeight: 25px;
$alertBannerHeight: 58px;
$navigationOverlayTopLinkBreakpoint: 350px;
$navigationOverlayHorizontalPadding: 35px;
$navigationOverlayOffset: 20px;
