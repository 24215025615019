.Text-Box {
	text-align: center;

	&-title {
		@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);
	}

	&-text {
		margin-top: 0;
		margin-bottom: 0;

		@include viewport(large) {
			@include typography(b1);
		}

		@include wls {
			@include viewport(large) {
				font-size: rem(14, 16);
				line-height: em(27, 14);
				letter-spacing: em(0.5, 14);
				font-family: 'Work Sans';
			}
			@include viewport(xlarge) {
				font-size: rem(18, 16);
				line-height: em(27, 18);
				letter-spacing: em(0.5, 18);
				font-family: 'Work Sans';
			}
		}
	}

	&--GrayBox {
		background-color: $gray4;
		color: $black;
		padding: 60px 0;
		margin-right: 15px;
		margin-left: 15px;

		@include viewport(medium) {
			padding: 80px 0;
			margin-right: auto;
			margin-left: auto;
		}

		.Text-Box-title {
			margin-bottom: 20px;
			margin-top: 0;
		}

		&-text {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}
