.CardGridTall {
	&-heading {
		font-size: 24px;
		margin: 0 0 30px 0;

		@include viewport(large) {
			margin: 0 0 40px 0;
		}

		& .Heading-title {
			margin: 0;
		}
	}
	&-cardContainer-mobile {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-gap: 30px 15px;
		width: 100%;
		@include viewport(large) {
			display: none;
		}

		.TallImageCard-title {
			@include multiLineElipsisClamp($lineCount: 2);
		}

		.CardGridTall-button-container {
			grid-column: 1 / span 2;
			margin: 0 auto;
			margin-top: 10px;
		}
	}
	&-cardContainer-desktop {
		display: none;
		width: 100%;
		@include viewport(large) {
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-gap: 30px 30px;
		}

		.CardGridTall-button-container {
			grid-column: 1 / span 3;
			margin: 0 auto;
			margin-top: 10px;
		}
	}
	&-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	.hide {
		display: none;
	}

	&-Button-label::after {
		@include icon('icon-plus_sign');
		position: relative;
		font-size: 8px;
		margin-left: 7px;
		font-weight: bold;

		@include rtl {
			margin-left: 0px;
			margin-right: 7px;
		}
	}
	&-Button-label.less::after {
		@include icon('icon-less_sign');
	}
}
