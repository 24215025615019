.MeetingsAndEventsHero {
	.BackgroundImage {
		.MeetingsAndEventsHero-noSubtitle {
			.Heading-title {
				&::after {
					display: none;
				}
			}
		}
		&::after {
			height: 55%;

			@include viewport(medium) {
				height: 70%;
			}

			@include viewport(large) {
				height: 60%;
			}
		}
	}

	.Hero-headingContainer {
		bottom: 40px;

		@include viewport(medium) {
			bottom: 75px;
		}

		@include viewport(large) {
			bottom: 80px;
		}

		@include viewport(xlarge) {
			bottom: 100px;
		}
	}

	&-contactBar {
		position: relative;
		max-width: 930px;
		z-index: 2;

		@include viewport(large) {
			margin-top: -55px;
		}

		@media screen and (min-width: 960px) and (max-width: 1350px) {
			max-width: 550px;
		}

		.ContactBar {
			&-container {
				box-shadow: 0 1px 30px 4px rgba(0, 0, 0, 0.12);
			}

			&-title {
				font-style: normal;
			}
		}
	}
}
