.hotelSearchBar {
	display: inline-block;
	z-index: 2;
	width: 345px;
	max-width: 100%;

	@include viewport(medium) {
		transition: width 0.3s linear, height 0.3s linear;
		width: 425px;
	}
	.hotelSearchBar-input {
		position: relative;
		width: 100%;
		.textInput__field,
		.textInput__field:valid {
			border-bottom: 1px solid #d8d8d8;
			padding: 12px 15px;
			height: 50px;
			width: 100%;

			@include viewport(medium) {
				transition: height 0.3s linear;
				padding: 8px 20px;
				height: 40px;
			}
			&:placeholder-shown {
				~ .icon-search {
					opacity: 1;
					display: block;
					top: calc(50% - 13px);
				}
			}
			&:not(:placeholder-shown) ~ .icon-close {
				opacity: 1;
				display: block;
			}
			&:focus-visible {
				outline: none;
			}
		}
		.textInput__label {
			color: white;
			letter-spacing: 3px;
			top: 0;
		}
		.icon {
			position: absolute;
			bottom: 0;
			right: 16px;
			width: 15px;
			height: 100%;
			opacity: 0;
			display: none;
			color: black;

			@include rtl {
				right: auto;
				left: 16px;
			}
			&.icon-close::before {
				font-weight: 900;
				font-size: rem(13, 16);
			}
			&.icon-search::before {
				font-weight: 900;
				font-size: rem(20, 16);
			}
		}
	}
	.hotelSearchBar-results {
		display: none;
		background: white;
		max-height: 250px;
		overflow-y: auto;
		width: 100%;
		transition: max-height 0.3s linear;

		@include viewport(medium) {
			max-height: 315px;
		}
		.results .hotelSearchBar-result:first-of-type {
			border: none;
		}
		.hotelSearchBar-result {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 60px;
			color: black;
			text-decoration: none;
			margin: 0 15px;
			border-top: 1px solid #d8d8d8;
			text-align: left;

			@include viewport(medium) {
				margin: 0 22px;
			}

			@include rtl {
				text-align: right;
			}
			&.group {
				border: none;
				height: auto;
				padding-top: 10px;
				.group-title {
					height: 27px;
					display: flex;
					.icon {
						padding-right: 10px;

						@include rtl {
							padding-right: 0;
							padding-left: 10px;
						}
					}
				}
				.hotelSearchBar-result {
					margin-left: 35px;
					margin-right: 0;

					@include rtl {
						margin-right: 35px;
						margin-left: 0;
					}

					@include viewport(medium) {
						margin-left: 40px;

						@include rtl {
							margin-right: 40px;
						}
					}
				}
			}
			&:not(.group):hover,
			&:not(.group):focus-visible {
				color: white;
				background: #2d2d2d;
				margin: 0;
				padding-left: 15px;

				@include rtl {
					padding-left: 0;
					padding-right: 15px;
				}

				@include viewport(medium) {
					padding-left: 22px;

					@include rtl {
						padding-right: 22px;
					}
				}
			}
			.group-results .hotelSearchBar-result:hover,
			.group-results .hotelSearchBar-result:focus-visible {
				margin: 0 -15px;
				padding-left: 50px;

				@include rtl {
					padding-left: 0;
					padding-right: 50px;
				}

				@include viewport(medium) {
					margin: 0 -22px;
					padding-left: 62px;

					@include rtl {
						padding-right: 62px;
					}
				}
			}
			&.no-results {
				color: #2d2d2d;
				text-transform: uppercase;
				border: none;
				font-size: rem(10, 16);
				letter-spacing: rem(3, 16);
				height: 43px;
				display: none;
			}
			&.view-all {
				flex-direction: row;
				justify-content: start;
				align-items: center;
				height: 46px;
				text-transform: uppercase;
				border-color: #7d7d7d;
				font-size: rem(10, 16);
				letter-spacing: rem(3, 16);
				&::after {
					@include icon('icon-arrow_right');
					padding-left: 4px;
					display: inline-block;
					font-size: rem(12, 16);
					vertical-align: middle;
					height: 14px;
				}

				@include rtl {
					&::after {
						@include icon('icon-arrow_left');
						padding-left: 0;
						padding-right: 4px;
					}
				}
			}
			.results {
				display: none;
			}
		}
	}
	&.active {
		@include viewport(medium) {
			width: 460px;
		}
		.hotelSearchBar-input {
			.textInput__field,
			.textInput__field:valid {
				@include viewport(medium) {
					height: 56px;
				}
			}
			.textInput__label {
				transform: translateY(-22px);
				opacity: 1;
			}
		}
	}
}
