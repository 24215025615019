$tripleLineBreakpoint: 420px;
$tripleLineBreakpointEs: 480px;
$doubleLineBreakpoint: 960px;

.BookOfComplaintsForm {
	a {
		color: black;
		.BodySection--dark & {
			color: $white;
		}
	}
	&-Section {
		margin-bottom: 50px;
	}

	&-Header {
		margin-top: 0;
		margin-bottom: 30px;
		text-align: center;
	}
	&-Header-intro {
		margin-top: 0;
		margin-bottom: 40px;
		text-align: center;
		p {
			margin: 0;
		}
	}
	&-RadioButtons {
		margin-top: 15px;
		.RadioButtons-label {
			font-size: rem(20, 16);
		}
		.RadioButtons-button {
			margin: 0;
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
	&-RadioButtonsLabel {
		line-height: 1.5em;
		display: block;
	}

	.ContactForm {
		padding-top: 75px;

		.CTA-light-on-light {
			border-color: $black;
		}

		.ContactForm-SubmitCTA {
			width: 175px;
			min-width: 175px;
			max-width: 175px;
			&:disabled {
				background: $gray2;
				cursor: default;
				border: 1px solid $gray2;
			}
			&:disabled:hover {
				color: $white;
			}
		}
	}

	.TextareaField {
		&:lang(es) {
			@media screen and (max-width: $tripleLineBreakpointEs) {
				margin-top: 30px;
			}
		}

		@media screen and (max-width: $tripleLineBreakpoint) {
			margin-top: 30px;
		}

		&.formElement-text {
			// label when in focus
			.formElement-field:focus + .formElement-label,
			.formElement-field--labelOn + .formElement-label,
			.formElement-field--filled + .formElement-label,
			.formElement-field--readOnly + .formElement-label:not(.formElement-label--hidden),
			.formElement--focusAlways {
				transform: translateY(-15px);

				@media screen and (max-width: $doubleLineBreakpoint) {
					transform: translateY(-30px);
				}

				&:lang(es) {
					@media screen and (max-width: $tripleLineBreakpointEs) {
						transform: translateY(-45px);
					}
				}

				@media screen and (max-width: $tripleLineBreakpoint) {
					transform: translateY(-45px);
				}
			}
		}
	}

	.hidden {
		display: none;
	}
}
