.SummaryTab--cell {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
	justify-content: flex-start;
	flex: auto;
	gap: 5px;
	width: 130px;
	flex-basis: calc(50% - 15px);

	@include rtl {
		text-align: right;
	}

	@include viewport(medium) {
		align-items: center;
		text-align: center;
		justify-content: center;
		flex-basis: calc(33% - 31px);

		&:first-child:nth-last-child(8), //when there are 4 cells need to use 8 to acount for dividers
		&:first-child:nth-last-child(8) ~ .SummaryTab--cell {
			flex-basis: calc(25% - 31px);
		}
	}

	&--title {
		color: var(--color-text-primary, #1f1f1f);
		font-feature-settings: 'clig' off, 'liga' off;
		text-transform: capitalize;
	}

	&--text {
		color: var(--color-text-primary, #1f1f1f);
		font-feature-settings: 'clig' off, 'liga' off;
	}

	&--cta {
		color: var(--color-text-primary, #1f1f1f);
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 13px; /* 130% */
		letter-spacing: 3px;
		padding-bottom: 0;
		margin-bottom: 1px;
	}

	&--divider {
		display: none;
		flex: none;
		height: auto;
		width: 1px;
		margin: 0 15px;

		@include viewport(medium) {
			display: block;
			&:nth-child(6):not(:nth-last-child(3)) {
				//actually the 3rd child but need to use 6 to account for dividers
				display: none;
			}
			&:last-child {
				display: none;
			}
		}

		@include viewport(large) {
			&:nth-child(6):not(:last-child) {
				//actually the 3rd child but need to use 6 to account for dividers
				display: block;
			}
		}
	}
}
