.BodySection--dark {
	/* colors */
	--color-backdrop: #03030380;
	--color-border-default: #ffffff73;
	--color-border-inverse: #4d4d4d73;
	--color-border-strong-default: #fffffff2;
	--color-border-strong-inverse: #4d4d4df2;
	--color-border-subtle-default: #ffffff26;
	--color-border-subtle-inverse: #4d4d4d26;
	--color-button-on-image-primary-default: #{$neutral-0};
	--color-button-on-image-primary-disabled: #{$neutral-900};
	--color-button-on-image-primary-hover: #{$neutral-100};
	--color-button-on-image-primary-pressed: #{$neutral-200};
	--color-button-on-image-primary-ghost-hover: #ffffff0a;
	--color-button-on-image-primary-ghost-pressed: #ffffff14;
	--color-button-on-image-primary-inverse-default: #{$neutral-1700};
	--color-button-on-image-primary-inverse-disabled: #{$neutral-1300};
	--color-button-primary-default: #{$neutral-0};
	--color-button-primary-hover: #{$neutral-200};
	--color-button-primary-inverse: #{$neutral-1700};
	--color-button-primary-pressed: #{$neutral-300};
	--color-button-primary-disabled-default: #{$neutral-900};
	--color-button-primary-disabled-inverse: #{$neutral-1300};
	--color-button-primary-ghost-hover: #ffffff0a;
	--color-button-primary-ghost-pressed: #ffffff14;
	--color-button-primary-inactive-default: #{$neutral-1500};
	--color-button-primary-inactive-hover: #{$neutral-1400};
	--color-button-primary-inactive-inverse: #{$neutral-200};
	--color-button-primary-inactive-pressed: #{$neutral-1300};
	--color-error-border-default: #{$red-600};
	--color-error-container-default: #{$red-300};
	--color-error-container-hover: #{$red-400};
	--color-error-container-pressed: #{$red-500};
	--color-error-container-subtle-default: #{$red-1100};
	--color-error-container-subtle-hover: #{$red-1000};
	--color-error-container-subtle-pressed: #{$red-900};
	--color-error-icon-default: #{$red-500};
	--color-error-icon-inverse: #{$red-1000};
	--color-error-text-default: #{$red-100};
	--color-error-text-inverse: #{$red-1200};
	--color-img-overlay-20: #03030333;
	--color-img-overlay-40: #03030366;
	--color-img-overlay-60: #03030399;
	--color-img-overlay-80: #030303cc;
	--color-security-border-default: #{$blue-600};
	--color-security-container-default: #{$blue-300};
	--color-security-container-hover: #{$blue-400};
	--color-security-container-pressed: #{$blue-500};
	--color-security-container-subtle-default: #{$blue-1100};
	--color-security-container-subtle-hover: #{$blue-1000};
	--color-security-container-subtle-pressed: #{$blue-900};
	--color-security-icon-default: #{$blue-500};
	--color-security-icon-inverse: #{$blue-1000};
	--color-security-text-default: #{$blue-100};
	--color-security-text-inverse: #{$blue-1200};
	--color-success-border-default: #{$green-600};
	--color-success-container-default: #{$green-300};
	--color-success-container-hover: #{$green-400};
	--color-success-container-pressed: #{$green-500};
	--color-success-container-subtle-default: #{$green-1100};
	--color-success-container-subtle-hover: #{$green-1000};
	--color-success-container-subtle-pressed: #{$green-900};
	--color-success-icon-default: #{$green-500};
	--color-success-icon-inverse: #{$green-1000};
	--color-success-text-default: #{$green-100};
	--color-success-text-inverse: #{$green-1200};
	--color-surface-default: #{$neutral-1600};
	--color-surface-form: #ffffff14;
	--color-surface-inverse: #{$neutral-100};
	--color-surface-recessed: #{$neutral-1700};
	--color-surface-sticky: #{$neutral-1400};
	--color-surface-translucent: #2d2d2d99;
	--color-surface-overlay-default: #{$neutral-1400};
	--color-surface-overlay-raised: #{$neutral-1300};
	--color-surface-raised-default: #{$neutral-1400};
	--color-surface-raised-subtle: #{$neutral-1500};
	--color-text-disabled: #{$neutral-700};
	--color-text-heading: #{$neutral-0};
	--color-text-primary: #{$neutral-200};
	--color-text-secondary: #{$neutral-500};
	--color-text-inverse-disabled: #{$neutral-1000};
	--color-text-inverse-heading: #{$neutral-1700};
	--color-text-inverse-primary: #{$neutral-1500};
	--color-text-inverse-secondary: #{$neutral-1200};
	--color-text-on-image-disabled: #ffffff61;
	--color-text-on-image-heading: #{$neutral-0};
	--color-text-on-image-primary: #ffffffde;
	--color-text-on-image-secondary: #ffffffab;
	--color-warning-border-default: #{$yellow-600};
	--color-warning-container-default: #{$yellow-300};
	--color-warning-container-hover: #{$yellow-400};
	--color-warning-container-pressed: #{$yellow-500};
	--color-warning-container-subtle-default: #{$yellow-1100};
	--color-warning-container-subtle-hover: #{$yellow-1000};
	--color-warning-container-subtle-pressed: #{$yellow-900};
	--color-warning-icon-default: #{$yellow-500};
	--color-warning-icon-inverse: #{$yellow-1000};
	--color-warning-text-default: #{$yellow-100};
	--color-warning-text-inverse: #{$yellow-1200};
	--vos-surface-default: #8080804d;
	//Surfaces
	--surface-raised-subtle-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
}
.BodySection--light {
	/* colors */
	--color-backdrop: #03030380;
	--color-border-default: #4d4d4d73;
	--color-border-inverse: #ffffff73;
	--color-border-strong-default: #4d4d4df2;
	--color-border-strong-inverse: #fffffff2;
	--color-border-subtle-default: #4d4d4d26;
	--color-border-subtle-inverse: #ffffff26;
	--color-button-on-image-primary-default: #{$neutral-0};
	--color-button-on-image-primary-disabled: #{$neutral-900};
	--color-button-on-image-primary-hover: #{$neutral-100};
	--color-button-on-image-primary-pressed: #{$neutral-200};
	--color-button-on-image-primary-ghost-hover: #ffffff0a;
	--color-button-on-image-primary-ghost-pressed: #ffffff14;
	--color-button-on-image-primary-inverse-default: #{$neutral-1700};
	--color-button-on-image-primary-inverse-disabled: #{$neutral-1300};
	--color-button-primary-default: #{$neutral-1700};
	--color-button-primary-hover: #{$neutral-1500};
	--color-button-primary-inverse: #{$neutral-0};
	--color-button-primary-pressed: #{$neutral-1400};
	--color-button-primary-disabled-default: #{$neutral-800};
	--color-button-primary-disabled-inverse: #{$neutral-300};
	--color-button-primary-ghost-hover: #0303030a;
	--color-button-primary-ghost-pressed: #03030314;
	--color-button-primary-inactive-default: #{$neutral-200};
	--color-button-primary-inactive-hover: #{$neutral-300};
	--color-button-primary-inactive-inverse: #{$neutral-1600};
	--color-button-primary-inactive-pressed: #{$neutral-400};
	--color-error-border-default: #{$red-400};
	--color-error-container-default: #{$red-900};
	--color-error-container-hover: #{$red-800};
	--color-error-container-pressed: #{$red-700};
	--color-error-container-subtle-default: #{$red-100};
	--color-error-container-subtle-hover: #{$red-200};
	--color-error-container-subtle-pressed: #{$red-300};
	--color-error-icon-default: #{$red-700};
	--color-error-icon-inverse: #{$red-200};
	--color-error-text-default: #{$red-1200};
	--color-error-text-inverse: #{$red-100};
	--color-img-overlay-20: #03030333;
	--color-img-overlay-40: #03030366;
	--color-img-overlay-60: #03030399;
	--color-img-overlay-80: #030303cc;
	--color-security-border-default: #{$blue-400};
	--color-security-container-default: #{$blue-900};
	--color-security-container-hover: #{$blue-800};
	--color-security-container-pressed: #{$blue-700};
	--color-security-container-subtle-default: #{$blue-100};
	--color-security-container-subtle-hover: #{$blue-200};
	--color-security-container-subtle-pressed: #{$blue-300};
	--color-security-icon-default: #{$blue-700};
	--color-security-icon-inverse: #{$blue-200};
	--color-security-text-default: #{$blue-1200};
	--color-security-text-inverse: #{$blue-100};
	--color-success-border-default: #{$green-400};
	--color-success-container-default: #{$green-900};
	--color-success-container-hover: #{$green-800};
	--color-success-container-pressed: #{$green-700};
	--color-success-container-subtle-default: #{$green-100};
	--color-success-container-subtle-hover: #{$green-200};
	--color-success-container-subtle-pressed: #{$green-300};
	--color-success-icon-default: #{$green-700};
	--color-success-icon-inverse: #{$green-200};
	--color-success-text-default: #{$green-1200};
	--color-success-text-inverse: #{$green-100};
	--color-surface-default: #{$neutral-0};
	--color-surface-form: #03030314;
	--color-surface-inverse: #{$neutral-1600};
	--color-surface-recessed: #{$neutral-200};
	--color-surface-sticky: #{$neutral-0};
	--color-surface-translucent: #ffffff99;
	--color-surface-overlay-default: #{$neutral-0};
	--color-surface-overlay-raised: #{$neutral-0};
	--color-surface-raised-default: #{$neutral-0};
	--color-surface-raised-subtle: #{$neutral-100};
	--color-text-disabled: #{$neutral-1000};
	--color-text-heading: #{$neutral-1700};
	--color-text-primary: #{$neutral-1500};
	--color-text-secondary: #{$neutral-1200};
	--color-text-inverse-disabled: #{$neutral-700};
	--color-text-inverse-heading: #{$neutral-0};
	--color-text-inverse-primary: #{$neutral-200};
	--color-text-inverse-secondary: #{$neutral-500};
	--color-text-on-image-disabled: #ffffff61;
	--color-text-on-image-heading: #{$neutral-0};
	--color-text-on-image-primary: #ffffff;
	--color-text-on-image-secondary: #ffffffab;
	--color-warning-border-default: #{$yellow-400};
	--color-warning-container-default: #{$yellow-900};
	--color-warning-container-hover: #{$yellow-800};
	--color-warning-container-pressed: #{$yellow-700};
	--color-warning-container-subtle-default: #{$yellow-100};
	--color-warning-container-subtle-hover: #{$yellow-200};
	--color-warning-container-subtle-pressed: #{$yellow-300};
	--color-warning-icon-default: #{$yellow-700};
	--color-warning-icon-inverse: #{$yellow-200};
	--color-warning-text-default: #{$yellow-1200};
	--color-warning-text-inverse: #{$yellow-100};
	--vos-surface-default: #8080804d;
	//Surfaces
	--surface-raised-subtle-shadow: none;
}

body {
	/*colors*/
	--color-neutral-1700: #{$neutral-1700};
	--color-neutral-1600: #{$neutral-1600};
	--color-neutral-1500: #{$neutral-1500};
	--color-neutral-1400: #{$neutral-1400};
	--color-neutral-1300: #{$neutral-1300};
	--color-neutral-1200: #{$neutral-1200};
	--color-neutral-1100: #{$neutral-1100};
	--color-neutral-1000: #{$neutral-1000};
	--color-neutral-900: #{$neutral-900};
	--color-neutral-800: #{$neutral-800};
	--color-neutral-700: #{$neutral-700};
	--color-neutral-600: #{$neutral-600};
	--color-neutral-500: #{$neutral-500};
	--color-neutral-400: #{$neutral-400};
	--color-neutral-300: #{$neutral-300};
	--color-neutral-200: #{$neutral-200};
	--color-neutral-100: #{$neutral-100};
	--color-neutral-0: #{$neutral-0};

	--color-red-1200: #{$red-1200};
	--color-red-1100: #{$red-1100};
	--color-red-1000: #{$red-1000};
	--color-red-900: #{$red-900};
	--color-red-800: #{$red-800};
	--color-red-700: #{$red-700};
	--color-red-600: #{$red-600};
	--color-red-500: #{$red-500};
	--color-red-400: #{$red-400};
	--color-red-300: #{$red-300};
	--color-red-200: #{$red-200};
	--color-red-100: #{$red-100};

	--color-yellow-1200: #{$yellow-1200};
	--color-yellow-1100: #{$yellow-1100};
	--color-yellow-1000: #{$yellow-1000};
	--color-yellow-900: #{$yellow-900};
	--color-yellow-800: #{$yellow-800};
	--color-yellow-700: #{$yellow-700};
	--color-yellow-600: #{$yellow-600};
	--color-yellow-500: #{$yellow-500};
	--color-yellow-400: #{$yellow-400};
	--color-yellow-300: #{$yellow-300};
	--color-yellow-200: #{$yellow-200};
	--color-yellow-100: #{$yellow-100};

	--color-green-1200: #{$green-1200};
	--color-green-1100: #{$green-1100};
	--color-green-1000: #{$green-1000};
	--color-green-900: #{$green-900};
	--color-green-800: #{$green-800};
	--color-green-700: #{$green-700};
	--color-green-600: #{$green-600};
	--color-green-500: #{$green-500};
	--color-green-400: #{$green-400};
	--color-green-300: #{$green-300};
	--color-green-200: #{$green-200};
	--color-green-100: #{$green-100};

	--color-blue-1200: #{$blue-1200};
	--color-blue-1100: #{$blue-1100};
	--color-blue-1000: #{$blue-1000};
	--color-blue-900: #{$blue-900};
	--color-blue-800: #{$blue-800};
	--color-blue-700: #{$blue-700};
	--color-blue-600: #{$blue-600};
	--color-blue-500: #{$blue-500};
	--color-blue-400: #{$blue-400};
	--color-blue-300: #{$blue-300};
	--color-blue-200: #{$blue-200};
	--color-blue-100: #{$blue-100};

	--color-eci-green-1200: #{$eci-green-1200};
	--color-eci-green-1100: #{$eci-green-1100};
	--color-eci-green-1000: #{$eci-green-1000};
	--color-eci-green-900: #{$eci-green-900};
	--color-eci-green-800: #{$eci-green-800};
	--color-eci-green-700: #{$eci-green-700};
	--color-eci-green-600: #{$eci-green-600};
	--color-eci-green-500: #{$eci-green-500};
	--color-eci-green-400: #{$eci-green-400};
	--color-eci-green-300: #{$eci-green-300};
	--color-eci-green-200: #{$eci-green-200};
	--color-eci-green-100: #{$eci-green-100};

	--color-eci-lime-green-1200: #{$eci-lime-green-1200};
	--color-eci-lime-green-1100: #{$eci-lime-green-1100};
	--color-eci-lime-green-1000: #{$eci-lime-green-1000};
	--color-eci-lime-green-900: #{$eci-lime-green-900};
	--color-eci-lime-green-800: #{$eci-lime-green-800};
	--color-eci-lime-green-700: #{$eci-lime-green-700};
	--color-eci-lime-green-600: #{$eci-lime-green-600};
	--color-eci-lime-green-500: #{$eci-lime-green-500};
	--color-eci-lime-green-400: #{$eci-lime-green-400};
	--color-eci-lime-green-300: #{$eci-lime-green-300};
	--color-eci-lime-green-200: #{$eci-lime-green-200};
	--color-eci-lime-green-100: #{$eci-lime-green-100};

	--color-eci-powder-blue-1200: #{$eci-powder-blue-1200};
	--color-eci-powder-blue-1100: #{$eci-powder-blue-1100};
	--color-eci-powder-blue-1000: #{$eci-powder-blue-1000};
	--color-eci-powder-blue-900: #{$eci-powder-blue-900};
	--color-eci-powder-blue-800: #{$eci-powder-blue-800};
	--color-eci-powder-blue-700: #{$eci-powder-blue-700};
	--color-eci-powder-blue-600: #{$eci-powder-blue-600};
	--color-eci-powder-blue-500: #{$eci-powder-blue-500};
	--color-eci-powder-blue-400: #{$eci-powder-blue-400};
	--color-eci-powder-blue-300: #{$eci-powder-blue-300};
	--color-eci-powder-blue-200: #{$eci-powder-blue-200};
	--color-eci-powder-blue-100: #{$eci-powder-blue-100};

	--color-eci-burnt-orange-1200: #{$eci-burnt-orange-1200};
	--color-eci-burnt-orange-1100: #{$eci-burnt-orange-1100};
	--color-eci-burnt-orange-1000: #{$eci-burnt-orange-1000};
	--color-eci-burnt-orange-900: #{$eci-burnt-orange-900};
	--color-eci-burnt-orange-800: #{$eci-burnt-orange-800};
	--color-eci-burnt-orange-700: #{$eci-burnt-orange-700};
	--color-eci-burnt-orange-600: #{$eci-burnt-orange-600};
	--color-eci-burnt-orange-500: #{$eci-burnt-orange-500};
	--color-eci-burnt-orange-400: #{$eci-burnt-orange-400};
	--color-eci-burnt-orange-300: #{$eci-burnt-orange-300};
	--color-eci-burnt-orange-200: #{$eci-burnt-orange-200};
	--color-eci-burnt-orange-100: #{$eci-burnt-orange-100};

	--color-eci-pink-1200: #{$eci-pink-1200};
	--color-eci-pink-1100: #{$eci-pink-1100};
	--color-eci-pink-1000: #{$eci-pink-1000};
	--color-eci-pink-900: #{$eci-pink-900};
	--color-eci-pink-800: #{$eci-pink-800};
	--color-eci-pink-700: #{$eci-pink-700};
	--color-eci-pink-600: #{$eci-pink-600};
	--color-eci-pink-500: #{$eci-pink-500};
	--color-eci-pink-400: #{$eci-pink-400};
	--color-eci-pink-300: #{$eci-pink-300};
	--color-eci-pink-200: #{$eci-pink-200};
	--color-eci-pink-100: #{$eci-pink-100};
}
