.MeetingsAndEventsWorldwideSalesOffice {
	&-wsoCard {
		padding: 16px 0;
		border-bottom: 1px solid $gray2;
		display: flex;
		justify-content: space-between;

		&-information {
			&-property {
				width: 50%;
				margin-right: auto;
				justify-content: center;
				flex-direction: column;
				text-align: left;
				font-weight: 100;
			}
			&-text-container {
				width: 50%;
				color: $gray1;
				text-align: right;
			}

			&-email {
				.email {
					text-transform: lowercase;
				}
			}
		}
	}
}
