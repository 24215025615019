.WeddingsContactForm {
	&.ContactForm {
		padding-top: 0;

		&-error {
			margin-bottom: 50px;
		}
	}

	&-infoHeading .Heading-title {
		text-align: center;
		margin: 50px 0 80px;

		@include viewport(medium) {
			margin: 70px 0 100px;
		}

		@include viewport(large) {
			margin: 90px 0 120px;
		}
	}

	&-newsletter {
		margin-top: 53px;

		@include viewport(medium) {
			margin-top: 67px;
		}
	}
}
