.AnimatedCardCarouselBackground {
	position: relative;
	color: $white;
	background-color: $black;

	&-BackgroundImage {
		display: none;

		@include viewport(medium) {
			display: block;
			position: absolute !important;
			width: 100%;
			height: 100%;
			top: 0;
			&::after {
				height: 100%;
				width: 100%;
				top: 0;
				content: '';
				display: block;
				position: absolute;
				pointer-events: none;
				background: change-color($black, $alpha: 0.65);
			}

			transition: opacity 0.5s linear;
			opacity: 0;
			&--active {
				opacity: 1;
			}
		}
	}

	.CenterFocusCarousel {
		padding: 60px 0;
		z-index: 2;

		@include viewport(medium) {
			padding: 100px 0;
		}

		.flickity-viewport {
			margin-left: calc((100% - 290px) / 2);

			@include rtl {
				margin-left: 0;
				margin-right: calc((100% - 290px) / 2);
			}

			@include viewport(medium) {
				margin-left: 0;

				@include rtl {
					margin-right: 0;
				}
			}
		}

		&-heading,
		&-navigation {
			z-index: inherit;
		}

		&-heading {
			.Heading-title {
				margin-top: 0;
			}
		}

		&-navigation {
			margin-top: 20px;

			@include viewport(medium) {
				margin-top: 40px;
			}
		}

		&-pagination {
			@include viewport(medium) {
				@include typography(h3);
				&--button {
					font-size: rem(20, 16);
				}
			}
		}

		&-mobile {
			.CenterFocusCarousel {
				&-navigation--mobile {
					.CenterFocusCarousel-pagination {
						font-size: rem(12, 16);
						margin: 0 14px;

						&--button {
							font-size: rem(16, 16);
						}
					}
				}
			}
		}
	}
}
