$buttonTypes: 'primary', 'secondary';
$ctaThemes: 'light', 'dark', 'greenPink', 'greenGreen', 'blueGreen', 'pinkRed', 'lightBlueGreen',
	'black', 'white', 'yellowOrange';

@each $type in $buttonTypes {
	@each $theme in $ctaThemes {
		.CTA--#{$type}--#{$theme} {
			@include button($type, $theme);
		}
	}
}

$linkTypes: 'underlined', 'labeledPlay', 'arrow', 'backButton', 'text';

@each $type in $linkTypes {
	@each $theme in $ctaThemes {
		.CTA--#{$type}--#{$theme} {
			@include link($type, $theme);
		}
	}
}

.CTA--underlined--red {
	@include link('underlined', 'red');
}

$ctaIcons: 'gallery_external', 'checkmark';

@each $icon in $ctaIcons {
	@each $theme in $ctaThemes {
		.CTA--underlined--#{$theme}--#{$icon} {
			@include link('underlined', $theme, 'before', 'icon-#{$icon}');
		}
	}
}

.CTA--small {
	padding: 7px 20px;
}

.CTA {
	pointer-events: all;
}
