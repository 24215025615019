.RelatedLinks {
	&-container {
		@include viewport(medium) {
			flex-direction: row;
			padding: 60px;
		}

		@include viewport(large) {
			padding: 60px 80px;
		}

		@include viewport(xlarge) {
			padding: 90px 128px;
		}

		display: flex;
		flex-direction: column;
		padding: 40px 30px;

		&--dark {
			background-color: $white;
			color: $black;
		}

		&--light {
			background-color: $black;
			color: $white;
		}
	}

	&-column--one,
	&-column--two {
		@include viewport(medium) {
			flex: 1 0 0;
		}
	}

	&-column--one {
		border: 0 solid $separatorLight;

		.BodySection--light {
			border-color: $separatorDark;
		}

		@include viewport(medium) {
			border-bottom-width: 0;
			border-right-width: 1px;
			padding: 0 45px 0 0;
		}

		@include viewport(large) {
			padding-right: 95px;
		}

		@include viewport(xlarge) {
			padding-right: 115px;
		}

		@include rtl {
			@include viewport(medium) {
				border-right-width: 0;
				border-left-width: 1px;
				padding: 0 0 0 45px;
			}

			@include viewport(large) {
				padding-left: 95px;
			}

			@include viewport(xlarge) {
				padding-left: 115px;
			}
		}

		border-bottom-width: 1px;
		padding: 0 0 30px;
	}

	&-column--two {
		@include viewport(medium) {
			align-self: flex-start;
			padding: 0 0 0 45px;
		}

		@include viewport(large) {
			padding-left: 95px;
		}

		@include viewport(xlarge) {
			padding-left: 115px;
		}

		@include rtl {
			@include viewport(medium) {
				padding: 0 45px 0 0;
			}

			@include viewport(large) {
				padding-right: 95px;
			}

			@include viewport(xlarge) {
				padding-right: 115px;
			}
		}

		padding: 30px 0 0;
	}

	&-title {
		margin-bottom: 20px;
		margin-top: 0;

		@include viewport(large) {
			margin-bottom: 30px;
		}
	}

	&-copy {
		margin-bottom: 0;
	}

	&-cta {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&:lang(zh-TW),
		&:lang(zh) {
			font-size: 1.125rem;
		}

		&:not(:last-of-type) {
			padding: 0 0 30px;
		}

		&::after {
			align-self: flex-start;
		}
	}
}
