.MeetingsAndEventsPropertyFinder {
	margin-bottom: 40px;

	&-venueCard {
		margin-top: 80px;
		padding-bottom: 80px;
		border-bottom: $black 1px solid;
		position: relative;
		display: flex;
		flex-direction: column;

		@include viewport(medium) {
			display: block;
		}

		@include viewport(large) {
			display: flex;
			flex-direction: row;
		}

		&-compare {
			align-items: center;
			margin-bottom: 20px;

			@include viewport(large) {
				margin-bottom: 0;
				position: absolute;
				right: 0;
				top: 0;
			}
			.Checkbox {
				&-label {
					font-weight: 700;
					font-size: 0.75rem;
					letter-spacing: 0.25em;
					line-height: 1.333333333em;
					text-transform: uppercase;
					font-family: $sansFont;
				}
			}
		}

		&-image {
			&-container {
				width: 100%;

				@include viewport(medium) {
					width: 50%;
					margin-right: 5%;
					float: left;
				}

				@include viewport(large) {
					margin-right: 15px;
					float: none;
				}
			}
		}

		&-information {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin-top: 30px;
			float: left;

			@include viewport(medium) {
				width: 45%;
				margin-top: 0;
			}

			@include viewport(large) {
				margin-left: 15px;
				float: none;
			}

			&-title {
				margin-bottom: 20px;

				@include viewport(large) {
					margin-bottom: 30px;
					width: 100%;
					padding-right: 100px;
				}
			}

			&-text {
				&-container {
					width: 100%;
					display: flex;
					flex-direction: column;
				}

				&.unitHidden {
					display: none;
				}
			}
		}

		&-ctas {
			width: 100%;
			display: flex;

			&-cta {
				width: 100%;

				@include viewport(medium) {
					width: 221px;
					max-width: 50%;
				}
			}
			&-details {
				margin-bottom: 10px;

				@include viewport(medium) {
					margin-bottom: 0;
					margin-right: 15px;
				}
			}
			&-rfp {
				margin-top: 10px;

				@include viewport(medium) {
					margin-top: 0;
					margin-left: 15px;
				}
			}
			&.mobile {
				padding-top: 30px;
				flex-direction: column;

				@include viewport(medium) {
					flex-direction: row;
				}

				@include viewport(large) {
					display: none;
				}
			}
			&.desktop {
				display: none;

				@include viewport(large) {
					display: flex;
				}
			}
		}
	}

	&-compareBar {
		&-container {
			&--sticky {
				position: fixed;
				bottom: 40px;
				width: 100%;
			}
		}

		&--hidden {
			display: none;
		}

		.CTA.buttonDisabled {
			pointer-events: none;
			background-color: $gray2;
			border-color: $gray2;
			&:active,
			:hover {
				color: $black;
				background-color: $gray2;
				border-color: $gray2;
			}
		}
	}

	.countryDropdown {
		&--disabled {
			opacity: 0.4;
		}
	}
}
