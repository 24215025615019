.TravelStatusDisplay {
	position: relative;
	color: black;
	margin: auto;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	&.TravelStatusDisplay--open {
		background-color: rgba(45, 167, 113, 0.1);
		border: 1px solid #2da771;
	}
	&.TravelStatusDisplay--close {
		background-color: rgba(255, 30, 30, 0.1);

		border: 1px solid #ff1e1e;
	}
	&.TravelStatusDisplay--neutral {
		background-color: rgba(216, 216, 216, 0.1);
		border: 1px solid #d8d8d8;
	}

	&.TravelStatusDisplay--quarantine {
		background-color: rgba(223, 148, 60, 0.2);
		border: 1px solid #df943c;
	}

	.TravelStatusDisplay-container {
		min-height: 80px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 20px;
	}

	.iconStatus {
		top: 35%;
		left: 2.29%;
		right: 94.99%;
		bottom: 35%;
		font-size: 24px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.iconStatus--neutral {
		display: hidden;
	}
	.bodyText {
		display: flex;
		justify-content: space-around;
		align-items: center;
		text-align: left;
	}
}
