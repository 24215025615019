$startRadius: 50% 0 0 50%;
$endRadius: 0 50% 50% 0;

.hideCaw {
	visibility: hidden;
}
.caw-comp__wrapper,
.caw-popup__wrapper {
	@include media($navigationBreakpoint) {
		display: block;
		.Calendar {
			margin-left: -10px;
			display: inline-block;
			box-shadow: 0px 9px 120px 0px #00000033;
			&-row {
				height: auto;
				opacity: 0;
				transition: opacity 300ms ease-in-out;
				z-index: 999;
				&--active {
					transform: none;
					position: absolute;
					left: 0;
					top: 0;
					display: block;
					opacity: 1;
					height: auto;
					padding: auto;
				}
				@include rtl {
					left: auto;
					right: 0;
				}
			}
			&-wrapper {
				position: relative;
				transform: translateY(160px);
				transform: none;
				background: $gray6;
				.Calendar-row--active & {
					transform: none;
				}
			}
		}
	}
}

.caw-comp__wrapper,
.caw-popup__wrapper {
	.Calendar-Divider-Outer {
		background: $gray6;
		margin: 0px;

		.Calendar-Divider {
			margin: 0px 22px;

			@include viewport(xlarge) {
				margin: 0px 55px;
			}

			border-bottom: 1px solid $gray3;
		}
	}

	.Calendar-Done,
	.Calendar-Meta {
		background: white;
		margin-bottom: 0px;
		margin-top: 0px;
		margin-right: -25px;
		padding-left: 22px;
		padding-right: 29px;
		@include viewport(xlarge) {
			padding-left: 77px;
			padding-right: 77px;
		}

		@include rtl {
			margin-right: 0;
		}

		&-inner {
			padding: 0 0 20px 0;

			@include media($navigationBreakpoint) {
				padding: 0 0 20px 0;
			}

			@include viewport(xlarge) {
				padding: 0 33px 20px 0px;

				@include rtl {
					padding: 0 0px 20px 33px;
				}
			}
		}
	}

	.Calendar-Meta {
		padding-top: 20px;
	}
}

.caw-popup__wrapper,
.caw-ppc__wrapper {
	.Calendar-Divider-Outer {
		.Calendar-Divider {
			margin: 0px 22px;
		}
	}

	.Calendar-Done,
	.Calendar-Meta {
		margin-top: 0px;
		@include viewport(xlarge) {
			padding-left: 60px;
			padding-right: 60px;
		}

		&-inner {
			@include viewport(xlarge) {
				padding: 0 0 20px 0;
			}
		}
	}
}

.caw-comp__wrapper,
.caw-popup__wrapper,
.caw-ppc__wrapper {
	@include media($navigationBreakpoint) {
		.Calendar {
			margin-left: 0px;
		}
		.Calendar-Divider,
		.Calendar-Done,
		.Calendar-Meta {
			padding-left: 22px;
			padding-right: 22px;
		}
	}
}

.caw__wrapper .Calendar {
	display: inline-block;
	width: 100%;
	&-row {
		height: 0;
		opacity: 0;
		transition: opacity 300ms ease-in-out;
		z-index: 199;
		&--active {
			position: fixed;
			left: 0;
			right: 0;
			top: 105px;
			opacity: 1;
			height: auto;
		}
		@include rtl {
			left: auto;
			right: 0;
		}
		@include media($navigationBreakpoint) {
			transform: none;
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			opacity: 1;
			height: auto;
			padding: auto;
		}
	}

	&-wrapper {
		position: relative;
		transform: translateY(0px);
		transition: transform 400ms ease-in-out;
		@include media($navigationBreakpoint) {
			transform: none;
			background: $gray6;
		}

		.Calendar-row--active & {
			transform: translateY(0);
			@include media($navigationBreakpoint) {
				transform: none;
			}
		}
	}
}
.DateField:focus,
.DateField:active,
.DateField_row:focus,
.DateField_row:active {
	outline: none;
}
.DayPicker {
	position: relative;
	text-align: left;
	background: $gray6;
	@include media($navigationBreakpoint) {
		background: none;
		padding-bottom: 5px;
	}

	.caw-standalone__wrapper:not(.caw-ppc__wrapper) & {
		@include viewport(xlarge) {
			width: 990px !important;
		}
	}
	&__verticalScrollable {
		height: 0;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		.DateField--takeover & {
			height: calc(100vh - 170px);
		}
	}

	&__hidden {
		visibility: hidden;
	}

	&_portal__horizontal {
		box-shadow: none;
		position: absolute;
		left: 50%;
		top: 50%;
	}

	&_portal__vertical {
		position: initial;
	}

	&_focusRegion {
		outline: 0;
	}

	&_weekHeaders {
		position: relative;

		&__horizontal {
			margin-left: 9px;
		}
	}

	// hero caw (default):	.caw-comp__wrapper .caw-standalone__wrapper
	// small caw:			.caw-popup__wrapper .caw-standalone__wrapper
	// takeover caw:		.caw__wrapper

	&_weekHeader {
		position: absolute;
		z-index: 2;
		padding: 0;
		text-align: left;

		@include media($navigationBreakpoint) {
			top: 80px;
			.caw-popup__wrapper & {
				top: 60px;
			}
		}

		@include viewport(xlarge) {
			.caw__wrapper & {
				top: 80px;
			}
		}

		&__vertical {
			left: 50%;
		}

		&__vertical {
			top: 0;
			display: table-row;
			margin-left: 0;
			left: 0;
			width: 100%;
			text-align: center;
			background: $white;
			padding: 20px 0 10px;
		}

		&_ul {
			list-style: none;
			margin: 1px 0;
			padding-left: 0;
			padding-right: 0;
		}

		&_li {
			display: inline-block;
			text-align: center;
			@include typography(c2);

			small {
				font-size: 100%;
				text-transform: none;
			}
		}
	}

	&_transitionContainer {
		position: relative;
		overflow-x: hidden;
		border-radius: 3px;
		@include media($navigationBreakpoint) {
			overflow: hidden;
			max-height: 400px;
			min-height: 383px;
		}
		@include viewport(xlarge) {
			width: 990px !important;
			min-height: 500px;
			max-height: none;
		}

		.caw-popup__wrapper & {
			@include media($navigationBreakpoint) {
				overflow: hidden;
				max-height: 372px;
				min-height: 372px;
			}
			@include viewport(xlarge) {
				width: 100% !important;
				min-height: 372px;
				max-height: 372px;
			}
		}
	}
}

.DayPickerNavigation {
	&_container {
		position: relative;
		z-index: 2;
		@include media($navigationBreakpoint) {
			position: absolute;
			left: 0;
			width: 100%;
			top: 0;
		}

		&__vertical {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 52px;
			width: 100%;
		}

		&__verticalScrollable {
			position: relative;
		}
	}

	&_button {
		cursor: pointer;
		line-height: 0.78;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&__default {
			color: #757575;
		}

		&__horizontal {
			border-radius: 3px;
			padding: 6px 9px;
			top: 25px;
			position: absolute;
			@include rtl {
				transform: rotate(180deg);
			}

			.caw-popup__wrapper & {
				top: 13px;
			}
		}

		&__vertical {
			display: inline-block;
			position: relative;
			height: 100%;
			width: 50%;

			&__default {
				padding: 5px;
			}
		}

		&_icon {
			width: 18px;
			height: 18px;

			&--next {
				transform: rotate(180deg);
			}
		}
	}

	&_leftButton__horizontal {
		left: 13px;
	}

	&_rightButton__horizontal {
		right: 13px;
	}

	&_nextButton__vertical__default {
		border-left: 0;
	}

	&_nextButton__verticalScrollable {
		width: 100%;
	}

	&_svg__horizontal {
		height: 19px;
		width: 19px;
		fill: #82888a;
	}

	&_svg__vertical {
		height: 42px;
		width: 42px;
		fill: #565a5c;
	}
}

.CalendarMonthGrid {
	text-align: left;
	z-index: 0;
	display: none;

	.Calendar-row--active & {
		display: block;
	}

	@include media($navigationBreakpoint) {
		display: block;
	}

	&__animating {
		z-index: 1;
	}

	&__horizontal {
		position: absolute;

		.caw-popup__wrapper & {
			@include viewport(xlarge) {
				left: 9px;
			}
		}
	}

	&_month__horizontal {
		display: inline-block;
		vertical-align: top;
		min-height: 100%;
	}
}

.CalendarMonth {
	text-align: center;
	padding: 0 13px;
	vertical-align: top;
	user-select: none;
	@include viewport(xlarge) {
		padding: 0 30px;
	}

	.caw-popup__wrapper & {
		@include viewport(xlarge) {
			padding: 0 13px;
		}
	}

	&_table {
		border-collapse: separate;
		border-spacing: 0 7px;
	}

	tr {
		& > td:empty + td.CalendarDay__hovered_span,
		& > td:empty + td.CalendarDay__selected_span {
			border-radius: $startRadius;
			@include rtl {
				border-radius: $endRadius;
			}
		}

		& > td:empty + td.CalendarDay__selected_end {
			border-radius: 50%;
		}

		& > td.CalendarDay__selected_span ~ td.CalendarDay__selected_span:last-of-type {
			border-radius: $endRadius;
			@include rtl {
				border-radius: $startRadius;
			}
		}

		&:last-child > td.CalendarDay__hovered_span + td:empty,
		&:last-child > td.CalendarDay__selected_span + td:empty,
		&:last-child > td.CalendarDay__selected_start + td:empty {
			position: relative;

			&::after,
			&::before {
				content: '';
				display: block;
				width: 50%;
				height: 50%;
				position: absolute;
				left: -50%;
				pointer-events: none;
			}
			@include rtl {
				&::after,
				&::before {
					left: auto;
					right: -50%;
				}

				&::before {
					top: 0;
					background: radial-gradient(circle at 100% 100%, transparent 69%, $gray6 0);
				}

				&::after {
					bottom: 0;
					background: radial-gradient(circle at 100% 0, transparent 69%, $gray6 0);
				}
			}

			&::before {
				background: radial-gradient(circle at 0 100%, transparent 69%, $gray6 0);
				top: 0;
			}

			&::after {
				background: radial-gradient(circle at 0 0, transparent 69%, $gray6 0);
				bottom: 0;
			}
		}
	}

	&_caption {
		font-size: 18px;
		font-style: italic;
		text-align: center;
		padding-top: 30px;
		padding-bottom: 30px;
		caption-side: initial;
		@include media($navigationBreakpoint) {
			padding-bottom: 54px;
			opacity: 1;
			transition: opacity 0.1s;

			&_hidden {
				opacity: 0;
			}
		}

		strong {
			font-weight: 300;
		}

		&__verticalScrollable {
			padding-top: 40px;
			padding-bottom: 7px;
		}

		.caw-popup__wrapper & {
			padding-top: 15px;
		}
	}
}

.CalendarDay {
	&_container {
		padding: 0;
		@include typography(c2);
		@include sansBold;
		font-weight: bold;

		&:hover:not(.CalendarDay__hovered_span):not(.CalendarDay__selected_start):not(
				.CalendarDay__selected_end
			):not(.CalendarDay__selected_span):not(.CalendarDay__blocked_out_of_range) {
			background: $gray3;
			border-radius: 50%;
		}
	}

	&_button {
		position: relative;
		height: 100%;
		width: 100%;
		text-align: center;
		background: 0 0;
		border: 0;
		margin: 0;
		padding: 0;
		color: inherit;
		font: inherit;
		line-height: normal;
		overflow: visible;
		box-sizing: border-box;
		cursor: pointer;
		letter-spacing: 0;

		&:active {
			outline: 0;
		}

		&__default {
			cursor: default;
		}
	}

	&__blocked_out_of_range {
		color: #7d7d7d;
	}

	&__selected_end,
	&__selected_span,
	&__selected_span.CalendarDay__hovered_span,
	&__selected_start {
		background: $black;
		color: $white;
	}

	&__selected_span:hover {
		border-radius: 0;
		background: $gray3;
		color: $black;
	}

	&__selected_span:first-child,
	&__selected_start {
		border-radius: $startRadius;
	}

	&__selected_span:first-child,
	&__selected_start {
		@include rtl {
			border-radius: $endRadius;
		}
	}

	&__selected_end,
	&__selected_end:hover,
	&__selected_span:last-child {
		border-radius: $endRadius;
	}

	&__selected_end,
	&__selected_span:last-child {
		@include rtl {
			border-radius: $startRadius;
		}
	}

	&__selected_end:first-child,
	&__selected_start:last-child {
		border-radius: 50%;
	}

	&__hovered_span {
		background: $gray3;
		border-radius: 0;

		&:first-child {
			border-radius: $startRadius;
			@include rtl {
				border-radius: $endRadius;
			}

			&:hover {
				border-radius: 50%;
				@include rtl {
					border-radius: 50%;
				}
			}
		}

		&:hover {
			border-radius: $endRadius;
			@include rtl {
				border-radius: $startRadius;
			}
		}

		&:last-child {
			border-radius: $endRadius;
			@include rtl {
				border-radius: $startRadius;
			}
		}
	}

	&__selected_start
		+ td:not(.CalendarDay__selected):not(.CalendarDay__selected_span):not(
			.CalendarDay__hovered_span
		),
	&__selected_start:hover + td:not(.CalendarDay__selected):not(.CalendarDay__selected_span) {
		position: relative;

		&::after,
		&::before {
			content: '';
			display: block;
			width: 50%;
			height: 50%;
			position: absolute;
			left: -50%;
			pointer-events: none;
		}
		@include rtl {
			&::after,
			&::before {
				left: auto;
				right: -50%;
			}

			&::before {
				top: 0;
				background: radial-gradient(circle at 100% 100%, transparent 69%, $gray6 0);
			}

			&::after {
				bottom: 0;
				background: radial-gradient(circle at 100% 0, transparent 69%, $gray6 0);
			}
		}

		&::before {
			background: radial-gradient(circle at 0 100%, transparent 71%, $gray6 0);
			top: 0;
		}

		&::after {
			background: radial-gradient(circle at 0 0, transparent 71%, $gray6 0);
			bottom: 0;
		}
	}

	&__selected_start:hover + td.CalendarDay__hovered_span:not(.CalendarDay__selected_span) {
		border-radius: 0;
		background: none;
		@include rtl {
			border-radius: $startRadius;
		}
	}

	+ td.CalendarDay__hovered_span {
		background: none;

		&::after,
		&::before {
			display: none;
		}

		&.CalendarDay__selected_span {
			background: $black;
			border-radius: 0;
		}
	}

	&__outside {
		background: #fff;
		color: #565a5c;
	}

	&__highlighted_calendar:not(.CalendarDay__blocked_out_of_range) {
		color: $black;
		position: relative;

		&.CalendarDay__selected,
		&.CalendarDay__selected_span {
			color: $white;

			&:hover {
				background: $black;
			}
		}

		& button:after {
			pointer-events: none;
			content: '';
			position: absolute;
			width: 70%;
			height: 70%;
			top: 16%;
			left: 16%;
			border: 1px solid $black;
			border-radius: 50%;
			pointer-events: none;
		}
	}

	&__blocked_out_of_range button {
		cursor: default;
	}

	&__blocked_calendar:not(.CalendarDay__selected_start):not(.CalendarDay__selected_span):not(
			.CalendarDay__hovered_span
		):not(.CalendarDay__selected_end):not(.CalendarDay__blocked_out_of_range) {
		color: $black;
		background: $gray6;
		position: relative;

		button,
		button:hover {
			cursor: pointer;
			position: relative;

			&::after {
				pointer-events: none;
				content: '';
				position: absolute;
				background: $gray2;
				height: 1px;
				width: 100%;
				left: 0;
				top: 50%;
			}
		}
	}
}

.flexdates-button,
.flexdates-done-button {
	min-width: 115px;
	height: 35px;
	margin: 0px 10px 20px 0px;

	&:focus,
	&:hover {
		background-color: $black;
		border-color: $white;
		color: $white;
	}
	&:focus-visible {
		background-color: $black;
		color: $white;
	}
}

.flexdates-button:not(.selected) {
	background-color: transparent;
	border-color: $black;
	color: $black;

	&:focus,
	&:hover {
		color: $black;
		background-color: $white;
		border-color: $white;
	}
	&:focus-visible {
		color: $black;
		background-color: $white;
	}
}

.flexdates-done-button {
	min-width: 100px;
	margin: 0;
}

.Flex-Dates {
	bottom: 0px;
	width: 100%;
	padding: 20px 30px 0px;
	background-color: #fff;
	@include viewport(xlarge) {
		padding: 20px 75px 0px 75px;
	}
	&-label {
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 3px;
		text-transform: uppercase;
		flex: none;
		order: 0;
		flex-grow: 0;
		color: #000;

		@include rtl {
			margin-right: 10px;
		}
	}

	.caw-popup__wrapper & {
		@include viewport(xlarge) {
			margin-left: 0px;
			margin-right: 22px;
			padding: 20px 30px 0px 30px;
		}
	}
}

.Calendar-Done,
.Calendar-Meta {
	transition: opacity 0.8s;
	bottom: 65px;
	width: 100%;
	@include media($navigationBreakpoint) {
		margin-bottom: 50px;
	}
}

.Calendar-Done .Calendar-Done-inner,
.Calendar-Meta .Calendar-Meta-inner {
	opacity: 0;
	transition: opacity 0.8s;
}

.Calendar-Done--active .Calendar-Done-inner,
.Calendar-Meta--active .Calendar-Meta-inner {
	opacity: 1;
}

.caw__wrapper .Calendar-row--active .Calendar-Meta--active {
	position: fixed;
	@include media($navigationBreakpoint) {
		position: static;
	}
}

.Calendar-row--active .Calendar-Meta--active {
	position: static;
}

.Calendar-Done-inner,
.Calendar-Meta-inner {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	position: relative;
	@include media($navigationBreakpoint) {
		padding: 20px 0;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 25px;
		left: 0;
		height: 75px;
		width: 100%;
		pointer-events: none;
		background: linear-gradient(rgba(255, 255, 255, 0.001), $white);
		@include media($navigationBreakpoint) {
			display: none;
		}
	}
}

.Calendar-updated {
	p {
		@include serifItalic;
		font-size: rem(15, 16);
		letter-spacing: em(0.5, 15);
		line-height: em(25, 15);
		font-style: italic;
		color: $gray2;
		margin: 0;
	}
}

.legend {
	&-list {
		@include clearfix;
		padding: 0 15px;
		list-style: none;
		margin: 0;
		display: flex;
		width: 100%;
		position: relative;
		background: $white;
		@include media($navigationBreakpoint) {
			background: none;
			width: auto;
			margin: 0;
			position: static;
			bottom: auto;
			padding: 0;
		}
	}

	&-item {
		float: left;
		display: flex;
		align-items: center;
		flex: 1 0 50%;
		@include media($navigationBreakpoint) {
			margin-right: 30px;
			&:lang(ru) {
				margin-right: 5px;
			}
			flex: none;
			@include rtl {
				margin-right: 0;
				margin-left: 30px;
			}
		}
		@include viewport(xlarge) {
			margin-right: 50px;
			@include rtl {
				margin-right: 0;
				margin-left: 50px;
			}
		}

		.caw-popup__wrapper & {
			@include viewport(xlarge) {
				margin-right: 30px;
				flex: none;

				@include rtl {
					margin-right: 0;
					margin-left: 30px;
				}
			}
		}
	}

	&-icon {
		display: inline-block;
		width: 20px;
		height: 20px;
		position: relative;
		border-radius: 50%;
		margin-right: 10px;
		&:lang(ru) {
			margin-right: 5px;
		}
		@include rtl {
			margin-right: 0;
			margin-left: 10px;
		}

		&--restricted::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			border-radius: 50%;
			left: 0;
			border: 1px solid $black;
			pointer-events: none;
		}

		&--unavailable::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			border-radius: 50%;
			left: 0;
			border: 1px solid $black;
			pointer-events: none;
		}

		&--unavailable::after {
			content: '';
			position: absolute;
			background: $gray2;
			height: 1px;
			width: 100%;
			left: 0;
			top: 50%;
		}
	}
}

.caw-ppc__wrapper {
	@include media($navigationBreakpoint) {
		.legend {
			&-item {
				margin-right: 10px;
				@include rtl {
					margin-right: 0;
					margin-left: 10px;
				}
			}
		}
	}
}

.Calendar-apply {
	position: fixed;
	left: 0;
	bottom: 0;
	padding: $gutter_medium * 0.5;
	width: 100%;
	background: $white;
	display: none;

	&--active {
		display: block;
		z-index: 199;
		@include media($navigationBreakpoint) {
			display: none;
		}
	}
}

.Calendar-apply--button {
	display: block;
	text-align: center;
	width: 100%;
}

.Calendar-done-updated {
	text-align: left;
	width: calc((100% - 110px) / 2);

	@include rtl {
		text-align: right;
	}
}

.Calendar-done-date-range {
	width: calc((100% - 110px) / 2);
	background: none;
	margin: 0;
	position: static;
	bottom: auto;
	padding: 0;
	padding-right: 20px;

	@include rtl {
		padding-left: 20px;
		padding-right: 0px;
	}

	.ty-c4 {
		width: 100%;
		text-align: right;
		text-transform: none;
		font-size: 12px;
		color: $gray7;
		letter-spacing: 0.5px;

		@include rtl {
			text-align: left;
		}

		p {
			/* Prevents a slight position tik when dates are selected vs when they are not. */
			margin-block-start: 0.8em;
			margin-block-end: 0.8em;
		}
	}
}
