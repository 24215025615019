.Overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	z-index: $overlay-z-index;
	display: none;
	opacity: 0;

	&--dark {
		background-color: $black;
		color: $white;
	}

	&--light {
		background-color: $white;
		color: $black;
	}

	&-content {
		position: relative;
		display: none;
		opacity: 0;
	}

	&-closeButton {
		position: absolute;
		top: $gutter-small;
		right: $gutter-small;
		height: 20px;
		width: 20px;
		line-height: 0;
		font-size: inherit;
		z-index: $overlay-z-index;
		cursor: pointer;

		@include rtl {
			right: auto;
			left: $gutter-small;
		}

		&::before {
			line-height: 20px;
		}
	}

	&--open-transition-start {
		opacity: 0;
		transition: opacity 0.4s ease-in;
		display: block;
		overflow: hidden;

		.Overlay-content {
			opacity: 0;
			display: block;
			transition: all 0.3s ease-in;
			transition-delay: 0.5s;
		}

		.TopNav-overlay-campaign,
		.TopNav-overlay-headline,
		.TopNav-overlay-links {
			transform: translateY(20px);
			transition: all 0.3s ease-in;
			transition-delay: 0.5s;
		}
	}

	&--open-transition {
		opacity: 1;

		.Overlay-content {
			opacity: 1;
		}

		.TopNav-overlay-campaign,
		.TopNav-overlay-headline,
		.TopNav-overlay-links {
			transform: translateY(0);
		}
	}

	&--opened {
		opacity: 1;
		display: block;

		.Overlay-content {
			opacity: 1;
			display: block;
		}

		.TopNav-overlay-campaign,
		.TopNav-overlay-headline,
		.TopNav-overlay-links {
			transform: translateY(0);
		}
	}

	&--close-transition-start {
		opacity: 1;
		display: block;
		transition: opacity 0.4s ease-in;
		overflow: hidden;

		.Overlay-content {
			opacity: 1;
			display: block;
			transition: all 0.3s ease-in;
		}

		.TopNav-overlay-campaign,
		.TopNav-overlay-headline,
		.TopNav-overlay-links {
			transform: translateY(0);
			transition: all 0.4s ease-in;
		}
	}

	&--close-transition {
		opacity: 0;

		.Overlay-content {
			opacity: 0;
		}
	}

	&--close-transition-end {
		opacity: 0;
		display: none;

		.Overlay-content {
			opacity: 0;
			display: none;
			transform: translateY(0);
		}
	}
}

.backdrop {
	position: absolute;
	background: var(--color-backdrop, rgba(3, 3, 3, 0.5));

	/* blur/default-15 */
	backdrop-filter: blur(7.5px);
}

.overlay {
	&-20 {
		background: var(--color-img-overlay-20, rgba(3, 3, 3, 0.2));
	}
	&-40 {
		background: var(--color-img-overlay-40, rgba(3, 3, 3, 0.4));
	}
	&-60 {
		background: var(--color-img-overlay-20, rgba(3, 3, 3, 0.6));
	}
	&-80 {
		background: var(--color-img-overlay-20, rgba(3, 3, 3, 0.8));
	}
}

.gradient {
	&-top {
		background: var(
			--dark-gradient-top-down,
			linear-gradient(180deg, rgba(3, 3, 3, 0.8) 0%, rgba(3, 3, 3, 0) 100%)
		);
	}
	&-bottom {
		background: var(
			--dark-gradient-bottom-up,
			linear-gradient(180deg, rgba(3, 3, 3, 0) 0%, rgba(3, 3, 3, 0.8) 100%)
		);
	}
}

.blur {
	&-5 {
		/* blur/light-5 */
		backdrop-filter: blur(2.5px);
	}
	&-15 {
		backdrop-filter: blur(7.5px);
	}
	&-30 {
		backdrop-filter: blur(15px);
	}
}
