@import '../../../components/content/navigation/variables';

.AlertBanner {
	position: relative;
	top: 0;
	border-bottom-width: 1px;
	z-index: 10;
	transition: z-index 0.3s;
	width: 100%;
	padding: 4px 0;
	.Body-navigationOverlay--shown & {
		z-index: 9;
	}

	&-ctaBlock {
		position: relative;
		z-index: 1;
	}

	&-WebMessage {
		max-width: 930px;
		margin: auto;
		padding: 0 30px;
		& > p {
			vertical-align: bottom;
			&:first-of-type {
				display: inline;
			}
			&:last-of-type {
				margin-bottom: 0;
			}

			a {
				color: inherit;

				&:hover {
					color: $whover;
				}
			}
		}
	}
}
