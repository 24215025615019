.HeadingAndImageGroup {
	&-heading {
		text-align: center;
		.ty-h2 {
			font-size: 3.375rem;
			font-weight: 100;
			letter-spacing: 0.0625rem;
			line-height: 4rem;
			text-align: center;
			text-transform: none;
		}
		margin-bottom: 40px;
	}

	&-items {
		display: none;

		@include viewport(medium) {
			display: flex;
			flex-direction: row;
			align-items: baseline;
			justify-content: space-around;
			flex-wrap: wrap;

			&.moreThan3 {
				justify-content: space-around;

				.HeadingAndImageGroupItem {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	.MobileCarousel {
		@include viewport(medium) {
			display: none;
		}

		.flickity-page-dots {
			bottom: 0;
		}

		&--light {
			.dot.is-selected {
				background-color: $black;
			}
		}
	}
}

.HeadingAndImageGroupItem {
	width: 83.33333%;
	margin-left: 8.33333%;

	@include rtl {
		margin-left: 0;
		margin-right: 8.33333%;
	}

	@include viewport(medium) {
		margin-left: 7%;
		margin-right: 7%;
		flex-basis: 28.33333%;
	}

	&-image {
		margin-bottom: 40px;
		opacity: 1;
		transition: opacity 0.5s;
	}
	&-text {
		text-align: center;
	}
}
