.ListingCardsGrid {
	&-list {
		display: flex;
		flex-wrap: wrap;
		// Negative margin for last row
		margin-bottom: -60px;

		// Fix for Safari bug
		// Search in stackoverflow for complete info:
		// flexbox-wraps-last-column-of-the-first-row-in-safari
		&.row::before,
		&.row::after {
			content: normal;
		}
	}

	&-item {
		margin: 0 0 60px;
	}

	&-Image {
		margin-bottom: 30px;

		img {
			max-width: 100%;
		}
	}

	&-CTA {
		@include headingBorder();
		max-width: 95%;

		&::after {
			margin: 20px 0;
		}

		.CTA {
			display: block;

			&::after {
				float: none;
			}
		}
	}

	.CTA {
		.BodySection--dark & {
			@include grayHover('dark');
			color: $white;
		}
	}

	&-description {
		margin-bottom: 0;
	}

	&-descriptionCTA {
		margin-top: 18px;
	}
}
