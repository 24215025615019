.PaginatedFilter {
	margin-bottom: 40px;
	position: relative;
	.active {
		display: block;
	}
	.hidden {
		display: none;
	}

	&-itemContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.FilterGroup {
		&-container {
			background-color: $gray4;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 5;
		}

		&-toggle {
			position: relative;
			margin: 15px 0;

			@include viewport(large) {
				margin: 30px 0;
			}

			&--open {
				.FilterGroup-toggle-button {
					background-color: $black;
					color: $white;

					&::after {
						transform: rotate(180deg);
					}
				}
			}

			&-button {
				border-radius: 5px;
				border-color: $white;
				padding: 16px 30px 16px 15px;
				justify-content: start;
				min-width: 100%;

				&::after {
					@include icon('icon-caret');
					position: absolute;
					right: 25px;
					font-size: 5px;
					pointer-events: none;
				}

				@include viewport(xlarge) {
					.Button-label {
						font-weight: 700;
						font-size: 0.75rem;
						letter-spacing: 0.25em;
						line-height: 1.333333333em;
					}
				}
			}

			&-container {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: start;
				align-items: center;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		&-optionGroup {
			background-color: $gray4;
			width: 100%;
			z-index: 1;
			border: none;
			border-top: 1px solid $black;

			&-fields {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-start;
				margin-top: 40px;
			}

			&-field {
				margin-top: 20px;
				padding-left: 0;
				padding-right: 30px;

				&.col-lg-4 {
					@include viewport(large) {
						padding-right: 10px;
						padding-left: 10px;
						&:nth-child(3n + 1) {
							padding-right: 20px;
							padding-left: 0;
						}
						&:nth-child(3n + 3) {
							padding-left: 20px;
							padding-right: 0;
						}
					}
				}

				.formElement {
					padding-bottom: 0;
					&-field {
						background-color: $gray4;
					}
					&-label {
						font-weight: 700;

						@include viewport(xlarge) {
							font-size: 0.75rem;
							letter-spacing: 0.25em;
							line-height: 1.333333333em;
						}
					}

					&--focusAlways {
						transform: translateY(-30px);
					}
				}

				.SwitchField {
					&-label {
						font-weight: 700;
					}

					&-switch {
						width: 1.5em;
						height: 0.75em;
					}

					&-slider {
						border-radius: 0.75em;
						&::before {
							width: 0.75em;
							height: 0.75em;
						}
					}

					&-checkbox:checked + .SwitchField-slider::before {
						-webkit-transform: translateX(0.75em);
						-ms-transform: translateX(0.75em);
						transform: translateX(0.75em);
					}
				}

				.Checkbox {
					&Group {
						&-checkbox {
							align-items: center;
						}
					}
					&-label {
						font-weight: 700;
						text-transform: uppercase;
						font-family: $sansFont;
						font-size: 0.625rem;
						letter-spacing: 0.3em;
						line-height: 1.3em;

						@include viewport(xlarge) {
							font-size: 0.75rem;
							letter-spacing: 0.25em;
							line-height: 1.333333333em;
						}
					}
					&-icon {
						color: $gray2;
					}
				}
			}

			&-buttons {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 60px;
				margin-bottom: 10px;

				@include viewport(medium) {
					margin: 40px 0;
					flex-direction: row;
					justify-content: flex-end;
				}
			}

			&-button {
				width: 100%;
				margin-bottom: 20px;

				@include viewport(medium) {
					width: unset;
					margin: 0 15px;
				}
			}
		}

		&-TextFilter {
			margin: 30px 0;

			.TextInput {
				margin: 0;
				padding: 0;
				position: relative;

				&::after {
					@include icon('icon-search');
					position: absolute;
					right: 20px;
					top: 15px;
					font-weight: bold;
				}

				@include rtl {
					&::after {
						right: auto;
						left: 20px;
					}
				}

				&-field {
					height: auto;
					padding: 15px 40px 15px 20px;
					background-color: $white;
					border: 1px solid $gray3;
					line-height: 1.5em;

					&:placeholder-shown {
						@include typography(h4);
					}

					@include rtl {
						padding-left: 40px;
						padding-right: 20px;
					}
				}

				.formElement-label {
					display: none;
				}
			}
		}
	}

	&--loading {
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
		width: 100%;
		padding-top: 301px;

		@include viewport(medium) {
			padding-top: 175px;
		}

		@include viewport(large) {
			padding-top: 131px;
		}
	}

	&-item {
		&-groupTitle {
			padding-top: 80px;
			padding-bottom: 40px;
			flex-basis: 50%;
			border-bottom: $gray3 1px solid;

			&:first-child {
				flex-grow: 1;
			}

			&:nth-child(2):nth-last-child(1) {
				text-align: right;
			}

			&-container {
				display: flex;
				border: none;
			}
		}
	}

	&-pagination {
		&-navigation {
			margin-top: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			@include viewport(medium) {
				flex-direction: row;
			}
		}

		&-button {
			height: 42px;
			width: 42px;
			margin: 10px 0;

			@include viewport(medium) {
				margin: 10px;
				height: 42px;
				width: 42px;
			}
			&.activeButton {
				height: 42px;
				width: 42px;
				color: $white;
				background-color: $black;
				border-radius: 50%;
			}
		}

		&-showAll {
			margin-top: 40px;

			@include viewport(medium) {
				margin-top: 0;
				margin-left: 80px;
			}
		}

		&-showLess-container {
			margin-top: 80px;
			display: none;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			@include viewport(medium) {
				flex-direction: row;
			}
		}

		&-showLess {
			margin-top: 40px;

			@include viewport(medium) {
				margin-top: 0;
				margin-left: 80px;
			}
		}
	}

	&-noResults {
		text-align: center;
		margin-top: 80px;
	}

	&-filtersHeading {
		padding: 16px 30px 16px 15px;

		@include typography(c3);

		@include viewport(large) {
			@include typography(c1);
		}
	}
}
