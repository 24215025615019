@import '../../../components/content/navigation/variables';

$st-hero-offset: 200px;

.StandardHero {
	background-color: $black;
	text-align: center;
	padding-top: 30px;

	&.hasEliteGuestComponent {
		padding-bottom: 50px;

		@include media($navigationBreakpoint) {
			padding-bottom: 0;
		}
	}

	// Removes margin added on PLP hero
	.Hero {
		margin-top: 0;
	}

	&-breadcrumb {
		display: inline-block;
		margin: 0 0 4px;
		text-decoration: none;
		text-transform: none;
		border: 0;
	}

	&-eyebrow {
		@include serifItalic;
		font-weight: bold;
	}

	&-title {
		padding-bottom: 30px;

		.ty-h4 {
			font-size: 16px;
			letter-spacing: 0.5px;
			line-height: 24px;

			@include wls {
				font-family: 'EB Garamond';
				font-weight: 400;
				font-size: rem(18, 16);
				line-height: em(27, 18);
				letter-spacing: em(0.5, 18);
			}
		}
	}

	&-heading {
		.Heading-title {
			margin: 0;

			@include viewport(small) {
				font-size: 28px;
				letter-spacing: 6px;
				line-height: 42px;

				@include wls {
					font-family: 'EB Garamond';
					font-weight: 400;
					font-size: rem(36, 16);
					line-height: em(52.2, 36);
					letter-spacing: em(1, 36);
				}
			}

			@include viewport(medium) {
				font-size: 32px;
				letter-spacing: 5px;
				line-height: 42px;

				@include wls {
					font-family: 'EB Garamond';
					font-weight: 400;
					font-size: rem(40, 16);
					line-height: em(52.2, 40);
					letter-spacing: em(1, 36);
				}
			}
		}
	}

	&-cta {
		display: inline-block;
		margin-top: 21px;
		margin-left: 14px;

		&::before {
			font-size: 14px;
			top: 0;
			left: -22px;
		}

		@include rtl {
			margin-right: 14px;

			&::before {
				right: -22px;
			}
		}

		.StandardHero--light & {
			border-color: $separatorLight;
		}

		.StandardHero--dark & {
			border-color: $separatorDark;
		}

		&.text-only {
			border-bottom: none;
			cursor: text;

			&:hover,
			&:focus {
				color: #fff;
			}
		}
	}

	&-hero {
		.BackgroundImage {
			height: calc(100vw * (3 / 4));
			@include viewport(medium) {
				height: calc(100vw * (9 / 16));
			}

			@include viewport(large) {
				height: calc(90vh - #{$st-hero-offset});
			}
		}
	}

	&-hero {
		.Hero-video.Hero-video-mobile {
			width: 100vw;
			height: calc(100vw * (3 / 4));

			&::after {
				height: 25%;
				bottom: 0%;
			}

			.Video-playButton {
				bottom: 0%;
				right: 1px;
			}
		}
		.Hero-video.Hero-video-tablet {
			.Video-playButton {
				bottom: 0%;
				right: 1px;
			}
		}
	}
	&-hero {
		.BackgroundImage,
		.Hero-video {
			height: calc((100vw * (3 / 4)));

			@include viewport(medium) {
				height: calc((100vw * (9 / 16)));
			}

			@include viewport(large) {
				height: calc(90vh - #{$st-hero-offset});
			}

			&::after {
				@include gradient2transparent($height: 25%);

				@include viewport(large) {
					@include gradient2transparent($height: 38%);
				}
			}
			.video-mobile {
				z-index: 1;
			}
		}
	}

	&-anchors {
		margin-top: 21px;
	}

	//Themes
	&--light {
		background-color: $white;
		color: $black;
	}

	&--dark {
		background-color: $black;
		color: $white;
	}

	//LLL Themes
	@mixin lllStandardHero($backgroundColor, $textColor) {
		background-color: $backgroundColor;
		color: $textColor;
		padding-top: 20px;
		@include viewport(medium) {
			padding-top: 40px;
		}
		@include viewport(large) {
			padding-top: 50px;
		}

		.Heading-title {
			font-family: Saol Display;
			font-weight: 300;
			letter-spacing: 0px;
			font-size: 40px;
			line-height: 35px;

			@include viewport(medium) {
				font-size: 60px;
				line-height: 55px;
			}
			@include viewport(large) {
				font-size: 80px;
				line-height: 65px;
			}
		}

		.StandardHero-breadcrumb {
			@include typography(c1);
			margin-bottom: 10px;
			@include viewport(large) {
				margin-bottom: 20px;
			}
		}

		.StandardHero-title {
			padding-bottom: 15px;
			@include viewport(medium) {
				padding-bottom: 30px;
			}
			@include viewport(large) {
				padding-bottom: 40px;
			}

			i {
				margin-right: 3px;
				@include viewport(medium) {
					margin-right: 5px;
				}
				@include rtl {
					margin-right: 0;
					margin-left: 3px;
					@include viewport(medium) {
						margin-left: 5px;
					}
				}
			}
		}

		.StandardHero-hero {
			.BackgroundImage::after {
				background: none;
			}
			.Hero-video::after {
				background: none;
			}
			.Button--pauseIcon--dark::before,
			.Button--playIcon--dark::before {
				background-color: change-color($black, $alpha: 0.5);
				border-radius: 20px;
			}
		}
	}

	&--greenPink {
		@include lllStandardHero($lll-green, $lll-pink);
	}
	&--greenGreen {
		@include lllStandardHero($lll-lightgreen, $lll-green);
	}
	&--blueGreen {
		@include lllStandardHero($lll-blue, $lll-lightgreen);
	}
	&--pinkRed {
		@include lllStandardHero($lll-pink, $lll-red);
	}
	&--lightBlueGreen {
		@include lllStandardHero($lll-lightblue, $lll-green);
	}
	&--yellowOrange {
		@include lllStandardHero($lll-yellow, $lll-orange);
	}
	&--black {
		@include lllStandardHero($white, $black);
	}
	&--white {
		@include lllStandardHero($black, $white);
	}
}
