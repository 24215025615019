.ConversationCard {
	display: flex;
	flex-direction: column;
	background-color: $white;
	color: $black;
	width: 345px;
	padding: 30px 25px 10px;
	margin: auto;

	@include viewport(medium) {
		width: 435px;
		padding: 30px 40px 10px;
	}

	@include viewport(large) {
		width: 550px;
		padding: 50px 60px 30px;
	}
	.Heading {
		.Hero .BackgroundImage & {
			text-align: left;

			@include rtl {
				text-align: right;
			}
		}
		.Heading-eyebrow {
			color: $black;
			margin-bottom: 12px;
			display: inline-block;

			@include typography(c3);
		}
		.Heading-title {
			display: none;
		}
		.Heading-text {
			color: $black;

			@include sansThin;
			font-size: rem(22, 16);
			letter-spacing: em(0.85, 22);
			line-height: em(30, 22);

			@include i18nFonts;
			margin-bottom: 25px;
			margin-top: 0;
		}
	}

	&-iconLinks {
		display: flex;
		flex-wrap: wrap;
		column-gap: 15px;
		.QuickLinks-link--column {
			padding: 0 0 20px;
			width: auto;
			.QuickLinks-link--container {
				margin: 0;
				width: 90px;
				height: 85px;
				padding: 11px 5px;

				@include viewport(large) {
					width: 130px;
					height: 120px;
					padding: 34px 10px;
				}
				.QuickLinks-link--icon {
					font-size: rem(17, 16);

					@include viewport(large) {
						font-size: rem(23, 16);
					}
				}
			}
		}
	}
	&-buttonLinks,
	&-textLinks {
		display: flex;
		flex-wrap: wrap;
		column-gap: 10px;

		@include viewport(medium) {
			column-gap: 15px;
		}
		.ConversationCard-link {
			padding: 0 0 20px;
		}
	}
	&-buttonLinks .CTA {
		padding: 13.5px 12px;
		letter-spacing: em(2.2, 10);

		@include viewport(medium) {
			padding: 13.5px 20px;
			letter-spacing: em(3, 10);
		}
	}

	&-highlighted {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 15px 0 10px;
		margin: 0 0 25px;
		border-top: 1px solid $gray3;
		border-bottom: 1px solid $gray3;

		p {
			margin: 0;
			font-weight: 600;
		}

		& .CTA {
			flex-basis: 0;
			text-align: center;

			@include viewport(large) {
				flex-basis: unset;
			}
		}
	}
	&-heroWrapper {
		z-index: 2;
		padding-right: 20px;
		margin-bottom: 50px;
		align-self: flex-end;

		@include viewport(medium) {
			margin-left: 30px;
		}

		@include viewport(large) {
			padding-right: 30px;
			margin-bottom: 70px;
		}
	}
}
