.IconTabContainer {
	.container {
		@include viewport(xlarge) {
			max-width: 930px;
		}
	}

	color: $black;
	background-color: $gray4;
	position: relative;
	padding-bottom: 100px;
	overflow: hidden;

	&-container {
		max-width: 760px;
		margin: auto 15px;

		@include viewport(medium) {
			margin: auto;
		}

		@include viewport(large) {
			max-width: 1060px;
		}
	}

	&-centerBox {
		background-color: $gray1;
		width: 100px;
		height: calc(100% + 30px);
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		@include viewport(large) {
			width: 130px;
			height: calc(100% + 34px);
		}

		&::after {
			content: '';
			height: 20px;
			position: absolute;
			bottom: -20px;
			left: 50px;
			border-left: 1px solid $gray1;

			@include viewport(medium) {
				height: 30px;
				bottom: -30px;
			}

			@include viewport(large) {
				height: 50px;
				bottom: -50px;
				left: 65px;
			}
		}
	}

	&-tabs {
		position: relative;
		min-height: 120px;
		display: flex;
		justify-content: center;

		margin: 0;

		@include viewport(medium) {
			margin: auto;
		}

		@include viewport(large) {
			min-height: 184px;
		}

		.container {
			@include viewport(medium) {
				max-width: 760px;
			}

			@include viewport(large) {
				max-width: 930px;
			}
		}
	}

	&-tab {
		&.is-selected {
			.IconTabContainer-list-item-text {
				font-weight: 700;
			}
		}
	}

	&-pagination {
		&-button {
			display: none;

			@include viewport(large) {
				display: block;
				font-size: 12px;
				top: 0;
				padding-top: 45px;
				width: 65px;
				height: 100%;
				z-index: 3;

				@include rtl {
					font-size: 12px;
					padding-top: 45px;
					width: 65px;
					height: 100%;
				}
			}
		}

		&--prev {
			left: 15px;
			padding-left: 9px;

			@include rtl {
				left: auto;
				right: 15px;
				padding-right: 9px;
				padding-left: 0;
			}
		}

		&--next {
			right: 15px;
			padding-right: 9px;

			@include rtl {
				right: auto;
				left: 15px;
				padding-left: 9px;
				padding-right: 0;
			}
		}
	}

	&-listContainer {
		width: 100%;
		margin: 0;
		transition: transform 1s ease;
	}

	&-list {
		overflow: visible;
		z-index: 2;
		&-item {
			position: absolute;
			opacity: 0;
			display: flex;
			flex-direction: column;
			width: 95px;
			padding: 35px 2.5px 7.5px;
			text-align: center;
			align-items: center;
			transition: color 0.3s linear;
			cursor: pointer;
			&-icon {
				font-size: 20px;
				line-height: 20px;
				margin-bottom: 15px;
			}
			&-text {
				font-size: rem(8, 16);
				line-height: em(11, 8);
				letter-spacing: em(2.5, 8);

				@include viewport(large) {
					@include typography(c4);
				}
			}

			&.is-selected {
				color: $white;
			}

			@include viewport(medium) {
				width: 105px;
				padding: 35px 7.5px 10px;
			}

			@include viewport(large) {
				width: 135px;
				padding: 90px 10px 20px;
			}
		}

		@include viewport(medium) {
			overflow: hidden;
		}
	}

	&-body {
		background-color: $white;
		padding: 65px 0;
		box-shadow: 0 0 20px 0 #00000026;
		overflow: hidden;

		@include viewport(medium) {
			padding: 75px 0;
		}

		@include viewport(large) {
			padding: 114px 0;
			box-shadow: none;
		}
	}

	&-pages {
		position: relative;
	}

	&-page {
		&:not(.IconTabContainer-page--init) {
			transition: opacity 0.3s ease;
		}
		&--active {
			opacity: 1;
		}

		&--inactive {
			opacity: 0;
			top: 0;
			position: absolute;
			width: calc(100% - 15px);

			@include viewport(medium) {
				width: calc(100% - 30px);
			}
		}
	}

	&--init {
		&.IconTabContainer-listContainer {
			transform: translateX(-20px);

			@include rtl {
				transform: translateX(20px);
			}
		}

		&.IconTabContainer-page {
			opacity: 0;
			&.IconTabContainer-page--active {
				transition: opacity 1s ease;
			}
		}
	}
}
