.SubscriptionBody {
	background: $white;

	.SubscriptionContainer {
		text-align: center;
		margin-top: 20px;
		background: $white;
		padding: 20px 20px 40px;

		@include viewport(medium) {
			margin-top: 60px;
			padding: 20px 40px 40px;
		}
	}

	.SubscriptionChoices-choices {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		max-width: 1015px;
		margin: auto;

		@include viewport(medium) {
			flex-direction: row;
			justify-content: space-around;
			flex-wrap: wrap;
		}
	}

	.Checkbox {
		margin-top: 15px;
		text-align: left;
		line-height: 20px;
	}

	.Checkbox-decoration::before {
		line-height: 1;
	}

	.SubmitButton {
		@include button('primary', 'light');
		border-radius: 0;
		appearance: none;
		margin: 30px;

		&:disabled {
			background: $gray2;
			cursor: default;
			border: 1px solid $gray2;

			&:hover {
				color: $white;
			}
		}
	}

	.SubscriptionThanks {
		display: none;
	}

	.SubscriptionResubscribe {
		color: var(--00-grayscale-black, #000);
		text-align: center;
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 15px; /* 150% */
		letter-spacing: 3px;
	}

	.SubscriptionUnsubscribeSurvey {
		&Container {
			display: flex;
			justify-content: center;
			padding-top: 40px;
			width: 100%;
		}

		width: 100%;
		height: 600px;

		@include viewport(large) {
			width: 770px; //610px + padding
			height: 443px;
		}
	}

	&.UnsubscribeBody .SubscriptionContainer {
		margin-top: 0px;
		padding-top: 0;
		.SubscriptionThanks p,
		.Intro p {
			margin-top: 0;
		}
		@include viewport(medium) {
			margin-top: 10px;
		}

		&.SubscriptionThanksShowing {
			padding: 0 5px 40px;
			@include viewport(medium) {
				padding: 0 40px 40px;
			}
		}
	}

	.QSIUserDefinedHTML {
		z-index: 1;
		width: 100% !important;
		height: 100% !important;
		position: unset !important;

		div {
			width: 100% !important;
			height: 100% !important;
			position: unset !important;

			div {
				width: 100% !important;
				height: 100% !important;
				position: unset !important;

				div {
					width: 100% !important;
					height: 100% !important;
					position: unset !important;
				}
			}
		}
	}

	.LegalAgreement {
		font-size: 1.375rem;
	}

	.Subscription {
		&-error {
			color: $white;
			background-color: $error-red;
			padding-bottom: 30px;
			padding-top: 30px;
			padding-left: 14px;

			@include rtl {
				padding-left: 0;
				padding-right: 14px;

				@include viewport(medium) {
					padding-right: 48px;
				}
			}

			@include viewport(medium) {
				padding-left: 48px;
			}

			&--hidden {
				display: none;
			}
		}
	}
}
