.NonBleedHero {
	.Image {
		display: block;
		margin-top: 50px;

		@include viewport(medium) {
			margin-top: 95px;
		}

		@include viewport(large) {
			margin-top: 80px;
		}

		@include viewport(xlarge) {
			margin-top: 78px;
		}

		&-img {
			width: 100%;
		}
	}
}
