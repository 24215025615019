.Spotlight {
	position: relative;
	overflow: hidden;

	&-container {
		@include viewport(medium) {
			position: relative;
		}

		&-dark .CtaBlock {
			color: $white;
		}

		&-light .CtaBlock {
			color: $black;

			@include viewport(medium) {
				color: $white;
			}
		}
	}

	&-gradient {
		::after {
			@include gradient2transparent($height: 28%);
		}
	}

	&-backgroundImage {
		display: block;
		width: 100%;
		height: calc(100vw * (9 / 16));
		position: relative;
		margin-bottom: 40px;

		@include viewport(medium) {
			margin-bottom: 0;
		}

		@include viewport(large) {
			height: 90vh;
			max-height: 1000px;
			min-height: 600px;
		}

		@include viewport(xlarge) {
			max-height: 1200px;
		}

		&::after {
			@include viewport(medium) {
				height: 38%;
			}

			@include viewport(large) {
				height: 28%;
			}

			@include viewport(xlarge) {
				height: 16%;
			}

			content: '';
			bottom: 0;
			position: absolute;
			z-index: 1;
		}
	}

	&-ctaBlock {
		@include viewport(small) {
			padding-left: get-gutter(small);
			padding-right: get-gutter(small);
		}

		@include viewport(medium) {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 0 30px 30px;
			z-index: 2;
		}

		@include viewport(large) {
			padding: 0 30px 30px;
			z-index: 1;
		}

		@include viewport(xlarge) {
			padding: 0 40px 40px;
		}

		.CtaBlock {
			&-title {
				@include typography(h3);
				display: block;
				margin-bottom: 10px;
			}

			&-text {
				@include typography(b2);

				@include viewport(medium) {
					display: block;
					margin: 0;
				}

				margin: 10px 0;
			}

			&-cta {
				.Spotlight-container-dark & {
					@include grayHover('dark');
					border-color: $white;
					color: $white;

					@include viewport(medium) {
						display: inline-block;
					}
				}

				.Spotlight-container-light & {
					@include viewport(medium) {
						@include grayHover('dark');
						display: inline-block;
						border-color: $white;
						color: $white;
					}
				}

				margin-top: 15px;
			}
		}
	}

	&-video {
		display: none;
		position: absolute;
		top: 0;
		width: 100%;
		height: 90vh;

		@include viewport(large) {
			display: block;
			max-height: 1000px;
			min-height: 600px;
		}

		@include viewport(xlarge) {
			max-height: 1200px;
		}

		.Video {
			&-video {
				position: absolute;
				width: auto;
				height: auto;
				top: 50%;
				left: 50%;
				min-height: 100%;
				min-width: 100%;
				transform: translate(-50%, -50%);
			}

			&-playButton {
				position: absolute;
				bottom: 16px;
				right: 16px;
				z-index: 1;

				@include rtl {
					left: 16px;
					right: auto;
				}
			}
		}
	}
}
