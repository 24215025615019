.WeddingsHero {
	.Hero-headingContainer {
		bottom: 40px;

		&.WeddingsHero--hasGallery {
			bottom: 70px;

			@include viewport(medium) {
				bottom: 100px;
			}

			@include viewport(large) {
				bottom: 140px;
			}
		}

		@include viewport(medium) {
			bottom: 100px;
		}

		@include viewport(large) {
			bottom: 140px;
		}
	}

	.BackgroundImage::after {
		height: 55%;

		@include viewport(large) {
			height: 45%;
		}
	}
}
