.FolioRequestForm {
	.ContactForm {
		padding-top: 0;
		padding-bottom: 0;

		@include viewport(medium) {
			padding-top: 20px;
		}
	}

	&-Heading {
		margin-bottom: 30px;
		&--title {
			text-align: center;
			color: #000;
			margin: 0 0 30px;
		}
		border-bottom: 1px solid $gray3;
	}

	&-Section {
		margin-bottom: 23px;

		&--disclaimer {
			padding-top: 7px;
			margin: 0;
			text-align: center;
		}
	}

	&-ButtonCTA {
		width: 100%;
		max-width: 379px;
		float: none;
		margin-bottom: 20px;

		&--container {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}

	&-TermsAndConditions {
		text-transform: none;
		font-weight: bold;
		padding-bottom: 0;
	}

	&-ThankYou {
		&--body {
			margin: 0 auto 50px;
			max-width: 480px;
		}
	}
}
