.LanguageToggle {
	@include grayHover('dark', 'after');

	position: relative;
	display: inline-block;
	z-index: 1;

	&::after {
		content: '';
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid;
		position: absolute;
		top: 6px;
		right: 4px;
		z-index: -1;

		@include wls {
			@include viewport(large) {
				top: 12px;
			}
		}
	}

	@include rtl {
		&::after {
			right: auto;
			left: 0;
		}
	}

	&-select {
		background: transparent;
		border: none;
		color: inherit;
		appearance: none;
		border-radius: 0;
		padding: 0 18px 0px 0;
		cursor: pointer;

		@include rtl {
			padding-right: 0;
			padding-left: 18px;
			text-align-last: left;
		}

		option {
			color: $black;
		}

		&.ty-c1 {
			@include wls {
				font-family: 'Work Sans';
				font-size: 12px;
				line-height: 14px;
				letter-spacing: 1px;
				font-weight: 400;
			}
		}
	}
}
