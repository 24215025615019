// variables
$error-red: #ea0a0a;

//mixins
@mixin placeholder-default {
	opacity: 1;
	color: $gray1;
	transition: 1s ease;

	.textInput--error & {
		color: $error-red;
	}
}
@mixin placeholder-hover {
	color: $gray1;
}
@mixin placeholder-focus {
	opacity: 0;
	transition: 300ms ease;
}
@mixin placeholder-disabled {
	color: $gray2;
}
@mixin placeholder-takeover {
	color: transparent;
}
@mixin labelFocus() {
	opacity: 1;
	transform: translateY(-22px);
	letter-spacing: 2.05px;
}

// textInput
.textInput {
	position: relative;

	.icon-close {
		fill: $black;
	}

	&--error {
		color: $error-red;

		.icon-close {
			fill: $error-red;
		}
	}

	&--toggled {
		padding: 0;
	}

	&--destination {
		padding-bottom: 0;
	}
}

// field

.textInput__field {
	width: 100%;
	border: none;
	border-bottom: 1px solid $black;
	padding: 0.5em 0;
	color: $gray1;
	background: #fff;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&--readOnly {
		border-bottom: 0;
		padding-bottom: 0;
	}

	&:invalid {
		box-shadow: none;
	}

	// field when in focus and valid - filled in
	&:valid {
		padding-left: 0;
		padding-right: 30px;
		@include rtl {
			padding-left: 30px;
			padding-right: 15px;
		}
	}

	// field disabled
	&--disabled {
		cursor: not-allowed;
	}

	.textInput--error & {
		color: $error-red;
		border-color: $error-red;
	}
}

// label
.textInput__label {
	position: absolute;
	left: 0;
	opacity: 0;
	transition: 0.5s ease;
	@include ie {
		transition: none;
	}
	@include rtl {
		left: auto;
		right: 0;
	}

	.textInput--error & {
		color: $error-red;
	}
}

// label when in focus
.textInput__field:focus + .textInput__label:not(.textInput__label--hidden) {
	@include labelFocus();
}

// label when valid (filled in)
.textInput__field:valid + .textInput__label:not(.textInput__label--hidden) {
	@include labelFocus();
}

.textInput__field--readOnly + .textInput__label:not(.textInput__label--hidden) {
	@include labelFocus();
}

.textInput__field--labelAbove + .textInput__label:not(.textInput__label--hidden) {
	@include labelFocus();
}

// placeholder
::-webkit-input-placeholder {
	@include placeholder-default;
}

:-moz-placeholder {
	@include placeholder-default;
}

::-moz-placeholder {
	@include placeholder-default;
}

:-ms-input-placeholder {
	@include placeholder-default;
}

// placeholder hover
.textInput__field:hover::-webkit-input-placeholder {
	@include placeholder-hover();
}

.textInput__field:hover:-moz-placeholder {
	@include placeholder-hover();
}

.textInput__field:hover::-moz-placeholder {
	@include placeholder-hover();
}

.textInput__field:hover:-ms-input-placeholder {
	@include placeholder-hover();
}

// placeholder - become hidden when in focus
.textInput__field:focus::-webkit-input-placeholder {
	@include placeholder-focus();
}

.textInput__field:focus:-moz-placeholder {
	@include placeholder-focus();
}

.textInput__field:focus::-moz-placeholder {
	@include placeholder-focus();
}

.textInput__field:focus:-ms-input-placeholder {
	@include placeholder-focus();
}

// placeholder disabled
.textInput__field--disabled::-webkit-input-placeholder,
.textInput__field--disabled:hover::-webkit-input-placeholder {
	@include placeholder-disabled();
}

.textInput__field--disabled:-moz-placeholder,
.textInput__field--disabled:hover:-moz-placeholder {
	@include placeholder-disabled();
}

.textInput__field--disabled::-moz-placeholder,
.textInput__field--disabled:hover::-moz-placeholder {
	@include placeholder-disabled();
}

.textInput__field--disabled:-ms-input-placeholder,
.textInput__field--disabled:hover:-ms-input-placeholder {
	@include placeholder-disabled();
}

.textInput_clear {
	position: absolute;
	bottom: 17px;
	right: 0;
	width: 15px;
	height: 15px;
	@include placeholder-focus();
	color: $gray1;

	&:focus {
		opacity: 1;
	}

	@include rtl {
		right: auto;
		left: 13px;
	}
}

.textInput__field:focus ~ .textInput_clear,
.textInput__field:focus:valid ~ .textInput_clear,
.textInput__field:valid ~ .textInput_clear {
	opacity: 1;
}

[class$='__takeover'] {
	.textInput__field + .textInput__label:not(.textInput__label--hidden) {
		@include labelFocus();
	}
	// placeholder hover
	.textInput__field::-webkit-input-placeholder {
		@include placeholder-takeover();
	}

	.textInput__field:-moz-placeholder {
		@include placeholder-takeover();
	}

	.textInput__field::-moz-placeholder {
		@include placeholder-takeover();
	}

	.textInput__field:-ms-input-placeholder {
		@include placeholder-takeover();
	}
}
