.SocialGallery {
	display: none;

	&--visible {
		display: block;
		overflow: hidden;

		@include viewport(medium) {
			overflow: visible;
		}
	}

	&-wrapper {
		&--hidden {
			display: none;
		}
	}

	&-loader {
		&--hidden {
			display: none;
		}
	}

	&-loaderImage {
		display: block;
		margin: auto;
	}

	&-heading {
		margin-bottom: 54px;
		text-align: center;
	}

	&-title {
		text-transform: uppercase;
	}

	&-list {
		&--flickity {
			&::after {
				content: 'flickity';
				display: none;
			}
		}

		@include viewport(medium) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0 auto;
			width: 690px;
		}

		@include viewport(large) {
			width: 930px;
		}
	}

	&-listItem {
		width: 290px;
		margin: 0 15px;

		@include viewport(medium) {
			width: 210px;
			margin: 0 0 30px;
		}

		@include viewport(large) {
			width: 290px;
		}
	}

	&-button {
		background: transparent;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		border: none;
		height: 290px;
		margin: 0 0 26px;
		overflow: hidden;
		padding: 0;
		position: relative;
		width: 290px;
		display: block;

		&::-moz-focus-inner {
			border: 0;
			padding: 0;
		}

		@include viewport(medium) {
			width: 210px;
			height: 210px;
			margin-bottom: 11px;
		}

		@include viewport(large) {
			width: 290px;
			height: 290px;
			margin-bottom: 16px;
		}
	}

	&-attribution {
		text-align: left;
		font-weight: normal;

		@include rtl {
			text-align: right;
		}
	}

	.Gallery {
		text-align: center;

		&-button {
			margin: 60px 0 5px;

			@include viewport(medium) {
				margin-top: 50px;
			}

			@include viewport(large) {
				margin-top: 60px;
			}
		}
	}
}
