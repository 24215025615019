.PRFilteredListing-Card {
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	> a {
		color: #000;
		text-decoration: none;
	}

	@include viewport(medium) {
		margin-bottom: 5px;
	}

	&-container {
		display: block;
	}

	&-textContainer {
		margin: 15px 0 30px;

		@include viewport(medium) {
			margin: 20px 0 35px;
		}
	}
}
