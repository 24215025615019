.caw-comp__wrapper,
.caw-popup__wrapper {
	.DateField {
		&_row {
			border-bottom: 1px solid #ffffff;
		}
		&--takeover {
			height: auto;
			&::before {
				height: auto;
			}
		}
		&_wrap {
			transition: none;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-right: 15px;
			overflow: hidden;
			letter-spacing: 0;
			line-height: 1.8em;

			@include rtl {
				padding-left: 15px;
				padding-right: 5px;
			}
		}

		&_label {
			&--active {
				margin: 0;
				transform: translateY(-22px);
			}
			letter-spacing: 2.05px;
			transition: none;
		}

		&_placeholder {
			transition: none;
			padding: 0 16px 0 0;
			margin-top: 2px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			@include rtl {
				padding: 0 0 0 16px;
			}
		}

		&_container {
			width: 100%;
			background-color: $gray6;
			height: 40px;
			padding: 5px 12px 20px 10px;
			span {
				padding: 0;
				font-size: inherit;
			}
		}

		&_clear {
			top: -22px;
			float: right;
			width: 14px;
			height: 14px;
			padding-right: 2px;

			svg {
				display: block;
			}
		}
	}

	.active .DateField {
		&_row {
			border-bottom: 1px solid #f5f5f5;
		}
	}
}

.caw-popup__wrapper {
	.DateField {
		&_wrap {
			padding-right: 23px;
			cursor: text;
		}
	}
}

.caw__wrapper {
	.DateField {
		&_clear {
			position: absolute;
			top: auto;
			right: 15px;
			margin-top: -22px;
			bottom: auto;
		}
		&.active .DateField_row {
			height: 45px;
		}
	}

	.DateField_wrap {
		padding-top: 8px;
	}
}

.DateField {
	&_row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border-bottom: 1px solid $black;

		.DateField--takeover & {
			position: relative;
			@include media($navigationBreakpoint) {
				position: static;
			}
		}

		.DateField--error & {
			border-bottom: 1px solid $error-red;
		}
	}

	&_wrap {
		position: absolute;
		opacity: 0;
		transition: none;
		pointer-events: none;
		@include ie {
			transition: none;
		}

		&--active {
			display: block;
			position: relative;
			visibility: visible;
			opacity: 1;
			transition: 0.5s ease;
			pointer-events: auto;
		}
	}

	&_label {
		position: absolute;
		opacity: 0;
		transition: 0.5s ease 0.2s;
		height: 44px;
		cursor: pointer;
		width: 100%;
		@include media($navigationBreakpoint) {
			width: auto;
		}

		.DateField--error & {
			color: $error-red;
			border-color: $error-red;
		}

		&--active {
			opacity: 1;
			height: auto;
			margin: 1em 0;
			transform: translateY(-30px);
			transition: 0.5s ease;
			width: auto;
			@include ie {
				transition: none;
			}
		}
		@include ie {
			transition: none;
		}
	}

	&_placeholder {
		opacity: 1;
		transition: 0.5s ease;
		margin: 0;
		padding: 0.5em 0;
		color: $gray1;

		.DateField--error & {
			color: $error-red;
		}

		&--active {
			opacity: 0;
			position: absolute;
		}
		@include ie {
			transition: none;
		}
	}

	&_input {
		border: none;
		width: auto;
		padding: 0.5em 0;
		color: $gray2;

		.DateField--error & {
			color: $error-red;
		}

		&--active {
			color: $gray1;
		}
	}

	&_hiddenlabel {
		visibility: hidden;
		position: absolute;
		width: 0;
		height: 0;
	}

	&_separator {
		color: $gray2;
		display: inline-block;
		margin: 0 6px;
		font-size: 0.7em;

		.DateField--error & {
			color: $error-red;
		}

		&--active {
			color: $gray1;
		}
	}

	&_clear {
		position: relative;
		bottom: -15px;
		width: 15px;
		height: 15px;
		opacity: 0;
		transition: 0.3s ease;
		color: $gray1;

		.DateField--error & {
			fill: $error-red;
		}

		&--active {
			opacity: 1;
		}
		@include rtl {
			float: left;
			padding-right: 0;
			padding-left: 2px;
		}
	}

	&::before {
		content: '';
		opacity: 0;
		display: block;
		position: fixed;
		width: 0;
		height: 0;
		top: 0;
		right: 0;
		background: $white;
		transition: opacity 200ms ease-in-out;
		@include media($navigationBreakpoint) {
			display: none;
			transition: none;
		}
	}

	&--takeover {
		position: fixed;
		z-index: 500;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 57px 40px 40px;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			opacity: 1;
		}
		@include media($navigationBreakpoint) {
			position: static;
			width: auto;
			height: auto;
			background: transparent;
			padding: 40px 0 0;
			margin-bottom: 40px;
		}
	}
}
