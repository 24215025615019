.GlobalFeaturedProperties {
	color: $white;
	background-color: $black;
	&-Heading {
		display: none;
		text-align: center;
		padding-top: 70px;
		margin-bottom: 12px;
		overflow: hidden;
		width: 100%;
		min-height: 145px;

		@include viewport(medium) {
			display: block;
		}

		&--mobile {
			text-align: center;
			padding-top: 40px;
			width: 100%;
			min-height: 145px;

			@include viewport(medium) {
				display: none;
			}
		}
	}

	.CenterFocusCarousel-heading {
		height: 0;
		margin: 0;
	}
	.AnimatedCardCarouselBackground-BackgroundImage::after {
		background: linear-gradient(180deg, $black 0%, change-color($black, $alpha: 0.55) 100%);
	}

	&-Eyebrow {
		margin-top: 0;
		margin-bottom: 25px;

		@include viewport(medium) {
			border: none;
			margin-bottom: 15px;
		}
	}

	&-AnimatedTitle {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		transition: transform 1s ease;
		margin-bottom: 5px;

		&--bullet {
			border: 2px solid;
			border-radius: 10px;
			margin: auto 30px;
		}
		&--title {
			transition: transform 1s ease, width 1s ease;
			white-space: nowrap;
		}

		&--underline {
			margin: auto;
			border: 1px solid $white;
			transition: width 1s ease;
		}

		&-button--active {
			transform: scale(1.3333333333);
		}

		&--mobile {
			border-top: 1px solid #5c5c5c;
			border-bottom: 1px solid #5c5c5c;
			overflow: hidden;
			width: 100vw;
			height: 50px;
			display: flex;
			justify-content: center;
			position: relative;
			&--drag {
				display: flex;
				justify-content: center;
				flex-wrap: nowrap;
				margin: auto 0;
			}

			&--animated {
				transition: transform 1s ease;
			}

			.GlobalFeaturedProperties-AnimatedTitle--title--mobile {
				width: auto;
				display: inline-block;
				white-space: nowrap;
				transition: transform 1s ease, font-weight 1s ease, margin 1s ease, width 1s ease;
				margin: auto 0;
				height: inherit;

				&--active {
					font-weight: 700;
					transform: scale(1.15);
				}

				&--hotels {
					margin-right: 15px;

					@include rtl {
						margin-right: 0;
						margin-left: 15px;
					}
				}

				&--residences {
					margin: auto 15px;
				}

				&--private {
					margin-left: 15px;

					@include rtl {
						margin-right: 15px;
						margin-left: 0;
					}
				}
			}

			&--underline {
				width: 100px;
				margin: 0 auto;
				border: 1px solid $white;
				transition: width 1s ease, transform 1s ease;
				position: absolute;
				bottom: 0;
			}
		}
	}

	&-Body {
		position: relative;
		width: 100%;

		@include viewport(small) {
			min-height: 700px;
		}

		@include viewport(medium) {
			min-height: 920px;
		}

		&-section {
			position: absolute;
			width: 100%;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.5s linear;
			margin-top: 0;
			&--active {
				opacity: 1;
				pointer-events: inherit;
				z-index: 2;
			}

			@include viewport(medium) {
				position: relative;
			}
		}

		&-item {
			@include viewport(medium) {
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;

				@include rtl {
					left: auto;
					right: 0;
				}
			}
		}

		&-CTABlock {
			z-index: 2;
			padding-bottom: 30px;
			text-align: left;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;

			@include rtl {
				text-align: right;
			}

			@include viewport(medium) {
				align-items: center;
				text-align: center;

				@include rtl {
					text-align: center;
				}
			}

			.CtaBlock {
				&-text {
					@include typography(b3);
					margin-top: 30px;
					margin-bottom: 10px;

					@include viewport(medium) {
						@include typography(b2);
						margin: 18px auto;
					}
				}

				&-cta {
					display: flex;

					@include typography(c3);
				}
			}
		}

		&-Carousel {
			@include viewport(medium) {
				width: 100%;
			}
		}

		.AnimatedCardCarouselBackground .CenterFocusCarousel {
			padding-top: 0;

			@include viewport(medium) {
				padding: 100px 0;
			}
		}
	}
}
