.Hero {
	width: 100%;

	.VideoPlaceholderImage {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	> .ThirdPartyVideo {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.IndicatorColor {
		position: relative;
		border-radius: 50%;
		width: 8px;
		height: 8px;
		margin: 0 5px;
		display: inline-flex;

		&--red {
			background-color: #ff1e1e;
		}

		&--green {
			background-color: #2ad062;
		}

		&--orange {
			background-color: #df943c;
		}
	}

	.IndicatorBackground {
		position: absolute;
		z-index: 1;
		height: 36px;
		width: 100%;
		background: rgba(0, 0, 0, 0.6);
		backdrop-filter: blur(10px);
		align-items: center;
		align-content: center;
		justify-content: center;
	}

	.IndicatorMessage {
		display: inline-flex;
	}
}
