.caw-comp__wrapper,
.caw-popup__wrapper {
	.OccupancyField {
		&_row {
			.Occupancy-wrapper {
				z-index: 10;
				transform: none;
				background: #f0f0f0;
				border: 1px solid $gray3;
				min-width: 525px;
				padding: 30px;

				&:lang(fr) {
					min-width: 535px;
				}

				&:lang(pt),
				&:lang(br) {
					min-width: 545px;
				}

				&:lang(tr) {
					min-width: 560px;
				}

				&:lang(de) {
					min-width: 580px;
				}

				.guests-dropdown-option {
					background: #ffffff;
					margin-bottom: 10px;
					height: 80px;

					.RoomOption-title {
						text-transform: uppercase;
						font-weight: bold;
						padding: 15px 15px 10px;
					}

					.remove-button {
						border: none;
						float: right;
						min-width: 30px;
						height: 30px;
						margin: -32px 0 0;
						padding: 5px;
						background-color: transparent;
						color: $black;

						&:focus,
						&:hover {
							background-color: $black;
							color: $white;
						}

						@include rtl {
							float: left;
						}
					}

					.RoomOption-adult,
					.RoomOption-child {
						padding-left: 15px;
						float: left;
					}

					.RoomOption-adult {
						width: 45%;

						&:lang(de),
						&:lang(ru) {
							width: 52%;
						}

						&:lang(fr),
						&:lang(it),
						&:lang(kr),
						&:lang(el),
						&:lang(es),
						&:lang(es-es) {
							width: 50%;
						}
					}

					.RoomOption-child {
						width: 55%;

						&:lang(de),
						&:lang(ru) {
							width: 48%;
						}

						&:lang(fr),
						&:lang(it),
						&:lang(kr),
						&:lang(el),
						&:lang(es),
						&:lang(es-es) {
							width: 50%;
						}
					}

					.RoomOption-adultstr,
					.RoomOption-childstr {
						float: left;
						padding-top: 10px;

						@include rtl {
							float: right;
							padding: 10px;
						}
					}

					.increment-button,
					.decrement-button {
						min-width: 30px;
						height: 30px;
						margin-left: 5px;
						padding: 5px;
						background-color: transparent;
						border-color: $black;
						color: $black;

						&.button-disabled {
							pointer-events: none;
							opacity: 0.3;
						}

						&:focus,
						&:hover {
							background-color: $black;
							border-color: $white;
							color: $white;
						}
					}

					.remove-button {
						&::after {
							@include icon('icon-close_alt');
							font-weight: 400;
							font-size: 10px;
							pointer-events: none;
							float: right;
							right: -1px;
							margin-top: 3px;
							position: relative;

							@include rtl {
								float: left;
								left: 12px;
								right: 0;
							}
						}
					}

					.increment-button {
						&::after {
							@include icon('icon-plus_sign');
							font-weight: 400;
							font-size: 12px;
							pointer-events: none;
							float: right;
							right: -1px;
							margin-top: 3px;
							position: relative;

							@include rtl {
								float: left;
								left: 12px;
								right: 0;
							}
						}
					}

					.decrement-button {
						&::after {
							@include icon('icon-less_sign');
							font-weight: 400;
							font-size: 12px;
							pointer-events: none;
							float: right;
							right: -1px;
							margin-top: 3px;
							position: relative;

							@include rtl {
								float: left;
								left: 12px;
								right: 0;
							}
						}
					}
				}

				.Guests-Update {
					transition: opacity 0.8s;
					bottom: 65px;
					width: 100%;
					clear: both;

					&-inner {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: space-between;
						position: relative;
						padding: 10px 0 0 0;

						.guests-update-button {
							min-width: 110px;
							height: 35px;
							margin: 0;

							&:focus,
							&:hover {
								background-color: $black;
								border-color: $white;
								color: $white;
							}
						}

						&::before {
							content: '';
							position: absolute;
							bottom: 25px;
							left: 0;
							height: 75px;
							width: 100%;
							pointer-events: none;
							background: linear-gradient(rgba(255, 255, 255, 0.001), $white);
							@include media($navigationBreakpoint) {
								display: none;
							}
						}
					}

					&-room-info {
						width: 72%;
						background: none;
						margin: 0;
						position: static;
						bottom: auto;
						padding: 0;

						&:lang(de),
						&:lang(fr) {
							width: 65%;
						}

						&:lang(br),
						&:lang(pt),
						&:lang(tr),
						&:lang(el),
						&:lang(es),
						&:lang(es-es) {
							width: 68%;
						}

						.ty-c4 {
							width: 100%;
							text-align: right;
							text-transform: none;
							font-size: 12px;
							color: $gray7;
							letter-spacing: 0.5px;

							@include rtl {
								text-align: left;
							}
						}
					}
				}

				.roomoptions-add-button {
					text-transform: uppercase;
					text-decoration: underline;
					font-weight: bold;
					padding: 10px 0px;
					width: auto;
					letter-spacing: 3px;

					&::before {
						@include icon('icon-plus_sign');
						font-weight: 400;
						font-size: 12px;
						pointer-events: none;
						float: left;
						padding-right: 5px;
					}

					@include rtl {
						&::before {
							float: right;
							padding-left: 5px;
						}
					}
				}

				.roomoptions-contact {
					text-transform: uppercase;
					font-weight: 400;
					padding: 10px 0px;
					width: 100%;
					background-color: transparent;
					color: $black;

					.phone-number {
						text-decoration: underline;
						font-weight: bold;
						float: left;
					}

					&::before {
						@include icon('icon-call_alt_inverted');
						font-weight: 400;
						font-size: 12px;
						pointer-events: none;
						float: left;
						width: 20px;
						margin-top: 10px;
					}

					@include rtl {
						&::before {
							float: right;
							margin-top: 0px;
						}
					}
				}
			}
		}
		&--takeover {
			height: auto;
			&::before {
				height: auto;
			}
		}
		&_wrap {
			transition: none;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-right: 15px;
			overflow: hidden;
			letter-spacing: 0;
			line-height: 1.8em;

			@include rtl {
				padding-left: 15px;
				padding-right: 5px;
			}
		}

		&_label {
			&--active {
				margin: 0;
				transform: translateY(-22px);
			}
			letter-spacing: 2.05px;
			transition: none;
		}

		&_placeholder {
			transition: none;
			padding: 0 16px 0 0;
			margin-top: 2px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			@include rtl {
				padding: 0 0 0 16px;
			}
		}

		&_container {
			width: 100%;
			background-color: $gray6;
			height: 40px;
			padding: 5px 12px 20px 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			span {
				padding: 0;
				font-size: inherit;
			}
		}

		&_clear {
			top: -22px;
			float: right;
			width: 14px;
			height: 14px;
			padding-right: 2px;

			svg {
				display: block;
			}
		}
	}
}

.caw-popup__wrapper {
	.OccupancyField {
		&_wrap {
			padding-right: 23px;
			cursor: text;
		}
	}
}

.caw__wrapper {
	.OccupancyField {
		&_clear {
			position: absolute;
			top: auto;
			right: 15px;
			margin-top: -22px;
			bottom: auto;
		}
		&.active .OccupancyField_row {
			height: 45px;
		}
	}

	.OccupancyField_wrap {
		padding-top: 8px;
	}
}

.OccupancyField {
	&_row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.OccupancyField--takeover & {
			position: relative;
			@include media($navigationBreakpoint) {
				position: static;
			}
		}

		.OccupancyField--error & {
			border-bottom: 1px solid $error-red;
		}
	}

	&_wrap {
		position: absolute;
		opacity: 0;
		transition: none;
		pointer-events: none;
		@include ie {
			transition: none;
		}

		&--active {
			display: block;
			position: relative;
			visibility: visible;
			opacity: 1;
			transition: 0.5s ease;
			pointer-events: auto;
		}
	}

	&_label {
		position: absolute;
		opacity: 0;
		transition: 0.5s ease 0.2s;
		height: 44px;
		cursor: pointer;
		width: 100%;
		@include media($navigationBreakpoint) {
			width: auto;
		}

		.OccupancyField--error & {
			color: $error-red;
			border-color: $error-red;
		}

		&--active {
			opacity: 1;
			height: auto;
			margin: 1em 0;
			transform: translateY(-30px);
			transition: 0.5s ease;
			width: auto;
			@include ie {
				transition: none;
			}
		}
		@include ie {
			transition: none;
		}
	}

	&_placeholder {
		opacity: 1;
		transition: 0.5s ease;
		margin: 0;
		padding: 0.5em 0;
		color: $gray1;

		.OccupancyField--error & {
			color: $error-red;
		}

		&--active {
			opacity: 0;
			position: absolute;
		}
		@include ie {
			transition: none;
		}
	}

	&_input {
		border: none;
		width: auto;
		padding: 0.5em 0;
		color: $gray2;

		.OccupancyField--error & {
			color: $error-red;
		}

		&--active {
			color: $gray1;
		}
	}

	&_hiddenlabel {
		visibility: hidden;
		position: absolute;
		width: 0;
		height: 0;
	}

	&_separator {
		color: $gray2;
		display: inline-block;
		margin: 0 6px;
		font-size: 0.7em;

		.OccupancyField--error & {
			color: $error-red;
		}

		&--active {
			color: $gray1;
		}
	}

	&_clear {
		position: relative;
		bottom: -15px;
		width: 15px;
		height: 15px;
		opacity: 0;
		transition: 0.3s ease;
		color: $gray1;

		.OccupancyField--error & {
			fill: $error-red;
		}

		&--active {
			opacity: 1;
		}
		@include rtl {
			float: left;
			padding-right: 0;
			padding-left: 2px;
		}
	}

	&::before {
		content: '';
		opacity: 0;
		display: block;
		position: fixed;
		width: 0;
		height: 0;
		top: 0;
		right: 0;
		background: $white;
		transition: opacity 200ms ease-in-out;
		@include media($navigationBreakpoint) {
			display: none;
			transition: none;
		}
	}

	&--takeover {
		position: fixed;
		z-index: 500;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 57px 40px 40px;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			opacity: 1;
		}
		@include media($navigationBreakpoint) {
			position: static;
			width: auto;
			height: auto;
			background: transparent;
			padding: 40px 0 0;
			margin-bottom: 40px;
		}
	}
}
