.HeadingComponent {
	margin-top: 40px;
	border-top: 1px solid $separatorLight;
	padding-top: 8px;
	margin-bottom: 40px;

	@include viewport(large) {
		margin-top: 60px;
		padding-top: 28px;
	}

	@include viewport(xlarge) {
		margin-top: 80px;
		padding-top: 48px;
	}

	.BodySection--light & {
		border-color: $separatorDark;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.Heading {
		&-title {
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 32px;
		}

		&-text {
			margin-top: 40px;

			&:lang(ja) {
				font-size: 1rem;
			}
		}
	}
}
