.HoverCardList {
	&.TitleAndCards {
		display: none;

		@include viewport(medium) {
			display: block;
		}
	}

	&-MobileCarousel {
		@include viewport(medium) {
			display: none;
		}
		&--container {
			overflow: hidden;
			padding-left: 15px;
		}
		&--slide {
			width: 61.25%;
			padding-right: 7.5px;
			padding-left: 7.5px;
			.HoverCardList-column {
				padding: 0;
			}
		}
		&--title {
			text-align: center;
			.TitleAndCards-title {
				margin-bottom: 30px;
			}
		}
		&--cta {
			text-align: center;
		}
		.row {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.HoverCardGrid-GenericCard-overlay {
		bottom: 20px;
		left: 20px;
		margin-right: 20px;

		@include rtl {
			left: 0;
			margin-left: 20px;
			right: 20px;
			margin-right: 0;
		}

		@include viewport(medium) {
			bottom: 30px;
			left: 30px;
			margin-right: 30px;

			@include rtl {
				left: 0;
				margin-left: 30px;
				right: 30px;
				margin-right: 0;
			}
		}

		@include viewport(large) {
			bottom: 20px;
			left: 20px;
			margin-right: 20px;

			@include rtl {
				left: 0;
				margin-left: 20px;
				right: 20px;
				margin-right: 0;
			}
		}
	}
	&--2 {
		.TitleAndCards-cardsContainer {
			.HoverCardList-column {
				.HoverCardList-GenericCard {
					&-image {
						&--landscape {
							display: none;

							@include viewport(large) {
								display: block;
								padding-top: 56.25%;
							}
						}
						&--portrait {
							display: block;

							@include viewport(large) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	&--3 {
		.TitleAndCards-cardsContainer {
			.HoverCardList-column {
				&:first-child {
					.HoverCardList-GenericCard {
						&-image {
							&--landscape {
								display: block;
								padding-top: 56.25%;

								@include viewport(large) {
									display: none;
								}
							}
							&--portrait {
								display: none;

								@include viewport(large) {
									display: block;
								}
							}
						}
					}
				}
				.HoverCardList-GenericCard {
					&-image {
						&--landscape {
							display: none;
						}
						&--portrait {
							display: block;
						}
					}
				}
			}
		}
	}
}
