.FilteredColumnsList {
	&-tabBar {
		display: none;

		@include viewport(large) {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 8.5px;
		}

		&-GrayLine {
			display: none;

			@include viewport(large) {
				display: block;
				border-bottom: 1px solid #cacaca;
				width: calc(100% - 30px);
				margin-left: 15px;

				@include rtl {
					margin-left: 0;
					margin-right: 15px;
				}
			}

			@include viewport(xlarge) {
				width: calc(66.66667% - 30px);
				margin-left: calc(16.66667% + 15px);

				@include rtl {
					margin-left: 0;
					margin-right: calc(16.66667% + 15px);
				}
			}
		}
	}

	&-filter {
		margin-right: 30px;
		text-align: left;

		@include rtl {
			margin-right: 0;
			margin-left: 30px;
			text-align: right;
		}

		&.FilteredColumnsList-filter--light {
			@include grayHover($theme: 'light');
		}
		&.FilteredColumnsList-filter--dark {
			@include grayHover($theme: 'dark');
		}

		&--active {
			@include typography(c1);
			&:hover,
			&:active,
			&:focus {
				&.FilteredColumnsList-filter--light {
					color: $black;
				}
				&.FilteredColumnsList-filter--dark {
					color: $white;
				}
			}
		}

		&--viewAll {
			white-space: nowrap;
			position: relative;
			margin-right: 60px;

			&::after {
				content: '';
				height: 16px;
				position: absolute;
				border-left: 1px solid $black;
				right: -30px;
				top: 2.5px;
				pointer-events: none;
			}

			@include rtl {
				margin-right: 0;
				margin-left: 60px;

				&::after {
					right: 0;
					left: -30px;
				}
			}

			&.FilteredColumnsList-filter--dark::after {
				border-left: 1px solid $white;
			}

			&.FilteredColumnsList-filter--active {
				line-height: em(18, 12);
			}
		}
	}

	&-displayButtons {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-left: auto;

		@include rtl {
			margin-right: auto;
			margin-left: 0;
		}

		&-button {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			width: 65px;
			height: 56px;

			@include typography(c4);
			font-size: rem(6, 16);
			letter-spacing: em(2, 10);
			transition: font-weight 0.3s ease-out;

			&--light {
				background-color: $gray4;
				border: 1px solid $gray4;
				.FilteredColumnsList-displayButtons-GrayBox {
					background-color: $gray3;
				}
			}

			&--dark {
				background-color: $gray1;
				border: 1px solid $gray1;
				.FilteredColumnsList-displayButtons-GrayBox {
					background-color: $gray2;
				}
			}

			p {
				width: 100%;
				margin-top: 2px;
				margin-bottom: 10px;
			}

			&--active {
				@include typography(c3);
				font-size: rem(6, 16);
				letter-spacing: em(2, 10);

				&.FilteredColumnsList-displayButtons-button--light {
					border: 1px solid $gray1;

					.FilteredColumnsList-displayButtons-GrayBox {
						background-color: $gray2;
					}
				}
				&.FilteredColumnsList-displayButtons-button--dark {
					border: 1px solid $gray4;

					.FilteredColumnsList-displayButtons-GrayBox {
						background-color: $gray4;
					}
				}
			}

			&:hover,
			&:active,
			&:focus {
				@include typography(c3);
				font-size: rem(6, 16);
				letter-spacing: em(2, 10);
				&.FilteredColumnsList-displayButtons-button--light {
					.FilteredColumnsList-displayButtons-GrayBox {
						background-color: $gray2;
					}
				}
				&.FilteredColumnsList-displayButtons-button--dark {
					.FilteredColumnsList-displayButtons-GrayBox {
						background-color: $gray4;
					}
				}
			}
		}

		&--columns {
			margin-right: 8px;

			@include rtl {
				margin-right: 0;
				margin-left: 8px;
			}

			.FilteredColumnsList-displayButtons-GrayBox {
				width: 11px;
			}
		}

		&--list {
			.FilteredColumnsList-displayButtons-GrayBox {
				width: 24px;
			}
		}

		&-GrayBox {
			height: 15px;
			margin: 15px 1px 1px;
			transition: background-color 0.3s ease-out;
		}
	}

	&-stickyNav {
		display: block;
		position: relative;
		z-index: 3;

		.formElement-label {
			transform: none !important;
		}

		.Dropdown {
			padding: 0;
			margin: 15px 0 5px;
			&-field {
				border: none;
				padding: 6px 0 0;
			}
			&::after {
				top: 5px;
				right: 0;
			}

			@include rtl {
				&::after {
					right: auto;
					left: 0;
				}
			}
		}

		&-GrayLine {
			position: absolute;
			bottom: 0;
			border-bottom: 1px solid #cacaca;
			width: 100vw;
			left: calc((100% - 100vw) / 2);
		}

		&--light {
			background-color: $white;
			.Dropdown-field {
				background-color: $white;
				color: $black;
			}

			option {
				color: $black;
				font-size: 16px;
			}
		}

		&--dark {
			background-color: $black;
			.Dropdown-field {
				background-color: $black;
				color: $white;
			}

			option {
				color: $white;
			}
		}

		@include viewport(large) {
			display: none;
		}
	}

	&-filterSection {
		&-title {
			margin: 0 0 10px;
		}

		&-text {
			margin: 0;
		}

		&-heading {
			margin-top: 50px;
			margin-bottom: 40px;

			@include viewport(medium) {
				margin-bottom: 20px;
			}
		}
	}

	&-itemsContainer {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		align-items: stretch;
	}

	&-item {
		margin-bottom: 20px;

		@include viewport(medium) {
			margin-bottom: 30px;
		}
	}
}
