.eoBanner {
	text-align: center;
	padding-bottom: 10px;

	div,
	h2 {
		color: black;
		margin: 0;
		padding-bottom: 10px;
	}

	#offer-mail-icon {
		&::after {
			@include icon('icon-exclusive_offer');
			color: $black;
			font-size: 21px;
			display: inline-block;
			padding: 3px 0 9px;
		}
	}
}

#FAHROfferBanner {
	display: none;
}
