.QuickLinks-link {
	&--container {
		height: 40.533333333vw;
		padding: 33% 10px;
		margin-bottom: 15px;

		@include viewport(medium) {
			height: 120px;
			width: 130px;
			padding: 34px 10px;
			margin: 0 15px 30px;
			touch-action: none;
		}

		@include viewport(large) {
			margin: 0 0 30px;
			touch-action: auto;
		}
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		border-radius: 5px;
		text-decoration: none;
		text-align: center;

		&--light {
			border: 1px solid $gray3;
			background-color: $white;
			color: $black;

			&:hover,
			&:focus {
				@include viewport(medium) {
					box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2);
				}
			}
		}

		&--dark {
			border: 1px solid change-color($gray3, $alpha: 0.1);
			background-color: $gray1;
			color: $white;

			&:hover,
			&:focus {
				@include viewport(medium) {
					border: 1px solid change-color($gray3, $alpha: 0.2);
					background-color: $gray5;
					box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.3);
				}
			}
		}
	}

	&--icon {
		font-size: rem(23, 16);
		margin-bottom: 5px;
	}

	&--text {
		word-break: break-word;
		font-size: rem(9, 16);
		letter-spacing: em(1.08, 9);
	}
}

.QuickLinks--hero {
	.QuickLinks-link {
		&--container {
			// opacity: 0.6; did this change?
			border: 1px solid change-color($gray3, $alpha: 0.1);
			// background-color: $gray1;
			background-color: change-color($gray1, $alpha: 0.94);
			color: $white;
			margin-left: 7.5px;
			margin-right: 7.5px;

			&:hover,
			&:focus {
				border: 1px solid change-color($gray3, $alpha: 0.2);
				background-color: $gray5;
				box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.3);
			}

			& span {
				transition: opacity 0.3s linear;
			}

			@include viewport(medium) {
				margin-left: 10px;
				margin-right: 10px;
				touch-action: none;
			}

			@include viewport(large) {
				margin-left: 7.5px;
				margin-right: 7.5px;
			}
		}

		&--column {
			padding: 0;
		}

		&-cutoff {
			pointer-events: none;
			& span {
				opacity: 0.5;
			}
		}
	}
}
