//Form Element
//***************************************************
.formElement {
	margin: 15px 0 10px;
	padding-bottom: 17px;

	&-message {
		position: absolute;
		bottom: 0;
		left: 0;

		@include sansThin;
		font-size: rem(12, 16);
		letter-spacing: em(1, 12);
		line-height: em(18, 12);

		@include rtl {
			left: auto;
			right: 0;
		}
	}

	// field
	&-field {
		width: 100%;
		height: 47px;
		border-radius: 0;
		border: none;
		border-bottom: 1px solid currentColor;
		padding: 0.5em 0 0.5em 16px;
		color: $black;
		background: $gray6;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		appearance: none;

		@include rtl {
			padding-left: 0;
			padding-right: 16px;
		}

		&.ty-b2 {
			line-height: em(18, 18);
		}

		&:required,
		&:invalid {
			// Remove default red border for required and invalid fields
			// in FF, Edge and IE11
			box-shadow: none;

			&:not(:focus) {
				outline: none;
			}
		}

		&--invalid {
			&,
			& ~ .formElement-message,
			& ~ .formElement-label,
			& ~ .Dropdown-labelContainer .mask,
			& ~ .chosen-container-multi.chosen-container .chosen-choices {
				color: $error-red;
				border-color: $error-red;
			}

			//Has to be separate to keep it from breaking on IE
			&::placeholder {
				color: $error-red;
				border-color: $error-red;
			}

			/* IE 10+ */
			&:-ms-input-placeholder {
				color: $error-red;
				border-color: $error-red;
			}
		}
	}

	// label
	&-label {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		transition: 0.5s ease;

		@include ie {
			transition: none;
		}

		@include rtl {
			left: auto;
			right: 0;
		}
	}

	//Shared Text (Text Input, Text Area)
	&-text {
		// label when in focus
		.formElement-field:focus + .formElement-label,
		.formElement-field--labelOn + .formElement-label,
		.formElement-field--filled + .formElement-label,
		.formElement-field--readOnly + .formElement-label:not(.formElement-label--hidden),
		.formElement--focusAlways {
			opacity: 1;
			transform: translateY(-15px);
		}

		.formElement-field {
			// placeholder
			&::placeholder {
				opacity: 1;
				transition: 1s ease;
			}

			/* IE 10+ */
			&:-ms-input-placeholder {
				opacity: 1;
				transition: 1s ease;
			}

			// placeholder - become hidden when in focus
			&:focus::placeholder {
				opacity: 0;
				transition: 300ms ease;
			}

			/* IE 10+ */
			&:focus:-ms-input-placeholder {
				opacity: 0;
				transition: 300ms ease;
			}

			// placeholder disabled
			&--disabled::placeholder,
			&--disabled:hover::placeholder {
				color: $gray2;
			}

			/* IE 10+ */
			&--disabled:-ms-input-placeholder,
			&--disabled:hover:-ms-input-placeholder {
				color: $gray2;
			}
		}
	}
}

//Text Input
//***************************************************
.TextInput {
	position: relative;
	.icon-close {
		fill: $black;
	}
}

.TextInput-field {
	&--readOnly {
		border-bottom: 0;
		padding-bottom: 0;
	}

	// field when in focus and valid - filled in
	&.formElement-field--valid {
		padding-left: 16px;
		padding-right: 25px;

		@include rtl {
			padding-left: 25px;
			padding-right: 16px;
		}
	}

	// field disabled
	&--disabled {
		cursor: not-allowed;
	}

	.TextInput--error & {
		color: $error-red;
		border-color: $error-red;
	}
}

//Text Area
//***************************************************
.TextareaField {
	position: relative;

	&-field {
		display: block;
		min-height: 200px;
		width: 100%;
		overflow: auto;
		white-space: normal;
		padding-right: 16px;

		@include rtl {
			padding-left: 16px;
		}
	}

	&.formElement-field {
		white-space: normal;
		overflow: scroll;
	}
}

//Dropdown
//***************************************************
.Dropdown {
	position: relative;

	.formElement-field {
		white-space: pre;
	}

	&::after {
		@include icon('icon-caret');
		position: absolute;
		top: 10px;
		right: 16px;
		font-size: 5px;
		pointer-events: none;
	}

	&.noCaret {
		&::after {
			display: none;
			content: '';
		}
	}

	//Remove All button for multi select
	&-removeAll {
		display: none;
		position: absolute;
		top: 15px;
		right: 16px;
		z-index: 10;
		width: 16px;
		height: 16px;
		text-align: center;
		pointer-events: all;
		cursor: pointer;
		line-height: 10px;
		padding: 2px;
		color: $gray6;
		background-color: $gray2;
		border-radius: 50%;
		transition: background-color 0.3s ease-out;
		opacity: 0.6;

		@include rtl {
			right: auto;
			left: 16px;
		}
	}

	&.showRemoveAll {
		.Dropdown-removeAll {
			display: block;

			&::before {
				font-size: 8px;
				font-weight: bold;
			}

			&:hover {
				background-color: $gray1;
			}
		}
	}

	&.minimal {
		width: 100%;
		.formElement-label {
			font-weight: 300;
			font-size: 8px;
			line-height: 15px;
			letter-spacing: 2.5px;
			text-transform: uppercase;
			white-space: nowrap;
		}
		.Dropdown-field,
		.formElement-field {
			border: none;
		}
		@include viewport(medium) {
			width: fit-content;
			&::after {
				@include icon('icon-caret_down');
				top: 3px;
				right: 6px;
			}
			@include rtl {
				&::after {
					right: auto;
					left: 6px;
				}
			}
			.Dropdown-field,
			.formElement-field {
				padding-left: 0;
				height: auto;
				width: auto;
				background-color: white;
				@include typography(h2);
				font-weight: 400;
				font-size: 10px;
				line-height: 13px;
				letter-spacing: 3px;
				text-transform: uppercase;
				@include rtl {
					padding-right: 0;
					padding-left: 27px;
				}
			}
		}
	}

	@include rtl {
		&::after {
			right: auto;
			left: 16px;
		}
	}

	&-field {
		position: relative;
		padding-left: 16px;
		padding-right: 27.5px;

		@include rtl {
			padding-left: 27.5px;
			padding-right: 16px;
		}

		// placeholder - become hidden when in focus
		&:focus ~ .Dropdown-labelContainer,
		& ~ .chosen-container-active ~ .Dropdown-labelContainer,
		&.formElement-field--labelOn ~ .Dropdown-labelContainer,
		&.formElement-field--filled ~ .Dropdown-labelContainer,
		&.Dropdown-field--filled ~ .Dropdown-labelContainer,
		& ~ .Dropdown-labelContainer .formElement--focusAlways + {
			.mask {
				opacity: 0;
				transition: 300ms ease;
			}
		}

		option {
			color: $black;
		}
	}

	&-field[multiple] {
		overflow-y: scroll;
	}

	&-field--changed {
		color: $black;
	}

	.formElement--focusAlways {
		opacity: 1;
		transform: translateY(-15px);
	}

	.mask {
		opacity: 1;
		position: absolute;
		top: 10px;
		left: 16px;
		transition: 1s ease;
		pointer-events: none;
		color: $black;
		text-align: left;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-right: 27.5px;
		max-width: 90%;

		@include rtl {
			left: 0;
			right: 16px;
			text-align: right;
			padding-right: 0;
			padding-left: 27.5px;
		}
	}

	// label when in focus
	.Dropdown-field:focus ~ .Dropdown-labelContainer,
	.formElement-field--labelOn ~ .Dropdown-labelContainer,
	.Dropdown-field.formElement-field--filled ~ .Dropdown-labelContainer,
	.Dropdown-field--filled ~ .Dropdown-labelContainer,
	.Dropdown-field--readOnly ~ .Dropdown-labelContainer,
	.chosen-container-active ~ .Dropdown-labelContainer,
	.Dropdown-field.formElement-field--filled ~ .Dropdown-labelContainer {
		.formElement-label,
		.formElement-label:not(.formElement-label--hidden) {
			opacity: 1;
			transform: translateY(-15px);
		}
	}

	.chosen-container-multi {
		.chosen-choices {
			width: 100%;
			min-height: 47px;
			border-radius: 0;
			border: none;
			border-bottom: 1px solid currentColor;
			padding: 0.5em 0;
			color: $black;
			background: $gray6;
			cursor: pointer;
			white-space: nowrap;
			overflow-x: hidden;
			text-overflow: ellipsis;
			appearance: none;
			padding-right: 27.5px;

			li.search-choice {
				color: $white;
				background-color: $black;
				padding: 0 33px 0 11px;
				line-height: 27px;
				font-size: 18px;
				margin-left: 10px;
				background-image: none;
				border-radius: 0;
				border: none;

				.search-choice-close {
					text-align: right;
					display: block;
					font-size: 10px;
					color: $white;
					background: none;
					height: 10px;
					width: 10px;
					top: 0;
					right: 10px;

					&::after {
						@include icon('icon-close');
					}

					&:hover {
						color: $gray2;
					}
				}
			}
		}

		.chosen-results {
			.active-result,
			.result-selected {
				color: $black;
				margin: 0;
				padding: 5px 0 5px 10px;
			}
		}

		@include rtl {
			.chosen-results {
				.active-result,
				.result-selected {
					padding-left: 0;
					padding-right: 10px;
				}
			}
		}

		.chosen-results .active-result.highlighted {
			background-image: none;
			background-color: $gray6;
		}

		.chosen-results {
			.Checkbox {
				&-decoration {
					width: 16px;
					height: 16px;
					margin-top: auto;
					margin-bottom: auto;

					&::before {
						top: 2px;
						left: 2px;
						font-size: 10px;
					}
				}

				&-label {
					cursor: pointer;
				}
			}

			.result-selected {
				.Checkbox {
					&-decoration {
						background-color: $black;

						&::before {
							opacity: 1;
						}
					}

					&-label {
						color: $black;
					}
				}
			}

			.no-results {
				.Checkbox-decoration {
					display: none;
				}
			}
		}
	}

	&Switch {
		position: relative;

		&-switch {
			position: absolute;
			right: 0;
			top: -22px;

			.SwitchField {
				&-switch {
					width: 1.25em;
					height: 0.625em;
				}

				&-checkbox {
					&:checked + .SwitchField-slider::before {
						-webkit-transform: translateX(0.625em);
						-ms-transform: translateX(0.625em);
						transform: translateX(0.625em);
					}
				}

				&-slider {
					border-radius: 0.625em;

					&::before {
						height: 0.625em;
						width: 0.625em;
					}
				}
			}
		}
	}
}

.EmptyElement {
	height: 64px;
	&--desktop {
		display: none;

		@include viewport(medium) {
			display: block;
		}
	}
}
