$featuresHorizontalPadding: 30px;

.FeatureList {
	&-heading {
		margin-bottom: 35px;

		@include viewport(medium) {
			margin-bottom: 50px;
		}

		.Heading-title {
			letter-spacing: 3px;
			margin: 0 0 16px;
		}

		.Heading-text {
			margin: 0;
		}
	}

	&-features {
		display: block;

		@include viewport(medium) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		&Item {
			margin-bottom: 30px;

			@include viewport(medium) {
				width: calc(50% - #{$featuresHorizontalPadding / 2});

				&:nth-child(odd) {
					margin-right: $featuresHorizontalPadding;
				}
			}
		}
	}
}
