.FileUpload {
	&-description {
		color: $gray1;
		max-width: 60%;
		font-size: 0.8em;
	}

	&-button {
		margin-bottom: 20px;
	}

	/* ---------------------------------------
	/* Fine Uploader Styles
	/* ---------------------------------------



	/* Drop Zone
	------------------------------------------ */
	.qq-uploader {
		position: relative;
		overflow-y: hidden;
		width: inherit;
	}

	.qq-upload-list {
		margin: 0;
		padding: 0;
		list-style: none;
		max-height: 450px;
		overflow-y: auto;
		clear: both;
	}

	/* Uploaded Elements
	------------------------------------------ */
	.qq-upload-list li {
		margin: 0;
		padding: 10px;
		color: $white;
	}
	.qq-text-wrapper {
		margin-bottom: 10px;
	}

	.qq-upload-spinner {
		display: inline-block;
		background: url('/alt/fshr/design3/images/loading-indicator.gif');
		width: 50px;
		height: 50px;
		vertical-align: text-bottom;
		background-size: contain;
		margin-left: -10px;

		@include rtl {
			margin-left: 0;
			margin-right: -10px;
		}
	}

	.qq-upload-failed-text {
		display: none;
	}
	.qq-upload-fail .qq-upload-failed-text {
		display: inline;
	}
	.qq-upload-retrying .qq-upload-failed-text {
		display: inline;
	}
	.qq-upload-list li.qq-in-progress {
		color: $black;
	}
	.qq-upload-list li.qq-upload-success {
		background-color: $success-green-soft;
		color: $black;
	}

	.qq-upload-list li.qq-upload-fail {
		background-color: $error-red;
	}

	.qq-upload-size {
		border-right: solid 1px;
		padding-right: 5px;

		@include rtl {
			border-right: 0;
			padding-right: 0;
			border-left: solid 1px;
			padding-left: 5px;
		}
	}

	li.qq-upload-success .qq-upload-size {
		border: 0;
		padding: 0;
	}

	li.qq-upload-fail {
		.CTA--primary--dark:hover {
			border: $white;
		}
	}

	.qq-alert-dialog-selector {
		border: 1px solid;
	}

	.qq-dialog-message-selector {
		margin-bottom: 10px;
	}

	.qq-hide {
		display: none;
	}

	&-error {
		&,
		&::placeholder {
			color: $error-red;
		}
	}
}
