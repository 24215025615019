button[disabled] {
	cursor: default;
	pointer-events: none;
}

.Slideshow {
	color: black;
	background-color: #f5f5f5;
	padding: 40px 0 20px;

	@include viewport(medium) {
		padding: 80px 0;
	}

	@include viewport(large) {
		padding: 0;
		background-color: transparent;
	}
	.Slideshow-title {
		@include typography(h2);
		text-align: center;
		margin: 0 15px 15px;

		@include viewport(large) {
			margin-bottom: 40px;

			@at-root .Slideshow-dark#{&} {
				color: white;
			}
		}
	}
	.Slideshow-separator {
		width: 30px;
		margin: 0 auto 20px;
		border: 1px solid #000;

		@include viewport(medium) {
			width: 40px;
			margin-bottom: 30px;
		}

		@include viewport(large) {
			display: none;
		}
	}
	.ExperienceCards {
		overflow: hidden;
		display: flex;
		flex-direction: column;

		@include viewport(large) {
			display: block;
		}
		&-image {
			&-portrait {
				display: none;

				@include viewport(medium) {
					display: block;
				}
			}
			&-landscape {
				display: block;

				@include viewport(medium) {
					display: none;
				}
			}
		}
		&-item {
			background-color: #f5f5f5;
			width: 100%;
			max-width: 285px;
			margin: 0 7.5px;

			@include viewport(medium) {
				width: 450px;
				max-width: none;
				margin: 0 15px;
			}

			@include viewport(large) {
				width: auto;
				margin: 0;
				height: calc(100vh - 96px);
			}
			.ExperienceCards-Pagination {
				display: none;

				// @include viewport(large) {
				// 	display: flex;
				// }
			}
		}
		&-list {
			&.flickity-enabled .ExperienceCards-item {
				&.is-selected {
					.ExperienceCards-CTA,
					.ExperienceCards-Heading {
						opacity: 1;
						transition: opacity 1s ease-in;
					}

					.Image {
						opacity: 1;
						transition: opacity 1s ease-in;
					}
				}

				&:not(.is-selected) {
					cursor: pointer;

					.Image {
						opacity: 0.6;
						transition: opacity 1s ease-out;

						&:hover {
							opacity: 0.5;
							transition: opacity 0.5s;
						}
					}

					.ExperienceCards-CTA,
					.ExperienceCards-Heading {
						opacity: 0;
						transition: opacity 1s ease-out;
					}
				}
			}
		}
		&-itemContent {
			display: flex;
			flex-direction: column;

			@include viewport(large) {
				height: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
			}
			.Heading .Heading-title {
				@include typography(h3);

				@include viewport(large) {
					@include typography(h2);
					white-space: normal;
				}
			}
			&-image {
				// hide text and add padding for tablet and mobile
				.Heading {
					text-align: center;
					padding-bottom: 20px;

					@include viewport(medium) {
						padding-bottom: 30px;
					}
					.Heading-text {
						display: none;
					}
				}
				margin-bottom: 20px;

				@include viewport(medium) {
					margin-bottom: 30px;
				}

				@include viewport(large) {
					height: 100%;
					margin: 0;
					padding: 0;
					.Image.Image-ratioContainer {
						height: 100%;
						width: auto;
						overflow: hidden;
						.Image-img {
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
			&-content {
				text-align: center;
				flex-grow: 1;
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				// don't show title here for mobile
				.Heading-eyebrow,
				.Heading-title {
					display: none;
				}

				@include viewport(large) {
					text-align: left;
					flex-direction: row;
					// reset and show title here for desktop
					.Heading-eyebrow,
					.Heading-title {
						display: inherit;
					}
				}
			}
		}

		&-textPanel {
			padding-bottom: 50px;

			@include viewport(medium) {
				padding-bottom: 60px;
			}

			@include viewport(large) {
				padding-left: 10%;
				padding-right: 30%;
				padding-bottom: 0;
				width: 100%;

				@include rtl {
					text-align: right;
					padding-right: 10%;
					padding-left: 30%;
				}
			}
		}

		&-Heading {
			margin: 0;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			@include viewport(large) {
				display: block;
				&.hide-lg {
					display: none;
				}
				justify-content: inherit;
			}
			.Heading-eyebrow {
				margin: 0;

				@include viewport(large) {
					margin-bottom: 10px;
				}
			}
			.Heading-title {
				margin: 0;

				@include viewport(large) {
					margin-bottom: 20px;
				}
			}
			.Heading-text {
				margin: 0 0 20px; //margin bottom

				@include viewport(large) {
					margin-bottom: 30px;
				}
				&:lang(ja) {
					font-size: 1rem;
				}
			}
		}

		&-CTA {
			display: block;
		}

		&-Pagination {
			width: 103px;
			align-self: center;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 30px;
			span {
				font-size: 10px;
				line-height: 13px;
				white-space: nowrap;

				@include viewport(medium) {
					font-size: 12px;
					line-height: 16px;
				}
			}

			@include viewport(medium) {
				width: 117px;
			}

			@include viewport(large) {
				padding: 0;
				min-width: 67px;
				width: 67px;
				height: 110px;
				flex-direction: column;
				position: fixed;
				right: 30px;
				top: 50%;
				margin-top: -55px;
				z-index: 9;
				opacity: 0;

				@include rtl {
					right: auto;
					left: 30px;
				}
			}

			@include viewport(large) {
				&.hide-lg {
					display: none;
				}
			}
		}
		&-navigationButton {
			width: 12px;
			height: 12px;
			font-size: 12px;
			line-height: 12px;
			&::before {
				line-height: 13px;
			}

			@include viewport(large) {
				transform: rotate(90deg);

				@include rtl {
					transform: rotate(-90deg);
				}
			}
			&[hiddden] {
				display: block;
			}
		}
	}
}
