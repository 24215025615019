.CheckoutFooter {
	height: 100px;
	width: 100%;
	max-width: 100vw;
	background: #f5f5f5;

	&-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 30px;
		min-height: 52px;
	}

	&-brgLink {
		color: #000;
		width: 100%;
		text-decoration: none;
		padding-top: 5px;

		& .CheckoutFooter-icon-icon {
			margin: 0 8px 0 0;
		}

		& .CheckoutFooter-icon-text {
			font-size: 12px;
			font-weight: 700;
			letter-spacing: 1.5px;
			vertical-align: text-top;
		}

		& .CheckoutFooter-BrgAdditionalText {
			font-size: 10px;
			font-weight: 400;
			letter-spacing: 1px;
			vertical-align: middle;
			text-transform: none;
		}
	}

	&-background {
		text-align: center;
		margin: 0 auto;
		width: 330px;
	}

	@include viewport(large) {
		display: none;
	}
}
