.ImageAndCtaTitled {
	&-title {
		@include component-spacing($small: 40px, $medium: 60px, $large: 80px, $xlarge: 60px);
		text-align: center;
	}

	.Image {
		font-size: 0;
		margin-bottom: 30px;
	}

	.CtaBlock {
		&-title {
			@include typography(c1);

			@include viewport(medium) {
				max-width: percentage(8/12);
			}

			@include viewport(large) {
				max-width: percentage(6/12);
			}

			@include viewport(xlarge) {
				max-width: percentage(4/12);
			}
		}

		&-text {
			@include typography(b2);
			margin-bottom: 30px;
			margin-top: 20px;

			@include viewport(medium) {
				max-width: percentage(8/12);
				margin-top: 10px;
			}

			@include viewport(large) {
				max-width: percentage(6/12);
			}

			@include viewport(xlarge) {
				max-width: percentage(5/12);
			}
		}
	}
}
