.ImageAndCTABlocksRowOfTwo {
	display: flex;
	flex-direction: column;

	@include viewport(medium) {
		flex-direction: row;

		&-imageAndCTABlock--1 {
			margin-right: 30px;
		}

		&-imageAndCTABlock--2 {
			margin-left: 15px;
		}
	}

	.ImageAndCTABlockWithEyebrows {
		width: 100%;
		margin-bottom: 30px;

		@include viewport(medium) {
			width: 50%;
			margin-bottom: 0;
		}

		&-image {
			margin-bottom: 20px;
		}

		&-eyebrow {
			@include typography(c1);
		}

		&-secondaryEyebrow,
		&-bullet {
			@include typography(c2);
		}

		.CtaBlock {
			&-title {
				font-family: $sansFont;
				font-size: 24px;
				line-height: 33px;
				font-weight: 300;
			}

			&-text {
				margin-top: 10px;
				margin-bottom: 20px;

				@include typography(b1);
			}
		}
	}
}
