.CardTrio {
	@include viewport(medium) {
		min-height: 830px;
	}
	&-title {
		text-align: center;
		margin-bottom: 40px;

		@include viewport(medium) {
			@include typography(h2);
		}
	}
	&-Item-title {
		display: flex;
	}

	&-image {
		margin-bottom: 20px;
		border: 1px solid $gray3;
	}

	&-CTA {
		display: flex;
		margin-bottom: 10px;
	}
	&-text {
		margin-top: 10px;
		margin-bottom: 40px;

		@include viewport(medium) {
			margin-bottom: 30px;

			&--one,
			&--three {
				margin-bottom: 0;
			}
		}

		@include viewport(large) {
			margin-bottom: 20px;

			&--one,
			&--three {
				margin-bottom: 0;
			}
		}
	}
	&-Cardtext {
		&--dark {
			color: $white;
		}
		&--light {
			color: $black;
		}
	}

	&--dark {
		.CardTrio-image {
			border: 1px solid $gray1;
		}
	}

	.offset-md-0 {
		@include viewport(medium) {
			margin-left: 0;

			@include rtl {
				margin-right: 0;
			}
		}
	}
	a {
		text-decoration: none;
	}
}
