@import '../../../navigation/variables';

.Overlay_caw_offline {
	&_contactLinks {
		display: flex;
		flex-direction: row;
	}

	&_phone,
	&_email {
		display: inline-flex;

		&::before {
			vertical-align: middle;
			width: 30px;
			height: 30px;
			margin: auto;
			font-size: 20px;

			@include viewport(small) {
				display: block;
				padding-bottom: 5px;
			}

			@include media($navigationBreakpoint) {
				margin-right: 10px;
				padding-bottom: 0;
			}
		}

		&Link {
			align-self: center;
			font-family: $sansFont;
			@include link('underlined', 'dark');
			font-size: 12px;
			color: $black;
			border-bottom: 1px solid $black;
			text-decoration: none;
		}
	}

	&_phone {
		margin-right: 40px;

		&::before {
			@include icon('icon-call_alt_inverted');
			font-size: 20px;
		}
	}

	&_email {
		&::before {
			@include icon('icon-mail');
			font-size: 16px;
		}
	}
}
