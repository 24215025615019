.ArticlesHeading {
	&-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&-article {
		&:first-child {
			margin-top: 0;
		}

		margin-top: 20px;
	}

	&-cta {
		white-space: nowrap;
	}

	&-column {
		float: none;
	}

	.BodySection & .SpacedComponent:not(:last-child) {
		margin-bottom: 0;
	}
}
