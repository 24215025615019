@mixin paginationSeparator($position: 'after') {
	&::#{$position} {
		@include icon('icon-pagination_line');
		font-size: 20px;
		vertical-align: middle;
		margin-#{if($position == "after", "left", "right")}: 7px;
	}
}

.Gallery {
	&-overlay {
		background-color: white;
		color: $black;
		left: 0;
		opacity: 0;
		position: fixed;
		top: 0;
		transition: visibility 0.8s 0s, opacity 0.8s ease-out;
		visibility: hidden;
		width: 100vw;
		height: 100%;
		z-index: $overlay-z-index;

		&--shown {
			opacity: 1;
			transition: visibility 0.8s 0s, opacity 0.8s ease-out;
			visibility: visible;
		}
	}

	&-pagination {
		display: none;
		position: absolute;
		top: 30px;
		left: 30px;

		.Gallery-overlay--paginated & {
			display: block;

			@include rtl {
				right: 30px;
				left: auto;
				display: flex;

				@include viewport(large) {
					left: auto;
					right: 50%;
					transform: translateX(50%);
				}
			}

			@include viewport(large) {
				left: auto;
				right: 50%;
				transform: translateX(50%);
			}
		}
	}

	&-currentPageNumber {
		@include paginationSeparator;

		@include rtl {
			@include paginationSeparator('before');

			&::after {
				content: '';
			}
		}
	}

	&-closeButton {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 1;

		@include rtl {
			left: 15px;
			right: auto;
		}
	}

	&-slide {
		text-align: center;
		height: 100vh;
		width: 100vw;
		padding: 104px 15px;

		// Landscape phones
		@include media('568px') {
			// 23.08% is the padding that let us have an
			// image 360px wide in a 667px viewport
			padding: 70px 23.08% 102px;
		}

		@include viewport(medium) {
			padding: 128px 12.5%;
		}

		@include viewport(large) {
			padding: 96px 12.5%;
		}

		@include viewport(xlarge) {
			padding: 135px 12.5%;
		}

		.Image {
			height: 100%;
			width: 100%;

			&-img {
				height: 100%;
				object-fit: contain;
				width: 100%;

				&--ieFixed {
					width: auto;
					height: auto;
				}

				&--taller {
					height: 100%;
					margin: auto;
				}

				&--wider {
					width: 100%;
				}
			}
		}

		.ThirdPartyVideo {
			&--wider {
				width: 100%;
			}

			&--taller {
				height: 100%;
				margin: 0 auto;
			}
		}
	}

	.GalleryItem {
		&-caption {
			bottom: 30px;
			position: absolute;
			right: 50%;
			transform: translateX(50%);
			line-height: 13px;

			&Separator {
				height: 1px;
				background-color: $separatorLight;
				margin: 12px 7.5px 0;
				text-align: center;

				@include viewport(medium) {
					margin: 15px 15px 0;
				}
			}
		}

		&--grip {
			position: absolute;
			left: 0;
			width: calc(50% - 50px);
			top: 32px;
			bottom: 40px;
			z-index: 3;

			&::before {
				position: absolute;
				display: block;
				content: '';
				width: 60%;
				height: 42%;
				top: 0;
				left: 100%;
			}

			+ .GalleryItem--grip {
				left: auto;
				right: 0;
				&::before {
					bottom: 0;
					top: auto;
					right: 100%;
					height: 39%;
					left: auto;
				}
			}
		}

		&--image {
			// Fixes a bug in iPhone (images disappear on slide change in landscape)
			transform: translate3d(0, 0, 0);
		}

		&-attribution {
			margin: 14px auto 0;
			float: none;
			text-align: center;

			@include viewport(medium) {
				margin-top: 15px;
			}

			.Attribution-author {
				@include typography(c4);
			}

			@include rtl {
				// Necessary to overwrite attribution
				text-align: center;
			}
		}

		&-wrapper {
			height: 100%;
			width: 100%;
			position: relative;

			&--taller {
				display: flex;
				align-items: center;
			}
		}
	}

	&-navigationButton {
		display: none;
		position: absolute;
		bottom: 50%;
		transform: translateY(50%);
		z-index: 1;

		.Gallery-overlay--paginated & {
			@include viewport(medium) {
				display: block;
			}
		}
	}

	&-prevButton {
		left: 30px;

		@include rtl {
			right: 30px;
			left: auto;
		}
	}

	&-nextButton {
		right: 30px;

		@include rtl {
			left: 30px;
			right: auto;
		}
	}

	&-list-single-item {
		.GalleryItem--grip {
			display: none;
		}
	}
}
