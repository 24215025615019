.SubscribeBar {
	position: relative;
	margin: auto;
	padding: 10px 40px 10px 20px;
	width: 100%;
	background-color: $gray4;
	font-size: 1rem;
	opacity: 0.9;

	&--dark {
		background-color: $gray1;
		color: $white;
	}

	@include viewport(medium) {
		padding: 16px 50px;
		letter-spacing: 0.02778em;
	}

	@include viewport(large) {
		padding: 34px 150px;
		font-size: 1.375rem;
		letter-spacing: 0.02778em;
	}

	&--closed {
		display: none;
	}

	&-container {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;
		margin: auto;
		max-width: 1100px;
		width: 100%;
	}

	&-text {
		margin: 0 auto 0 0;
		font-size: 1.175rem;

		@include viewport(large) {
			font-size: 1.375rem;
		}
	}

	&-cta {
		margin-left: 10px;
		background-color: transparent;
	}

	&-closeButton {
		position: absolute;
		top: 50%;
		right: 0;
		width: 42px;
		height: 42px;
		transform: translateY(-50%);
		font-size: 13px;

		@include viewport(large) {
			right: 46px;
		}
	}
}
