.CategoryFeature {
	&-intro {
		@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);

		&-container {
			text-align: center;
		}

		.Heading-title {
			@include headingBorder-2();
			text-align: center;
		}

		.Heading-quote {
			margin: 0;
		}

		.Quote-text {
			@include quote();
			text-align: center;
		}

		.Attribution {
			.Attribution-author,
			.Attribution-role {
				display: block;

				@include viewport(large) {
					display: inline;
				}
			}
		}
	}

	&-image {
		position: relative;
	}

	&-backgroundImage {
		height: calc(100vw * 9 / 16);

		&::after {
			@include gradient2transparent($height: 60%);
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
		}
	}

	&-videoOverlay {
		position: absolute;
		bottom: get-gutter(small) - 4px;
		padding: 0 get-gutter(small);

		@include viewport(medium) {
			bottom: get-gutter(medium) - 4px;
			padding: 0 get-gutter(medium);
		}

		@include viewport(xlarge) {
			bottom: 36px;
			padding: 0 40px;
		}
	}
}
