.QuoteBackgroundImage {
	position: relative;
	&-image {
		&::after {
			height: 100%;
			width: 100%;
			top: 0;
			content: '';
			display: block;
			position: absolute;
		}

		height: calc(100vw * 2);

		@include viewport(medium) {
			height: calc(100vw * 5 / 4);
		}

		@include viewport(large) {
			height: calc(100vw * 9 / 16);
		}
	}

	&--white {
		color: $white;
		.QuoteBackgroundImage-image::after {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.99999) 30%, rgba(0, 0, 0, 0) 70%);
		}
	}

	&--black {
		color: $black;
		.QuoteBackgroundImage-image::after {
			background: linear-gradient(
				180deg,
				hsla(0deg, 0%, 100%, 0.99999) 30%,
				hsla(0deg, 0%, 100%, 0) 70%
			);
		}
	}

	&-quote {
		position: relative;
		z-index: 2;
		opacity: 0;
		text-align: center;
		margin-top: 55px;
		transform: translate(0, 30px);

		@include viewport(medium) {
			margin-top: 100px;
		}

		@include viewport(large) {
			transform: translate(0, 70px);
		}

		@include viewport(xlarge) {
			margin-top: 120px;
		}

		.Quote {
			&-text {
				margin: 0 0 20px;

				@include quote(inline);
				@include typography(q1);
			}
		}

		.Attribution-author {
			margin-bottom: 5px;
		}

		.Attribution-author,
		.Attribution-role {
			display: block;

			@include viewport(large) {
				display: inline-block;
			}
		}
	}
}
