.TextBlocks {
	.TextBlock {
		&-title {
			font-family: Helvetica;
			font-weight: 300;
			font-style: normal;
			font-size: 24px;
			line-height: 31px;
			letter-spacing: 0.5px;
			margin-top: 30px;
			text-align: left;

			@include viewport(small) {
				margin-top: 30px;
				margin-bottom: 24px;
				width: 315px;
			}

			@include viewport(medium) {
				//tablet
				margin-top: 30px;
				margin-bottom: 24px;
				width: 659px;
			}

			@include rtl {
				text-align: right;
			}

			@include viewport(large) {
				//desktop
				margin-top: 30px;
				margin-bottom: 24px;
				width: 770px;
			}
		}

		&-text {
			margin-top: 30px;
			margin-bottom: 24px;
			text-align: left;

			@include rtl {
				text-align: right;
			}

			@include viewport(small) {
				margin-top: 30px;
				margin-bottom: 24px;
				width: 315px;
			}

			@include viewport(medium) {
				//tablet
				margin-top: 30px;
				margin-bottom: 24px;
				width: 659px;
			}

			@include viewport(large) {
				//desktop
				margin-top: 30px;
				margin-bottom: 24px;
				width: 770px;
			}
		}
		margin: auto;
		border-bottom: 1px solid $gray2;
		&:last-child {
			border-bottom: none;
		}

		@include rtl {
			margin: 0;
		}

		@include viewport(medium) {
			//tablet
			margin-top: 30px;
			margin-bottom: 24px;
			width: 659px;
		}

		@include viewport(large) {
			@include rtl {
				margin-right: 8.33%;
			}
			//desktop
			margin-top: 30px;
			margin-bottom: 24px;
			width: 770px;
			margin-left: 8.33%;
		}
	}
}
