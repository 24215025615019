.IconTable {
	&-heading {
		.Heading-title {
			text-align: center;
			margin-top: 0;
			margin-bottom: 30px;

			@include viewport(medium) {
				margin-bottom: 50px;
			}
		}
	}

	&-Table {
		border: 1px solid $gray3;
		padding: 20px 30px;

		@include viewport(medium) {
			padding: 30px;
		}

		@include viewport(large) {
			padding: 20px 30px;
		}

		& .CTA::after {
			float: none;
		}

		&.two-column {
			.IconTable-Cell {
				width: 100%;

				@include viewport(medium) {
					width: 50%;

					&:nth-child(odd) {
						.IconTable-Cell-inner {
							margin-right: 15px;
						}

						@include rtl {
							.IconTable-Cell-inner {
								margin-right: 0;
								margin-left: 15px;
							}
						}
					}

					&:nth-child(even) {
						.IconTable-Cell-inner {
							margin-left: 15px;
						}

						@include rtl {
							.IconTable-Cell-inner {
								margin-right: 15px;
								margin-left: 0;
							}
						}
					}
				}

				@include viewport(large) {
					.IconTable-Cell-inner {
						flex-wrap: nowrap;
					}

					&:nth-child(odd) {
						.IconTable-Cell-inner {
							margin-right: 40px;
						}

						@include rtl {
							.IconTable-Cell-inner {
								margin-right: 0;
								margin-left: 40px;
							}
						}
					}

					&:nth-child(even) {
						.IconTable-Cell-inner {
							margin-left: 40px;
						}

						@include rtl {
							.IconTable-Cell-inner {
								margin-right: 40px;
								margin-left: 0;
							}
						}
					}
				}
			}

			.IconTable-Cell:not(:last-child) {
				.IconTable-Cell-inner {
					border-bottom: 1px solid $gray3;
				}
			}

			@include viewport(medium) {
				.IconTable-Cell:nth-last-child(-n + 2) {
					.IconTable-Cell-inner {
						border: 0;
					}
				}

				.IconTable-Table-Cells {
					display: flex;
					flex-wrap: wrap;
				}
			}
		}

		&.one-column {
			@include viewport(large) {
				.IconTable-Cell-inner {
					flex-wrap: nowrap;
				}
			}
			.IconTable-Cell:not(:last-child) {
				.IconTable-Cell-inner {
					border-bottom: 1px solid $gray3;
				}
			}
		}
	}
}
