.TallImageCard {
	width: 100%;
	background-color: $white;

	.BodySection--dark & {
		background-color: $black;
	}

	&-link {
		text-decoration: none;
		color: #000000;

		.BodySection--dark & {
			color: $white;
		}
	}

	&-image {
		position: relative;
		left: 0px;
		top: 0px;
		overflow: hidden;
	}

	&-image img {
		transition: 0.5s all ease-in-out;
	}

	&-image:hover img {
		transform: scale(1.1);
	}

	&-eyebrowContainer {
		margin-top: 20px;
		line-height: 0;
	}

	&-title {
		/* or 150% */
		letter-spacing: 0.5px;
		text-transform: capitalize;
		margin-top: 20px;
		margin-bottom: 15px;

		/* 00. Grayscale/Black */
		color: #000000;

		/* Inside auto layout */
		flex: none;
		order: 0;
		align-self: stretch;
		flex-grow: 0;

		.BodySection--dark & {
			color: $white;
		}
		&.has-eyebrows {
			margin-top: 10px;
		}
		&.sm-gap {
			margin-bottom: 0px;
		}
	}

	&-icon-subtitleListItem,
	&-icon-eyebrowListItem {
		display: inline-flex;
		margin: 0 10px 0 0;

		@include rtl {
			margin: 0 0 0 10px;
		}
	}

	&-icon-eyebrowListItem {
		.TallImageCard-icon {
			font-size: 18px;
			line-height: 12px;

			@include viewport(large) {
				line-height: 16px;
			}
		}
	}

	&-icon {
		position: relative;
		margin: 0 8px 0 0;

		@include rtl {
			margin: 0 0 0 8px;
		}

		&-subtitle {
			font-size: 14px;
			line-height: 18px;
			padding-top: 3px;
			max-width: 95%;

			/* identical to box height, or 129% */
			letter-spacing: 1.5px;

			/* 00. Grayscale/Black */
			color: #000000;

			/* Inside auto layout */
			flex: none;
			order: 1;
			flex-grow: 0;

			.BodySection--dark & {
				color: $white;
			}
		}

		&-eyebrow {
			font-size: 10px;
			line-height: 12px;
			max-width: 95%;

			@include viewport(large) {
				font-size: 12px;
				line-height: 16px;
			}

			/* identical to box height, or 129% */
			letter-spacing: 3px;

			/* 00. Grayscale/Black */
			color: #000000;

			/* Inside auto layout */
			flex: none;
			order: 1;
			flex-grow: 0;

			.BodySection--dark & {
				color: $white;
			}
		}
	}

	&-description {
		/* Body/B3 */
		font-size: 15px;
		line-height: 25px;

		/* or 167% */
		letter-spacing: 0.5px;

		/* 00. Grayscale/Black */
		color: #000000;

		/* Inside auto layout */
		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;

		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;

		.BodySection--dark & {
			color: $white;
		}
	}

	&-callout {
		font-size: 16px;
		line-height: 21px;
		text-transform: unset;
		margin-top: 5px;

		/* identical to box height, or 131% */
		letter-spacing: 1px;

		color: #000000;

		/* Inside auto layout */
		flex: none;
		order: 0;
		flex-grow: 0;

		.BodySection--dark & {
			color: $white;
		}
	}

	&-qualifier {
		font-size: 14px;
		line-height: 18px;

		/* identical to box height, or 129% */
		letter-spacing: 1.5px;

		color: #2d2d2d;

		/* Inside auto layout */
		flex: none;
		order: 1;
		flex-grow: 0;

		.BodySection--dark & {
			color: #d2d2d2;
		}
	}

	&-CTA {
		margin-top: 15px;
		display: inline-block;
	}

	&-cardsContainer {
		display: block;
	}
}
