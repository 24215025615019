.Header {
	padding: 27px 0 32px;
	@include media($navigationBreakpoint) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		background-color: $white;
	}

	&-text {
		&__title {
			margin: 0 0 15px;
		}

		&__link {
			margin: 0 0 7px;
			position: relative;
			transition: all 0.8s;
		}

		&__CTA {
			padding-left: 20px;
			color: $black;
			text-decoration: none;
			position: relative;
			transition: all 0.8s;

			&--link {
				cursor: pointer;

				&::after {
					content: '';
					display: block;
					position: absolute;
					bottom: -3px;
					left: 20px;
					height: 1px;
					background-color: #cdcccd;
					width: calc(100% - 20px);
					opacity: 1;
					transition: all 0.8s;

					&:hover {
						opacity: 0;
					}
				}

				&:hover {
					color: $gray2;

					&::after {
						background-color: #ebe8e8;
					}
				}
			}
			@include rtl {
				padding-left: 0;
				padding-right: 20px;

				&::after {
					left: auto;
					right: 20px;
				}
			}

			&:hover {
				[class^='icon-'] {
					transition: opacity 0.3s ease-out;
					opacity: 0.5;
				}
			}
		}

		&__icon {
			position: absolute;
			left: 0;
			width: 15px;
			height: 15px;
			@include rtl {
				left: auto;
				right: 0;
			}
		}
	}

	.mobileTakeover--active & {
		display: none;
	}

	.caw__wrapper.overlay--active & {
		z-index: -100;
	}
}
