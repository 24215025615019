.VideoPlaceholderImage {
	position: relative;

	&-button {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 100px;
		width: 100px;
		transform: translate(-50%, -50%);
		z-index: 1;

		&::before {
			font-size: 50px;
			line-height: 1em;

			@include viewport(medium) {
				font-size: 80px;
			}

			@include viewport(xlarge) {
				font-size: 96px;
			}
		}
	}
}
