.FullscreenHero + div[id^='caw-'] {
	margin-top: -60px;
	max-width: 790px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 2;

	@media only screen and (max-width: 1266px) {
		display: none;
	}
}

.FullscreenHero.FullscreenHero-CenteredContent {
	.Hero,
	.Hero .BackgroundImage,
	.Hero-video {
		max-height: calc(640px - var(--alertheight, 0));

		@include viewport(medium) {
			max-height: calc(950px - var(--alertheight, 0));
		}
		@include viewport(large) {
			max-height: calc(100vh - var(--alertheight, 0));
		}
		&::after {
			background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000 100%);
			top: 0;
			@include viewport(large) {
				background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #000 100%);
				top: 0;
			}
		}
	}
}
.FullscreenHero.FullscreenHero-CenteredContent {
	.Hero .hero-background-tablet .BackgroundImage {
		min-height: calc(510px - var(--alertheight, 0));

		.BackgroundImage-picture {
			height: calc(100vw * 9 / 16);
		}
	}
	.Hero .Hero-video-tablet {
		.Video-playButton {
			bottom: 15%;
		}
	}
}

.FullscreenHero {
	&-dark {
		background-color: $black;
	}
	.Hero .BackgroundImage {
		z-index: auto;
	}
	.Hero,
	.Hero .BackgroundImage,
	.Hero-video {
		max-height: calc(950px - var(--alertheight, 0));
		min-height: calc(150px - var(--alertheight, 0));
		@include viewport(medium) {
			min-height: calc(350px - var(--alertheight, 0));
		}

		@include viewport(large) {
			height: calc(100vh - var(--alertheight, 0));
			max-height: calc(100vh - var(--alertheight, 0));
			min-height: calc(90vh - var(--alertheight, 0));
		}
		&::after {
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 100%);
			height: 25%;

			@at-root .FullscreenHero-prioritize-links#{&} {
				height: 25%;

				@include viewport(large) {
					height: 55%;
				}
			}

			@include viewport(large) {
				height: 55%;
			}
		}
	}

	.Hero-video-tablet {
		width: 100vw;
		height: calc(100vw * (9 / 16));

		.Video-playButton {
			bottom: 0%;
			right: 1px;
			@include rtl {
				left: 1px;
				right: auto;
			}
		}
	}

	.Hero-video-mobile {
		width: 100vw;
		height: calc(100vw * (3 / 4));

		.Video-playButton {
			bottom: 0%;
			right: 1px;
			@include rtl {
				left: 1px;
				right: auto;
			}
		}
	}

	.FullscreenHero-container {
		.rectangle {
			position: absolute;
			width: 2px;
			height: 40px;
			left: calc(50% + 1px);
			bottom: 0;
			background: #7d7d7d;
			z-index: 2;
			opacity: 0;
			overflow: hidden;
			display: none;

			@include viewport(large) {
				display: block;
			}
			.innerRectangle {
				background: white;
				width: 2px;
				height: 40px;
				position: relative;
				bottom: 100%;
			}
		}

		@at-root .FullscreenHero-CenteredContent#{&} {
			.ConversationCard-heroWrapper {
				flex: 1 1 auto;
				align-self: center;
				.ConversationCard {
					@include viewport(medium) {
						width: 690px;
						padding-top: 44px;
						padding-bottom: 24px;
					}

					@include viewport(xlarge) {
						width: 760px;
						padding-top: 64px;
						padding-bottom: 44px;
					}

					&-iconLinks {
						justify-content: center;
					}

					.Heading-text {
						font-size: rem(24, 16);
						font-weight: 300;
						letter-spacing: em(0.5, 24);
						line-height: em(31, 24);
						margin-bottom: 30px;
					}

					.ConversationCard-iconLinks
						.QuickLinks-link--column
						.QuickLinks-link--container {
						width: 130px;
						height: 120px;
						padding: 34px 10px;

						.QuickLinks-link--icon {
							font-size: rem(23, 16);
						}

						.QuickLinks-link--text {
							font-size: rem(10, 16);
							letter-spacing: em(2, 10);
						}
					}
				}
			}
			.Heading {
				text-align: center;
			}
		}
	}
	.Hero .BackgroundImage {
		.FullscreenHero-container {
			display: flex;
			align-content: flex-end;
			flex-wrap: wrap;
			width: 100%;
			height: 100%;

			@include viewport(large) {
				height: 90vh;
			}

			@at-root .has-conversation-card#{&} {
				justify-content: space-between;
				flex-wrap: nowrap;
			}

			@at-root .FullscreenHero-CenteredContent#{&} {
				flex-direction: column;
				// flex-wrap: wrap;
				align-content: center;
			}
			.Heading-title {
				@include viewport(large) {
					font-size: 44px;
					line-height: 62px;
					letter-spacing: 6px;
				}
				&::after {
					display: none;
				}
			}

			// styles for components for animation to work correctly
			> div:not(.rectangle):not(.FullscreenHero-headingContainer) {
				display: none;

				@include viewport(medium) {
					display: block;

					@at-root .has-quick-links#{&} {
						padding-bottom: 50px;
						align-self: flex-end;
						.QuickLinks-link--container {
							margin-bottom: 0;
						}
					}
				}

				@include viewport(large) {
					opacity: 0;
					transform: translate(150px, 100%);
					//get out of way of play button
					margin-bottom: 70px;

					@at-root .has-quick-links#{&} {
						padding-bottom: 0;
					}

					@include rtl {
						transform: translate(-150px, 100%);
					}
				}
			}
		}
		.FullscreenHero-headingContainer {
			z-index: 2;
			flex: 1 1 auto;
			align-self: flex-end;
			padding: 10px 15px 20px 15px;

			@include viewport(medium) {
				padding-left: 22px;
				padding-right: 0;
				padding-bottom: 50px;

				@include rtl {
					padding-left: 0;
					padding-right: 22px;
				}

				@at-root .has-quick-links#{&} {
					padding-bottom: 30px;
				}
			}

			@include viewport(large) {
				max-width: 50%;
				padding-bottom: 70px;
				padding-left: 45px;

				@include rtl {
					padding-left: 0;
					padding-right: 45px;
				}

				@at-root .has-quick-links#{&} {
					max-width: 545px;
					padding-bottom: 70px;
					flex: 0 1 auto;
				}

				@at-root .FullscreenHero-CenteredContent#{&} {
					max-width: 100%;
				}
			}

			@at-root .FullscreenHero-CenteredContent#{&} {
				padding-top: 50px;
				padding-bottom: 180px;
				align-self: center;
				flex: 0;

				@include viewport(medium) {
					padding-left: 0;
					padding-top: 70px;
					padding-bottom: 130px;
				}

				@include viewport(large) {
					padding-top: 160px;
					padding-bottom: 40px;
				}

				@include viewport(xlarge) {
					padding-top: 165px;
					padding-bottom: 100px;
				}
			}

			.Heading {
				@include viewport(medium) {
					text-align: left;

					@include rtl {
						text-align: right;
					}

					@at-root .FullscreenHero-CenteredContent#{&} {
						text-align: center;

						@include rtl {
							text-align: center;
						}
					}
				}

				width: auto;

				.Heading-eyebrow {
					@at-root .FullscreenHero-CenteredContent#{&} {
						font-size: rem(15, 16);
						line-height: em(25, 15);

						@include viewport(medium) {
							@include typography(h4);
						}
					}
				}

				.Heading-title {
					@at-root .FullscreenHero-CenteredContent#{&} {
						margin-top: 5px;
						font-size: rem(20, 16);
						line-height: em(22, 20);
						letter-spacing: em(2, 20);

						@include viewport(medium) {
							font-size: rem(32, 16);
							letter-spacing: em(5, 32);
							line-height: em(47, 32);
						}
					}
				}

				.Heading-subtitle {
					display: none;

					@include viewport(medium) {
						display: block;
					}
				}
			}
		}
		.FullscreenHero-linksContainer {
			display: none;
			flex-direction: row;

			@at-root .FullscreenHero-prioritize-links#{&} {
				display: flex;
			}

			justify-content: center;

			@include viewport(medium) {
				justify-content: normal;
			}

			@include viewport(medium) {
				display: flex;
			}
		}
		.VideoHero-heading-noTitle {
			display: none;
		}

		.QuickLinks.QuickLinks--hero {
			@at-root .FullscreenHero-CenteredContent#{&} {
				width: 100%;
				align-self: center;
			}
		}
	}

	.Hero .BackgroundImage.Hero-yield.mobile-only {
		max-height: calc(950px - var(--alertheight, 0));
		min-height: calc(120px - var(--alertheight, 0));
		background-color: $black;
		height: 100%;

		&::after {
			height: 0%;
		}

		.FullscreenHero-headingContainer {
			@at-root .FullscreenHero-CenteredContent#{&} {
				padding-top: 0px;
				padding-bottom: 180px;
				align-self: flex-start;
			}
		}
	}
	.Hero .BackgroundImage.Hero-yield.tablet-only {
		max-height: calc(950px - var(--alertheight, 0));
		min-height: calc(120px - var(--alertheight, 0));
		background-color: $black;
		height: 100%;

		&::after {
			height: 0%;
		}

		.FullscreenHero-container {
			height: auto;
		}
		.FullscreenHero-headingContainer {
			@at-root .FullscreenHero-CenteredContent#{&} {
				padding-top: 0px;
				padding-bottom: 180px;
				align-self: flex-start;
			}
		}
	}

	&-underHero {
		text-align: center;
		display: none;
		padding-top: 20px;
		opacity: 1;
		height: 15vh;

		@include viewport(large) {
			display: block;
		}

		&-dark {
			color: $white;
			background-color: $black;
		}
		&-light {
			color: $black;
			background-color: $white;
		}
	}
	&-mobileUnderHero {
		margin-top: 30px;
		padding-bottom: 50px;

		&-dark {
			color: $white;
			background-color: $black;
		}
		&-light {
			color: $black;
			background-color: $white;
		}

		@at-root .has-quick-links #{&} {
			margin-bottom: 0;
		}

		@include viewport(medium) {
			display: none;
		}
		.Heading-title,
		.Heading-eyebrow {
			display: none;
		}
		.Heading-subtitle {
			text-align: center;
			margin: 0 35px;
		}
		.FullscreenHero-linksContainer {
			align-items: center;
			padding: 0;
			gap: 20px;
		}

		.FullscreenHero-separator {
			border: 1px solid $gray3;
			margin: 20px 25px;
			width: calc(100% - 50px);
		}
		.ConversationCard {
			opacity: 0;
			position: absolute;
			width: calc(100% - 30px);
			margin: 0 15px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
			z-index: 2;
		}

		@at-root .FullscreenHero-CenteredContent {
			.ConversationCard {
				margin: 0 auto;
			}
			.ConversationCard {
				&-iconLinks {
					justify-content: center;
					.QuickLinks-link--column .QuickLinks-link--container {
						width: 130px;
						height: 120px;
						padding: 34px 10px;

						.QuickLinks-link--icon {
							font-size: rem(23, 16);
						}

						.QuickLinks-link--text {
							font-size: rem(10, 16);
							letter-spacing: em(2, 10);
						}
					}
				}
				&-buttonLinks,
				&-textLinks {
					justify-content: center;
				}
			}
			.Heading {
				text-align: center;

				@include rtl {
					text-align: center;
				}

				.Heading-text {
					font-size: rem(20, 16);
					font-weight: 300;
					letter-spacing: em(0.5, 20);
					line-height: em(24, 20);
				}
			}
		}
	}
	&-linksContainer {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 15px;
		.CTA {
			@include typography(c3);
		}
	}

	.Heading {
		@include viewport(medium) {
			text-align: left;

			@include rtl {
				text-align: right;
			}

			@at-root .FullscreenHero-CenteredContent#{&} {
				text-align: center;

				@include rtl {
					text-align: center;
				}
			}
		}
		width: auto;
		.Heading-title {
			margin: 0;

			@include viewport(medium) {
				&.has-subtitle {
					margin: 0 0 15px;
				}
			}
		}
		.Heading-subtitle {
			display: block;

			@include typography(c2);
			margin-bottom: 10px;
		}
	}

	@at-root .FullscreenHero-CenteredContent {
		.Hero .BackgroundImage .ConversationCard .Heading {
			@include rtl {
				text-align: center;
			}
		}
	}
}
