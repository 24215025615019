.ItalicText {
	&-text {
		@include serifItalic;
		margin-top: 0;
		margin-bottom: 0;

		& > p {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}
