.PlannersDirectory {
	&-planner {
		margin: 0 $gutter_small;
		padding-top: 22px;
		border-top: 1px solid $separatorLight;

		.BodySection--dark & {
			border-top-color: $separatorDark;
		}

		&:not(:last-child) {
			margin-bottom: 40px;
		}

		@include viewport(medium) {
			display: flex;
			margin-left: $gutter_medium;
			margin-right: $gutter_medium;

			&:not(:last-child) {
				margin-bottom: 26px;
			}
		}

		@include viewport(large) {
			margin-left: $gutter_large;
			margin-right: $gutter_large;

			&:not(:last-child) {
				margin-bottom: 22px;
			}
		}

		@include viewport(xlarge) {
			margin-left: $gutter_xlarge;
			margin-right: $gutter_xlarge;
		}
	}

	&-title {
		margin-top: auto;
		margin-bottom: 30px;

		@include viewport(medium) {
			margin-bottom: auto;
			width: 50%;
		}
	}

	&-plannerInfo {
		display: flex;
		flex-direction: column;

		@include viewport(medium) {
			text-align: right;
			width: 50%;

			@include rtl {
				text-align: left;
			}
		}
	}

	&-emailContainer,
	&-name {
		margin-bottom: 5px;
	}

	&-email,
	&-phone {
		color: $gray1;
		border-color: $gray1;
		text-transform: lowercase;

		.BodySection--dark & {
			@include grayHover('dark');
			color: $white;
			border-color: $white;
		}
	}

	.Accordion-item-region {
		padding-bottom: 45px;

		@include viewport(medium) {
			padding-bottom: 55px;
		}

		@include viewport(large) {
			padding-bottom: 67px;
		}
	}
}
