.EmbeddedMap {
	&-map {
		&--fixedHeight {
			height: 457px;
			margin-bottom: 17px;
			width: 100%;

			@include viewport(medium) {
				height: 388px;
				margin-bottom: 27px;
			}

			@include viewport(large) {
				height: 523px;
			}

			@include viewport(xlarge) {
				height: 861px;
			}
		}
	}
}
