$separator-height: 2px;

.VideoDetail {
	.OverlayPage-Header {
		.StandardHero-breadcrumb {
			font-weight: bold;
		}
		.Heading {
			&-title {
				@include typography(h1);

				&::after {
					background-color: $black;
					content: '';
					display: block;
					height: $separator-height;
					margin: 0 auto;
					position: relative;
					top: (10px - $separator-height);
					width: 40px;
				}
			}
		}
	}
}
