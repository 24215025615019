.WideGridContactBar {
	border: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
	margin-left: 15px;
	margin-right: 15px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: var(--container-padding-x-large, 30px);

	&-icon-text {
		@include viewport(medium) {
			display: flex;
			align-items: center;
		}
		.icon {
			font-size: 24px;
			margin-bottom: 10px;
			@include viewport(medium) {
				font-size: 32px;
				margin-right: 30px;
				margin-bottom: 0;
			}
		}
	}
	&-text {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&-ctas {
		display: flex;
	}

	.CTAsSideBySide-cta,
	.CTA {
		border-radius: 5px;
		@include viewport(small) {
			display: block;
			width: 100%;
		}
	}

	span {
		display: block;
	}

	@include viewport(medium) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;

		padding: var(--spacing-400, 40px) var(--spacing-500, 50px);
		margin-left: 60px;
		margin-right: 60px;
	}

	@include viewport(large) {
		margin-left: 90px;
		margin-right: 90px;
	}

	@include viewport(xlarge) {
		margin-left: 120px;
		margin-right: 120px;
	}
}
