// fonts
$serifFont: 'Monotype Garamond', garamond, serif;
$sansFont: 'Neue Helvetica', helvetica, sans-serif;
$cairo: 'Cairo', serif; // cairo google font
$openSans: 'Open Sans', helvetica, sans-serif;
$nanum: 'NanumGothic', helvetica, sans-serif;
$notoSans: 'Noto Sans TC', sans-serif;
$notoSansJP: 'Noto Sans Japanese', helvetica, sans-serif;
$notoSansSC: 'Noto Sans SC', sans-serif;
$icon-font: 'fs-iconfont';
$font-size-base: 16px;

// colors
$black: #000;
$gray1: #2d2d2d;
$gray2: #7d7d7d;
$gray3: #d8d8d8;
$gray4: #f5f5f5;
$gray5: #4d4d4d;
$gray6: #f0f0f0;
$gray7: #666;
$white: #fff;
$separatorLight: $gray3;
$separatorDark: $gray2;
$error-red: #ea0a0a;
$success-green-soft: #ebf6e0;

// Hover colors
$whover: #767676;
$bhover: #999;

// Grid and Layout
$_total-columns: 12;
$_layout-setup: (
	small: (
		container: 100%,
		gutter: 15px,
		shortname: sm,
	),
	medium: (
		container: $containerWidth-md,
		gutter: 30px,
		shortname: md,
	),
	large: (
		container: $containerWidth-lg,
		gutter: 30px,
		shortname: lg,
	),
	xlarge: (
		container: $containerWidth-xl,
		gutter: 30px,
		shortname: xl,
	),
);
// gutter variables
$gutter_small: get-gutter(small);
$gutter_medium: get-gutter(medium);
$gutter_large: get-gutter(large);
$gutter_xlarge: get-gutter(xlarge);
// column variables
$grid-columns: $_total-columns;
$desktopLargePadding: 120px;
$desktopPadding: 100px;
$mobilePadding: 60px;
$articleDesktopPadding: 80px;
$navigationHeight: 160px;
// borders
$border: 1px solid $black;
$linkDefaultBorder: 2px solid transparent;
$linkHoverBorder: 2px solid $black;
$linkHoverRevBorder: 2px solid $white;
$lightBoxShadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
// transitions
$mainTransition: all 0.4s ease;
$opacityTransition: opacity 1s ease-out;
// image hover transitions
$linkColorTransition: color 300ms;
$linkBgTransition: background 500ms;
$overlayTransition: all 0.3s ease-in-out;
// Social
$socialNetworks: (
	facebook: icon-facebook_tag,
	instagram: icon-instagram,
	twitter: icon-twitter,
	pinterest: icon-pinterest,
);
// z-index values
$overlay-z-index: 10;
$navigation-z-index: $overlay-z-index - 1;
// activity calendar
$ac-grey-1: #686868;
$ac-grey-2: #f7f7f7;
$ac-grey-3: #ececec;
$ac-off-black: #1d1d1b;
$ac-teal: #007b8a;
$ac-heading-color: $ac-grey-1;
$ac-background-color: $ac-grey-2;
$ac-border-color: $ac-grey-3;
$ac-link-color: $ac-teal;
$ac-primary-font: helvetica, sans-serif;
$ac-primary-font-weight: 200;
$ac-primary-text-color: $ac-off-black;
$ac-primary-font-size: 16px;
$ac-screen-xs: 322px;
$ac-screen-sm: 540px;
$ac-screen-md: 720px;
$ac-screen-lg: 960px;
$ac-screen-xl: 1140px;

//Luxury Love Language
$lll-green: #175250;
$lll-green-hover: #000c0b;
$lll-lightgreen: #f3ffd1;
$lll-lightgreen-hover: #ffffff;
$lll-pastelgreen: #bfdcd1;
$lll-pink: #fbe4ee;
$lll-pink-hover: #ffffff;
$lll-blue: #0f587c;
$lll-lightblue: #dff3fb;
$lll-red: #d10023;
$lll-red-hover: #8f0018;
$lll-yellow: #ffc550;
$lll-lightyellow: #f9d8a1;
$lll-orange: #a72906;
$lll-orange-hover: #651904;
$lll-lightorange: #f3ad9f;
