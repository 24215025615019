.surface {
	&-sticky {
		background: var(--color-surface-sticky, #2d2d2d);

		/* shadow/sticky */
		box-shadow: 0px 15px 35px 0px rgba(3, 3, 3, 0.4);
	}
	&-translucent {
		background: var(--color-surface-translucent, rgba(45, 45, 45, 0.6));

		/* blur/heavy-30 */
		backdrop-filter: blur(15px);
	}
	&-page {
		background: var(--color-surface-default, #0f0f0f);
	}
	&-raised-subtle {
		background: var(--color-surface-raised-subtle, #1f1f1f);
		box-shadow: var(--surface-raised-subtle-shadow, none);
	}
	&-raised {
		background: var(--color-surface-raised-default, #2d2d2d);
		box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
	}
	&-overlay-raised {
		background: var(--color-surface-overlay-raised, #3d3d3d);
		/* shadow/raised */
		box-shadow: 0px 5px 15px 0px rgba(3, 3, 3, 0.12);
	}
	&-overlay {
		border-radius: 10px;
		background: var(--color-surface-overlay-default, #2d2d2d);
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
	}

	&-recessed {
		background: var(--color-surface-recessed, #030303);
		box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.04) inset;
	}
}
