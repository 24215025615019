.SubscriptionsHero-heading.StandardHero-heading {
	.Heading-title {
		@include viewport(large) {
			font-size: 2.25rem;
		}

		@include viewport(xlarge) {
			font-size: 3rem;
		}
	}
}
