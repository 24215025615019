.ReactCAW {
	&.loading {
		.cawComponent {
			display: flex;
			justify-content: center;
			padding: 0 !important;
			.LoadingIndicator-image {
				height: 100%;
				width: auto;
			}
		}
	}
	.DateField {
		cursor: pointer;
		width: 100%;
		.DateField_clear {
			top: -28px;
		}
	}
	&.caw-comp__wrapper {
		.DateField_clear {
			top: -31px;
			right: 10px;
			@include rtl {
				right: auto;
				left: 10px;
			}
		}
		.PromoCode-container .icon-close {
			font-size: 15px;
			position: relative;
			top: -7px;
			right: 5px;
		}
		@include media($navigationBreakpoint) {
			.textInput__field.ty-h4:disabled {
				background: $gray3;
			}
		}
		.PromoCode-warning-container {
			.up-pointer {
				@include media($navigationBreakpoint) {
					color: $gray4;
				}
			}
			.PromoCode-warning {
				display: flex;
				gap: 10px;
				padding-top: 10px;
				font-style: normal;
				@include media($navigationBreakpoint) {
					background: $gray4;
					color: black;
					border: none;
				}
				.icon {
					@include media($navigationBreakpoint) {
						font-size: 24px;
					}
				}
			}
		}
	}

	&.MobileReactCaw.Dialog-wrapper {
		border-radius: 0;
		.Dialog-closebutton {
			display: none;
		}
		.Dialog-content {
			padding: 25px 0 0 0;
			gap: 0;
			.Dialog-content-title {
				.Step {
					width: 100%;
					padding-bottom: 25px;
					position: relative;
					text-align: center;
					display: inline-block;
					&::after {
						display: none;
					}
					button.Step-back {
						font-size: 14px;
						position: absolute;
						left: 45px;
						@include viewport($navigationBreakpoint) {
							left: 0;
						}
						@include rtl {
							left: auto;
							right: 45px;
							@include viewport($navigationBreakpoint) {
								left: auto;
								right: 0;
							}
						}
					}
					button.Step-forward {
						font-size: 14px;
						position: absolute;
						right: 45px;
						@include viewport($navigationBreakpoint) {
							right: 0;
						}
						@include rtl {
							right: auto;
							left: 45px;
							@include viewport($navigationBreakpoint) {
								right: auto;
								left: 0;
							}
						}
						.icon-caret_down {
							font-size: 10px;
						}
					}
				}
				.CAW-progress {
					width: calc(100% + 40px);
					margin: 0 -20px;
					border-radius: 0;
					appearance: none;
					-webkit-appearance: none;
					overflow: hidden;
					height: 2px;
					background-color: $gray3;
					&::before {
						content: '';
						display: block;
						height: 2px;
						background-color: $gray2;
						transition: width 0.5s;
					}
				}
				.CAW-progress-25 {
					&::before {
						width: 25%;
					}
				}
				.CAW-progress-50 {
					&::before {
						width: 50%;
					}
				}
				.CAW-progress-75 {
					&::before {
						width: 75%;
					}
				}
				.CAW-progress-100 {
					&::before {
						width: 100%;
					}
				}
				&::after {
					display: none;
				}
			}
			.Dialog-content-content {
				padding: 0;
				flex-grow: 1;
				.DestinationPicker {
					margin-top: 20px;
					.ReactTypeahead {
						.ReactTypeahead-input {
							position: relative;
							margin-right: 15px;
							margin-left: 15px;
							input.formElement-field {
								background: $gray4;
								border: 1px solid $gray3;
							}
							.icon {
								position: absolute;
								right: 18px;
								top: 11px;
								font-size: 22px;
							}
							.icon-close {
								font-size: 15px;
							}
						}
						li.ReactTypeahead-item {
							margin-left: 20px;
							margin-right: 20px;
							height: 80px;
							border-bottom: 1px solid $gray3;
							.DestinationPicker-item {
								height: 100%;
								width: 100%;
								display: flex;
								align-items: center;
								position: relative;
								text-align: left;
								justify-content: center;
								flex-direction: column;
								align-items: flex-start;
								gap: 7px;
								.DestinationPicker-item-row {
									justify-content: space-between;
									display: flex;
									align-items: center;
									width: 100%;
								}
								.icon {
									position: relative;
									right: 0;
									top: 0;
									font-size: 15px;
									padding-right: 15px;
									@include rtl {
										right: auto;
										left: 0;
									}
								}
								&:focus,
								&:focus-visible {
									outline: none;
								}
							}

							&.selected {
								background: $gray1;
								color: white;
								margin-right: -20px;
								margin-left: -20px;
								padding-left: 40px;
								padding-right: 40px;
							}
						}
					}
				}
			}
		}
	}
}
.caw-popup__wrapper.caw-ppc__wrapper .App-wrapper .container .row {
	.cawComponent.singleProperty .cawFieldsContainer {
		.Calendar-root {
			position: relative;
			.DateField_clear {
				position: absolute;
				top: 28px;
				right: 15px;
			}
		}
	}
	.hideButton {
		display: none;
	}
}
.caw-comp__wrapper .App-wrapper .container .row {
	.Calendar-container {
		.Inner-Dialog.Calendar-container,
		.Dialog.Calendar-container {
			.Calendar-dialog.Dialog-wrapper {
				@include viewport(xlarge) {
					min-width: 990px;
				}
				.Dialog-content.Calendar-row.Calendar-row--active {
					.Dialog-content-content {
						.Calendar {
							.DayPicker_container,
							.CalendarDay_container {
								@include viewport(xlarge) {
									height: 58px;
									width: 58px;
								}
							}
							.Calendar-Done {
								padding-top: 5px;
							}
						}
					}
				}
			}
		}
	}
}
.PPCHero .Hero {
	overflow: visible;
}
.Calendar-container {
	position: relative;
	.Inner-Dialog.Calendar-container,
	.Dialog.Calendar-container {
		top: 40px;
		position: absolute;
		.Calendar-dialog.Dialog-wrapper {
			min-width: 600px;
			height: fit-content;
			.Dialog-content.Calendar-row.Calendar-row--active {
				.Dialog-content-content {
					.Calendar {
						width: 100%;
						display: block;
						overflow: hidden;
						.CalendarMonthGrid,
						.CalendarMonthGrid_month__horizontal,
						.CalendarMonth {
							width: 100%;
						}
						.DayPicker_container,
						.CalendarDay_container {
							width: 37px;
							height: 37px;
						}
						.DayPicker_transitionContainer {
							overflow: visible;
							height: auto;
							@include viewport(xlarge) {
								min-height: 540px;
							}
						}
						.CalendarMonth {
							.CalendarMonth_caption {
								padding-bottom: 30px;
							}
						}

						.ReactCarousel {
							.ReactCarousel-item {
								justify-content: flex-start;
								@include media($navigationBreakpoint) {
									width: 50%;
								}
							}
							.ReactCarousel-nav-button {
								position: absolute;
								top: 30px;
								text-decoration: none;
								color: black;
								width: 35px;
								height: 35px;
								text-align: center;
								z-index: 2;
								&.ReactCarousel-nav-button-prev {
									left: 22px;
								}
								&.ReactCarousel-nav-button-next {
									right: 22px;
								}
							}
						}
					}
				}
			}
		}
	}
}
.invisible {
	visibility: hidden;
}
.cawComponent-mobile.cawComponent {
	.LoadingIndicator-image {
		width: 100%;
		text-align: center;
	}
	&.isOffline {
		.Overlay_caw_wrap {
			padding-top: 15px;
			flex-direction: column;
			gap: 15px;
			.Overlay_caw_contents {
				margin: 0;
				max-width: none;
			}
		}
		.Overlay_caw_button {
			top: 0;
			transform: none;
		}
	}
}

.caw-comp__wrapper.MobileReactCaw {
	.MobileCAWDialog {
		.Calendar-container {
			position: relative;
			&.Calendar-mobile {
				position: relative;
				max-width: 100vw; /* fallback */
				max-width: fill-available;
				margin-top: 42px;
				.Inner-Dialog.Calendar-container,
				.Dialog.Calendar-container {
					top: 0;
					position: relative;
					width: 100%;
					.Calendar-dialog.Dialog-wrapper {
						min-width: auto;
						width: 100%;
						.Dialog-content.Calendar-row.Calendar-row--active {
							.Dialog-content-content {
								.Calendar {
									.CalendarMonthGrid,
									.CalendarMonthGrid_month__horizontal,
									.CalendarMonth {
										width: auto;
										position: relative;
										margin: 0 auto;
										padding: 0;
										.CalendarMonth_caption {
											padding-top: 10px;
											padding-bottom: 10px;
											text-align: left;
										}
										.CalendarMonth_table {
											width: auto;
										}
										.ReactCarousel
											.ReactCarousel-carousel
											.ReactCarousel-items {
											flex-direction: column;
											scroll-snap-type: none;
											scroll-behavior: auto;
										}
									}
									.DayPicker_container,
									.CalendarDay_container {
										width: 45px;
										height: 45px;
										&.CalendarDay__blocked_calendar:not(
												.CalendarDay__selected
											) {
											background-color: white;
										}
									}
									.DayPicker {
										background-color: white;
									}
									.DayPicker_transitionContainer {
										display: flex;
										flex-direction: column;
										align-items: center;
									}
								}
							}
						}
					}
				}
			}
		}
		.WeekHeader {
			padding: 10px 0;
			border-bottom: 1px solid $gray3;
			position: fixed;
			width: 100%;
			z-index: 1000;
			top: 67px;
			background-color: white;
			table {
				margin: auto;
				width: 315px;
				.DayPicker_container {
					width: 45px;
					text-align: center;
				}
			}
		}

		.MobileReactCaw-footer-nav {
			display: flex;
			padding: 15px 20px;
			justify-content: space-between;
			border-top: 1px solid $gray3;
			.MobileReactCaw-footer-nav-back {
				text-transform: uppercase;
				height: 15px;
				align-self: center;
			}
			.MobileReactCaw-footer-nav-next {
				width: 190px;
				height: 40px;
			}
			.MobileReactCaw-footer-nav-submit {
				height: 40px;
				width: 100%;
			}
			.MobileReactCaw-footer-nav-next,
			.MobileReactCaw-footer-nav-submit {
				&:hover {
					background-color: black;
					color: white;
				}
			}
		}
		.Flex-Dates {
			padding: 0;
			border-top: 1px solid $gray3;
			.Flex-Dates-inner {
				text-align: center;
				.Flex-Dates-label {
					@include typography(c4);
				}
				.flexdates-button {
					min-width: 60px;
					padding: 8px 10px;
					margin-bottom: 10px;
				}
			}
		}
		.Calendar-Meta {
			margin: 0;
			padding: 0;
			bottom: auto;
			transition: none;
			position: relative;
			.Calendar-Meta-inner,
			.legend-list {
				&::before,
				&::after {
					content: none;
				}
			}
			.Calendar-Meta-inner {
				.legend-list {
					justify-content: center;
					.legend-item {
						flex: none;
						&:first-child {
							padding-right: 15px;
						}
					}
				}
				.Calendar-updated {
					text-align: center;
					width: 100%;
				}
			}
		}
		.Occupancy-container {
			.Occupancy-dialog.OccupancyField_row {
				width: 100%;
				.Occupancy-wrapper {
					width: 100%;
					min-width: auto;
					background-color: white;
					.RoomOption-container {
						.roomoptions-contact {
							padding: 20px;
							display: inline-block;
						}
						.guests-dropdown-option {
							height: auto;
							.RoomOption-title {
								padding-left: 20px;
							}
							.RoomOption-fields {
								background: $gray4;
								display: flex;
								flex-direction: column;
								margin: 10px 20px;
								padding: 0 15px;
								.RoomOption-adult,
								.RoomOption-child {
									width: 100%;
									display: flex;
									height: 65px;
									justify-content: space-between;
									align-items: center;
									padding: 0;
								}
								.RoomOption-adult {
									border-bottom: 1px solid $gray3;
								}
								.RoomOption-controls {
									display: flex;
									width: 110px;
									justify-content: space-between;
									align-items: center;
									.increment-button,
									.decrement-button {
										margin: 0;
										height: 35px;
										padding: 10px;
									}
								}
								.RoomOption-adultstr,
								.RoomOption-childstr {
									padding: 0;
									@include typography(c3);
									.RoomOption-age {
										display: block;
									}
								}
							}
							.remove-button {
								margin-right: 14px;
								margin-top: -24px;
								&::after {
									font-size: 14px;
								}
							}
						}
						.roomoptions-add-button {
							width: 100%;
							display: flex;
							justify-content: center;
						}
					}
				}
			}
		}
		.Summary-container {
			.Summary-field {
				margin: 10px 20px;
				padding: 15px;
				background: $gray4;
				position: relative;
				display: flex;
				justify-content: space-between;
				min-height: 75px;
				align-items: center;
				text-align: initial;
				.Summary-content {
					display: flex;
					flex-direction: column;
					.Summary-label {
						padding-bottom: 5px;
					}
				}
				.Summary-edit-button {
					align-self: center;
					height: 21px;
				}
				&.Promo-summary {
					flex-direction: column;
					align-items: normal;
					.PromoCode-container {
						height: auto;
						width: 100%;
						padding: 0;
					}
					.textInput,
					.textInput__field {
						background: white;
						width: 100%;
						height: 47px;
						&[disabled] {
							background: #d8d8d8;
						}
					}
				}
			}
		}
		.cawComponent-mobile,
		.Dialog-content-footer {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
		}
		.MobileCAWDialog-section {
			transform: translateX(0%);
			grid-column: 1 /1;
			grid-row: 1 / 1;
			&.MobileCAWDialog-section-footer {
				align-self: end;
			}
			&.MobileCAWDialog-right {
				transform: translateX(100%);
			}
			&.MobileCAWDialog-left {
				transform: translateX(-100%);
			}
			&.MobileCAWDialog-transition {
				transition: transform 0.5s;
			}
		}
	}
}
.Inner-Dialog.Calendar-container,
.Dialog.Calendar-container,
.Dialog.Occupancy-container {
	.Dialog-wrapper {
		min-height: 200px;
		width: auto;
		margin: 0;
		padding: 0;
		position: relative;
		overflow: visible;
		.Dialog-content {
			padding: 0;
			margin: 0;
			width: 100%;
			max-height: 100vh;
			overflow: visible;
			position: relative;
			.Dialog-content-content {
				background: white;
				overflow: visible;
			}
		}
		.Dialog-closebutton {
			display: none;
		}
	}
	.Dialog-overlay {
		display: none;
	}
}
.OccupancyField_row {
	position: relative;
	.Dialog.Occupancy-container {
		width: 425px;
		position: absolute;
		top: 40px;
		.Occupancy-dialog.Dialog-wrapper {
			width: 525px;
			height: fit-content;
			.Dialog-content.Occupancy-content {
				padding: 30px;
				.Dialog-content-content {
					background: $gray6;
				}
			}
		}
	}
}

.booking-flow-mode {
	.Navigation-sticky-footer-gradient,
	.Navigation-sticky-footer {
		display: none;
	}
	.Navigation-sm.nav-sm {
		flex-wrap: wrap;
		max-width: 100vw;
		height: 6rem;
		transition-property: all;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 500ms;
		a {
			flex-basis: calc(100% - 30px);
			@include rtl {
				flex-basis: 30px;
			}
		}
		.Navigation-menuButton {
			flex-basis: 30px;
			height: 60px;
			@include rtl {
				flex-basis: calc(100% - 30px);
			}
		}
		.Summary-container {
			border-top: 1px solid $gray3;
			display: flex;
			justify-content: space-between;
			padding: 15px;
			width: calc(100% + 40px);
			max-width: 100vw;
			margin: 0 -20px;
			background-color: #fff;
			border-bottom: 1px solid #d8d8d8;
			.Summary-field {
				display: flex;
				flex-direction: column;
				.Summary-content {
					line-height: 1em;
					.ty-c3 {
						padding-bottom: 10px;
						display: inline-block;
					}
				}
			}
			.icon {
				font-size: 16px;
				vertical-align: middle;
			}
			.Summary-cta {
				width: 72px;
				height: 30px;
				align-self: flex-end;
				min-width: 72px;
			}
		}
	}
}
