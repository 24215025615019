.LongFormArticlePage {
	.StandardHero {
		&-title {
			.ty-h4 {
				@include typography(c1);
			}
		}
		&-breadcrumb {
			margin-bottom: 30px;
		}
		.Heading {
			&-title {
				text-transform: none;
				font-size: 3rem;
				line-height: 1.3em;
				font-weight: 200;
				letter-spacing: normal;
			}

			&-eyebrow {
				@include typography(c1);
				font-weight: 400;
			}
		}
	}

	.ImageAndCTABlockWithEyebrows {
		&-imageWrapper {
			.Image-picture {
				position: relative;
			}
		}
	}

	.Text-Box {
		text-align: left;
	}
}
