.SocialLinks {
	&-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto;
		max-width: 70%;

		@include viewport(medium) {
			max-width: none;
		}
	}
}

.SocialLink {
	display: block;
	height: 48px;
	margin: 0 10px;
	text-decoration: none;
	text-align: center;

	// Needed to override some styles set by iconButton mixin.
	&[class*='SocialLink--'] {
		width: auto;
		padding: 0 10px;

		.BodySection--light & {
			@include grayHover('light', before);

			&::before {
				color: $black;
			}
		}
	}

	&--twitter {
		@include iconButton(before, 'icon-twitter', 'dark');
		font-size: 15px;
	}

	&--facebook {
		@include iconButton(before, 'icon-facebook', 'dark');
		font-size: 16.5px;
	}

	&--tripadvisor {
		@include iconButton(before, 'icon-tripadvisor', 'dark');
		font-size: 16.5px;
	}

	&--youtube {
		@include iconButton(before, 'icon-youtube', 'dark');
		font-size: 14px;
	}

	&--pinterest {
		@include iconButton(before, 'icon-pinterest', 'dark');
		font-size: 16.5px;
	}

	&--googleplus {
		@include iconButton(before, 'icon-googleplus', 'dark');
		font-size: 16.5px;
	}

	&--instagram {
		@include iconButton(before, 'icon-instagram', 'dark');
		font-size: 15px;
	}

	&--foursquare {
		@include iconButton(before, 'icon-foursquare', 'dark');
		font-size: 15px;
	}

	&--weibo {
		@include iconButton(before, 'icon-weibo', 'dark');
		font-size: 15px;
	}

	&--youku {
		@include iconButton(before, 'icon-youku', 'dark');
		font-size: 15px;
	}

	&--douban {
		@include iconButton(before, 'icon-douban', 'dark');
		font-size: 15px;
	}

	&--qq {
		@include iconButton(before, 'icon-qq', 'dark');
		font-size: 15px;
	}

	&--wechat {
		@include iconButton(before, 'icon-wechat', 'dark');
		font-size: 15px;
	}
}
