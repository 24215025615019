.ThumbnailWithDescription {
	display: flex;
	gap: 15px;
	width: 100%;

	h3 {
		margin: 0;
	}
	&-image {
		position: relative;
		display: block;
		height: 130px;
		min-width: 104px;
		@include viewport(medium) {
			height: 187.5px;
			min-width: 150px;
		}
	}

	&-description {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.text {
			margin: 10px 0;
			@include viewport(medium) {
				margin: 15px 0;
			}
		}
	}
}
