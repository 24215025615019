.RadioButtons {
	float: inherit;
	display: flex;
	flex-wrap: wrap;
	justify-content: left;
	&--horizontal {
		flex-direction: row;
	}
	&--vertical {
		flex-direction: column;
	}
	&-input {
		display: block;
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;

		&:focus {
			outline: none;
		}
	}
	&-button {
		display: flex;
		align-items: center;
		margin: 10px 0;
		&:not(:last-child) {
			margin-right: 60px;

			@include rtl {
				margin-right: 0;
				margin-left: 60px;
			}
		}
	}

	&-decoration {
		display: block;
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		border: 1px solid $gray2;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
		margin-right: 20px;
		line-height: 1;
		transition: background-color 0.3s;

		@include rtl {
			margin-right: 0;
			margin-left: 20px;
		}

		.RadioButtons--dark & {
			border-color: $white;
		}

		.RadioButtons-input:disabled ~ & {
			opacity: 0.4;
		}

		.RadioButtons-input:focus ~ & {
			border: 2px solid $black;
			.RadioButtons--dark & {
				border: 2px solid $white;
			}
		}

		&::after {
			content: '';
			position: absolute;
			top: 4px;
			left: 4px;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			opacity: 0;
			background-color: $black;
			speak: none;
			transition: opacity 0.3s;

			.RadioButtons--dark & {
				background-color: $white;
			}

			.RadioButtons-input:checked ~ & {
				opacity: 1;
			}

			.RadioButtons-input:focus ~ & {
				top: 3px;
				left: 3px;
			}
		}
	}

	&-label {
		display: flex;
		align-items: center;
		.RadioButtons-input:disabled ~ & {
			opacity: 0.4;
		}
	}
}
