.VideoAndTable {
	&-container {
		margin: auto;
		padding: 16px 15px;
		box-shadow: $lightBoxShadow;

		.VideoAndTable--light & {
			background-color: $black;
			color: $white;
		}

		.VideoAndTable--dark & {
			background-color: $white;
			color: $black;
		}
	}

	&-video {
		position: relative;
		margin-bottom: 1rem;

		@include viewport(large) {
			margin-bottom: 0;
		}
	}

	&-heading {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
	}

	&-title {
		margin: 0;

		@include typography('h3');
		font-size: 16px;
		letter-spacing: 3px;

		@include viewport(medium) {
			font-size: 18px;
			line-height: 1.5em;
			letter-spacing: 4px;
		}
	}

	&-text {
		flex: 100% 0 0;
		width: 100%;
		margin: 1rem -1em 1rem 0;
		padding-right: 1em;

		@include rtl {
			margin: 1rem 0 1rem -1em;
			padding-right: 0;
			padding-left: 1em;
		}

		@include viewport(large) {
			margin-bottom: 25px;
			margin-top: 25px;
		}
	}

	&-cta {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 25px;

		.VideoAndTable--light & {
			border: 1px solid $white;
		}

		.VideoAndTable--dark & {
			border: 1px solid $black;
		}

		@include rtl {
			left: -50%;
			transform: translateX(50%);
		}

		@include viewport(large) {
			left: 0;
			transform: none;

			@include rtl {
				left: 0;
				transform: none;
			}
		}
	}

	&-content {
		@include viewport(large) {
			padding-bottom: 0;
		}
	}

	&-table {
		display: none;

		&.large-only {
			margin-top: 20px;

			@include viewport(large) {
				display: block;
			}

			@include viewport(xlarge) {
				display: none;
			}
		}

		&.xlarge-only {
			margin-top: 25px;

			@include viewport(xlarge) {
				display: block;
			}
		}

		.row > & {
			padding-right: 15px;
			padding-left: 15px;
		}

		.TableList {
			margin-bottom: 1rem;

			@include viewport(medium) {
				margin-bottom: 0;
			}

			&-items {
				margin: 0;

				.TableList-item {
					padding: 15px 0;

					&-description-text {
						line-height: 1.33333em;
					}
				}

				& > .TableList-item:last-of-type {
					padding-bottom: 0;
				}
			}
		}
	}

	.VideoPlaceholderImage-button {
		width: 50px;
		height: 50px;
		&::before {
			font-size: 50px;
		}

		@include viewport(large) {
			width: 40px;
			height: 40px;
			&::before {
				font-size: 40px;
			}
		}

		@include viewport(xlarge) {
			width: 55px;
			height: 55px;
			&::before {
				font-size: 55px;
			}
		}
	}
}
