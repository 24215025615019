.PersonHighlight {
	padding: 24px 15px;
	width: calc(100% - 30px);
	box-shadow: $lightBoxShadow;

	&--light {
		background-color: $black;
		color: $white;
	}

	&--dark {
		background-color: $white;
		color: $black;
	}

	@include viewport(medium) {
		display: flex;
		padding: 36px 15px;
	}

	@include rtl {
		text-align: right;
	}

	&-picture {
		position: relative;
		margin: auto;
		margin-bottom: 1rem;
		width: 100%;
		max-width: 135px;

		img {
			width: 100%;
			max-width: 100%;
		}

		@include viewport(medium) {
			max-width: 160px;
			margin: 0 calc(20px + 15px) 0 20px;

			@include rtl {
				margin: 0 20px 0 calc(20px + 15px);
			}
		}

		@include viewport(large) {
			max-width: 220px;
			margin: 0 calc(40px + 15px) 0 40px;

			@include rtl {
				margin: 0 40px 0 calc(40px + 15px);
			}
		}

		@include viewport(xlarge) {
			margin: 0 calc(100px + 15px) 0 100px;

			@include rtl {
				margin: 0 100px 0 calc(100px + 15px);
			}
		}

		.Image {
			position: relative;

			@include viewport(medium) {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&-cta {
		@include viewport(large) {
			margin-right: 100px;

			@include rtl {
				margin-right: 0;
				margin-left: 100px;
			}
		}

		.CtaBlock-title {
			text-align: center;

			@include typography('h3');
			letter-spacing: 3px;
			line-height: 24px;

			@include viewport(medium) {
				text-align: left;
				font-size: 18px;
				line-height: 1.5em;
				letter-spacing: 4px;
				font-weight: bold;

				@include rtl {
					text-align: right;
				}
			}
		}

		.CtaBlock-text {
			margin-top: 16px;
			margin-bottom: 16px;

			// Desktop text
			&--primary {
				display: none;

				@include viewport(medium) {
					display: block;
				}
			}

			// Mobile text
			&--alternate {
				text-align: center;

				@include viewport(medium) {
					display: none;
				}
			}
		}
	}

	&-socialLinks {
		.SocialLinksGeneral-container {
			display: flex;
			flex-direction: row;
			justify-content: center;

			@include viewport(medium) {
				justify-content: flex-start;
				margin-left: -15px;
			}

			.SocialLink {
				&[class*='SocialLink--'] {
					margin: 0 15px;
					padding: 0;
				}

				&::before {
					.PersonHighlight--light & {
						color: $white;
					}

					.PersonHighlight--dark & {
						color: $black;
					}
				}
			}
		}
	}
}
