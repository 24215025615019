.DiningOutletCard {
	background: var(--color-surface-raised-subtle, #f5f5f5);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	height: 100%;

	&-featured-tag {
		color: var(--color-text-primary, #1f1f1f);
		font-feature-settings: 'clig' off, 'liga' off;
		position: absolute;
		left: 4px;
		top: 4px;
		background: var(--color-surface-default, #fff);
		/* blur/heavy-30 */
		backdrop-filter: blur(15px);
		padding: var(--spacing-040, 4px) var(--spacing-060, 6px);
		@include viewport(medium) {
			left: 6px;
			top: 6px;
			padding: var(--container-padding-x-small, 6px) var(--container-padding-small, 10px);
		}
	}

	&-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
		align-self: stretch;
		justify-content: space-between;
		height: 100%;
		padding: 15px;

		@include viewport(large) {
			padding: 20px;
		}
		@include viewport(xlarge) {
			padding: 25px;
		}
	}

	&-heading {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: left;
		width: 100%;
		gap: 5px;
	}

	&-title {
		margin-bottom: 5px;
	}

	&-icon,
	&-iconText,
	&-description {
		color: var(--color-text-secondary, #4d4d4d);
	}

	&-icon {
		width: 16px;
		height: 16px;
		font-size: 16px;
		line-height: 21px;
	}

	&-subtitle {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 6px;
	}

	&-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 15px;
		align-self: stretch;
	}

	&-description {
		display: none;
		@include viewport(medium) {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			align-self: stretch;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			@include rtl {
				text-align: right;
			}
		}
	}

	&-learnMore {
		width: 100%;
		text-decoration: none;
	}
}
