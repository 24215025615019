.ContactIntro {
	&-container {
		text-align: center;
	}

	&-title {
		margin-top: 0;
		margin-bottom: 40px;

		@include viewport(medium) {
			margin-bottom: 60px;
		}

		@include viewport(medium) {
			margin-bottom: 70px;
		}
	}

	&-image {
		margin: 0 auto;
	}

	&-description {
		margin-top: 30px;
		margin-bottom: 40px;
	}

	&-info {
		display: block;

		.ContactIntro--noDescription &,
		.ContactIntro--noImage & {
			margin-top: 40px;

			@include viewport(medium) {
				margin-top: 60px;
			}

			@include viewport(medium) {
				margin-top: 70px;
			}
		}
	}

	&-contact {
		border-top: 1px solid $separatorDark;
		margin-top: 20px;
		margin-bottom: 35px;
		padding-top: 10px;

		@include viewport(medium) {
			display: flex;
			justify-content: space-around;
			margin-bottom: 60px;
		}
	}

	&-emailContainer {
		margin-bottom: 25px;

		@include viewport(medium) {
			margin-bottom: 0;
			order: 1;
		}
	}

	&-phoneContainer {
		margin-bottom: 25px;

		@include viewport(medium) {
			margin-bottom: 0;
		}
	}

	&-email,
	&-phone {
		display: inline-block;
	}
}
