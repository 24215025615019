.Message404 {
	&-heading {
		text-align: center;
		margin-top: 0;
		margin-bottom: 30px;
	}

	&-text {
		text-align: center;
		margin-bottom: 40px;

		@include viewport(medium) {
			padding: 0 4%;
			margin-bottom: 60px;
		}

		@include viewport(large) {
			padding: 0 8%;
		}

		@include viewport(xlarge) {
			padding: 0 12%;
		}
	}

	&-ctaContainer {
		display: flex;
		flex-direction: column;

		@include viewport(medium) {
			flex-direction: row;
			justify-content: center;
		}
	}

	&-CTA {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		height: 40px;
		margin-bottom: 20px;

		&:last-of-type {
			margin-bottom: 0;
		}

		.CTA {
			width: 100%;
		}

		@include viewport(medium) {
			width: 245px;
			margin: 0 15px;
			&:first-of-type {
				margin-left: 0;
			}
			&:last-of-type {
				margin-right: 0;
			}

			@include rtl {
				&:first-of-type {
					margin-left: 15px;
				}
				&:last-of-type {
					margin-right: 15px;
				}
			}
		}
	}
}
