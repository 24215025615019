.RandomSlidesList {
	&-Slides {
		display: none;

		@include viewport(medium) {
			display: block;
		}
	}

	&-MobileCarousel {
		display: block;
		overflow: hidden;

		@include viewport(medium) {
			display: none;
		}

		&--container {
			margin: 0 22.5px;
		}
	}

	&-GenericSlide {
		min-width: 180px;

		@include viewport(medium) {
			min-width: none;
		}

		.ImageAndCta-picture {
			margin-bottom: 10px;

			@include viewport(medium) {
				margin-bottom: 20px;
			}
		}

		.CTA {
			display: flex;
		}

		&-Description {
			display: none;

			@include viewport(medium) {
				display: block;
				margin-top: 10px;

				& > p {
					margin-top: 0;
				}
			}
		}
	}

	&--dark {
		.RandomSlidesList-GenericSlide {
			.CTA {
				@include link('arrow', 'dark');
			}
		}
	}
}
