.PropertiesListing {
	&-Heading {
		margin-bottom: 30px;

		@include viewport(medium) {
			margin-bottom: 40px;
		}

		@include viewport(large) {
			margin-bottom: 30px;
		}
		.Heading {
			text-align: center;

			&-title {
				margin-top: 0;
				margin-bottom: 0;

				@include viewport(large) {
					margin-bottom: 10px;
				}

				@include viewport(xlarge) {
					font-size: rem(44, 16);
					letter-spacing: em(6, 44);
					line-height: em(62, 44);
				}
			}

			&-subtitle {
				margin-bottom: 30px;
			}
		}
	}

	.RandomSlidesList-MobileCarousel {
		overflow: visible;

		&--container {
			margin: 0 -7.5px;
		}
	}

	.LinksList-linkContainer {
		margin-bottom: 20px;
		.CTA {
			@include typography(c2);
			display: inline;
			border-bottom: 1px solid;
			text-decoration: none;
			margin-bottom: 0;
		}

		position: relative;
		padding-left: 19px;
		padding-bottom: em(5.5, 18);
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 8px;
			border: 2px solid;
			border-radius: 10px;
		}

		@include rtl {
			padding-left: 0;
			padding-right: 15px;

			&::before {
				left: auto;
				right: 0;
			}
		}
	}
}
