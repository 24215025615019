.SwitchField {
	&-switch {
		position: relative;
		display: inline-block;
		width: 3em;
		height: 1.5em;
		vertical-align: middle;
	}

	&-checkbox {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked + .SwitchField-slider::before {
			-webkit-transform: translateX(1.5em);
			-ms-transform: translateX(1.5em);
			transform: translateX(1.5em);
		}
	}

	&-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $gray3;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 1.5em;

		&::before {
			position: absolute;
			content: '';
			height: 1.5em;
			width: 1.5em;
			background-color: $black;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			border-radius: 50%;
		}
	}
}
