.ImageAndTextCollage {
	&-heading {
		text-align: center;
		.ty-h2 {
			text-transform: none;
			font-size: 2rem;
			font-weight: 100;
			letter-spacing: 0.0725rem;
			line-height: 2.4375rem;

			@include viewport(medium) {
				font-size: 3.375rem;
				letter-spacing: 0.0625rem;
				line-height: 4rem;
				margin-bottom: 60px;
			}
		}
	}

	.ImageAndTextCollageItem {
		&-container {
			display: flex;
			flex-direction: column-reverse;

			@include viewport(medium) {
				align-items: center;
				flex-direction: row;
				margin-bottom: 5%;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}

		&-image-container {
			@include viewport(medium) {
				width: 45%;
				margin-right: 5%;

				@include rtl {
					margin-right: 0;
					margin-left: 5%;
				}
			}
		}

		&-text {
			&-container {
				width: 83.33333%;
				margin-left: 8.33333%;

				@include rtl {
					margin-right: 8.33333%;
					margin-left: 0;
				}

				@include viewport(medium) {
					width: 45%;
					margin-right: 0;
					margin-left: 5%;

					@include rtl {
						margin-right: 5%;
						margin-left: 0;
					}
				}
			}

			text-align: center;
			font-family: $serifFont;
			letter-spacing: 0.03125rem;
			line-height: 2.0625rem;
			font-size: 1.325rem;

			@include viewport(medium) {
				text-align: left;
				font-family: $sansFont;
				font-weight: 100;

				@include viewport(large) {
					letter-spacing: 0.036875rem;
					line-height: 3.125rem;
					font-size: 2rem;
				}

				@include rtl {
					text-align: right;
				}
			}
		}

		&--right-aligned {
			@include viewport(medium) {
				flex-direction: row-reverse;
			}
			.ImageAndTextCollageItem {
				&-image-container {
					@include viewport(medium) {
						margin-right: 0;
						margin-left: 5%;

						@include rtl {
							margin-right: 5%;
							margin-left: 0;
						}
					}
				}

				&-text {
					&-container {
						@include viewport(medium) {
							margin-right: 5%;
							margin-left: 0;

							@include rtl {
								margin-right: 0;
								margin-left: 5%;
							}
						}
					}

					@include viewport(medium) {
						text-align: right;

						@include rtl {
							text-align: left;
						}
					}
				}
			}
		}
	}
}
