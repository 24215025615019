.Image-ratioContainer {
	position: relative;
	width: 100%;

	&::after {
		content: '';
		display: block;
		height: 0;
		width: 100%;
	}

	& > .Image-picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
	}

	.Image-img {
		width: 100%;
		height: 100%;
	}
}

.ImageCircle-img {
	border-radius: 50%;
}

.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 0.3s;
}

.fade-in {
	opacity: 0.3;

	&.fade-in-completed {
		opacity: 1;
		transition: opacity 0.3s;
	}
}
