.Menu {
	.col-md-6:nth-child(odd) {
		@include viewport(medium) {
			clear: both;
		}
	}

	&-section-header {
		text-align: center;

		@include clearfix;
	}

	&-section {
		position: relative;
		padding-top: 50px;
		padding-bottom: 20px;

		&::before {
			content: '';
			position: absolute;
			border-top: 1px solid;
			top: 0;
			left: 0;
			height: 1px;
			width: 100%;
		}

		@include viewport(large) {
			padding-bottom: 40px;

			&::before {
				width: percentage(10/12);
				left: percentage(2/12) / 2;
			}
		}

		@include viewport(xlarge) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		.Heading-title {
			@include viewport(medium) {
				margin-bottom: 35px;
			}
		}

		&-options {
			font-style: italic;
			text-align: center;

			@include clearfix;

			@include viewport(medium) {
				margin-top: 12px;
				margin-bottom: 12px;
			}
		}

		&-price {
			@include clearfix;

			text-align: center;
		}

		&-list {
			margin-top: 55px;
		}
	}

	&-item {
		margin: 0 0 50px;
		padding: 0;

		&-title {
			margin-bottom: 15px;
		}

		&-description {
			margin: 0 0 16px;
		}

		&-details {
			margin: 0;
		}
	}
}
