.PopupCaw {
	position: fixed;
	right: 30px;
	top: 140px;
	z-index: 11;
	display: none;

	@include rtl {
		right: inherit;
		left: 30px;
	}
}
