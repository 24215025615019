.SkipLink {
	&-container {
		position: relative;
		height: 15px;
	}

	&-link {
		position: absolute;
		top: -1000px;
		left: -1000px;
		width: 1px;
		height: 1px;
		overflow: hidden;

		&:focus,
		&:active {
			left: 0;
			top: 0;
			width: auto;
			height: auto;
			overflow: visible;
			color: $black;
		}

		.BodySection--dark & {
			&:focus,
			&:active {
				color: $white;
			}
		}

		@include rtl {
			left: auto;
			right: -1000px;

			&:focus,
			&:active {
				right: 0;
			}
		}
	}
}
.SkipToMainContent {
	display: block;
	position: absolute;
	top: auto;
	left: -999px;
	z-index: -999;
	background-color: $black;
	color: $black;
	padding: 0 20px;
	width: 0;
	height: 0;
	overflow: hidden;
	outline: none;
	transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out,
		height 0.3s ease-out, padding 0.3s ease-out;
	border: 1px solid $gray1;

	@include rtl {
		left: auto;
		right: -999px;
	}
	&:active,
	&:focus {
		box-sizing: border-box;
		display: inline-flex;
		top: 0;
		left: 0;
		align-items: center;
		justify-content: center;
		z-index: 999;
		margin: 0;
		cursor: pointer;
		padding: 10px 20px;
		width: 250px;
		min-width: 250px;
		min-height: 35px;
		height: auto;
		overflow: hidden;
		text-align: center;
		text-transform: uppercase;
		text-decoration: underline;
		font-weight: bold;
		border-color: $gray1;

		@include typography(c3);

		@include rtl {
			right: 0;
			left: auto;
		}
		&:hover {
			background-color: $white;
			color: $black;
			border-color: $gray1;
		}
	}
}
