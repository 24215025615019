.TextAndAppLinks {
	&-heading {
		text-align: center;
		font-family: $sansFont;

		.Heading-title {
			&.ty-h2 {
				text-transform: none;
				font-size: 2rem;
				font-weight: 100;
				line-height: 2.4375rem;
				letter-spacing: 0.0725rem;

				@include viewport(medium) {
					margin-bottom: 15px;
					font-size: 4.5rem;
					line-height: 5.3125rem;
					letter-spacing: 0.163125rem;
				}
			}
		}
	}

	&-text {
		@include viewport(medium) {
			margin-top: 15px;
			margin-bottom: 30px;
		}

		font-family: $serifFont;
		font-size: 1.375rem;
		letter-spacing: 0.03125rem;
		line-height: 2.0625rem;
		text-align: center;
	}

	&-app-links {
		@include viewport(medium) {
			margin-top: 30px;
			margin-bottom: 90px;
		}

		display: flex;
		align-items: center;

		.app-store {
			width: 39%;
			margin-left: 3%;
			margin-right: 9%;

			&.app-link:only-child {
				margin-left: 30.5%;
			}
		}

		.google-play {
			width: 49%;

			&.app-link:only-child {
				margin-left: 25.5%;
			}
		}

		img {
			width: 100%;
		}
	}

	&-fullbleed-image {
		&-mobile {
			@include viewport(medium) {
				display: none;
			}
		}
		&-desktop {
			@include viewport(small) {
				display: none;
			}

			@include viewport(medium) {
				display: block;
			}
		}
	}

	&-badgeImage {
		&::after {
			display: none;
			content: '' !important;
		}

		.Image-picture {
			position: relative;
		}
	}

	&--defaultBadge {
		.Image-picture {
			position: relative;
		}
	}
}
