.ChatOptions {
	text-align: center;

	& .container {
		padding-right: 30px;
		padding-left: 30px;
	}
	&-image {
		&-default {
			max-width: 300px;
			width: 100%;
		}

		& .Image-ratioContainer {
			margin: 0 auto;
			max-width: 300px;
		}
	}

	&-title {
		text-transform: uppercase;
	}

	&-intro {
		margin: 40px 0;

		@include viewport(medium) {
			margin: 50px 0;
		}
	}

	&-grid {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	&-option {
		display: flex;
		margin-bottom: 30px;
		width: 50%;

		@include viewport(medium) {
			margin-bottom: 40px;
			width: 25%;
		}

		&-center {
			margin: 0 auto;
		}

		a {
			text-decoration: none;

			& .Image {
				height: 100px;
				width: 100px;
				margin: 0 auto;

				&.needs-padding {
					box-shadow: inset 0.5px 0.5px 0.5px 1px $gray3;
					padding: 10px;
					border-radius: 20%;

					img {
						position: relative;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	&-icon {
		max-width: 100%;
		max-height: 100%;
	}
}
