.CTAsSideBySide {
	display: flex;
	flex-direction: column;
	justify-content: center;

	&--centered {
		align-items: center;

		.Heading-title {
			text-align: center;
		}
	}
	&--right {
		align-items: end;

		.Heading-title {
			text-align: right;
			@include rtl {
				text-align: left;
			}
		}
	}
	&--left {
		align-items: start;

		.Heading-title {
			text-align: left;
			@include rtl {
				text-align: right;
			}
		}
	}

	&-ctas {
		display: table-row;
	}

	&-cta {
		display: table-cell;
		height: 100%;
		padding-right: 15px;
		vertical-align: middle;

		@include viewport('medium') {
			padding-right: 25px;
		}

		.CTAsSideBySide-ctas > &:last-child {
			padding-right: 0;
			padding-left: 0;
		}

		.CAWCTA-cta {
			height: 100%;
		}

		@include rtl {
			padding-right: 0;
			padding-left: 15px;
			@include viewport('medium') {
				padding-left: 25px;
			}
		}
	}

	.Heading {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.Heading-title {
		font-family: 'Helvetica Neue Light', 'Neue Helvetica', Helvetica, sans-serif;
		font-weight: 200;
		letter-spacing: 4px;
		font-size: 24px;
		text-transform: uppercase;
		color: currentColor;
		padding-bottom: 14px;
	}
}
