.TakeYourTime-container {
	max-width: 1030px;
	margin: 0 auto;
	width: auto;
	padding: 0;

	@media only screen and (min-width: 1060px) {
		max-width: 1030px;
	}
	label {
		text-transform: uppercase;
		margin-right: 6px;
		font-size: 12px;
	}
	.SummaryCardList.SpacedComponent,
	.DetailCardList.SpacedComponent {
		margin-bottom: 0;
	}
}
.TakeYourTime-filter {
	text-align: center;
	margin-bottom: 1rem;

	h2 {
		margin-bottom: 1rem;
	}

	.Dropdown.formElement {
		padding-bottom: 0;
		margin: 0;
		select {
			font-size: 1.25rem;
		}
		&::after {
			@include icon('icon-caret');
			right: 12px;
			color: white;
			top: 11px;
		}
	}

	.TakeYourTime-filterReset {
		color: $black;
		border-bottom-color: $black;
		transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;

		&:hover {
			color: $bhover;
			border-color: $bhover;
		}

		@include viewport(xlarge) {
			margin-left: 12px;
		}
	}

	.TakeYourTime-filterContainer {
		display: inline-block;
		position: relative;
		background: $black;
		transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
		max-width: 100%;
		margin: 1rem 0;

		&:hover {
			background: $bhover;
		}

		#TakeYourTime-filter {
			padding: 12px 24px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border-radius: 0;
			border: none;
			padding: 12px 50px 12px 24px;
			background: none;
			color: $white;
			font-style: italic;
			position: relative;
			max-width: 100%;
			line-height: 1.5rem;
			height: auto;

			&::-ms-expand {
				display: none;
			}

			option {
				color: black;
				font-style: italic;
			}
		}
	}
}

@keyframes slideUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.TakeYourTime-heading {
	h1 {
		text-align: center;
	}
}
.DetailCardList,
.SummaryCardList {
	overflow: hidden;

	@media only screen and (min-width: 1060px) {
		max-width: 1030px;
	}
	.row.row-eq-height {
		display: flex;
		flex-wrap: wrap;

		@include viewport(medium) {
			flex-wrap: nowrap;
		}
	}
	.CardColumn {
		display: flex;
		flex-direction: column;
		.CardTitle {
			margin: 1rem 0 0.5rem;
			min-height: 50px;
			position: relative;
			text-align: center;
			a,
			span {
				width: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-decoration: none;
				color: $whover;
				border: none;
				> span {
					position: relative;
					transform: none;
					top: initial;
					left: initial;
					white-space: nowrap;
				}
			}

			a:hover,
			span:hover {
				color: $whover;
			}
		}
		.CardContent {
			background: $gray4;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			.CardLabel {
				text-transform: uppercase;
			}
		}
	}
}

.DetailCardList {
	.CardColumn {
		opacity: 0;
		animation: slideUp 1s ease;
		animation-fill-mode: forwards;
		.CardContent {
			text-align: center;
			margin-bottom: 1rem;
			.CardLabel {
				text-decoration: none;
				margin-bottom: 1rem;
				display: block;
			}

			.CardDetailHeader {
				position: relative;
				padding-bottom: 0.5rem;

				&::after {
					background: $black;
					position: absolute;
					bottom: 0;
					left: 50%;
					height: 3px;
					width: 10%;
					transform: translateX(-50%);
					content: ' ';
				}

				.CardDetailHeaderTitle {
					font-style: normal;
					margin-bottom: 1rem;
					text-decoration: none;
				}
			}

			.CardImage img {
				width: 100%;
				max-height: 175px;
				overflow: hidden;
			}

			.CardDetailContent {
				padding: 1.5rem 1rem;
				position: relative;
				flex-grow: 1;

				.CardBody {
					margin: 1rem 0 2rem;
				}

				.CardContact {
					color: $black;
					border-color: $black;
					text-transform: uppercase;

					@include viewport(large) {
						position: absolute;
						bottom: 1rem;
						left: 16px;
						right: 16px;
					}

					a {
						display: inline-block;
						color: $black;
						border: none;
					}
				}
			}
		}

		&:nth-child(1) {
			animation-delay: 0.25s;
		}

		&:nth-child(2) {
			animation-delay: 0.5s;
		}

		&:nth-child(3) {
			animation-delay: 0.75s;
		}

		&:nth-child(4) {
			animation-delay: 1s;
		}

		&:nth-child(5) {
			animation-delay: 1.25s;
		}

		&:nth-child(6) {
			animation-delay: 1.5s;
		}

		&:nth-child(7) {
			animation-delay: 1.75s;
		}

		&:nth-child(8) {
			animation-delay: 2s;
		}

		&:nth-child(9) {
			animation-delay: 2.25s;
		}
	}
}

.SummaryCardList {
	.CardColumn {
		.CardContent {
			position: relative;
			overflow: hidden;
			.CardImage img {
				width: 100%;
				max-height: 171px;
				overflow: hidden;
				margin-bottom: -2px;
			}

			.CardDetails {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				height: 100%;
				.CardDetail {
					&::before {
						display: none;
					}

					&:hover {
						transition: 0.3s;

						.CardDetailHeaderTitle,
						.CardLabel {
							color: $whover;
						}

						.icon-chevron_right {
							transition: 0.3s;
							transform: translateX(3px);
						}
					}
					&:last-child .CardDetailHeader {
						border-bottom: none;
					}

					&.CardDetail-open .CardDetailContent {
						visibility: visible;
						transform: translate3d(0, 0, 0);
						display: flex;
						flex-direction: column;
					}

					.CardDetailHeader {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 1.5rem 0;
						margin: 0 1rem;
						border-bottom: solid 1px $black;
						color: $black;
						min-height: 85px;
						text-decoration: none;
						.CardLabel {
							font-size: 0.8rem;
							width: 25%;
							white-space: normal;
							text-overflow: ellipsis;
							overflow: hidden;

							@include viewport(medium) {
								font-size: 0.865rem;
							}
						}

						.CardDetailHeaderTitle {
							width: 65%;
							margin: 0;
							white-space: normal;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}

					.CardDetailContent {
						background: $black;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						transform: translate3d(100%, 0, 0);
						transition: 0.333s;
						color: $white;
						visibility: hidden;

						@media all and (-ms-high-contrast: none) {
							transition: none;
						}

						.CardDetailContent-header {
							display: inline-block;
							width: 100%;

							.CardLabel {
								padding: 32px 32px 18px;
								display: block;
								text-align: left;
							}

							.CardDetailHeaderTitle {
								color: $white;
								padding: 0 32px 18px;
								text-align: left;
								text-transform: uppercase;
							}

							.CardDetailClose {
								color: $white;
								text-decoration: none !important;
								border: none;
								position: absolute;
								right: 18px;
								top: 18px;
							}

							a,
							span,
							h3,
							dl dt {
								color: $white;
								text-decoration: none;
								line-height: normal;
								margin: 0;
								border: none;
								text-align: center;
							}
						}

						.CardDetailNav {
							.CardDetailNavIcon {
								position: absolute;
								top: 50%;
								left: 8px;
								transform: translateY(-50%);
								display: inline-block;
								color: $white;
								border-bottom: none;
								text-decoration: none;
							}

							.CardDetailNavIconNext {
								left: auto;
								right: 8px;
							}
						}
						.CardBody {
							color: $white;
							padding: 0 32px;
							margin: 0;
							overflow-x: auto;
							flex-grow: 1;
						}

						.CardContact {
							margin: 1rem 0;
							text-align: center;
							text-transform: uppercase;
							color: $white;

							a {
								color: $white;
								border: none;
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
}

.SummaryCardList-lazyLoad,
.TakeYourTime-viewAll {
	margin: 3em 0;
	text-align: center;
}

.TakeYourTime-propertyCTA .TakeYourTime-propertyCTAInner {
	margin: 1rem auto 3rem;
	padding: 1rem;
	background: $black;
	position: relative;

	h3,
	dl dt {
		color: $white;
		text-transform: none;
		font-style: italic;
		text-align: center;
		margin-bottom: 1rem;
	}
	&::after {
		background: $ac-off-black;
		position: absolute;
		bottom: -3rem;
		left: 50%;
		height: 1px;
		width: 140px;
		transform: translateX(-50%);
		content: ' ';
	}

	@include viewport(medium) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 80%;
		h3,
		dl dt {
			text-align: left;
			margin: 0;
		}
	}
}
