// This overrides the default .StandardHero spacing
// specifically in the PropertyFinder page
// due to the jump link in the embedded map.
// So, the new spacing will substract that jump link
// height from the default spacing
.PropertyFinder {
	.StandardHero {
		&-title {
			padding-bottom: 13px;

			@include viewport(medium) {
				padding-bottom: 33px;
			}

			@include viewport(xlarge) {
				padding-bottom: 53px;
			}
		}
	}
}
