/*! Flickity v2.0.9
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus {
	outline: none;
}

.flickity-viewport {
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
	position: absolute;
	top: 0;
	height: 100%;
	border: none;
	background: none;
	cursor: pointer;
	width: 15px;
	padding: 0;
	opacity: 1;
	transition: opacity 0.8s;

	@include viewport(medium) {
		width: 30px;
	}
	/* vertically center */
}

.flickity-prev-next-button:focus {
	outline: none;
	box-shadow: 0 0 0 5px #09f;
}

.flickity-prev-next-button:active {
	box-shadow: 0 0 0 5px $gray3;
}

.flickity-prev-next-button.previous {
	left: 0;

	@include viewport(medium) {
		left: -15px;
	}
}
.flickity-prev-next-button.next {
	right: 0;

	@include viewport(medium) {
		right: -15px;
	}
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: -7.5px;
}
.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: -7.5px;
}

.flickity-prev-next-button:disabled {
	opacity: 0;
}

.flickity-prev-next-button svg {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

.flickity-prev-next-button .arrow {
	fill: transparent;
	display: none;
}

/* ---- page dots ---- */

.flickity-page-dots {
	position: absolute;
	display: block;
	width: 100%;
	bottom: 12px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	pointer-events: none;
	line-height: 1;
}

.flickity-rtl .flickity-page-dots {
	direction: rtl;
}

.flickity-page-dots .dot {
	display: inline-block;
	width: 6px;
	height: 6px;
	margin: 0 3px;
	background: $gray2;
	border-radius: 50%;
	opacity: 0.5;
	cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
	background: $white;
	width: 8px;
	height: 8px;
	opacity: 1;
	margin: -1px 3px;
}

.flickity-enabled.is-fade .flickity-slider > * {
	pointer-events: none;
	z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
	pointer-events: auto;
	z-index: 1;
}
