.PrivateJetDestinations {
	&-overlay {
		.OverlayPage-backButton {
			margin-top: 0;
			@include viewport(medium) {
				height: 100px;
			}
			& .CTA {
				text-transform: none;
				padding-left: 35px;
				color: var(--color-text-heading, #000000);
				font-feature-settings: 'liga' off, 'clig' off;

				/* Heading/H4 */
				font-family: 'Garamond MT Std';
				font-size: 18px;
				font-style: italic;
				font-weight: 400;
				line-height: 27px; /* 150% */
				letter-spacing: 0.5px;
			}
		}
		& .OverlayPage-Header {
			padding: 0;
		}
		& .OverlayPage-Body .BodySection:first-child {
			padding-top: 0;
			@include viewport(medium) {
				padding-top: 20px;
			}
		}

		.ImageCtaCollage-item:last-child {
			padding-bottom: 0;
		}

		.CardCarousel-parent {
			@include viewport(large) {
				padding-bottom: 80px;
			}
		}
	}

	&-tabButton {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;
		&.FullscreenTabContainer-tab--active .labels-label {
			color: var(--color-text-primary, #1f1f1f);
		}
	}
	&-Dates {
		white-space: nowrap;
	}
}
