.LocationBar {
	text-align: center;

	&-address {
		text-transform: uppercase;
	}

	&-separator {
		border: 1px solid $gray3;
		margin: 20px auto;
		width: 100%;
	}

	&-phone {
		.LocationBar--light & {
			@include link('underlined', 'light');
		}

		.LocationBar--dark & {
			@include link('underlined', 'dark');
		}
	}

	&-links {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include viewport(medium) {
			flex-direction: row;
			justify-content: center;
		}
	}

	&-cta {
		margin-top: 40px;

		@include viewport(medium) {
			margin-top: 0;
			margin-left: 30px;

			@include rtl {
				margin-left: 0;
				margin-right: 30px;
			}
		}

		.LocationBar--light & {
			@include link('underlined', 'light');
		}

		.LocationBar--dark & {
			@include link('underlined', 'dark');
		}
	}
}
