.GreatGroupValues {
	&-OfferItem {
		padding-top: 30px;
		&-wrapper {
			border-bottom: 1px solid $black;

			@include viewport(medium) {
				display: flex;
				justify-content: space-between;
			}
		}
		&-property {
			padding-bottom: 20px;
			width: 100%;
		}
		&-info {
			&-dates {
				padding-bottom: 20px;
			}
			&-description {
				padding-bottom: 20px;
			}

			@include viewport(medium) {
				width: 65%;
			}
		}
		&-offer {
			&-price {
				padding-bottom: 10px;

				.start {
					@include serifItalic;
					line-height: 100%;
					vertical-align: middle;
					color: $gray2;
					font-size: 0.9rem;
				}
				.currency {
					padding-left: 5px;
					vertical-align: middle;
					color: $black;
				}
			}
			&-discount {
				padding-bottom: 20px;
			}
			&-link {
				padding-bottom: 30px;

				@include viewport(medium) {
					align-self: flex-end;
				}
			}

			@include viewport(medium) {
				width: 35%;
				margin-left: auto;
				text-align: right;
				display: flex;
				flex-direction: column;
				& > div {
					flex: 1;
				}
			}
		}
	}
}
