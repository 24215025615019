.widget-container {
	&.eo-container {
		display: none;
	}
	.circle-base {
		border-radius: 50%;
	}

	#offer-mail-icon {
		padding: 15px 12px 0;
		width: 55px;
		height: 55px;
		background-color: black;
		right: 40px;
		top: 175px;
		position: fixed;
		z-index: 3;
		display: none;
		border: 1px solid lightgray;
		&::after {
			@include icon('icon-exclusive_offer');
			color: lightgray;
			font-size: 27px;
		}
	}

	.Loading-Indicator {
		z-index: 10;
	}

	#dialog-container {
		width: 295px;
		overflow: hidden;
		right: 40px;
		position: fixed;
		top: 235px;
		color: #fff;
		z-index: 8;
		line-height: 1.4em;
	}

	#dialog-arrow {
		content: '';
		margin-left: 260px;
		width: 0;
		z-index: 8;
		border-bottom: 7px solid lightgray; /* rgba(24,24,24,0.9); /* lightgray; */
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		display: none;
	}

	#dialog-container::after.fs-teale-background {
		border-bottom: 7px solid #007b8a;
	}

	#dialog-container ul li.with-before::before {
		content: '\25BA \0020';
		position: absolute;
		left: 33px;
		font-size: 6pt;
		font-family: Arial;
	}

	#dialog-container ul {
		padding-left: 15px;
		font-size: 0.7em;
		margin: 1em 0;
	}

	.dialog-box,
	#offer-dialog,
	#previous-search-dialog {
		background-color: rgba(24, 24, 24, 0.9);
		padding: 15px 30px;
		margin-bottom: 15px;
		display: none;
		border: 1px solid lightgray;
	}

	#offer-dialog {
		padding: 15px 30px 7px;
	}

	.bordered-box {
		padding: 15px 30px 5px;
		margin-bottom: 15px;
		border: 1px solid lightgray;
	}

	#previous-search-dialog hr {
		margin-left: -30px;
		margin-right: -30px;
		border-color: gray;
	}

	#widget-notification {
		background-color: rgba(24, 24, 24, 0.9);
		padding: 15px;
		display: none;
		border: 1px solid lightgray;
	}

	.offer-reminder {
		margin-left: 79px;
	}

	.previous-search-reminder {
		margin-left: 109px;
	}

	#offer-dialog p {
		margin: 0;
		color: #fff;
		letter-spacing: 3px;
		font-size: 15pt;
		line-height: 1.4em;
	}

	#previous-search-dialog p {
		text-align: center;
		color: #fff;
		margin: 0;
		font-size: 0.8em;
	}

	#widget-notification p {
		margin: 0;
		font-size: 0.8em;
		color: #fff;
		line-height: 1.2em;
	}

	#previous-search-dialog.fs-teale hr {
		border-top: 1px solid #007b8a;
	}

	#previous-search-dialog ul {
		padding-left: 35px;
		margin-top: -5px;
	}

	#previous-search-dialog ul li::before {
		margin-left: 15px;
		font-family: Arial;
	}

	#previous-search-dialog .header {
		line-height: 0.8em;
		text-transform: unset;
	}

	.overlay {
		position: fixed;
		display: none;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.9);
		z-index: 10;
		line-height: 1.2;
	}

	#toc-overlay {
		z-index: 10;
	}

	.overlay .header {
		text-transform: uppercase;
		letter-spacing: 4px;
		font-size: 18pt;
	}

	.overlay-text {
		width: 35%;
		position: absolute;
		top: 50%;
		left: 50%;
		color: #fff;
		border-left: 1px solid white;
		padding-left: 25px;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
	}

	.overlay-text .eo-toc {
		padding: 5px;
		max-width: 275px;
	}

	.overlay-text .explore-offers-btn {
		max-width: 275px;
		overflow-y: auto;
	}

	.eo-toc {
		z-index: 10;
	}

	#toc-overlay-text .body {
		list-style: disc;
		color: #fff;
		margin: 1em 0;
		font-size: 0.5em;
	}

	#offer-overlay-text {
		max-width: 375px;
	}

	#offer-overlay-text ul {
		color: #fff;
		margin: 1em 0;
		font-size: 0.8em;
		padding-left: 15px;
	}

	#offer-overlay-text ul li.with-before::before {
		content: '\25BA \0020';
		position: absolute;
		left: 25px;
		font-size: 6pt;
		line-height: 3em;
		font-family: Arial;
	}

	#toc-overlay-text p {
		margin: 0 0 15px;
		font-size: 1.5em;
		color: #fff;
		text-align: justify;
	}

	#toc-overlay-text p.header {
		text-align: left;
	}

	#offer-overlay-text p {
		margin: 0;
		font-size: 1.5em;
		color: #fff;
	}

	.explore-offers-btn {
		border: 1px solid #fff;
		white-space: nowrap;
		font-size: 9pt;
		text-align: center;
		display: inherit;
		padding-top: 10px;
		padding-bottom: 10px;
		color: #fff;
		text-decoration: none !important;
		letter-spacing: 3px;
		font-weight: bold;
		line-height: 1;
		overflow: hidden;
	}

	#offer-dialog > a.explore-offers-btn.font-helvetica-neue,
	#offer-dialog > a.explore-offers-btn.font-helvetica-neue:visited {
		color: #fff;
	}

	.resume-search-btn {
		border: 1px solid #fff;
		white-space: nowrap;
		font-size: 9pt;
		text-align: center;
		display: inherit;
		padding-top: 10px;
		padding-bottom: 10px;
		color: #fff;
		text-decoration: none !important;
		letter-spacing: 3px;
		font-weight: bold;
		line-height: 1;
		overflow: hidden;

		margin-bottom: 15px;
	}

	/*
	.explore-offers-btn.fs-teale {
		background-color: #007b8a;
	}
	*/

	.fs-teale-background {
		background-color: #007b8a;
	}

	.fs-teale-border {
		border: 1px solid #007b8a !important;
	}

	.eo-toc {
		font-size: x-small;
		display: block;
		text-align: center;
		color: #fff;
		text-decoration: underline;
		cursor: pointer;
		padding: 10px 0 0;
	}

	.text-left {
		text-align: left;
	}

	.text-black {
		color: #000;
	}

	.text-decoration-none {
		text-decoration: none !important;
	}

	.text-decoration-underline {
		text-decoration: underline !important;
	}

	.font-helvetica-neue {
		font-family: Helvetica Neue LT W01_35 Thin, Helvetica, sans-serif;
	}

	.font-pt-serif {
		font-family: 'PT Serif', 'HelveticaNeue', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial,
			sans-serif;
	}

	ul.font-pt-serif li {
		font-size: 12.6px;

		/* font-weight: 400px; */
		line-height: 25.2px;
		letter-spacing: 0.5px;
	}

	.explore-offers-btn.font-helvetica-neue {
		font-size: 12px;
		font-weight: 700;
		line-height: 12px;
		letter-spacing: 3px;
		cursor: pointer;
	}

	.widget-x {
		position: absolute;
		float: left;
		color: gray;
		margin: inherit;
		cursor: pointer;
		font-family: 'HelveticaNeue-Light', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial,
			sans-serif;
	}

	.dialog-x {
		top: 10px;
		left: 10px;
		font-size: 17pt;
	}

	.overlay-x {
		top: -11px;
		left: -45px;
		font-size: 30pt;
	}

	@media only screen and (min-width: 1100px) {
		.Navigation-sm {
			display: none;
		}
	}

	#offer-mail-icon line,
	#offer-mail-icon polyline,
	#offer-mail-icon path {
		stroke: #bbb;
	}

	.eo-icon {
		font-family: HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial,
			sans-serif;
		-webkit-transform: scaleY(0.5); /* Safari and Chrome */
		-moz-transform: scaleY(0.5); /* Firefox */
		-ms-transform: scaleY(0.5); /* IE 9 */
		-o-transform: scaleY(0.5); /* Opera */
		transform: scaleY(0.5); /* W3C */
		color: #000;
	}

	.icon-arrow-up::before {
		content: '\5e';
	}

	.icon-arrow-down::before {
		content: '\56';
	}
}
