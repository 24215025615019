.PrivateJetItinerary {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&-heading {
		text-align: center;
		.Heading-title {
			margin-top: 0;
			margin-bottom: 40px;
		}
	}

	&-destinations {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}

	&Destination {
		color: initial;
		text-decoration: initial;
		display: flex;
		flex-direction: row;
		gap: 15px;
		align-items: center;

		@include viewport(medium) {
			gap: 30px;
		}

		&-image {
			width: 105px;
			height: 105px;

			@include viewport(medium) {
				width: 130px;
				height: 130px;
			}
		}

		&-content {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: 10px;

			@include viewport(medium) {
				gap: 5px;
			}
		}

		&-title {
			@include viewport(medium) {
				font-size: 22px;
			}
		}

		&-info {
			line-height: 18px;
			display: flex;
			flex-direction: column;
			gap: 5px;

			@include viewport(medium) {
				flex-direction: row;
				gap: 10px;
			}
			&-text {
				font-family: 'Helvetica Neue', sans-serif;
				color: var(--color-text-heading, #030303);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 150% */
				letter-spacing: 1px;
			}
		}

		&-arrow {
			margin-right: 15px;
			color: var(--color-text-heading, #030303);
			& .icon {
				font-size: 14px;
				@include viewport(medium) {
					font-size: 18px;
				}
			}
			@include rtl {
				margin-left: 15px;
				margin-right: 0;
			}
			@include viewport(medium) {
				margin-right: 30px;
				@include rtl {
					margin-left: 30px;
					margin-right: 0;
				}
			}
		}
	}
}
