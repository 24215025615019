.appPromotion {
	background-color: black;
	color: white;
	width: 100%;
	&-title {
		@include sansThin;
		font-size: rem(20, 16);
		letter-spacing: em(0.5, 20);
		line-height: em(24, 20);
		text-align: center;
		width: 242px;
		padding-top: 10px;
		padding-bottom: 20px;

		@include viewport(medium) {
			font-size: rem(50, 16);
			line-height: em(64, 50);
			letter-spacing: em(2, 50);
			width: 579px;
			padding-top: 0px;
		}
		@media only screen and (min-width: 1309px) {
			padding-top: 110px;
			padding-bottom: 58px;
		}
	}
	&-paragraph {
		text-align: center;
		padding-left: 38px;
		padding-right: 37px;
		width: 368px;

		@include viewport(medium) {
			font-size: rem(18, 16);
			line-height: em(27, 18);
			width: 580px;
		}

		@media only screen and (min-width: 1309px) {
			padding-bottom: 86px;
		}
	}
	&-titleContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 40px;
	}
	&-storeAndTitleContainer {
		display: flex;
		flex-direction: column;
		align-items: center;

		&-sectionTitle {
			text-align: center;
			padding-bottom: 20px;
			width: 211px;
			line-height: 18px;

			@include viewport(medium) {
				font-size: rem(16, 16);
				line-height: em(21, 16);
				letter-spacing: em(4, 16);
				width: 462px;
				padding-bottom: 39px;
			}
		}
	}
	&-appleStore {
		padding-bottom: 21.47px;
	}

	&-iPhoneContainer {
		display: flex;
		flex-direction: column;

		@include viewport(medium) {
			flex-direction: row;
			padding-bottom: 80px;
			justify-content: center;
			padding-top: 70px;
		}
	}
	&-phoneHomeScreen {
		padding-bottom: 20px;

		&-container {
			display: flex;
			flex-direction: column-reverse;
			align-items: center;

			@include viewport(medium) {
				flex-direction: column;
			}
		}
		&-textContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&-title {
			padding-bottom: 19px;
			line-height: em(27, 12);
			text-align: center;

			@include viewport(medium) {
				line-height: 18px;
			}
		}
		&-text {
			text-align: center;
			padding-bottom: 28px;
			padding-left: 87px;
			padding-right: 86px;
			width: 365px;
		}
	}
	&-phoneItineraryScreen {
		&-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			@media only screen and (min-width: 1309px) {
				padding-top: 10px;
			}
		}
		&-textContainer {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&-title {
			padding-bottom: 19px;
			line-height: em(27, 12);
			text-align: center;

			@include viewport(medium) {
				line-height: 18px;
			}
		}
		&-text {
			text-align: center;
			padding-bottom: 28px;
			padding-left: 87px;
			padding-right: 86px;
			width: 365px;

			@include viewport(medium) {
				padding-bottom: 11px;
			}
		}
	}
	&-storeContainer {
		display: flex;
		flex-direction: column;

		@include viewport(medium) {
			display: flex;
			flex-direction: row;
			.appStore {
				padding-right: 19px;
			}
			.googlePlay {
				padding-left: 19px;
			}
		}
	}

	.topIcon {
		display: none;
		@include viewport(medium) {
			display: flex;
		}
	}
	.bottomIcon {
		@include viewport(medium) {
			display: none;
		}
	}
	.container {
		display: flex;
		flex-direction: column-reverse;

		@media only screen and (min-width: 1309px) {
			flex-direction: row;
			justify-content: center;
		}
	}
	.appStore .googlePlay {
		width: 152px;
	}
}
