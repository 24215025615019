.ContactBar {
	&-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 40px 0;
		text-align: center;

		@include viewport(medium) {
			flex-direction: row;
			padding: 25px 30px;
		}

		@include viewport(xlarge) {
			padding-left: 60px;
			padding-right: 60px;
		}

		&--dark {
			background-color: $white;
			color: $black;
		}

		&--light {
			background-color: $black;
			color: $white;
		}
	}

	&-title {
		@include viewport(medium) {
			flex-grow: 1;
			margin: 0 15px 0 0;
			text-align: left;
		}

		@include rtl {
			@include viewport(medium) {
				margin: 0 0 0 15px;
				text-align: right;
			}
		}

		margin: 0 30px 20px;
	}

	&-cta {
		@include viewport(medium) {
			margin-left: 30px;

			@include rtl {
				margin-left: 0;
				margin-right: 30px;
			}
		}
	}

	&-phone {
		margin-bottom: 30px;
		white-space: nowrap;

		@include viewport(medium) {
			margin: 0;
		}

		.ContactBar-container--dark & {
			@include link('underlined', 'light');
		}

		.ContactBar-container--light & {
			@include link('underlined', 'dark');
		}
	}
}
