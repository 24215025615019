.BulletedList {
	&-separator {
		border-top: 1px solid;
	}

	&-body {
		padding-top: 32px;
		padding-bottom: 50px;

		@include clearfix;

		@include viewport(large) {
			padding-top: 60px;
			padding-bottom: 100px;
		}

		@include viewport(xlarge) {
			padding-top: 80px;
			padding-bottom: 120px;
		}
	}

	&-list {
		margin-top: 27px;

		@include viewport(large) {
			columns: 2 auto;
			column-gap: $gutter_large;
		}
	}

	&-item {
		position: relative;
		padding-left: 15px;
		padding-bottom: em(5.5, 18);
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 8px;
			border: 2px solid;
			border-radius: 10px;
		}

		@include rtl {
			padding-left: 0;
			padding-right: 15px;

			&::before {
				left: auto;
				right: 0;
			}
		}
	}

	&-top-heading {
		.Heading-title {
			@include component-spacing($small: 60px, $medium: 60px, $large: 80px, $xlarge: 80px);

			text-align: center;
			margin-top: 0;
		}
	}
}
