.TabContainer {
	padding-left: 15px;
	padding-right: 15px;

	&-heading {
		margin-bottom: 32px;
		text-align: center;
		font-size: 28px;
		line-height: 42px;
		letter-spacing: 4.4px;

		@include viewport(medium) {
			margin-bottom: 50px;
			font-size: 32px;
			letter-spacing: 5px;
		}
	}

	.container {
		padding-left: 0px;
		padding-right: 0px;
	}

	&-tabs {
		&--desktop {
			flex-direction: row;
			width: 100%;
			height: 60px;
			display: flex;
			gap: 30px;
			overflow-x: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
			scrollbar-width: none;

			&--default {
				.TabContainer--light & {
					border-top: 1px solid $black;
					border-bottom: 1px solid $black;
				}

				.TabContainer--dark & {
					border-top: 1px solid $white;
					border-bottom: 1px solid $white;
				}
			}

			&--underlined {
				border-top: 1px solid #d3d3d3;
				border-bottom: 1px solid #d3d3d3;

				@include viewport(medium) {
					flex-wrap: wrap;
					justify-content: space-around;
				}
			}
			padding-left: 15px;
			padding-right: 15px;

			@include viewport(medium) {
				gap: unset;
				overflow-x: hidden;
				padding-left: 0px;
				padding-right: 0px;
			}
		}
	}

	&-tabsContainer {
		&--desktop {
			margin-bottom: 50px;
			&--underlined {
				margin-bottom: 0px;
			}
			display: flex;
			flex-direction: column;
			width: 100%;
			overflow: hidden;
		}
	}

	&-tab {
		flex: 1 1 0px;
		align-self: center;
		text-align: center;
		display: inline-block;
		background: none;
		border: none;
		font-weight: 400;

		&--underlined {
			flex: 0;
		}

		&--dark {
			@include grayHover($theme: 'dark');
			color: $white;
		}

		&--light {
			@include grayHover($theme: 'light');
			color: $gray1;
		}

		// This psuedo-element makes the inactive tab text take up as much space
		// as the active (bold) text will to avoid layout shifting
		&::before {
			display: block;
			content: attr(title);
			font-weight: bold;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}

		&--active {
			font-weight: bold;
		}

		.TabContainer-tabs--desktop & {
			height: 100%;
		}
		.TabContainer-tabs--desktop--underlined & {
			display: inline-block;
			position: relative;

			&--light::after {
				background-color: black;
			}
			&--dark::after {
				background-color: white;
			}

			&--active {
				&::after {
					position: absolute;
					left: 0;
					bottom: 0px;
					height: 4px;
					width: 100%;
					content: '';

					@include rtl {
						left: auto;
						right: 0;
					}
				}
			}
		}
	}

	&-pages {
		display: block;
		width: auto;
	}

	&-page {
		position: relative;
		left: 0;
		top: 0;
		display: none;

		&--active {
			display: block;
		}
	}

	.TabPage {
		&-components {
			.SpacedComponent {
				.BodySection &:not(:last-child) {
					margin-bottom: 30px;
				}
			}

			.InlineCarousel {
				.CtaBlock--empty {
					display: none;
				}

				.InlineCarousel-list .CarouselSlide--emptyCTABlock {
					&-video {
						margin-top: 0;
						margin-bottom: 0;
					}

					.ImageAndCta .Image {
						margin-bottom: 0;
					}
				}
			}

			.CardCarousel-heading {
				margin-top: 40px;
			}
		}
	}
}
