.ExpandableCardGrid {
	&-row:not(.showall) {
		@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
			div.ExpandableCardGrid-item:nth-child(n + 5) {
				display: none;
			}
		}

		@include mediaRange($breakpoints-lg, calc($breakpoints-xl - 1px)) {
			div.ExpandableCardGrid-item:nth-child(n + 4) {
				display: none;
			}
		}

		@include media($breakpoints-xl) {
			div.ExpandableCardGrid-item:nth-child(n + 5) {
				display: none;
			}
		}
	}

	&-button {
		display: flex;
		height: 40px;
		padding: 10px 20px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		margin-top: 30px;

		&-hide {
			display: none;

			&-4 {
				@include mediaRange($breakpoints-lg, $breakpoints-xl) {
					display: none;
				}
			}

			&-5 {
				@include mediaRange($breakpoints-sm, $breakpoints-lg) {
					display: none;
				}
				@include media($breakpoints-xl) {
					display: none;
				}
			}
		}

		&-container {
			display: flex;
			justify-content: center;
			text-align: center;
		}
	}
	.Heading-title {
		margin-top: 0;
	}
}
