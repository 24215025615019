.ExclusivesAndBuyoutsChart {
	.container {
		@include viewport(large) {
			max-width: $containerWidth-xl;
		}
	}

	.ChartCell-cta {
		@include typography(b2);
		font-size: 15px;
		text-transform: none;
	}

	.Accordion {
		&-item {
			dt {
				@include viewport(large) {
					padding: 0 5px;
				}
			}
		}
	}
}
