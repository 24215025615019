.ContactForm {
	padding-bottom: 40px;
	padding-top: 80px;

	@include viewport(medium) {
		padding-bottom: 60px;
		padding-top: 120px;
	}

	@include viewport(large) {
		padding-bottom: 70px;
		padding-top: 140px;
	}

	@include viewport(xlarge) {
		padding-bottom: 90px;
		padding-top: 180px;
	}

	&-Phone {
		padding-bottom: 0;
		margin-bottom: 5px;
	}

	&-PrimaryPhone {
		margin: 0;
		padding-bottom: 0;
		margin-bottom: 19px;

		@include viewport(medium) {
			margin-bottom: 4px;
		}

		&-label {
			@include sansThin;
			font-size: rem(12, 16);
			letter-spacing: em(1, 12);
			line-height: em(18, 12);
		}

		.Checkbox-input:disabled ~ .Checkbox-label {
			opacity: 1;
			color: $gray1;
		}

		.Checkbox-decoration {
			width: 12px;
			height: 12px;
			margin-right: 10px;
			margin-top: em(3, 12);

			&::before {
				top: 0;
				left: 0;
				font-size: 10px;
			}
		}
	}

	&-SubmitContainer {
		margin-top: 15px;
		text-align: center;

		@include viewport(medium) {
			text-align: left;

			@include rtl {
				text-align: right;
			}
		}

		@include viewport(large) {
			// margin-top: 60px;
		}
	}

	&-SubmitCTA {
		display: inline-block;
		float: right;

		@include viewport(medium) {
			min-width: 216px;
		}
	}

	&-form--hidden {
		display: none;
	}

	&-success {
		text-align: center;

		&--hidden {
			display: none;
		}
	}

	&-error {
		color: $white;
		background-color: $error-red;
		padding-bottom: 30px;
		padding-top: 30px;
		padding-left: 14px;

		@include rtl {
			padding-left: 0;
			padding-right: 14px;

			@include viewport(medium) {
				padding-right: 48px;
			}
		}

		@include viewport(medium) {
			padding-left: 48px;
		}

		&--hidden {
			display: none;
		}
	}

	&-title {
		@include sansThin;
		font-size: rem(32, 16);
		letter-spacing: em(5, 32);
		line-height: em(42, 32);
		text-transform: uppercase;
		margin-top: 0;

		@include i18nFonts;
	}

	&-subtitle {
		margin-bottom: 60px;

		@include viewport(medium) {
			margin-bottom: 80px;
		}
	}
}
