.CAWIframe {
	position: fixed;
	display: none;
	opacity: 0;
	height: 0;
	width: 0;
	transition: opacity ease-in-out 300ms;
	z-index: -100000;

	&__frame {
		position: fixed;
		opacity: 0;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transform: translateY(30px);
		transition: all ease-in-out 300ms 300ms;
	}

	&--block {
		display: block;
	}

	&--active {
		opacity: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: $white;
		z-index: 100000;
	}

	&--active &__frame {
		opacity: 1;
		transform: translateY(0);
	}
}
