.Interstitial {
	display: block;
	z-index: -1;
	height: 100vh; /* Fallback for browsers that do not support Custom Properties */
	height: calc(
		var(--vh, 1vh) * 100
	); //mobile browsers use odd values for vh so we need to use innerHeight which we will get in js and set the vh css property
	width: 100%;
	position: absolute;
	inset: 0;
	background-color: black;

	.BackgroundImage {
		height: 100%;
		width: 100%;
		position: relative;
		&::after {
			left: 0;
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
			z-index: 51;
			height: 100%;
			width: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 18.1%, #000 100%),
				linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 27.6%, #000 73.73%);

			@include viewport(medium) {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 34.77%, #000 100%),
					linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 19.99%, #000 93.55%);
			}

			@include viewport(large) {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 13.81%, #000 92.78%),
					linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 16.92%, #000 93.55%);
			}
		}
		.Interstitial-logoContainer {
			position: absolute;
			width: 100%;
			top: 0;
			transform: translate(0, 0);
			z-index: 52;
			.Interstitial-logoLink {
				display: block;
				width: 125px;
				height: 125px;
				margin: auto;
				transform: translate(0, 0);
				background-color: black;
				.Interstitial-logo {
					text-align: center;
					line-height: 125px;
					font-size: 60px;
					transform: translate(0, 0);
					&::before {
						vertical-align: sub;
					}
				}
			}
		}
		.Interstitial-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			width: 100%;
			bottom: 0;
			z-index: 52;
			transform: translate(0, 0);
			.Heading {
				color: white;
				text-align: center;
				padding: 0 10px;
				.Heading-eyebrow {
					margin-bottom: 10px;

					@include viewport(large) {
						margin-bottom: 15px;
					}
				}
				.Heading-title {
					margin-top: 0;
					margin-bottom: 20px;
					font-size: rem(28, 16);
					letter-spacing: rem(3, 16);
					line-height: rem(42, 16);

					@include viewport(medium) {
						font-size: rem(32, 16);
						letter-spacing: rem(5, 16);
						line-height: rem(47, 16);
					}

					@include viewport(large) {
						font-size: rem(44, 16);
						letter-spacing: rem(6, 16);
						line-height: rem(62, 16);
					}
				}
				.Heading-subtitle {
					font-size: rem(18, 16);
					letter-spacing: rem(0.5, 16);
					line-height: rem(27, 16);

					@include viewport(large) {
						font-size: rem(30, 16);
						letter-spacing: rem(1, 16);
						line-height: rem(41, 16);
					}
				}
			}
			.CTA {
				border-color: #fff;
				margin-top: 30px;
				height: 35px;
			}
			.Interstitial-arrowButton {
				color: white;
				font-size: 13px;
				margin-bottom: 23px;
				margin-top: 33px;

				@include viewport(medium) {
					margin-bottom: 33px;
				}

				@include viewport(large) {
					margin-bottom: 43px;
				}
				.Button-label {
					display: block;
					font-size: 0;
					height: 1px;
					overflow: hidden;
				}
			}
		}
	}
	.Interstitial-countdown {
		position: absolute;
		right: 15px;
		bottom: 40px;
		z-index: 52;
		transform: translate(0, 0);
		font-size: 10px;
		color: white;
		line-height: 17px;
		letter-spacing: 3px;
		text-transform: uppercase;
		display: flex;

		@include rtl {
			right: auto;
			left: 15px;
		}

		@include viewport(medium) {
			right: 50px;
			bottom: 50px;

			@include rtl {
				right: auto;
				left: 50px;
			}
		}

		@include viewport(large) {
			right: 55px;
			bottom: 50px;

			@include rtl {
				right: auto;
				left: 55px;
			}
		}
		.timer {
			margin-left: 10px;

			@include rtl {
				margin-right: 10px;
				margin-left: 0;
			}
			height: 17px;
			width: 17px;
			text-align: center;
			letter-spacing: normal;
			svg {
				position: absolute;
				top: 0;
				right: 0;

				@include rtl {
					right: auto;
					left: 0;
				}
				width: 17px;
				height: 17px;
				transform: rotateY(-180deg) rotateZ(-90deg);
			}

			svg circle {
				stroke-dasharray: 47px;
				stroke-dashoffset: -47px;
				stroke-linecap: round;
				stroke-width: 2px;
				stroke: white;
				fill: none;
				animation: countdown 7s linear 1 forwards;
			}

			@keyframes countdown {
				from {
					stroke-dashoffset: -47px;
				}
				to {
					stroke-dashoffset: 0;
				}
			}
		}
	}
}
.InterstitialOfferBanner {
	position: relative;
	top: 0;
	border-bottom-width: 1px;
	z-index: 10;
	transition: z-index 0.3s;
	width: 100%;
	display: block;
	padding: 4px 0;
	background: $gray1;
	.Body-navigationOverlay--shown & {
		z-index: 9;
	}

	&-WebMessage {
		color: white;
		max-width: 930px;
		margin: auto;
		padding: 0 20px;
		a {
			color: white;
		}
	}
}

.Interstitial {
	&-video {
		display: none;
		width: 100%;

		@include viewport(large) {
			display: block;
			position: absolute;
			top: 0;
			z-index: 51;
			height: 100vh;
			overflow: hidden;
		}

		@include viewport(xlarge) {
			max-height: 1200px;
		}

		@include rtl {
			// Fixes video centering in IE11 and Edge
			direction: ltr;
		}

		.Video {
			&-video {
				position: absolute;
				left: 50%;
				top: 50%;
				min-height: 100%;
				min-width: 100%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.Video-playButton {
		display: none;
		@include viewport(large) {
			display: block;
		}
		position: absolute;
		bottom: 16px;
		right: 16px;
		z-index: 53;

		@include rtl {
			left: 16px;
			right: auto;
		}
	}

	&-lllContainer {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;

		@include viewport(large) {
			flex-direction: row;
		}

		.Interstitial {
			&-video {
				.Video {
					&-playButton {
						position: absolute;
						bottom: 16px;
						right: 16px;
						z-index: 53;

						@include rtl {
							left: 16px;
							right: auto;
						}

						&::before {
							background-color: change-color($black, $alpha: 0.5);
							border-radius: 20px;
						}
					}
				}
			}
		}
	}

	@mixin lllInterstitial($backgroundColor, $textColor) {
		color: $textColor;

		.Interstitial {
			&-backgroundContainer {
				width: 100%;
				@include viewport(large) {
					flex-basis: 62.5%;
				}
				height: 100%;
			}
			&-container {
				width: 100%;
				@include viewport(large) {
					flex-basis: 37.5%;
					height: 100%;
				}
				background-color: $backgroundColor;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;

				.Heading {
					margin-top: 40px;
					margin-bottom: 17px;
					@include viewport(medium) {
						margin-top: 63px;
						margin-bottom: 30px;
					}
					@include viewport(large) {
						margin-top: 0;
						margin-bottom: 100px;
					}
					&-eyebrow {
						@include typography(c1);
						margin-bottom: 10px;
						@include viewport(large) {
							margin-bottom: 20px;
						}
					}

					&-title {
						margin: 10px 15px;
						font-family: Saol Display;
						font-weight: 300;
						letter-spacing: 0px;
						font-size: 40px;
						line-height: 40px;

						@include viewport(medium) {
							margin: 20px 15px;
							font-size: 80px;
							line-height: 65px;
						}

						i {
							margin-right: 3px;
							@include viewport(medium) {
								margin-right: 5px;
							}
							@include rtl {
								margin-right: 0;
								margin-left: 3px;
								@include viewport(medium) {
									margin-left: 5px;
								}
							}
						}
					}

					&-subtitle {
						font-size: rem(18, 16);
						letter-spacing: rem(0.5, 16);
						line-height: rem(27, 16);

						@include viewport(medium) {
							font-size: rem(30, 16);
							letter-spacing: rem(1, 16);
							line-height: rem(41, 16);
						}
					}
				}
			}

			&-logoContainer {
				margin-bottom: 20px;
				@include viewport(medium) {
					margin-bottom: 43px;
				}
				@include viewport(large) {
					margin-bottom: 53px;
				}

				.Navigation-logo {
					color: $textColor;
					font-size: 50px;
					@include viewport(medium) {
						font-size: 100px;
					}
				}
			}

			&-logoImage {
				height: 51px;
				@include viewport(medium) {
					height: 98.5px;
				}
			}

			&-CtaContainer {
				margin-bottom: 90px;

				@include viewport(medium) {
					margin-bottom: 113.5px;
				}
				@include viewport(large) {
					margin-bottom: 0;
				}
				text-align: center;
			}

			&-arrowButton {
				font-size: 13px;
				bottom: 30px;
				margin-top: 33px;
				position: absolute;

				@include viewport(medium) {
					bottom: 40px;
				}

				@include viewport(large) {
					bottom: 50px;
				}
				.Button-label {
					display: block;
					font-size: 0;
					height: 1px;
					overflow: hidden;
				}
			}

			&-countdown {
				color: $textColor;
				.timer svg circle {
					stroke: $textColor;
				}
				bottom: 35px;
				@include viewport(medium) {
					bottom: 45px;
				}

				@include viewport(large) {
					bottom: 55px;
				}
			}
		}

		.BackgroundImage:after {
			background: none;
		}
	}

	&--greenPink {
		@include lllInterstitial($lll-green, $lll-pink);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%) invert(83%) sepia(2%) saturate(2118%)
				hue-rotate(298deg) brightness(114%) contrast(97%);
		}
	}

	&--greenGreen {
		@include lllInterstitial($lll-lightgreen, $lll-green);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%) invert(27%) sepia(41%) saturate(604%)
				hue-rotate(129deg) brightness(92%) contrast(99%);
		}
	}

	&--blueGreen {
		@include lllInterstitial($lll-blue, $lll-lightgreen);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%) invert(92%) sepia(14%) saturate(462%)
				hue-rotate(35deg) brightness(102%) contrast(109%);
		}
	}

	&--pinkRed {
		@include lllInterstitial($lll-pink, $lll-red);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%) invert(12%) sepia(56%) saturate(5903%)
				hue-rotate(340deg) brightness(107%) contrast(116%);
		}
	}
	&--lightBlueGreen {
		@include lllInterstitial($lll-lightblue, $lll-green);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%) invert(27%) sepia(41%) saturate(604%)
				hue-rotate(129deg) brightness(92%) contrast(99%);
		}
	}
	&--yellowOrange {
		@include lllInterstitial($lll-yellow, $lll-orange);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%) invert(20%) sepia(81%) saturate(1778%)
				hue-rotate(352deg) brightness(97%) contrast(106%);
		}
	}

	&--light {
		@include lllInterstitial($white, $black);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%);
		}
	}
	&--black {
		@include lllInterstitial($white, $black);
		.Interstitial-logoImage {
			filter: brightness(0) saturate(100%);
		}
	}
	&--white {
		@include lllInterstitial($black, $white);
	}

	&--dark {
		@include lllInterstitial($black, $white);
	}
}
