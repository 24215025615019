.PrivateJetTripSearch {
	& .surface-raised {
		background: $neutral-0;
	}

	&-container {
		color: $neutral-1700;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 40px 20px;
		gap: 20px;
		@include viewport(medium) {
			padding: 40px 50px;
		}
		@include viewport(large) {
			padding: 60px 50px 80px;
		}
	}

	&-heading {
		margin: 0;
		text-align: center;
		&.heading-2 {
			color: $neutral-1700;
		}
	}

	&-description {
		text-align: center;
		@include viewport(large) {
			margin-top: 10px;
		}
	}

	&-fields {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 30px;
		margin-top: 10px;

		@include viewport(large) {
			flex-direction: row;
			gap: 15px;
			margin-top: 32px;
		}

		@include viewport(xlarge) {
			margin-top: 17px;
		}
	}

	&-field {
		width: 100%;
		flex-basis: calc(45% - 15px);

		& .formElement {
			margin: 0;
			padding: 0;
		}

		& .Dropdown-field {
			height: 40px;
		}

		& .Dropdown::after,
		& .mask {
			top: 6px;
		}
	}

	&-cta {
		display: flex;
		flex-basis: 10%;

		& .button-filled {
			line-height: 20px;
			text-decoration: none;
			min-width: 150px;
			color: $neutral-0;
			background: $neutral-1700;

			&:hover {
				background: $neutral-1500;
			}

			&:focus {
				background: $neutral-1400;
			}
		}
	}
}
