.ImageItem {
	margin-bottom: 60px;

	@include viewport(medium) {
		margin-bottom: 80px;
	}

	@include viewport(large) {
		margin-bottom: 100px;
	}

	@include viewport(xlarge) {
		margin-bottom: 120px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&-picture {
		display: block;
		margin-bottom: 30px;

		@include viewport(medium) {
			margin-bottom: 20px;
		}

		.Image-img {
			width: 100%;
		}
	}

	&-title {
		margin: 0 0 10px;

		@include viewport(medium) {
			margin: 0 0 6px;
		}
	}

	&-text {
		margin: 0;
		font-style: italic;
	}
}
