.FeaturedGeneric {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	@include viewport(medium) {
		flex-direction: row;
		align-items: flex-end;
	}

	&-heading {
		text-align: center;
		flex: 9 3 0;

		@include viewport(medium) {
			text-align: left;

			@include rtl {
				text-align: right;
			}
		}

		.Heading-title {
			margin-top: 5px;
			margin-bottom: 20px;

			@include viewport(medium) {
				margin-bottom: 10px;
			}
		}

		.Heading-text {
			margin-top: 0;
			margin-bottom: 20px;

			@include viewport(medium) {
				margin-bottom: 20px;
			}

			&.HasVideoButton {
				margin-bottom: 20px;
			}
		}
	}

	&-gallery {
		flex: 1 1 0;
		text-align: center;

		@include viewport(medium) {
			text-align: right;
			margin-left: 50px;

			@include rtl {
				text-align: left;
				margin-left: 0;
				margin-right: 50px;
			}
		}

		@include viewport(large) {
			margin-left: 80px;

			@include rtl {
				margin-left: 0;
				margin-right: 80px;
			}
		}

		.Gallery-button {
			margin-bottom: 30px;
			text-align: inherit;
			white-space: nowrap;

			@include viewport(medium) {
				margin-bottom: 20px;
			}

			@include typography(c3);

			&::before {
				margin-right: 4px;
			}

			@include rtl {
				&::before {
					margin-right: 0;
					margin-left: 10px;
				}
			}

			&.Button--labeledPlay {
				&--light {
					.Button-label {
						@include link('underlined', 'light');
						@include typography(c3);

						@include rtl {
							padding-bottom: 1px;
						}
					}
				}
				&--dark {
					.Button-label {
						@include link('underlined', 'dark');
						@include typography(c3);

						@include rtl {
							padding-bottom: 1px;
						}
					}
				}
			}
		}
	}

	&-cta {
		margin: 0 auto 30px;
		text-align: inherit;
		white-space: nowrap;

		@include typography(c3);

		@include viewport(medium) {
			text-align: right;
			margin-left: 50px;
			margin-bottom: 20px;

			@include rtl {
				text-align: left;
				margin-left: 0;
				margin-right: 50px;
			}
		}

		@include viewport(large) {
			margin-left: 80px;

			@include rtl {
				margin-left: 0;
				margin-right: 80px;
			}
		}
	}

	&-image {
		flex: 0 0 100%;
	}
}
