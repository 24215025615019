.Accordion-item-subtitle-additionalSubtitle {
	&-Bold {
		font-weight: bold;
		white-space: nowrap;
	}
	&-Italic {
		font-style: italic;
	}
	&-Regular {
		text-transform: none;
		line-height: 18px;
		letter-spacing: 1px;
	}
}
