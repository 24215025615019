.Experiences {
	position: relative;
	overflow-x: hidden;

	&-spotlight {
		display: block;
		padding-top: percentage(9/16);
		position: relative;

		&::after {
			@include gradient2transparent($height: 28%);

			@include viewport(medium) {
				height: 38%;
			}

			@include viewport(large) {
				height: 28%;
			}

			@include viewport(xlarge) {
				height: 16%;
			}

			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			z-index: 1;
		}
	}

	&-intro {
		&-container {
			@include section-spacing;
			justify-content: center;
		}

		.Heading-title {
			@include headingBorder-2();
			text-align: center;
		}

		.Heading-quote {
			margin: 0;
		}

		.Quote-text {
			@include quote();

			text-align: center;
		}

		.Attribution {
			.Attribution-author,
			.Attribution-role {
				display: block;
			}

			@include viewport(large) {
				.Attribution-author,
				.Attribution-role {
					display: inline;
				}
			}
		}
	}

	&-background {
		position: relative;
	}

	&-video {
		display: none;

		@include viewport(large) {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			padding-top: percentage(9/16);
		}

		.Video {
			&-video {
				position: absolute;
				top: 0;
				width: 100%;
			}

			&-playButton {
				position: absolute;
				bottom: 16px;
				right: 16px;
				z-index: 1;

				@include rtl {
					left: 16px;
					right: auto;
				}
			}
		}
	}

	.Image-img {
		width: 100%;
	}

	.CtaBlock-title {
		@include typography(c1);
		max-width: 100%; /* fix for flex items overflowing container in IE11 */
	}

	.CtaBlock-text {
		@include typography(b2);
		margin: 15px 0 30px;
		max-width: 100%; /* fix for flex items overflowing container in IE11 */
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.Experiences-container {
	text-align: center;

	.CTA {
		.BodySection--dark & {
			background-color: $black;
			border-color: $white;
			color: $white;

			&:hover,
			&:focus {
				background-color: $white;
				color: $black;
			}
		}
	}
}
