$MobileTabs-img-margin-bottom: 30px;
$MobileTabs-img-spacing: (
	small: 15px,
	medium: 20px,
	large: 30px,
);
$MobileTabs-cols: (
	small: 10,
	medium: 10,
	large: 12,
);
$MobileTabs-breakpoint: 375px;

.MobileTabsCard {
	position: relative;
	margin: 0 40px 80px;
	width: 239px;
	height: 360px;
	border: 0.5px solid $white;
	border-radius: 5px;
	overflow: hidden;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.4) 0%,
		rgba(0, 0, 0, 0) 25%,
		rgba(0, 0, 0, 0) 75%,
		rgba(0, 0, 0, 0.4) 100%
	);

	&:lang(ja) {
		font-size: 1rem;
	}

	&:not(.is-selected) {
		cursor: pointer;
	}

	&-title {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		margin: 14px auto 0;
		color: $white;
		letter-spacing: 3.33px;
		line-height: 21px;
		text-align: center;
		text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	}

	&-name {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto 32px;
		color: $white;
		text-align: center;
		font-size: 14px;
		letter-spacing: 3.5px;
		line-height: 21px;
		text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	}
}
