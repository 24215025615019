.Heading {
	&-quote {
		.Quote-text {
			margin: 0;
			margin-top: 10px;
			margin-bottom: 30px;
		}
	}
}
.dt-display {
	color: var(--color-text-heading, #fff);
	font-feature-settings: 'clig' off, 'liga' off;

	/* mobile/display/display1 */
	font-family: 'Saol Display';
	font-size: 48px;
	font-style: normal;
	font-weight: 300;
	line-height: 100%; /* 48px */
	text-transform: uppercase;

	&-1 {
		font-size: 48px;
	}
	&-2 {
		font-size: 42px;
	}
	&-3 {
		font-size: 36px;
	}

	&-1,
	&-2,
	&-3 {
		@extend .dt-display;
	}

	@include viewport(medium) {
		&-1 {
			font-size: 74px;
		}
		&-2 {
			font-size: 62px;
		}
		&-3 {
			font-size: 52px;
		}
	}

	@include viewport(large) {
		&-1 {
			font-size: 112px;
		}
		&-2 {
			font-size: 90px;
		}
		&-3 {
			font-size: 72px;
		}
	}
}

.heading {
	color: var(--color-text-heading, #030303);
	font-feature-settings: 'clig' off, 'liga' off;
	/* desktop/heading/h1 */
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	font-weight: 300;
	line-height: 120%; /* 55.2px */
	letter-spacing: 3.68px;

	&-1 {
		font-size: 28px;
		font-weight: 300;
		line-height: 120%; /* 33.6px */
		letter-spacing: 0.84px;
		text-transform: none;
	}
	&-2 {
		font-size: 24px;
		font-weight: 300;
		line-height: 125%; /* 30px */
		letter-spacing: 0.72px;
		text-transform: none;
	}
	&-3 {
		font-size: 20px;
		font-weight: 500;
		line-height: 130%; /* 26px */
		letter-spacing: 1px;
	}
	&-4 {
		font-size: 20px;
		font-weight: 300;
		line-height: 135%; /* 27px */
		letter-spacing: 0.6px;
	}

	&-1,
	&-2,
	&-3,
	&-4 {
		@extend .heading;
	}

	@include viewport(medium) {
		&-1 {
			font-size: 36px;
			line-height: 120%; /* 43.2px */
			letter-spacing: 4.32px;
			text-transform: uppercase;
		}
		&-2 {
			font-size: 30px;
			line-height: 125%; /* 37.5px */
			letter-spacing: 2.4px;
			text-transform: uppercase;
		}
		&-3 {
			font-size: 24px;
			font-weight: 500;
			line-height: 130%; /* 31.2px */
			letter-spacing: 0.72px;
		}
		&-4 {
			font-size: 20px;
			font-weight: 300;
			line-height: 135%; /* 27px */
			letter-spacing: 0.6px;
		}
	}

	@include viewport(large) {
		&-1 {
			font-size: 46px;
			text-transform: uppercase;
		}
		&-2 {
			font-size: 36px;
			line-height: 125%; /* 45px */
			letter-spacing: 2.88px;
			text-transform: uppercase;
		}
		&-3 {
			font-size: 30px;
			font-weight: 400;
			line-height: 130%; /* 39px */
			letter-spacing: 0.9px;
		}

		&-4 {
			font-size: 24px;
			font-weight: 300;
			line-height: 135%; /* 32.4px */
			letter-spacing: 0.72px;
		}
	}
}

.title {
	&-1 {
		&-primary {
			color: var(--color-text-on-image-primary, rgba(255, 255, 255, 0.87));
			font-feature-settings: 'clig' off, 'liga' off;

			/* mobile/heading/subtitle1 */
			font-family: 'Helvetica Neue', sans-serif;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: 140%; /* 22.4px */
			letter-spacing: 0.48px;
		}
	}
}

.subtitle {
	color: var(--color-text-heading, #030303);
	font-feature-settings: 'clig' off, 'liga' off;
	/* desktop/heading/subtitle1 */
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 28px */

	&-1 {
		font-size: 16px;
		letter-spacing: 0.48px;
	}

	&-2 {
		font-size: 14px;
		letter-spacing: 0.42px;
	}

	&-1,
	&-2 {
		@extend .subtitle;
	}

	@include viewport(large) {
		&-1 {
			font-size: 20px;
			letter-spacing: 0.6px;
		}
		&-2 {
			font-size: 16px;
			letter-spacing: 0.48px;
		}
	}
}

.body {
	color: var(--color-text-heading, #030303);
	font-feature-settings: 'clig' off, 'liga' off;
	/* desktop/body/body1 */
	font-family: 'Garamond MT Std';
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 36px */

	&-1 {
		font-size: 18px;
		letter-spacing: 0.36px;
		&-primary {
			color: var(--color-text-on-image-primary, rgba(255, 255, 255, 0.87));
		}
		&-secondary {
			color: var(--color-text-on-image-secondary, rgba(255, 255, 255, 0.67));
		}
	}
	&-2 {
		font-size: 16px;
		letter-spacing: 0.48px;
		&-disabled {
			color: var(--color-text-on-image-disabled, rgba(255, 255, 255, 0.38));
		}
	}

	&-3 {
		font-size: 14px;
		letter-spacing: 0.56px;
	}

	&-1,
	&-2,
	&-3 {
		@extend .body;
	}

	@include viewport(medium) {
		&-1 {
			font-size: 20px;
			letter-spacing: 0.4px;
		}
	}
	@include viewport(large) {
		&-1 {
			font-size: 24px;
			letter-spacing: 0.48px;
		}
		&-2 {
			font-size: 20px;
			letter-spacing: 0.6px;
		}
	}

	@include viewport(medium) {
		&-3 {
			font-size: 16px;
			letter-spacing: 0.64px;
		}
	}
}

.labels {
	color: var(--color-text-heading, #030303);
	font-feature-settings: 'clig' off, 'liga' off;
	/* desktop/label/button1 */
	font-family: 'Helvetica Neue', sans-serif;
	font-style: normal;
	line-height: 150%; /* 18px */

	&-caption {
		color: var(--color-text-heading, #030303);

		/* desktop/label/caption */
		font-family: 'Helvetica Neue', sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 150%; /* 21px */
		letter-spacing: 0.72px;
		@include viewport(medium) {
			font-size: 14px;
			letter-spacing: 0.84px;
		}
	}

	&-overline {
		font-family: 'Garamond MT Std';
		font-size: 16px;
		font-style: italic;
		font-weight: 400;
		line-height: 140%; /* 22.4px */
		letter-spacing: 0.64px;
	}

	&-button {
		text-transform: uppercase;
		font-weight: 700;
		color: var(--color-button-primary-default, #030303);
		font-feature-settings: 'clig' off, 'liga' off;
		/* fs-mob/label/button2 */
		font-family: 'Helvetica Neue', sans-serif;
		font-size: 10px;
		font-style: normal;
		line-height: 150%; /* 15px */

		&-1 {
			@extend .labels-button;
			font-size: 12px;
			letter-spacing: 2.4px;
		}
		&-2 {
			@extend .labels-button;
			font-size: 10px;
			letter-spacing: 2px;
		}
	}

	&-secondary {
		color: var(--color-text-on-image-secondary, rgba(255, 255, 255, 0.67));
		font-feature-settings: 'clig' off, 'liga' off;

		/* mobile/label/label */
		font-family: 'Helvetica Neue', sans-serif;
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 15px */
		letter-spacing: 1.5px;
		text-transform: uppercase;
	}

	&-label {
		color: var(--color-text-disabled, #6b6b6b);
		/* desktop/label/label */
		font-family: 'Helvetica Neue', sans-serif;
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 18px */
		letter-spacing: 1.5px;
		text-transform: uppercase;
		@include viewport(large) {
			font-size: 12px;
			letter-spacing: 1.8px;
		}
	}
}
