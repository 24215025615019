.PRFilteredListing {
	&-Container {
		max-width: calc(100vw - 30px);

		@include viewport(medium) {
			max-width: 690px;
		}

		@include viewport(large) {
			max-width: 1040px;
		}
		margin: auto;
		padding: 0 0 30px;

		@include viewport(medium) {
			padding: 0 30px 50px;
		}

		.container {
			padding: 0;
		}
	}

	&-title {
		text-align: center;
		margin-bottom: 30px;
	}

	&-text {
		text-align: center;
		margin: 0 30px;
		margin-bottom: 10px;

		@include viewport(medium) {
			margin-bottom: 20px;
		}
	}

	.PaginatedFilter {
		margin-bottom: 25px;

		@include viewport(large) {
			margin-bottom: 0;
		}
		.FilterGroup {
			&-container {
				position: static;
				background-color: $white;
				.FilterGroup-toggle--open .FilterGroup-toggle-button,
				.Button--secondary--light:hover {
					background-color: $white;
					color: $black;

					.Button-label {
						border-bottom: 1px solid $black;
					}
				}

				.row {
					margin: 0;

					@include viewport(medium) {
						margin: 0 -5px;
					}
				}
			}

			&-TextFilter {
				margin-bottom: 20px;
				margin-top: 0;
				width: 100%;

				@include viewport(medium) {
					margin-bottom: 50px;
				}

				.TextInput {
					margin: auto;
					max-width: 400px;

					&-field {
						@include typography(c2);
						background-color: $gray4;
						line-height: 19px;

						&:placeholder-shown {
							@include typography(c2);
							line-height: 19px;
						}
					}
				}
			}

			&-toggle {
				margin: 0;

				&-container {
					padding-bottom: 20px;
					flex-direction: row;
					align-items: baseline;
					justify-content: flex-start;
					border-bottom: 1px solid $gray3;

					@include viewport(medium) {
						padding-bottom: 30px;
						flex-direction: row;
						align-items: baseline;
						justify-content: center;
					}
				}

				&-button {
					padding: 10px 0 10px;

					@include viewport(medium) {
						padding: 0;
					}
					.Button-label {
						text-align: left;

						@include typography(c3);
						font-weight: 400;

						@include viewport(large) {
							@include typography(c1);
							font-weight: 400;
						}

						@include rtl {
							text-align: right;
						}
					}
					&::after {
						margin-left: 10px;
						position: static;
					}

					@include rtl {
						&::after {
							margin-left: 0;
							margin-right: 10px;
						}
					}
				}
			}

			&-filtersHeading {
				width: 100%;

				@include viewport(medium) {
					width: auto;
				}
			}

			&-clearButtonContainer {
				margin: 5px 0 10px auto;
				padding: 0;
				width: auto;

				@include rtl {
					margin: 5px auto 10px 0;
				}

				@include viewport(medium) {
					margin: 0;
					padding: 0 0 0 15px;

					@include rtl {
						margin: 0;
						padding: 0 15px 0 0;
						border-left: none;
					}
				}
			}

			&-optionGroup {
				border: none;
				background-color: $gray6;

				&-container {
					margin-bottom: -10px;
					padding: 0;
					position: relative;
					top: -11px;
				}
				&-fields {
					margin-top: 0;
				}

				&-field {
					padding-left: 15px !important;
					padding-right: 15px !important;

					@include viewport(medium) {
						margin: 10px 0;
					}

					@include viewport(large) {
						margin: 20px 0;
					}

					.formElement-field {
						background-color: $gray6;
					}

					.formElement-label {
						font-weight: 400;
					}
				}

				&-buttons {
					flex-direction: row;
					margin: 20px 15px;

					@include viewport(medium) {
						margin: auto 0;

						&.col-md-12 {
							margin-bottom: 20px;
						}
					}

					@include viewport(large) {
						&.col-md-12 {
							margin: auto 0;
						}
					}
				}

				&-button {
					margin: 0;
					padding: 7.5px 20px;
					z-index: 1;
				}

				&-cancelButton {
					font-size: 10px;
					padding: 0;
					border-bottom: none;

					> span {
						border-bottom: 1px solid #000;
					}
				}

				&-saveButton {
					margin-left: 25px;

					@include rtl {
						margin-left: 0;
						margin-right: 25px;
					}

					@include viewport(medium) {
						margin-left: 30px;

						@include rtl {
							margin-left: 0;
							margin-right: 30px;
						}
					}
				}
			}
		}

		&-filtersHeading {
			color: #666;
			font-weight: 400;
			padding: 10px 0;

			@include viewport(medium) {
				padding: 0;
			}
		}

		&-clearButton {
			margin: 0;
			padding: 0;

			.Button-label {
				@include typography(c3);

				@include viewport(large) {
					@include typography(c1);
				}
			}
		}

		&-body {
			padding-top: 35px !important;

			@include viewport(medium) {
				padding-top: 40px !important;
			}
		}

		&-pagination {
			&-navigation {
				margin-top: 0;
				position: relative;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}

			&-showAll {
				display: block;
				margin: auto;
				margin-top: 15px;

				@include viewport(large) {
					margin-top: auto;
				}

				@include typography(c3);

				@include viewport(large) {
					@include typography(c1);
				}

				&::after {
					@include icon('icon-plus_sign');
					font-size: 9px;
					padding-left: 10px;
				}

				@include rtl {
					&::after {
						padding-left: 0px;
						padding-right: 10px;
					}
				}
			}

			&-showLess-container {
				display: none;
				margin-top: 0;
			}

			&-showLess {
				display: block;
				margin: auto;
				margin-top: 15px;

				@include viewport(large) {
					margin-top: auto;
				}

				@include typography(c3);

				@include viewport(large) {
					@include typography(c1);
				}

				&::after {
					@include icon('icon-less_sign');
					font-size: 9px;
					padding-left: 10px;
				}

				@include rtl {
					&::after {
						padding-left: 0px;
						padding-right: 10px;
					}
				}
			}

			&-button {
				display: none;
				height: auto;
				width: auto;
				margin: 0 7.5px;
				&.activeButton {
					color: $black;
					background-color: #f4f4f4;
					border-radius: 0;
					border: 1px solid $black;
					padding: 6px;
					padding-left: 8px;

					@include rtl {
						padding-left: 6px;
						padding-right: 8px;
					}
				}

				@include viewport(large) {
					margin: 0 15px;
				}
			}

			&-buttons {
				display: flex;
			}

			&-arrowButton {
				font-size: 15px;
				width: 15px;

				@include viewport(large) {
					width: 48px;
				}
			}

			&--next {
				margin-left: 6.25px;

				@include rtl {
					margin-left: 0;
					margin-right: 6.25px;
				}

				@include viewport(medium) {
					margin: 0;
				}
			}

			&--prev {
				margin-right: 6.25px;

				@include rtl {
					margin-left: 6.25px;
					margin-right: 0;
				}

				@include viewport(medium) {
					margin: 0;
				}
			}
		}

		&-noResults {
			display: flex;
			flex-direction: column;
			margin-top: 35px;

			&-text {
				@include typography(c1);
				margin-bottom: 10px;
			}

			&-subtext {
				margin-bottom: 60px;
			}

			&-cta {
				margin: auto;

				@include viewport(medium) {
					margin: 0;
					margin-left: auto;

					@include rtl {
						margin-left: 0;
						margin-right: auto;
					}
				}
			}
		}

		&-paginationContainer {
			display: flex;
			flex-wrap: wrap;
			position: relative;
			margin-bottom: 0;
			overflow: hidden;

			@include viewport(medium) {
				margin-bottom: 0px;
			}

			&::before {
				content: none;
			}
		}

		&--loading {
			padding-top: 0;
		}

		&-itemContainer {
			width: 100%;
		}
	}

	&-Filter {
		position: relative;
		padding: 0 15px;

		@include viewport(medium) {
			padding: 0 10px;
		}

		@include viewport(large) {
			padding: 0 15px;
		}

		&.FilterGroup-toggle--open {
			& + .FilterGroup-optionGroup-container--mobile .FilterGroup-optionGroup--mobile {
				display: block;
			}

			&::after {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				z-index: 1;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-bottom: 20px solid $gray6;
				left: 50%;
				transform: translateX(calc(-50% - 12px));
				bottom: -22px;
			}
		}

		&--location {
			&.FilterGroup-toggle.PRFilteredListing-Filter {
				margin-left: 0;
				padding: 0 20px 0 0;

				@include rtl {
					padding: 0 0 0 20px;
				}

				@include viewport(medium) {
					padding: 0 10px 0 20px;
					@include rtl {
						padding: 0 20px 0 10px;
						margin-right: 0;
					}
				}

				@include viewport(large) {
					padding: 0 15px 0 30px;
					@include rtl {
						padding: 0 30px 0 15px;
					}
				}
			}
		}

		&--vacationTypes {
			padding: 0 25px 0 0px;
			@include rtl {
				padding: 0 0 0 25px;
			}

			@include viewport(medium) {
				padding: 0 10px;

				@include rtl {
					padding: 0 10px;
				}
			}

			@include viewport(large) {
				padding: 0 15px;

				@include rtl {
					padding: 0 15px;
				}
			}
		}

		&--vacationStyles {
			padding: 0 25px 0 0px;
			@include rtl {
				padding: 0 0 0 25px;
			}

			@include viewport(medium) {
				padding: 0 10px;

				@include rtl {
					padding: 0 10px;
				}
			}

			@include viewport(large) {
				padding: 0 15px;

				@include rtl {
					padding: 0 15px;
				}
			}
		}

		.Button-label {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}
