.ComponentContainer {
	position: relative;

	&-stickyContainer {
		@include viewport(large) {
			position: absolute;
			width: 100%;
		}

		&.is-stick {
			position: fixed;
			top: 0;
			transition: top 0.3s;
		}

		&.is-bottomStick {
			bottom: 0;
		}
	}

	&-navigation {
		margin-bottom: 8px;

		@include viewport(large) {
			margin-bottom: 0;
			padding-right: 50px;

			@include rtl {
				padding-right: 0;
				padding-left: 50px;
			}
		}

		@include viewport(xlarge) {
			padding-right: 0;

			@include rtl {
				padding-left: 0;
			}
		}
	}

	&-title {
		margin: 32px 0 21px;
	}

	&-links {
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;

		.BodySection--light & {
			border-color: $black;
		}

		@include viewport(large) {
			border-bottom: none;
		}
	}

	&-link {
		padding-bottom: 20px;

		&:first-child {
			padding-top: 20px;
		}
	}

	&-items {
		position: relative;
		z-index: 1;
	}

	&-item {
		&.SpacedComponent {
			.ComponentContainer & {
				margin-bottom: 40px;

				@include viewport(large) {
					margin-bottom: 60px;
				}

				@include viewport(xlarge) {
					margin-bottom: 80px;
				}
			}
		}

		.container {
			padding-left: 0;
			padding-right: 0;
		}

		&.container,
		&.RichText {
			margin-bottom: 40px;
			padding-left: 0;
			padding-right: 0;

			@include viewport(medium) {
				margin-bottom: 60px;
			}

			@include viewport(large) {
				margin-bottom: 80px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&.HeadingComponent {
			&:first-child {
				border-top: 0;
				padding-top: 0;
				margin-top: 0;
			}

			&:last-child .Heading-text {
				margin-bottom: 0;
			}

			.Heading {
				&-text {
					margin-bottom: 40px;

					@include viewport(large) {
						margin-bottom: 60px;
					}

					&:lang(ja) {
						font-size: 1rem;
					}
				}
			}

			+ .RichText {
				margin-top: -30px;

				@include viewport(large) {
					margin-top: -50px;
				}
			}
		}
	}
}
