// variables
$error-red: #ea0a0a;

//mixins
@mixin placeholder-default {
	opacity: 1;
	color: $gray1;
	transition: 1s ease;

	.textInput--error & {
		color: $error-red;
	}
}
@mixin placeholder-hover {
	color: $gray1;
}
@mixin placeholder-focus {
	opacity: 0;
	transition: 300ms ease;
}
@mixin placeholder-disabled {
	color: $gray2;
}
@mixin placeholder-takeover {
	color: transparent;
}
@mixin labelFocus() {
	opacity: 1;
	transform: translateY(-22px);
	letter-spacing: 2.05px;
}

// textInput
.textInput {
	position: relative;

	.icon-close {
		fill: $black;
	}

	&--error {
		color: $error-red;

		.icon-close {
			fill: $error-red;
		}
	}

	&--toggled {
		padding: 0;
	}

	&--destination {
		padding-bottom: 0;
	}
}

// field

.caw-comp__wrapper,
.caw-popup__wrapper {
	.textInput {
		&__field {
			background: $gray6;
			border-bottom: 0;
			padding: 6px 15px;
			min-height: 40px;

			&.ty-h4 {
				letter-spacing: 1px;
			}

			&:valid {
				padding-left: 15px;
			}
		}

		&_clear {
			top: 16px;
			right: 13px;
			width: 12px;
			height: 12px;

			svg {
				display: block;
			}
		}

		&--destination .textInput__field {
			padding: 5px 45px 5px 15px;
		}
	}
}
