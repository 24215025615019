.Collage {
	&-item {
		.Image {
			display: block;
			line-height: 0;
		}
	}

	&-item:first-of-type {
		margin-bottom: 30px;

		@include viewport(medium) {
			margin-bottom: 0;
		}
	}

	&-heading {
		margin-bottom: 54px;
		text-align: center;
	}
	&-title {
		text-transform: uppercase;

		&::after {
			content: '';
			display: block;
			height: $separator-height;
			margin: 0 auto;
			position: relative;
			top: (10px - $separator-height);
			width: 40px;
		}
	}

	&-cta {
		text-align: center;
		margin-top: 30px;
	}

	&--light {
		.Collage-title::after {
			background-color: $black;
		}
	}

	&--dark {
		.Collage-title::after {
			background-color: $white;
		}
	}
}
