@function buildColClassList() {
	$commaClassList: '';

	@each $size, $setup in $_layout-setup {
		$bp: map-get($setup, 'shortname');

		@for $i from 1 through $_total-columns {
			@if $commaClassList == '' {
				$commaClassList: '.col-#{$bp}-#{$i}';
			} @else {
				$commaClassList: append($commaClassList, '.col-#{$bp}-#{$i}', 'comma');
			}
		}
	}

	@return $commaClassList;
}

#{buildColClassList()} {
	width: 100%;
	float: left;
	min-height: 1px;
	vertical-align: middle;
	padding-left: $gutter_small / 2;
	padding-right: $gutter_small / 2;

	@include rtl {
		float: right;
	}

	@include viewport(medium) {
		padding-left: $gutter_medium / 2;
		padding-right: $gutter_medium / 2;
	}
}

.clearfix {
	@include clearfix();
}

.container {
	margin-left: auto;
	margin-right: auto;
	padding-left: $gutter_small;
	padding-right: $gutter_small;

	@include viewport(medium) {
		padding: 0;
	}
}

.row {
	@include clearfix();
	margin-left: $gutter_small / -2;
	margin-right: $gutter_small / -2;

	@include viewport(medium) {
		margin-left: $gutter_medium / -2;
		margin-right: $gutter_medium / -2;
	}
}

// Build Bootstrap Classes for each breakpoint size
@include gridBP(small, sm);
@include gridBP(medium, md, $breakpoints-md);
@include gridBP(large, lg, $breakpoints-lg);
@include gridBP(xlarge, xl, $breakpoints-xl);

@each $viewportName in map-keys($_layout-setup) {
	@include rowVCenter($viewportName);
}

.auto-clear-sm {
	@include viewport(small) {
		@include row-first-child(sm, both);
	}
}

.auto-clear-md {
	@include viewport(medium) {
		@include row-first-child(md, both);
	}
}

.auto-clear-lg {
	@include viewport(large) {
		@include row-first-child(lg, both);
	}
}

.auto-clear-xl {
	@include viewport(xlarge) {
		@include row-first-child(xl, both);
	}
}
