$iconClickableArea: 48px;

@mixin grayHover($theme, $position: '') {
	transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;

	@if $position != '' {
		&:focus::#{$position},
		&:hover::#{$position} {
			@if $theme == 'light' {
				color: $whover;
			}
			@if $theme == 'dark' {
				color: $bhover;
			}
			@if $theme == 'white' {
				color: $whover;
			}
			@if $theme == 'black' {
				color: $bhover;
			}
			@if $theme == 'greenPink' {
				color: $lll-pink-hover;
			}
			@if $theme == 'greenGreen' {
				color: $lll-green-hover;
			}
			@if $theme == 'blueGreen' {
				color: $lll-lightgreen-hover;
			}
			@if $theme == 'pinkRed' {
				color: $lll-red-hover;
			}
			@if $theme == 'lightBlueGreen' {
				color: $lll-green-hover;
			}
			@if $theme == 'yellowOrange' {
				color: $lll-orange-hover;
			}
			transition: background-color 0.3s ease-out, color 0.3s ease-out,
				border-color 0.3s ease-out;
		}
	}

	&:focus,
	&:hover {
		@if $theme == 'light' {
			border-color: $whover;
			color: $whover;
		}
		@if $theme == 'dark' {
			border-color: $bhover;
			color: $bhover;
		}
		@if $theme == 'white' {
			border-color: $whover;
			color: $whover;
		}
		@if $theme == 'black' {
			border-color: $bhover;
			color: $bhover;
		}
		@if $theme == 'greenPink' {
			border-color: $lll-pink-hover;
			color: $lll-pink-hover;
		}
		@if $theme == 'greenGreen' {
			border-color: $lll-green-hover;
			color: $lll-green-hover;
		}
		@if $theme == 'blueGreen' {
			border-color: $lll-lightgreen-hover;
			color: $lll-lightgreen-hover;
		}
		@if $theme == 'pinkRed' {
			border-color: $lll-red-hover;
			color: $lll-red-hover;
		}
		@if $theme == 'lightBlueGreen' {
			border-color: $lll-green-hover;
			color: $lll-green-hover;
		}
		@if $theme == 'yellowOrange' {
			border-color: $lll-orange-hover;
			color: $lll-orange-hover;
		}
	}
}

/* BUTTONS */
@mixin borderedButton($textColor: $white, $bgColor: $black, $borderColor: $black) {
	background-color: $bgColor;
	border-color: $borderColor;
	color: $textColor;
}

@mixin button($type: 'primary', $theme: 'light') {
	@include typography(c3);
	align-items: center;
	border-style: solid;
	border-width: 1px;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-flex;
	font-weight: bold;
	justify-content: center;
	min-width: 130px;
	padding: 12px 20px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;

	@include wls {
		font-family: 'EB Garamond';
		text-align: center;
		font-size: rem(14, 16);
		line-height: em(27, 14);
		letter-spacing: em(1, 14);
	}

	@if $type == 'primary' {
		@if $theme == 'light' {
			@include borderedButton($white, $black, $black);

			&:focus,
			&:hover {
				@include borderedButton($black, $white, $black);
			}
		}
		@if $theme == 'dark' {
			@include borderedButton($black, $white, $white);

			&:focus,
			&:hover {
				@include borderedButton($white, $black, $white);
			}
		}
		@if $theme == 'black' {
			@include borderedButton($white, $black, $black);

			&:focus,
			&:hover {
				@include borderedButton($black, $white, $black);
			}
		}
		@if $theme == 'white' {
			@include borderedButton($black, $white, $white);

			&:focus,
			&:hover {
				@include borderedButton($white, $black, $white);
			}
		}
		@if $theme == 'greenPink' {
			@include borderedButton($lll-pink, $lll-green, $lll-pink);

			&:focus,
			&:hover {
				@include borderedButton($lll-green, $lll-pink, $lll-pink);
			}
		}
		@if $theme == 'greenGreen' {
			@include borderedButton($lll-green, $lll-lightgreen, $lll-green);

			&:focus,
			&:hover {
				@include borderedButton($lll-lightgreen, $lll-green, $lll-green);
			}
		}
		@if $theme == 'blueGreen' {
			@include borderedButton($lll-lightgreen, $lll-blue, $lll-lightgreen);

			&:focus,
			&:hover {
				@include borderedButton($lll-blue, $lll-lightgreen, $lll-lightgreen);
			}
		}
		@if $theme == 'pinkRed' {
			@include borderedButton($lll-red, $lll-pink, $lll-red);

			&:focus,
			&:hover {
				@include borderedButton($lll-pink, $lll-red, $lll-red);
			}
		}
		@if $theme == 'lightBlueGreen' {
			@include borderedButton($lll-green, $lll-lightblue, $lll-green);

			&:focus,
			&:hover {
				@include borderedButton($lll-lightblue, $lll-green, $lll-green);
			}
		}
		@if $theme == 'yellowOrange' {
			@include borderedButton($lll-orange, $lll-yellow, $lll-orange);

			&:focus,
			&:hover {
				@include borderedButton($lll-yellow, $lll-orange, $lll-orange);
			}
		}
	}

	@if $type == 'secondary' {
		@if $theme == 'light' {
			@include borderedButton($black, $white, $black);

			&:focus,
			&:hover {
				@include borderedButton($white, $black, $black);
			}
		}
		@if $theme == 'dark' {
			@include borderedButton($white, $black, $white);

			&:focus,
			&:hover {
				@include borderedButton($black, $white, $white);
			}
		}
		@if $theme == 'black' {
			@include borderedButton($white, $black, $black);

			&:focus,
			&:hover {
				@include borderedButton($black, $white, $black);
			}
		}
		@if $theme == 'white' {
			@include borderedButton($black, $white, $white);

			&:focus,
			&:hover {
				@include borderedButton($white, $black, $white);
			}
		}
		@if $theme == 'greenPink' {
			@include borderedButton($lll-green, $lll-pink, $lll-pink);

			&:focus,
			&:hover {
				@include borderedButton($lll-pink, $lll-green, $lll-pink);
			}
		}
		@if $theme == 'greenGreen' {
			@include borderedButton($lll-lightgreen, $lll-green, $lll-green);

			&:focus,
			&:hover {
				@include borderedButton($lll-green, $lll-lightgreen, $lll-green);
			}
		}
		@if $theme == 'blueGreen' {
			@include borderedButton($lll-blue, $lll-lightgreen, $lll-lightgreen);

			&:focus,
			&:hover {
				@include borderedButton($lll-lightgreen, $lll-blue, $lll-lightgreen);
			}
		}
		@if $theme == 'pinkRed' {
			@include borderedButton($lll-pink, $lll-red, $lll-red);

			&:focus,
			&:hover {
				@include borderedButton($lll-red, $lll-pink, $lll-red);
			}
		}
		@if $theme == 'lightBlueGreen' {
			@include borderedButton($lll-lightblue, $lll-green, $lll-green);

			&:focus,
			&:hover {
				@include borderedButton($lll-green, $lll-lightblue, $lll-green);
			}
		}
		@if $theme == 'yellowOrange' {
			@include borderedButton($lll-yellow, $lll-orange, $lll-orange);

			&:focus,
			&:hover {
				@include borderedButton($lll-orange, $lll-yellow, $lll-orange);
			}
		}
	}
}

/* LINKS */
@mixin playIcon() {
	@include icon('icon-play_button_small');
	display: inline-block;
	font-size: rem(20, 16);
	// Solves a problem with hidden overflow on Safari
	line-height: em(23, 16);
	vertical-align: middle;
}

@mixin link($type: 'underlined', $theme: 'light', $position: before, $icon: '') {
	@include typography(c1);
	background: none;
	border: 0;
	cursor: pointer;
	padding: 0;
	text-decoration: none;

	@include wls {
		font-family: 'EB Garamond';
		text-align: center;
		font-size: rem(14, 16);
		line-height: em(27, 14);
		letter-spacing: em(1, 14);
	}

	@if $type == 'underlined' {
		border-bottom: 1px solid;
		font-weight: bold;
		padding-bottom: 3px;
		text-transform: uppercase;

		@if $icon != '' {
			position: relative;
			margin-#{if($position == before, "left", "right")}: 30px;

			&::#{$position} {
				@include icon($icon);
				display: inline-block;
				font-size: rem(19, 16);
				position: absolute;
				top: 6px;
				#{if($position == before, "left", "right")}: -30px;
			}

			@include rtl {
				margin-#{if($position == before, "left", "right")}: 0;
				margin-#{if($position == before, "right", "left")}: 30px;

				&::#{$position} {
					#{if($position == before, "right", "left")}: -30px;
					#{if($position == before, "left", "right")}: auto;
				}
			}
		}
	}

	@if $type == 'labeledPlay' {
		@include typography(c2);
		font-weight: bold;
		text-transform: uppercase;

		.Button-label {
			vertical-align: middle;
		}

		&::before {
			@include playIcon();
			// Make the play button grow with the font
			// (should be 20px when the font is 12px)
			font-size: em(20, 12);
		}
	}

	@if $type == 'arrow' {
		&::after {
			@include icon('icon-arrow_right');
			float: right;
			padding-left: 10px;
			display: inline-block;
			font-size: rem(12, 16);
			vertical-align: middle;
		}

		@include rtl {
			&::after {
				@include icon('icon-arrow_left');
				float: left;
				padding-left: 0;
				padding-right: 10px;
			}
		}
	}

	@if $type == 'backButton' {
		@include typography(c4);

		position: absolute;
		display: block;
		top: get-gutter();
		left: get-gutter();

		@include viewport(medium) {
			top: 30px;
			left: 30px;
		}

		@include viewport(large) {
			top: 40px;
			left: 40px;
		}

		&::before {
			@include icon('icon-chevron_left');
			padding-right: 10px;
			display: inline-block;
			font-size: 20px;
			vertical-align: middle;
		}

		@include rtl {
			left: inherit;
			right: get-gutter();

			@include viewport(medium) {
				right: 30px;
			}

			@include viewport(large) {
				right: 40px;
			}

			&::before {
				@include icon('icon-chevron_right');
				padding-left: 10px;
				padding-right: 0;
			}
		}
	}

	&:focus,
	&:hover,
	&:focus::#{$position},
	&:hover::#{$position} {
		border-bottom-color: transparent;
	}

	@if $theme == 'light' {
		border-bottom-color: $black;
		color: $black;
	}

	@if $theme == 'dark' {
		border-bottom-color: $white;
		color: $white;
	}

	@if $theme == 'greenPink' {
		border-bottom-color: $lll-pink;
		color: $lll-pink;
	}

	@if $theme == 'greenGreen' {
		border-bottom-color: $lll-green;
		color: $lll-green;
	}

	@if $theme == 'blueGreen' {
		border-bottom-color: $lll-lightgreen;
		color: $lll-lightgreen;
	}

	@if $theme == 'white' {
		border-bottom-color: $white;
		color: $white;
	}

	@if $theme == 'black' {
		border-bottom-color: $black;
		color: $black;
	}

	@if $theme == 'pinkRed' {
		border-bottom-color: $lll-red;
		color: $lll-red;
	}

	@if $theme == 'lightBlueGreen' {
		border-bottom-color: $lll-green;
		color: $lll-green;
	}

	@if $theme == 'yellowOrange' {
		border-bottom-color: $lll-orange;
		color: $lll-orange;
	}

	@if $theme != 'red' {
		@include grayHover($theme);
	}
}

/* CHEVRONS */
@mixin chevron($direction: 'left', $theme: 'light', $position: before) {
	@include iconButton($position, 'icon-chevron_#{$direction}', $theme);

	@include rtl {
		@include iconButton(
			$position,
			'icon-chevron_#{if($direction == 'left', 'right' , 'left')}',
			$theme
		);
	}
}

/* PLAY/PAUSE BUTTON */
@mixin playButton($state: 'play', $theme: 'light', $position: before) {
	@include iconButton($position, 'icon-#{$state}_button_small', $theme);
}

@mixin iconButton($position: before, $icon: '', $theme: 'light') {
	background: none;
	border: 0;
	cursor: pointer;
	font-size: rem(20, 16);
	height: $iconClickableArea;
	padding: 0;
	width: $iconClickableArea;

	&::#{$position} {
		line-height: $iconClickableArea;
	}

	.Button-label {
		display: block;
		font-size: 0;
		height: 1px;
		overflow: hidden;
	}

	@if $icon != '' {
		&::#{$position} {
			@include icon('#{$icon}');
			color: if($theme == 'light', $black, $white);
		}
	}

	@include grayHover($theme, $position);
}

@mixin quotationIcons($position: before, $theme: 'light', $type: 'open') {
	&::#{$position} {
		color: if($theme == 'light', $gray3, $gray2);
		font-size: rem(20, 16);
		line-height: 1em;

		@if $type == 'close' {
			transform: rotate(180deg);
		}

		@include icon(icon-quotation_marks_s);

		@include viewport(large) {
			@include icon(icon-quotation_marks_l);
			font-size: rem(30, 16);
		}
	}

	@include rtl {
		&::#{$position} {
			transform: rotate(180deg);

			@if $type == 'close' {
				transform: none;
			}
		}
	}
}

@mixin gradient2transparent(
	$color: $black,
	$height: 100%,
	$start: 0%,
	$end: 100%,
	$direction: to bottom
) {
	background: linear-gradient($direction, transparentize($color, 1) $start, $color $end);
	height: $height;
	width: 100%;
}

@function _getString() {
	$string: '';

	@for $i from 1 through ($stops - 1) {
		$stop: ((1 / $stops) * $i);
		$string: '#{$string} #{transparentize($color, (-0.9523*$stop*$stop) - 0.0476*$stop + 1) 0% + percentage($stop)}, ';
	}

	@return 'transparent 0%, #{$string}#{$color} 100%';
}

@mixin easedGradient2transparent($color: $black, $height: 100%, $stops: 5) {
	background: linear-gradient(to bottom, #{_getstring()});
	height: $height;
	width: 100%;
}

@mixin rtl-side-border($side: left, $color: $white, $width: 1px, $padding: 20px) {
	@if $side == left {
		border-left: $width solid $color;
		border-right-width: 0;
		padding-left: $padding;
		padding-right: 0;
	} @else {
		border-left-width: 0;
		border-right: $width solid $color;
		padding-left: 0;
		padding-right: $padding;
	}
}

@mixin multiLineElipsisClamp($lineCount: 2) {
	display: -webkit-box;
	-webkit-line-clamp: $lineCount;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 1em;
}

@each $network, $icon in $socialNetworks {
	.#{$network}::before {
		@include icon($icon);

		display: inline-block;
		font-size: rem(15, 16);
		margin-right: 8px;
		vertical-align: bottom;
	}

	:lang(ar).#{$network}::before {
		margin-left: 8px;
		margin-right: 0;
	}
}
