@mixin height($value) {
	@media (min-height: $value) and (min-width: 970px) {
		@content;
	}
}

.Disclaimer {
	margin: 40px 0 40px;

	@include media($navigationBreakpoint) {
		margin: 30px 0 40px;
	}

	@include viewport(xlarge) {
		margin: 60px 0 80px;
	}

	// Hack to handle centering the dynamic sizing calendar with disclaimer
	// Shame!
	@include height(838px) {
		.App-wrapper__row > &:first-child {
			margin-top: -130px;
		}
	}

	&_text {
		@include serifItalic;
		font-size: rem(18, 16);
		letter-spacing: em(0.5, 18);
		line-height: em(27, 18);

		a {
			color: $black;
		}
	}
}
