$signatureCardAspectRatio: 16 / 9;

.SignatureCard {
	&-container {
		position: relative;
	}

	&-backgroundImage {
		position: relative;
		width: 100%;
		aspect-ratio: 1;
		@include viewport(medium) {
			aspect-ratio: $signatureCardAspectRatio;
		}
		height: auto;
	}

	&-video {
		position: absolute;
		top: 0;
		width: 100%;
		overflow: hidden;
		aspect-ratio: 1;
		height: auto;
		width: 100%;

		@include viewport(medium) {
			aspect-ratio: $signatureCardAspectRatio;
		}
		.Video {
			&-video {
				position: absolute;
				width: auto;
				height: 100%;
				top: 50%;
				left: 50%;
				min-height: 100%;
				min-width: 100%;
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);

				@include viewport(medium) {
					width: 100%;
					height: auto;
				}
			}

			&-playButton {
				position: absolute;
				bottom: 15px;
				right: 15px;
				font-size: 20px;
				height: 20px;
				width: 20px;
				background: var(--color-img-overlay-40);
				border-radius: 10px;

				&::before {
					line-height: 20px;
				}

				@include rtl {
					left: 15px;
					right: unset;
				}

				@include viewport(medium) {
					bottom: 20px;
					right: 20px;
					font-size: 40px;
					height: 40px;
					width: 40px;
					border-radius: 20px;

					&::before {
						line-height: 40px;
					}
				}

				@include viewport(large) {
					bottom: 30px;
					left: 30px;
					right: unset;
					@include rtl {
						left: unset;
						right: 30px;
					}
				}

				&--hasAvailabilityMessage {
					@extend .Video-playButton;
					bottom: 45px;

					@include rtl {
						left: 15px;
						right: unset;
					}

					@include viewport(medium) {
						bottom: 50px;
						right: 20px;
						left: unset;

						@include rtl {
							left: 20px;
							right: unset;
						}
					}

					@include viewport(large) {
						bottom: 30px;
						right: unset;
						left: 30px;

						@include rtl {
							left: unset;
							right: 30px;
						}
					}
				}
			}
		}
		&--hasIndicator .Video-playButton {
			bottom: 15px;
			right: 15px;
			left: unset;

			@include rtl {
				left: 15px;
				right: unset;
			}

			@include viewport(medium) {
				bottom: 20px;
				right: 20px;
				left: unset;

				@include rtl {
					left: 20px;
					right: unset;
				}
			}

			@include viewport(large) {
				bottom: 15px;
				right: unset;
				left: 30px;

				@include rtl {
					left: unset;
					right: 30px;
				}
			}
		}
	}

	&-textContent {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		width: 100%;
		padding: 20px;
		gap: 10px;
		align-self: stretch;
		background: var(--color-surface-raised-subtle, #1f1f1f);
		box-shadow: var(--surface-raised-subtle-shadow, none);
		backdrop-filter: blur(30px);

		@include viewport(medium) {
			padding: 30px;
		}

		@include viewport(large) {
			background: var(--color-surface-translucent, rgba(45, 45, 45, 0.6));
			box-shadow: none;
		}

		@include viewport(xlarge) {
			padding: 50px;
		}

		&-container {
			@include viewport(large) {
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: 1;

				width: 400px;

				@include rtl {
					right: unset;
					left: 0;
				}
			}

			@include viewport(xlarge) {
				width: 540px;
			}
		}
	}

	&-callout {
		display: flex;
		align-items: flex-end;
		gap: 5px;
		align-self: stretch;
	}

	&-price {
		font-size: 14px;
	}

	&-cta {
		margin-top: 10px;
		color: var(--color-button-primary-default, #030303);
		text-align: center;
		font-feature-settings: 'clig' off, 'liga' off;

		/* MVP/fs-mob-mvp/label/button2 */
		font-family: 'Helvetica Neue', sans-serif;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: 150%; /* 15px */
		letter-spacing: 2px;
		text-transform: uppercase;
		text-decoration: underline;
		text-underline-offset: 3px;
	}

	&-button {
		margin-top: 10px;
		display: flex;
		height: 40px;
		padding: 10px 25px 10px 20px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		&-icon {
			font-size: 16px;
		}
	}

	&-availMessage {
		position: relative;
		height: 30px;
		margin-top: -30px;
		z-index: 1;
		width: 100%;
		padding: 6px 4px;
		display: flex;
		align-items: center;
		gap: 8px;
		background: var(--color-img-overlay-60);
		color: var(--color-text-on-image-primary);
		justify-content: center;
		align-items: center;
		backdrop-filter: blur(30px);

		&::before {
			content: '';
			display: block;
			height: 7px;
			width: 7px;
			border-radius: 50%;
		}

		&--GREEN {
			&::before {
				background-color: #45ff04;
			}
		}

		&--YELLOW {
			&::before {
				background-color: #f8ad3d;
			}
		}

		&--RED {
			&::before {
				background-color: #ea0000;
			}
		}

		&__message {
			font-family: 'Helvetica Neue', sans-serif;
			font-style: normal;
			font-weight: 300;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: 0.72px;
			color: var(--color-text-on-image-primary);

			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	&-description {
		margin-bottom: 10px;
	}

	&-hide {
		&--desktop {
			display: flex;
			@include viewport(medium) {
				display: none;
			}
		}

		&--mobile {
			display: none;
			@include viewport(medium) {
				display: flex;
			}
		}
	}
}
