.ServiceTable {
	&-items {
		border: 1px solid $separatorLight;
		padding: 35px 15px;

		.ServiceTable--dark & {
			border-color: $separatorDark;
		}

		@include viewport(medium) {
			display: flex;
			flex-flow: wrap;
			border: none;
			padding: 0;
		}

		@include viewport(large) {
			flex-flow: row;
		}

		&--2,
		&--3 {
			@include viewport(medium) {
				flex-flow: row;
			}
		}
	}

	&-item {
		&:not(:last-child) {
			margin-bottom: 30px;
		}

		@include viewport(medium) {
			border: 1px solid $separatorLight;

			&:not(:last-child) {
				margin-bottom: 0;
			}

			.ServiceTable--dark & {
				border-color: $separatorDark;
			}
		}

		.ServiceTable-items--2 & {
			@include viewport(medium) {
				width: 50%;
				padding: 35px 50px;

				&:first-child {
					border-right-width: 0;
				}

				@include rtl {
					&:first-child {
						border-right-width: 1px;
						border-left-width: 0;
					}
				}
			}
		}

		.ServiceTable-items--3 & {
			@include viewport(medium) {
				padding: 36px 23px;
				width: 33.33%;

				&:first-child {
					border-right-width: 0;
				}

				&:last-child {
					border-left-width: 0;
				}

				@include rtl {
					&:first-child {
						border-right-width: 1px;
						border-left-width: 0;
					}

					&:last-child {
						border-left-width: 1px;
						border-right-width: 0;
					}
				}
			}

			@include viewport(large) {
				padding: 50px 28px;
			}
		}

		.ServiceTable-items--4 & {
			@include viewport(medium) {
				width: 50%;
				padding: 35px 70px;

				&:first-child {
					border-right-width: 0;
				}

				&:nth-child(3) {
					border-top-width: 0;
					border-right-width: 0;
				}

				&:last-child {
					border-top-width: 0;
				}

				@include rtl {
					&:first-child,
					&:nth-child(3) {
						border-right-width: 1px;
						border-left-width: 0;
					}
				}
			}

			@include viewport(large) {
				width: 25%;
				padding: 60px 39px;

				&:nth-child(3) {
					border-top-width: 1px;
					border-left-width: 0;
				}

				&:last-child {
					border-top-width: 1px;
				}

				@include rtl {
					&:nth-child(3) {
						border-right-width: 0;
					}
				}
			}
		}
	}

	&-service {
		@include viewport(medium) {
			text-align: center;
		}
	}

	&-title {
		margin: 0;
	}

	&-description {
		margin-bottom: 0;
	}
}
