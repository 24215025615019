.Testimonials {
	.InlineCarousel-button {
		height: 100%;
	}

	.TestimonialSlide {
		display: block;

		&-wrapperContent {
			display: block;
			position: relative;
			background-color: $gray1;
			height: 100%;
		}

		&-wrapper {
			display: block;
			margin: 0;
			padding: 20px;

			@include viewport(medium) {
				padding: 20px 30px;
			}

			@include viewport(large) {
				display: flex;
				align-items: stretch;
				padding: 40px 80px;
			}
		}

		&-quote {
			display: block;
			margin-bottom: 15px;

			.Quote-text {
				display: block;
				margin: 0;
			}
		}

		&-imageCircle {
			display: inline-block;

			.ImageCircle-img {
				display: block;
				width: 30px;
				height: 30px;
			}
		}

		&-separator {
			height: 1px;
			width: 100%;
			background-color: $separatorDark;
			margin: 20px 0;

			@include viewport(large) {
				position: absolute;
				width: 1px;
				height: 100%;
				right: 50%;
				transform: translateX(50%);
				margin: 0;
			}
		}

		&-attributionWrapper,
		&-iconTextWrapper {
			display: flex;
			align-items: center;
		}

		&-attribution {
			display: inline-block;
			margin-left: 15px;
			text-align: left;

			@include rtl {
				text-align: right;
				margin-left: 0;
				margin-right: 15px;
			}
		}

		&-icon {
			display: flex;
			margin-right: 10px;

			@include viewport(large) {
				margin-right: 15px;
			}

			@include rtl {
				margin-left: 10px;
				margin-right: 0;

				@include viewport(large) {
					margin-left: 15px;
				}
			}
		}

		&-iconTextWrapper {
			margin-bottom: 20px;

			@include viewport(medium) {
				max-width: 300px;
				margin-bottom: 0;
			}

			@include viewport(large) {
				max-width: 100%;
				margin-bottom: 30px;
			}
		}

		&-sectionSeparator {
			display: flex;
			justify-content: center;
			align-items: center;

			@include viewport(large) {
				display: block;
				position: relative;
			}
		}

		&-cta {
			border: solid 1px $white;
			background-color: transparent;
			color: $white;

			@include viewport(medium) {
				width: 180px;
			}

			@include viewport(large) {
				width: auto;
			}

			&:hover {
				background-color: $white;
				color: $black;
			}
		}

		&-attribution,
		&-description,
		&-quote,
		&-icon {
			color: $white;
		}

		&-sectionQuote,
		&-sectionContent,
		&-sectionSeparator {
			padding: 0;
		}

		&-sectionQuote {
			display: block;

			@include viewport(large) {
				padding-right: 15px;
			}

			@include rtl {
				@include viewport(large) {
					padding-right: 0;
					padding-left: 15px;
				}
			}
		}

		&-sectionContent {
			display: flex;
			flex-direction: column;

			@include viewport(medium) {
				justify-content: space-between;
				flex-direction: row;
			}

			@include viewport(large) {
				justify-content: center;
				flex-direction: column;
				padding-left: 15px;
				max-width: 320px;
			}

			@include rtl {
				@include viewport(large) {
					padding-left: 0;
					padding-right: 15px;
				}
			}
		}
	}

	&.InlineCarousel--js {
		.TestimonialSlide {
			&:not(.is-selected) {
				opacity: 0.6;
				transition: opacity 1s ease-out;

				&:hover {
					opacity: 0.5;
					transition: opacity 0.5s;
				}
			}

			&.is-selected {
				opacity: 1;
				transition: opacity 1s ease-in;
			}

			&:not(.is-selected) .TestimonialSlide-cta {
				pointer-events: none;
			}

			&.is-selected .TestimonialSlide-cta {
				pointer-events: auto;
			}
		}
	}
}
