.ComponentAccordion {
	&-title {
		@include component-spacing($small: 40px, $medium: 40px, $large: 40px, $xlarge: 40px);
		text-align: center;
	}

	.Accordion-item-region {
		padding: 30px 0 5px;
	}

	.Accordion--expansion-plusMinus {
		.Accordion-item--inline-desktop {
			& + .Accordion-item {
				border-top: none;

				@include viewport(large) {
					border-top: 1px solid $black;
				}
			}

			.Accordion-item-button--with-subtitle {
				margin-bottom: 0;

				@include viewport(large) {
					.Accordion-item-title {
						&--titleContainer {
							width: auto;
						}

						@include viewport(large) {
							&--plusMinus {
								display: none;
							}
						}
					}
				}
			}
		}

		.Accordion-item {
			&-button {
				&--with-subtitle {
					display: flex;
					margin-bottom: 7px;
					align-items: center;

					.Accordion-item {
						&-title {
							margin-bottom: 0;
							&::after {
								content: none;
							}

							&--titleContainer {
								width: 100%;

								@include viewport(large) {
									flex-direction: row;
									align-items: center;
								}
							}

							&--plusMinus::after {
								@include icon('icon-less_sign');
								font-size: 12px;
							}
						}

						&-subtitle {
							@include viewport(large) {
								margin-left: 20px;

								@include rtl {
									margin-left: 0;
									margin-right: 20px;
								}
							}
						}
					}
				}
			}

			&--collapsed {
				.Accordion-item-title--plusMinus::after {
					@include icon('icon-plus_sign');
				}
			}
		}
	}

	.Accordion-item {
		&-title {
			&--titleContainer {
				display: flex;
				flex-direction: column;

				@include viewport(large) {
					flex-direction: row;
				}
			}
		}
		&-subtitle {
			opacity: 0;
			transition: opacity 1s ease;
			margin-top: 5px;
		}

		&--collapsed {
			.Accordion-item-subtitle {
				display: block;
				color: #595959;
				opacity: 1;
			}
		}

		&--dark {
			.Accordion-item-subtitle {
				color: $white;
			}
		}

		&--inline-desktop {
			border-top: none;
			background-color: $gray4;
			position: relative;
			z-index: 1;
			padding-top: 30px;
			padding-bottom: 30px;
			transition: padding-top 1s ease;
			&::before {
				content: '';
				z-index: -1;
				position: absolute;
				background-color: inherit;
				top: 0;
				left: calc((100% - 100vw) / 2);
				width: 100vw;
				height: 100%;

				@include viewport(large) {
					content: none;
				}
			}

			@include viewport(medium) {
				padding-top: 40px;
				padding-bottom: 40px;
			}

			@include viewport(large) {
				background-color: inherit;
			}

			&.Accordion-item--collapsed {
				padding-top: 20px;
				padding-bottom: 10px;
			}

			&.Accordion-item--dark {
				.Accordion-item-button {
					@include viewport(large) {
						color: $white;
					}
				}
			}

			.Accordion-item {
				&-region {
					padding-top: 0;
					overflow: visible;

					@include viewport(large) {
						padding-bottom: 80px;
					}
				}

				&-button {
					border-bottom: none !important;
					padding-bottom: 10px !important;
					color: $black;

					@include viewport(large) {
						padding-bottom: 20px !important;
						display: flex;
						justify-content: center;
					}

					&--with-subtitle {
						.Accordion-item-title--expansionContainer {
							padding-bottom: 27px;

							@include viewport(large) {
								padding-bottom: 0;
							}
						}
					}
				}

				&-title {
					margin-bottom: 0;

					@include viewport(large) {
						margin: 0 20px;
						&::after {
							content: none;
						}
						&--hide {
							display: none;
						}
						&--chevron {
							display: none;
						}
					}

					&--line {
						display: none;

						@include viewport(large) {
							display: block;
							border-bottom: 1px solid $gray2;
							margin: auto 0;
							flex: auto;
						}
					}
				}

				&-subtitle {
					@include viewport(large) {
						display: none;
					}
				}
			}
		}
	}

	&--list-desktop {
		.ComponentAccordion-title {
			margin: 0 0 60px;

			@include viewport(large) {
				text-align: left;
				margin: 0;

				@include rtl {
					text-align: right;
				}
			}
		}

		.Accordion-item {
			.BodySection--light & {
				border-color: $black;
			}

			.BodySection--dark & {
				border-color: $white;
			}

			&:first-child:not(.Accordion-item--inline-desktop) {
				border: 0;
				padding: 0 0 30px;

				@include viewport(large) {
					padding: 0 0 20px;
				}
			}

			&:not(:first-child):not(.Accordion-item--inline-desktop) {
				padding: 30px 0;

				@include viewport(large) {
					padding: 20px 0;
				}
			}

			&--inline-desktop {
				// padding: 20px 0 10px;
				&:first-child {
					@include viewport(large) {
						padding-top: 0;
					}
				}
			}

			@include viewport(large) {
				&-button {
					cursor: default;
					&:hover,
					&:focus {
						color: inherit;
					}
				}

				&--inline-desktop {
					.Accordion-item-title {
						margin: 0;

						@include viewport(large) {
							width: auto;
							margin: 0 20px;
						}
					}
				}

				&-title {
					&::after {
						content: none;
					}
					&--expansionContainer {
						display: none !important;
					}
				}
			}

			&-description {
				padding: 0;
				margin: 0;
			}

			&-title {
				width: 100%;
				margin-bottom: 0;

				&--plusMinus {
					@include viewport(large) {
						display: none;
					}
				}
			}

			&-region {
				padding-top: 14px;
				padding-bottom: 0;
				padding-right: 40px;

				@include rtl {
					padding-right: 0;
					padding-left: 40px;
				}

				@include viewport(large) {
					padding-top: 10px;
				}
			}

			&--with-icon .Accordion-item-region {
				margin-left: 45px;

				@include rtl {
					margin-left: 0;
					margin-right: 45px;
				}

				@include viewport(medium) {
					margin-left: 60px;

					@include rtl {
						margin-left: 0;
						margin-right: 60px;
					}
				}

				@include viewport(large) {
					margin-left: 82px;

					@include rtl {
						margin-left: 0;
						margin-right: 82px;
					}
				}
			}
		}
	}
	.Accordion-item--with-image {
		.Image {
			text-align: center;
			position: absolute;
			border-radius: 10px;
			width: 100px;
			height: 60px;
		}

		.Accordion-item-title--plusMinus {
			display: none;
		}

		.Accordion-item-title--titleContainer {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			margin-left: 110px;
			margin-top: 20px;
			@include rtl {
				margin-left: 0px;
				margin-right: 110px;
			}
			.Accordion-item-title-additionalSubtitlesContainer {
				display: none;
			}
		}
		&.Accordion-item {
			padding-bottom: 35px;
			@include viewport(medium) {
				padding-bottom: 50px;
			}
			&--collapsed {
				.Accordion-item-title {
					margin-bottom: 0px;
				}
				.Accordion-item-title.ty-c1::after {
					font-size: 16px;
					top: 15px;
				}
				.Accordion-item-title--titleContainer {
					display: flex;
					align-items: stretch;
					flex-direction: column;
					margin-left: 110px;
					margin-top: 2px;
					@include viewport(medium) {
						margin-top: 5px;
					}
					@include rtl {
						margin-right: 110px;
						margin-left: 0px;
						margin-top: 6px;
					}
				}

				.Accordion-item-title-additionalSubtitlesContainer {
					margin-top: 0px;
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					align-content: center;
					justify-content: flex-start;
					align-items: center;
					@include viewport(medium) {
						margin-top: 10px;
					}
					@include rtl {
						margin-top: 10px;
					}

					.Accordion-item-subtitle-additionalSubtitle-Bold {
						margin-right: 5px;
						@include rtl {
							margin-left: 5px;
							margin-right: 0px;
						}
					}
				}
			}
		}
	}

	.Accordion-item--with-icon {
		.Accordion-item-button {
			display: flex;
			align-items: center;

			@include viewport(large) {
				align-items: normal;
			}
		}

		.Accordion-item-title,
		.Accordion-item-subtitle {
			margin-left: 45px;

			@include rtl {
				margin-left: 0;
				margin-right: 45px;
			}

			@include viewport(medium) {
				margin-left: 60px;

				@include rtl {
					margin-left: 0;
					margin-right: 60px;
				}
			}

			@include viewport(large) {
				margin-left: 82px;

				@include rtl {
					margin-left: 0;
					margin-right: 82px;
				}
			}
		}

		.Accordion-item-subtitle {
			@include viewport(large) {
				margin-left: 20px;

				@include rtl {
					margin-left: 0;
					margin-right: 20px;
				}
			}
		}

		.Accordion-item-button:not(.Accordion-item-button--with-subtitle) .Accordion-item-title {
			width: 100%;
		}

		.icon {
			text-align: center;
			font-size: 32px;
			line-height: 32px;
			position: absolute;
			width: 32px;
			height: 32px;
		}
	}

	.Accordion--expansion-chevron {
		.Accordion-item {
			&:not(.Accordion-item--inline-desktop) {
				border-top: 1px solid $gray3;
				padding-bottom: 20px;
				padding-top: 20px;

				@include viewport(large) {
					padding-bottom: 0;
					padding-top: 32px;
				}

				.Accordion-item-title {
					@include viewport(large) {
						@include typography(h3);
					}
				}

				.Accordion-item-region {
					padding-top: 10px;

					@include viewport(large) {
						padding-top: 30px;
					}
				}
			}

			&-button {
				display: flex;
				align-items: center;
				position: relative;
				transition: padding-bottom 1s ease;
				color: inherit;

				&--with-subtitle {
					margin-bottom: -18px;
					transition: padding-bottom 1s ease, margin-bottom 1s ease;

					@include viewport(large) {
						margin-bottom: 0;
						transition: padding-bottom 1s ease;
					}

					.Accordion-item-title--expansionContainer {
						padding-bottom: 18px;

						@include viewport(large) {
							padding-bottom: 0;
						}
					}
				}

				@include viewport(large) {
					padding-bottom: 15px;
				}
			}

			&-region {
				padding-bottom: 10px;

				@include viewport(large) {
					padding-bottom: 50px;
				}
			}

			&-title {
				margin-bottom: 0;

				&--titleContainer {
					width: calc(100% - 55px);

					@include viewport(large) {
						width: auto;
					}
				}

				&--expansionContainer {
					display: flex;
					position: absolute;
					right: 0;
					transition: padding-bottom 1s ease;

					@include rtl {
						left: 0;
						right: auto;
					}
				}

				&::after {
					content: none;
				}
				&--hide {
					margin-right: 2px;
					opacity: 1;
					transition: opacity 1s ease;

					@include rtl {
						margin-right: 0;
						margin-left: 5px;
					}
				}
				&--chevron {
					transform: rotate(180deg);
					transition: transform 1s ease;

					&::after {
						@include icon('icon-caret');
						font-size: 5px;
					}
				}
			}

			&-subtitle {
				@include viewport(large) {
					margin-left: 20px;

					@include rtl {
						margin-left: 0;
						margin-right: 20px;
					}
				}
			}
			&--collapsed {
				.Accordion-item {
					&-button {
						&--with-subtitle {
							.Accordion-item-title--expansionContainer {
								padding-bottom: 0;
							}
							margin-bottom: 0;
						}

						@include viewport(large) {
							padding-bottom: 30px;
						}
					}
					&-title {
						&--hide {
							opacity: 0;
						}
						&--chevron {
							transform: rotate(0deg);

							@include rtl {
								transform: rotate(360deg);
							}
						}
					}
				}
			}
		}
	}
}
