.TextHero {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 70px;

	@include mediaRange($breakpoints-sm, calc($breakpoints-md - 1px)) {
		padding-top: 40px;
	}

	@include mediaRange($breakpoints-md, calc($breakpoints-lg - 1px)) {
		padding-top: 60px;
	}

	&-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 50px;

		@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
			gap: 40px;
		}
	}

	&-heading {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 25px;
		align-self: stretch;

		@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
			gap: 20px;
		}
	}

	&-title-description {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		text-align: center;
		max-width: 1395px;

		@include mediaRange($breakpoints-sm, calc($breakpoints-md - 1px)) {
			gap: 15px;
			max-width: 100%;
		}

		@include mediaRange($breakpoints-md, calc($breakpoints-lg - 1px)) {
			gap: 15px;
			max-width: 468px;
		}

		@include mediaRange($breakpoints-lg, calc($breakpoints-xl - 1px)) {
			max-width: 1045px;
		}
	}

	&-title {
		max-width: 1110px;

		@include mediaRange($breakpoints-sm, calc($breakpoints-md - 1px)) {
			max-width: 100%;
		}

		@include mediaRange($breakpoints-md, calc($breakpoints-lg - 1px)) {
			max-width: 468px;
		}

		@include mediaRange($breakpoints-lg, calc($breakpoints-xl - 1px)) {
			max-width: 615px;
		}
	}

	&-description {
		color: var(--color-text-primary, #1f1f1f);
	}

	&-icons {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		align-content: flex-start;
		gap: 15px 50px;
		align-self: stretch;
		flex-wrap: wrap;
	}

	&-icon {
		&::before {
			font-size: 24px;
			line-height: 1em;
			color: var(--color-text-secondary, #4d4d4d);
			position: relative;
			padding-right: 5px;
			top: 6px;
		}

		&-text {
			color: var(--color-text-secondary, #4d4d4d);
		}
	}

	&-anchors {
		display: flex;
		align-items: flex-start;
		padding: 0 0 10px;
		width: 100vw;
	}
}
