.ContactTable {
	&-title {
		text-align: center;
		margin-bottom: 50px;

		@include viewport(medium) {
			margin-bottom: 80px;
		}

		@include viewport(xlarge) {
			margin-bottom: 100px;
		}
	}
}
