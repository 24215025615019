.IconTable-Cell {
	&-inner {
		position: relative;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		padding: 10px 0;

		@include viewport(medium) {
			padding: 12px 0;
		}

		@include viewport(large) {
			padding: 20px 0;
		}
	}

	.icon {
		width: 24px;
		height: 24px;
		font-size: 22px;
	}

	.IndicatorColor {
		position: absolute;

		left: 24px;
		top: calc(50% - 24px);

		@include viewport(large) {
			top: calc(50% - 12px);
		}

		@include rtl {
			left: auto;
			right: 24px;
		}
	}

	&--label {
		margin-left: 15px;
		display: inline-block;

		@include rtl {
			margin-left: 0;
			margin-right: 15px;
		}

		@include viewport(large) {
			width: 150px;
			flex-shrink: 0;
		}
	}

	&--text-wrap {
		width: 100%;

		margin-left: 39px;

		@include rtl {
			margin-left: 0;
			margin-right: 39px;
		}

		@include viewport(medium) {
			margin-left: 0;

			@include rtl {
				margin-left: 0;
				margin-right: 0;
			}
		}

		@include viewport(large) {
			width: auto;
			margin-left: 30px;

			@include rtl {
				margin-left: 0;
				margin-right: 30px;
			}
		}
	}

	&--text {
		display: inline-block;
	}

	&.has-indicator {
		.IconTable-Cell--label,
		.IconTable-Cell--text {
			font-style: italic;
		}
	}
}
