.QuickLinks {
	&-carousel-button {
		display: none;
	}

	&--desktopMobile {
		display: block;

		@include viewport(xlarge) {
			max-width: $containerWidth-lg;
		}

		@include viewport(medium) {
			display: none;
		}

		@include viewport(large) {
			display: block;
		}
	}

	&--tablet {
		display: none;

		@include viewport(medium) {
			display: block;
		}

		@include viewport(large) {
			display: none;
		}
	}

	&--hero {
		display: none;
		flex: 2 2 auto;

		@include viewport(medium) {
			display: block;
			width: 100%;
			padding: 0 15px;
			z-index: 3;
			margin-left: 0;
		}

		@include viewport(large) {
			width: auto;
			padding: 0 27px;
			margin-left: auto;

			@include rtl {
				margin-left: 0;
				margin-right: auto;
			}
		}

		.container {
			max-width: 100%;
		}

		.QuickLinks {
			&-container {
				position: relative;

				@include viewport(large) {
					margin-top: 1.5em; //matches line height of eyebrow
					margin-right: 13px;
					margin-left: 13px;
				}

				.row {
					margin: 0;

					@include viewport(large) {
						overflow: hidden;
					}
				}
			}

			&-carousel-button {
				display: none;

				@include viewport(large) {
					display: block;
				}
				position: absolute;
				height: 120px;
				width: 105px;
				top: 27px;
				font-size: 15px;
				z-index: 3;
				transition: opacity 0.3s linear;

				&--prev {
					left: 0;
					&::before {
						float: left;
						margin-left: 27px;
					}

					@include rtl {
						right: 0;
						left: auto;
						&::before {
							float: right;
							margin-right: 27px;
							margin-left: 0;
						}
					}
				}
				&--next {
					right: 0;
					&::before {
						float: right;
						margin-right: 27px;
					}

					@include rtl {
						left: 0;
						right: auto;
						&::before {
							float: left;
							margin-left: 27px;
							margin-right: 0;
						}
					}
				}
				&--disabled {
					opacity: 0;
				}
			}
		}
	}
}

.FullscreenHero .QuickLinks--body {
	padding-top: 50px;
	padding-bottom: 40px;

	@include viewport(medium) {
		display: none;
	}
}
