.LargeImageBlockCard {
	width: 100%;
	height: 100%;
	min-height: 200px;

	@include viewport(medium) {
		min-height: 300px;
	}

	@include viewport(large) {
		min-height: 400px;
	}

	&-backgroundImage {
		height: 100%;
		width: 100%;
		&::after {
			content: '';
			width: 100%;
			position: absolute;
			display: block;
			height: 66%;
			bottom: 0;
			background: linear-gradient(180deg, rgba(3, 3, 3, 0) 0%, #030303 100%);
		}
	}

	&-cta {
		margin-top: 10px;
		text-align: center;
		display: inline-flex;
		align-items: baseline;
		gap: 10px;
		position: absolute;
		left: 30px;
		bottom: 20px;
		color: var(--color-text-on-image-primary);
		text-decoration: none;
		::after {
			display: none;
		}

		.heading-3 {
			color: var(--color-text-on-image-primary);
		}
	}
}
