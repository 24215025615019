.PromotionalBanner {
	width: 100%;
	min-height: 400px;

	.BackgroundImage {
		text-align: center;
		height: 470px;
		position: relative;

		@include viewport(medium) {
			height: 900px;
		}

		@include viewport(large) {
			text-align: inherit;
			height: 392.5px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.BodySection--dark & {
				border: 1px solid $gray1;
			}
			.BodySection--light & {
				border: 1px solid $gray3;
			}
		}
		@include viewport(xlarge) {
			height: 523px;
		}
		.Heading {
			max-width: 285px;
			margin: auto;
			padding-top: 50px;
			position: relative;
			color: white;

			@include viewport(medium) {
				max-width: 450px;
				padding-top: 100px;
			}

			@include viewport(large) {
				max-width: 375px;
				margin: 0 0 0 100px;
				padding-top: 0;

				@include rtl {
					margin: 0 100px 0 0;
				}
			}
			.Heading-eyebrow {
				margin-bottom: 10px;
				display: inline-block;
			}
			.Heading-title {
				margin: 0 0 10px;

				@include viewport(medium) {
					margin-bottom: 15px;

					@include typography(h3);
				}
			}
			.Heading-text {
				margin: 0;
			}
		}
		.CTA {
			margin: auto;
			@include viewport(medium) {
				margin: 0;
			}
			&:hover {
				border-color: white;
			}
		}
		&::before {
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 36.91%, rgba(0, 0, 0, 0) 55.18%),
				linear-gradient(180deg, rgba(0, 0, 0, 0.35) 40.35%, rgba(0, 0, 0, 0) 70.34%),
				linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 30px);

			@include viewport(medium) {
				background: linear-gradient(
						180deg,
						rgba(0, 0, 0, 0.42) 20.53%,
						rgba(0, 0, 0, 0) 46.39%
					),
					linear-gradient(180deg, rgba(0, 0, 0, 0.45) 31.26%, rgba(0, 0, 0, 0) 57.2%),
					linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 30px);
			}

			@include viewport(large) {
				background: linear-gradient(
						90deg,
						rgba(0, 0, 0, 0.4) 35.06%,
						rgba(0, 0, 0, 0) 64.33%
					),
					linear-gradient(90deg, rgba(0, 0, 0, 0.6) 22.77%, rgba(0, 0, 0, 0) 69.39%);
			}
		}

		@include rtl {
			@include viewport(large) {
				&::before {
					background: linear-gradient(
							270deg,
							rgba(0, 0, 0, 0.4) 35.06%,
							rgba(0, 0, 0, 0) 64.33%
						),
						linear-gradient(270deg, rgba(0, 0, 0, 0.6) 22.77%, rgba(0, 0, 0, 0) 69.39%);
				}
			}
		}
		&::after {
			content: '';
			display: block;
			height: 30px;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);

			@include viewport(medium) {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
			}

			@include viewport(large) {
				display: none;
			}
		}
	}

	&-CTAs {
		display: flex;
		flex-direction: column;
		gap: 25px;
		position: relative;
		margin-top: 25px;

		@include viewport(medium) {
			justify-content: center;
			margin-top: 30px;
			flex-direction: row;
			gap: 20px;
		}

		@include viewport(large) {
			justify-content: flex-start;
			margin-left: 100px;

			@include rtl {
				margin-left: 0;
				margin-right: 100px;
			}
		}
	}

	&-finePrint {
		margin-top: 20px;
		text-align: center;
	}

	&.wide-grid-container {
		@include mediaRange($breakpoints-sm, calc($breakpoints-lg - 1px)) {
			padding-left: 0;
			padding-right: 0;
			max-width: 100%;

			.PromotionalBanner-finePrint {
				margin-left: auto;
				margin-right: auto;
				padding: 0 15px;
				max-width: calc($wideGridContainerWidth-sm + 30px);

				@include viewport(medium) {
					padding-left: 60px;
					padding-right: 60px;
					max-width: calc($wideGridContainerWidth-md + 120px);
				}
			}
		}
	}
}
