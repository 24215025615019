$breakpoints-xl: 1701px;
$breakpoints-lg: 961px;
$breakpoints-md: 701px;
$breakpoints-sm: 0;
$containerWidth-xl: 1530px;
$containerWidth-lg: 930px;
$containerWidth-md: 690px;
$containerWidth-sm: 670px;
$wideGridContainerWidth-xl: 1680px;
$wideGridContainerWidth-lg: 1260px;
$wideGridContainerWidth-md: 714px;
$wideGridContainerWidth-sm: 670px;
