.ImageAndCTABlocksRowOfThree {
	.col-sm-12 {
		display: flex;
		flex-direction: column;

		@include viewport(medium) {
			flex-direction: row;
		}
	}

	.ImageAndCTABlockWithEyebrows {
		width: 100%;
		margin-right: 30px;
		margin-bottom: 30px;

		@include viewport(medium) {
			width: calc(100% / 3);
			margin-bottom: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&-image {
			margin-bottom: 20px;
		}

		&-eyebrow {
			@include typography(c1);
		}

		&-secondaryEyebrow,
		&-bullet {
			@include typography(c2);
		}

		.CtaBlock {
			&-title {
				font-family: $sansFont;
				font-size: 24px;
				line-height: 33px;
				font-weight: 300;
			}

			&-text {
				margin-top: 10px;
				margin-bottom: 20px;

				@include typography(b1);
			}
		}
	}

	&--vertical {
		.col-sm-12 {
			flex-direction: column;

			.ImageAndCTABlockWithEyebrows {
				margin-right: 0;
				margin-bottom: 30px;

				@include viewport(medium) {
					width: 100%;
				}
			}
		}
	}
}
