@mixin media($min-width) {
	@media only screen and (min-width: $min-width) {
		@content;
	}
}

@mixin mediaRange($min-width, $max-width) {
	@media only screen and (min-width: $min-width) and (max-width: $max-width) {
		@content;
	}
}

@mixin viewport($viewport) {
	@if $viewport == small {
		@content;
	}

	@if $viewport == medium {
		@include media($breakpoints-md) {
			@content;
		}
	}

	@if $viewport == large {
		@include media($breakpoints-lg) {
			@content;
		}
	}

	@if $viewport == xlarge {
		@include media($breakpoints-xl) {
			@content;
		}
	}
}
