.PrivateJetTripCardGrid {
	.Heading-title {
		margin-top: 0;
		margin-bottom: 30px;
		@include viewport(large) {
			margin-bottom: 40px;
		}
	}

	.wide-grid-row-sm-1 {
		gap: 30px;
	}

	.wide-grid-row-md-2 {
		@include mediaRange($breakpoints-md, calc($breakpoints-lg - 1px)) {
			gap: 20px;
		}
	}
}
