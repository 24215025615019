.SingleCard {
	display: block;

	@include viewport(medium) {
		padding: 40px 30px;
		background-color: $gray4;
	}

	@include viewport(large) {
		padding: 46px;
	}

	&--dark {
		@include viewport(medium) {
			background-color: $gray1;
		}
	}

	&-wrapper {
		display: block;
		margin: 0;

		@include viewport(medium) {
			display: flex;
		}
	}

	&-image {
		display: block;
		padding: 0;

		@include viewport(medium) {
			padding-right: 15px;

			@include rtl {
				padding-left: 15px;
			}
		}
	}

	&-content {
		display: block;
		text-align: center;
		background-color: $gray4;
		padding: 30px;

		@include viewport(medium) {
			display: flex;
			padding: 0;
			text-align: left;
			flex-direction: column;
			justify-content: space-between;
			padding-left: 15px;

			@include rtl {
				text-align: right;
				padding-right: 15px;
			}
		}

		&--dark {
			background-color: $gray1;
		}
	}

	&-title {
		margin-top: 0;

		&::after {
			content: '';
			height: 1px;
			width: 30px;
			display: block;
			background-color: $black;
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;

			@include viewport(medium) {
				margin-left: 0;
				width: 32px;
			}

			@include viewport(large) {
				width: 40px;
			}
		}

		@include rtl {
			&::after {
				@include viewport(medium) {
					margin-right: 0;
					margin-left: auto;
				}
			}
		}

		&--dark {
			&::after {
				background-color: $white;
			}
		}
	}
}
