$noWrapBreakpoint: 1360px;

html.widget {
	box-sizing: border-box;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
}

body {
	@include rtl {
		direction: rtl;
	}
}

.hide {
	display: none !important;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	border-radius: 0;
	font: inherit;
	cursor: pointer;
}

main.caw__wrapper {
	position: relative;
	height: 100%;
	overflow-x: hidden;
	@include media($navigationBreakpoint) {
		height: calc(100% - 125px);
		margin-top: 125px;
		overflow-y: scroll;
	}

	&.overlay--active {
		height: 100%;
		overflow: hidden;
	}
}
.caw-comp__wrapper,
.caw-popup__wrapper,
.caw__wrapper {
	.Dropdown-container {
		select::-ms-expand {
			display: none;
		}

		&::after {
			@include icon('icon-caret_down');
			font-weight: 400;
			position: absolute;
			font-size: 7px;
			top: 7px;
			right: 12px;
			pointer-events: none;
			color: $gray1;
		}

		@include rtl {
			&.DestinationPicker-container::after,
			&::after {
				right: auto;
				left: 12px;
				float: left;
			}
		}

		&.DestinationPicker-container::after {
			position: relative;
			right: auto;
			float: right;
			margin: -40px 12px 0 0;
		}

		select {
			background: $gray6;
			color: $gray1;
			border-bottom: 0;
			padding: 0 30px 0 15px;
			border: none;
			line-height: 40px;
			height: 40px;
			width: 100%;
			border-radius: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			text-indent: 2px;
			text-overflow: '';

			@include rtl {
				padding: 0 15px 0 45px;
			}
		}

		&.toggle {
			padding: 0;
			margin: 0 20px 0 0;
			@include rtl {
				padding: 0;
				&:first-child {
					margin: 0;
				}
				&:last-child {
					margin: 0 20px 0 0;
				}
			}
			&:last-child {
				margin: 0;
			}

			select {
				@include rtl {
					direction: ltr;
				}
			}
		}
	}
	.form__submit-button {
		height: 40px;
		&:disabled {
			cursor: default;
			background-color: $bhover;
			border-color: $bhover;

			&:hover {
				color: $white;
				background-color: $bhover;
				border-color: $bhover;
			}
		}
	}
}

.caw-popup__wrapper:not(.caw-vertical__wrapper) {
	width: 332px;

	.PopupCaw--property-selector & {
		width: 344px;
	}
}

//default col layout of form fields
.cawFieldsContainer {
	.DestinationPicker-container.col {
		width: 25%;
		padding-right: 10px;
	}
	.Calendar-container.col {
		width: 25%;
		padding-right: 10px;
		.singleProperty & {
			width: 30%;
		}
	}
	.ToggleCount.col {
		min-width: 165px;
		padding-right: 10px;
		width: 18%;
		@include media($noWrapBreakpoint) {
			width: 17%;
		}
	}
	.Guestdropdown-container.col {
		padding-right: 10px;
		width: 20%;
		.singleProperty & {
			min-width: 265px;
		}
	}
	.PromoCode-container.col {
		width: 12%;
		padding-right: 10px;
		.singleProperty & {
			width: 14%;
		}
	}
	.Submit-container.col {
		width: 15%;
		padding-right: 0px;
		min-width: 168px;
		max-width: 180px;
		.singleProperty & {
			max-width: 185px;
		}
	}

	.PromoCode-warning {
		min-width: 325px;
		border: 1px solid #555;
		color: white;
		background-color: #555;
		padding: 10px;
		font-size: 1rem;
		font-style: normal;
		margin-top: -15px;
		margin-left: -55%;

		&.promo-set {
			color: white;
			border: 1px solid #b00;
			background-color: #b00;
		}
	}

	.up-pointer {
		position: relative;
		left: 45%;
		top: -6px;
		color: #555;

		&.promo-set {
			color: #b00;
		}

		&::before {
			@include icon('icon-collapse');
			font-weight: 400;
			font-size: 12px;
			pointer-events: none;
			width: 20px;
		}

		@include rtl {
			right: 45%;
		}
	}
}

.caw-ppc__wrapper {
	.cawFieldsContainer {
		.PromoCode-warning {
			border: none;
			color: $gray1;
			background-color: transparent;
			font-size: 13px;
			letter-spacing: 0.5px;
			line-height: 25px;
			margin-top: 0px;
		}

		.up-pointer {
			display: none;
		}
	}
}

.caw-comp__wrapper,
.caw-popup__wrapper {
	padding: 0;
	.App-wrapper {
		padding: 0;
		.container {
			margin: 0;
			max-width: 100vw;
			.row {
				margin: 0;
				.App-wrapper_mainCol {
					padding: 0;
					position: relative;
					.hideButton {
						text-transform: uppercase;
						letter-spacing: 2.05px;
						position: absolute;
						top: 0px;
						right: 110px;
						padding: 0;
						height: 40px;
						&::after {
							content: '';
							right: 50%;
							bottom: 24px;
							display: inline-block;
							position: relative;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-bottom: 10px solid rgba(255, 255, 255, 0.7);
							z-index: 2;
						}
						@include rtl {
							left: 110px;
							right: auto;
							&::after {
								right: auto;
								left: 50%;
							}
						}
					}
				}
				.cawComponent {
					padding: 55px 0 20px;
					&.horizontal {
						height: 115px;
						background-color: rgba(255, 255, 255, 0.7);
						position: relative;
						&.singleProperty {
							box-shadow: 0 25px 51px -30px #000;
							.cawFieldsContainer {
								padding: 0 145px;
							}
						}
						&.hasDestinationPicker {
							.cawFieldsContainer {
								padding: 0 45px;
							}
						}
						&.hasDestinationPicker.hasEliteGuestComponent {
							.cawFieldsContainer {
								padding: 0;
								@include media($noWrapBreakpoint) {
									padding-right: 0;
									@include rtl {
										padding-left: 0;
										padding-right: 0;
									}
								}
							}
						}
						&.hasAlert {
							padding: 20px 145px 20px 45px;
							height: auto;
							min-height: 115px;
							.Navigation.has-Secondary & {
								min-height: 135px;
								padding: 20px 145px 20px 45px;
								height: auto;
							}
						}
					}

					.cawFieldsContainer {
						max-width: 1440px;
					}
					.ppc-brg-cta {
						display: none;
					}
					&.ppc {
						width: 100%;
						padding: 0;
						background-color: $gray6;
						max-width: 35vw;
						display: none;
						@include media($navigationBreakpoint) {
							display: block;
						}
						.cawFieldsContainer {
							padding: 30px 40px;
							display: flex;
							flex-direction: column;
							.Calendar-container {
								width: 100%;
								padding: 0;
								.DateField_label {
									position: static;
									transform: none;
									margin-bottom: 6px;
								}
								.DateField_container {
									background-color: $white;
									.Calendar-wrapper {
										background-color: $gray6;
									}
								}
								.Calendar-row {
									right: auto;
									left: auto;
								}
							}

							.Guestdropdown-container {
								width: 100%;
								padding: 42px 0 10px;
								.OccupancyField_container {
									background-color: $white;
								}
							}

							.togglePromoWrapper {
								display: flex;
								width: 100%;
								.ToggleCount,
								.PromoCode-container {
									align-self: flex-end;
								}
								.ToggleCount {
									flex: 1 1 60%;
									max-width: 360px;
									.Dropdown-container {
										min-width: 65px;
										select {
											background-color: $white;
										}
									}
								}
								.PromoCode-container {
									flex: 2 2 45%;
									min-width: 30px;
									padding-right: 0px;
									.textInput input {
										background-color: $white;
										&:disabled {
											background-color: $gray3;
										}
									}
								}
								.promoWarningWrapper {
									height: 35px;
								}
								.PromoCode-warning {
									padding: 5px 10px;
									margin-left: 0;
								}
							}

							.Submit-container {
								align-self: center;
								padding-top: 5px;
								width: 50%;
								height: auto;
								min-width: 175px;
								max-width: none;
							}
						}
						.cal-focus-trigger {
							height: 50px;
							background-color: black;
							color: white;
							font-weight: bold;
							font-size: 14px;
							line-height: 50px;
							letter-spacing: 4px;
							text-align: center;
							cursor: pointer;
							position: relative;
							.closeButton {
								position: absolute;
								right: 30px;
								top: 50%;
								transform: translateY(-50%) scale(0.65);
								@include rtl {
									right: auto;
									left: 30px;
								}
							}
						}
						.ppc-brg-cta {
							display: flex;
							align-items: baseline;
							gap: 9px;
							align-self: center;
							margin-top: 10px;
							font-size: 9px;
							a {
								color: black;
							}
							&::before {
								font-size: 13px;
								width: 13px;
								height: 13px;
								transform: translateY(25%);
							}
						}
					}
				}
			}
		}
	}

	[class*='col'] {
		float: left;
		padding-left: 0px;
		padding-right: 20px;
		@include rtl {
			float: right;
			padding-left: 20px;
			padding-right: 0px;
		}
	}

	.Calendar-container .DateField,
	.Guestdropdown-container .OccupancyField {
		&::after {
			@include icon('icon-caret_down');
			font-weight: 400;
			font-size: 7px;
			pointer-events: none;
			float: right;
			right: 12px;
			margin-top: -33px;
			position: relative;
			color: $gray1;

			@include rtl {
				float: left;
				left: 12px;
				right: 0;
			}
		}

		@include rtl {
			&::after {
				float: left;
				left: 12px;
				right: auto;
			}
		}

		&.active {
			&::after {
				display: none;
			}
		}
	}
}

.caw-popup__wrapper {
	padding: 45px 30px 30px;
	.PromoCode-container {
		padding-top: 32px;
	}
	.Submit-container {
		padding-top: 10px;
	}
}

@media only screen and (min-width: 961px) {
	.caw-comp__wrapper,
	.caw-popup__wrapper {
		.DestinationPicker-container.Dropdown-container {
			min-width: 221px;
		}

		.Calendar-container {
			min-width: 240px;

			.PopupCaw--property-selector & {
				padding-top: 30px;
			}
		}

		.Submit-container {
			width: 100%;
		}
	}
}

@media only screen and (max-width: 960px) {
	.caw-comp__wrapper,
	.caw-popup__wrapper,
	.caw__wrapper {
		.Dropdown-container {
			&::after {
				right: 12px;
			}

			&.DestinationPicker-container {
				&::after {
					right: 22px;
				}
			}

			&.toggle {
				padding: 0;

				&:last-child {
					padding: 0;

					label {
						padding-left: 0;
					}
				}
			}

			.Dropdown-container {
				&::after {
					right: 128px;
				}
			}
		}

		[class*='col-'] {
			margin-top: 15px;
			&.Calendar-container {
				margin-top: 35px;
			}
		}
	}
}
.form__submit-button {
	width: 100%;
	display: block;
	border: 1px solid $black;
	transition: all 0.8s;

	&:disabled.CTA--secondary--dark,
	&:disabled.CTA--secondary--dark:hover,
	&[disabled].CTA--secondary--dark,
	&[disabled].CTA--secondary--dark:hover {
		background-color: #6d6d6d;
		border-color: #6d6d6d;
	}

	&:hover {
		border: 1px solid $black;
	}
}

.Loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 900;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}

input {
	border-radius: 0;
	appearance: none !important;

	&::-ms-clear {
		display: none;
	}
}

.fieldGroup {
	margin: 40px 0;
	@include clearfix;

	&:first-child {
		margin-top: 20px;
	}
}

.PromoCode-container.fieldGroup {
	margin: 0;
	@include media($navigationBreakpoint) {
		margin: 40px 0;
	}
}
.select__label {
	position: absolute;
	left: 0;
	transform: translateY(-22px);
	letter-spacing: 2.05px;
}
