.WgHoverCardList {
	.HoverCardList {
		&-MobileCarousel {
			&--list {
				.HoverCardList-MobileCarousel--slide:first-child {
					padding-left: 0;

					@include rtl {
						padding-left: 7.5px;
						padding-right: 0;
					}
				}
				.HoverCardList-MobileCarousel--slide:last-child {
					padding-right: 0;

					@include rtl {
						padding-left: 0;
						padding-right: 7.5px;
					}
				}
			}
		}
	}

	.HoverCardList-MobileCarousel--title {
		padding-left: 15px;
		text-align: left;
	}

	.TitleAndCards-titleContainer {
		margin-bottom: 0;
	}

	.TitleAndCards-title {
		@extend .heading-3;
		text-transform: none;
		text-align: left;
		margin-bottom: 20px;

		@include viewport(medium) {
			margin-bottom: 30px;
		}

		@include viewport(large) {
			margin-bottom: 40px;
		}
	}
}
