.ImageAndCTAGrid {
	&-featuredHeading,
	&-columnHeading {
		font-family: $sansFont;
		font-size: 24px;
		line-height: 33px;
		font-weight: 300;
		letter-spacing: 4px;
		text-transform: uppercase;
	}

	.ImageAndCTABlockWithEyebrows {
		margin-bottom: 30px;

		&-image {
			margin-bottom: 20px;
		}

		&-eyebrow {
			@include typography(c1);
		}

		&-secondaryEyebrow,
		&-bullet {
			@include typography(c2);
		}

		.CtaBlock {
			&-title {
				font-family: $sansFont;
				font-size: 24px;
				line-height: 33px;
				font-weight: 300;
			}

			&-text {
				margin-top: 10px;
				margin-bottom: 20px;

				@include typography(b1);
			}
		}
	}
}
