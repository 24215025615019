.TypeAhead {
	padding-bottom: 10px;
	.TextInput {
		margin-bottom: 0;
	}

	&-textField {
		margin-bottom: 10px;
	}

	&-results {
		display: none;
		position: absolute;
		z-index: 1;
		background-color: $gray4;
		width: 100%;
		top: 46px;
	}

	&-item {
		padding: 14px 22px 6px;

		a {
			span {
				color: $black;
			}
			text-decoration: none;
		}

		strong {
			font-weight: normal;
			color: $gray2;
		}

		&.active,
		&:hover {
			background-color: $black;

			a span {
				color: $white;
			}
		}

		&:first-child {
			padding-top: 15px;
		}

		.TypeAhead-generalInquiry & {
			&:not(:last-child) strong {
				font-weight: normal;
				color: $gray2;
			}

			&:last-child {
				&:not(:first-child) {
					padding: 0 21px 11px;
					margin-top: 37px;

					&::before {
						content: '';
						width: 100%;
						height: 1px;
						background-color: $black;
						display: block;
						margin-bottom: 12px;
					}
				}
			}
		}
	}

	&-container {
		position: relative;
	}
}
