.IconTextLinks {
	color: $black;

	&--Wrapper {
		display: flex;
		width: 100%;
		flex-direction: column;

		@include viewport(medium) {
			flex-direction: row;
		}

		@include viewport(large) {
			flex-direction: row;
		}
	}

	&-titleContainer {
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		justify-content: center;

		@include viewport(medium) {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		@include viewport(large) {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		@include rtl {
			@include viewport(small) {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			@include viewport(medium) {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			@include viewport(large) {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	&-title {
		margin: 0;
		margin-bottom: 20px;
		margin-bottom: 0;
		text-align: center;
	}

	&--dark {
		background-color: $black;
		color: $white;
	}
}
