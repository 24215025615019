.ProductCard-react-ssr-placeholder {
	background-color: white;
}

.SquareImageCard.DynamicProduct {
	display: none;
}

.HideSelectStatic {
	.SquareImageCard {
		&.DynamicProduct {
			display: block;
		}

		&.StaticProduct {
			display: none;
		}
	}
}
