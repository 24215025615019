.FullscreenTabContainer {
	&-title {
		text-align: center;
		margin-bottom: 30px;
		&-text {
			margin: 0;
		}
	}

	&-tabs {
		&Container {
			margin-bottom: 50px;
			display: flex;
			flex-direction: column;
			width: 100%;
			overflow: hidden;
			position: relative;
			border-bottom: 1px solid $gray3;
		}

		padding: 0 20px;
		flex-direction: row;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		gap: 20px;
		overflow-x: scroll;
		height: auto;

		&::-webkit-scrollbar {
			display: none;
		}
		scrollbar-width: none;

		@include viewport(large) {
			justify-content: center;
		}

		&-arrow {
			&Container {
				pointer-events: none;
				height: 100%;
				position: absolute;
				width: 90px;
				top: 50%;
				transform: translateY(-50%);
				display: flex;

				@include rtl {
					transform: translateY(-50%) rotateY(180deg);
				}

				&-right {
					background: linear-gradient(
						270deg,
						var(--color-text-inverse-heading, #fff) 34.96%,
						rgba(255, 255, 255, 0) 86.96%
					);
					justify-content: end;
					right: 0;

					@include rtl {
						right: unset;
						left: 0;
						justify-content: start;
					}
				}
				&-left {
					background: linear-gradient(
						90deg,
						var(--color-text-inverse-heading, #fff) 34.96%,
						rgba(255, 255, 255, 0) 86.96%
					);
					justify-content: start;
					left: 0;

					@include rtl {
						right: 0;
						left: unset;
						justify-content: end;
					}
				}
			}

			height: 100%;
			pointer-events: all;

			&::before {
				border-radius: 100px;
				border: 1px solid var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
				background: var(--color-surface-default, #fff);
				padding: 9px 13.5px;
			}

			&-left {
				padding-left: 20px;
			}
			&-right {
				padding-right: 20px;
			}
		}
	}

	&-tab {
		height: 100%;
		width: 100%;
		padding: 10px 20px 20px;
		align-self: stretch;
		text-align: center;
		display: inline-block;
		background: none;
		border: none;
		font-weight: 400;
		min-width: 160px;
		max-width: 160px;

		&--dark {
			@include grayHover($theme: 'dark');
			color: $white;
		}

		&--light {
			@include grayHover($theme: 'light');
			color: $gray1;
		}

		// This psuedo-element makes the inactive tab text take up as much space
		// as the active (bold) text will to avoid layout shifting
		&::before {
			display: block;
			content: attr(title);
			font-weight: bold;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}

		&--active {
			font-weight: bold;
			padding-bottom: 16px;
			border-bottom: 4px solid var(--color-text-heading, #030303) !important;
		}
	}

	&-pages {
		display: block;
		width: auto;
	}

	&-page {
		position: relative;
		left: 0;
		top: 0;
		display: none;

		&--active {
			display: block;
		}
	}
}
