.YachtSubscribeBody {
	.SubscriptionContainer {
		margin-top: 0 !important;
		padding-top: 0 !important;
		margin-bottom: 10px !important;
		padding-bottom: 0 !important;
	}

	.SubscriptionForm {
		.Intro {
			margin-bottom: 10px;
		}

		.formElement-label {
			@include typography('c4');
		}

		.LegalAgreement {
			.Checkbox-decoration {
				border-color: #000;
			}
		}
	}
}
