.FeaturedProperty {
	&-title {
		margin-top: 0;
		text-align: center;

		@include component-spacing;
	}
	&-image {
		position: relative;

		@include viewport(medium) {
			&::after {
				@include gradient2transparent($height: 60%);
				bottom: 0;
				content: '';
				display: block;
				position: absolute;
			}
		}
	}
	&-textBox {
		margin-top: 24px;
		.BodySection--dark & .CTA {
			@include grayHover('dark');
			color: $white;
			border-color: $white;
		}

		@include viewport(medium) {
			// We use extra pixels to account for the border of the CTA
			bottom: 34px;
			color: $white;
			left: 30px;
			position: absolute;
			z-index: 1;

			@include rtl {
				left: 0;
				right: 30px;
			}
			.CTA {
				@include grayHover('dark');
				color: $white;
				border-color: $white;
			}
		}

		@include viewport(xlarge) {
			// We use extra pixels to account for the border of the CTA
			bottom: 44px;
			left: 40px;

			@include rtl {
				left: 0;
				right: 40px;
			}
		}
	}
	&-subtitle {
		background-color: transparent;
		display: block;
		margin-bottom: 7px;
	}
}
