.HeadingTextCta {
	&-heading {
		margin-bottom: 20px;
		text-align: center;
		p {
			margin: 0;
		}
	}

	&-title {
		text-transform: uppercase;
	}

	&-cta {
		text-align: center;
		margin-bottom: 20px;

		@include viewport(large) {
			margin-top: 30px;
		}
	}

	&--light {
		.HeadingTextCta-title::after {
			background-color: $black;
		}
	}

	&--dark {
		.HeadingTextCta-title::after {
			background-color: $white;
		}
	}
	margin: 0 12px;

	@include viewport(small) {
		margin: auto;
		margin-top: 70px;
		margin-bottom: 70px;
		width: 325px;
	}

	@include viewport(medium) {
		//tablet
		margin: auto;
		margin-top: 74px;
		margin-bottom: 74px;
		width: 450px;
	}

	@include viewport(large) {
		//desktop
		margin: auto;
		margin-top: 94px;
		margin-bottom: 94px;
		width: 700px;
	}
}
