.caw-popup__wrapper {
	margin-top: 15px;
	.close-btn {
		position: absolute;
		display: block;
		top: 25px;
		right: 12px;
		font-size: 20px;
	}
	.arrow {
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 16px solid white;
		position: absolute;
		display: block;
		top: 0;
		right: 30px;
		@include rtl {
			right: inherit;
			left: 30px;
		}
	}
}
