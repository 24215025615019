.LWCHotelStatus {
	&-Container {
		max-width: calc(100vw - 30px);

		@include viewport(medium) {
			max-width: 690px;
		}

		@include viewport(large) {
			max-width: 1040px;
		}
		margin: auto;
		box-shadow: 0 0 10px 0 change-color($color: $black, $alpha: 0.1);
		padding: 30px 0;

		@include viewport(medium) {
			padding: 50px 30px;
		}
	}

	&-title {
		text-align: center;
		margin-bottom: 30px;
		letter-spacing: em(4, 32);

		@include viewport(large) {
			font-size: rem(32, 16);
			line-height: em(47, 32);
		}
	}

	&-text {
		text-align: center;
		margin: 0 30px;
		margin-bottom: 10px;

		@include viewport(medium) {
			margin-bottom: 20px;
		}
	}

	.PaginatedFilter {
		margin-bottom: 25px;

		@include viewport(large) {
			margin-bottom: 0;
		}
		.FilterGroup {
			&-container {
				position: static;
				background-color: $white;
				.FilterGroup-toggle--open .FilterGroup-toggle-button,
				.Button--secondary--light:hover {
					background-color: $white;
					color: $black;

					.Button-label {
						border-bottom: 1px solid $black;
					}
				}
			}

			&-TextFilter {
				margin: 0;
				margin-bottom: 10px;

				@include viewport(medium) {
					margin-bottom: 20px;
					margin-left: 25%;

					@include rtl {
						margin-left: 0;
						margin-right: 25%;
					}
				}

				@include viewport(large) {
					margin: 0;

					@include rtl {
						margin: 0;
					}
				}

				.TextInput-field {
					background-color: $gray4;
				}
			}

			&-toggle {
				margin: 0;

				&-container {
					padding-bottom: 10px;
					flex-direction: column;
					align-items: flex-start;

					@include viewport(medium) {
						padding-bottom: 20px;
						flex-direction: row;
						align-items: center;
					}
				}

				&-button {
					padding: 10px 0;

					@include viewport(medium) {
						padding: 0;
					}
					.Button-label {
						text-align: left;

						@include typography(c3);

						@include viewport(large) {
							@include typography(c1);
						}

						@include rtl {
							text-align: right;
						}
					}
					&::after {
						margin-left: 10px;
						position: static;
					}

					@include rtl {
						&::after {
							margin-left: 0;
							margin-right: 10px;
						}
					}
				}
			}

			&-clearButtonContainer {
				@include viewport(medium) {
					border-left: 1px solid $gray3;
					padding-left: 30px;

					@include rtl {
						border-left: none;
						border-right: 1px solid $gray3;
					}
				}
			}

			&-optionGroup {
				border-top: none;
				background-color: $gray6;
				position: relative;

				&--mobile {
					border-top: none;
					background-color: $gray6;
					position: relative;
					margin: 10px 0;
				}

				&-container {
					@include viewport(medium) {
						display: block;
						padding: 0;
						position: relative;
					}

					&--background {
						background-color: $gray6;
						height: 100%;
						width: 100vw;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						max-width: calc(100vw - 30px);

						@include viewport(medium) {
							max-width: 690px;
						}

						@include viewport(large) {
							max-width: 1040px;
						}
					}

					&--mobile {
						display: block;

						@include viewport(medium) {
							display: none;
						}
					}
				}
				&-fields {
					margin-top: 0;
					padding-top: 10px;
				}

				&-field {
					padding-left: 15px;
					padding-right: 15px;
					&:nth-child(3n + 1) {
						padding-left: 15px;
						padding-right: 15px;
					}

					@include viewport(medium) {
						margin: 10px 0;
					}

					@include viewport(large) {
						margin: 20px 0;
					}

					.formElement-field {
						background-color: $gray6;
					}

					.formElement-label {
						font-weight: 400;
					}
				}

				&-buttons {
					flex-direction: row;
					margin: 20px 15px;

					@include viewport(medium) {
						margin: auto 0;

						&.col-md-12 {
							margin-bottom: 20px;
						}
					}

					@include viewport(large) {
						&.col-md-12 {
							margin: auto 0;
						}
					}
				}

				&-button {
					margin: 0;
					padding: 7.5px 20px;
					z-index: 1;
				}

				&-cancelButton {
					background-color: $gray6;
				}

				&-saveButton {
					margin-left: 25px;

					@include rtl {
						margin-left: 0;
						margin-right: 25px;
					}

					@include viewport(medium) {
						margin-left: 30px;

						@include rtl {
							margin-left: 0;
							margin-right: 30px;
						}
					}
				}

				&#location {
					position: absolute;
					width: calc(100% - 30px);
					top: 125px;

					@include viewport(medium) {
						position: relative;
						width: 100%;
						top: auto;
					}
				}

				&#experiences {
					position: absolute;
					width: calc(100% - 30px);
					top: 161px;

					@include viewport(medium) {
						position: relative;
						width: 100%;
						top: auto;
					}
				}
			}
		}

		&-clearButton {
			&.FilterGroup-toggle {
				margin-left: 15px;

				@include rtl {
					margin-left: 0;
					margin-right: 15px;
				}

				@include viewport(medium) {
					margin-left: 0;

					@include rtl {
						margin-right: 0;
					}
				}
			}

			margin: 9px 0;
			.Button-label {
				@include typography(c3);

				@include viewport(large) {
					@include typography(c1);
				}
			}
		}

		&-body {
			padding-top: 0 !important;
		}

		&-pagination {
			&-navigation {
				margin-top: 10px;
				position: relative;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}

			&-showAll {
				display: block !important;
				margin: auto;
				margin-top: 15px;
				position: absolute;
				bottom: -25px;

				@include viewport(large) {
					margin-top: auto;
					bottom: auto;
					right: 0;

					@include rtl {
						right: auto;
						left: 0;
					}
				}

				@include typography(c3);

				@include viewport(large) {
					@include typography(c1);
				}
			}

			&-button {
				height: auto;
				width: auto;
				margin: 0 7.5px;
				&.activeButton {
					color: $black;
					background-color: #f4f4f4;
					border-radius: 0;
					border: 1px solid $black;
					padding: 6px;
					padding-left: 8px;

					@include rtl {
						padding-left: 6px;
						padding-right: 8px;
					}
				}

				@include viewport(large) {
					margin: 0 15px;
				}
			}

			&-buttons {
				display: flex;
			}

			&-arrowButton {
				font-size: 15px;
				width: 15px;

				@include viewport(large) {
					width: 48px;
				}
			}

			&--next {
				margin-left: 6.25px;

				@include rtl {
					margin-left: 0;
					margin-right: 6.25px;
				}

				@include viewport(medium) {
					margin: 0;
				}
			}

			&--prev {
				margin-right: 6.25px;

				@include rtl {
					margin-left: 6.25px;
					margin-right: 0;
				}

				@include viewport(medium) {
					margin: 0;
				}
			}
		}

		&-noResults {
			display: flex;
			flex-direction: column;
			margin-top: 50px;

			&-text {
				@include typography(c1);
				margin-bottom: 10px;
			}

			&-subtext {
				margin-bottom: 60px;
			}

			&-cta {
				margin: auto;

				@include viewport(medium) {
					margin: 0;
					margin-left: auto;

					@include rtl {
						margin-left: 0;
						margin-right: auto;
					}
				}
			}
		}

		&-item {
			.LWCHotelStatus-Card {
				border-top: 1px solid $gray3;
			}
		}

		&--loading {
			padding-top: 0;
		}
	}

	&-Filter {
		position: relative;
		padding: 0 22.5px;

		@include viewport(medium) {
			padding: 0 15px;
		}

		&.FilterGroup-toggle--open {
			& + .FilterGroup-optionGroup-container--mobile .FilterGroup-optionGroup--mobile {
				display: block;
			}

			&::after {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				border-left: 20px solid transparent;
				border-right: 20px solid transparent;
				border-bottom: 20px solid $gray6;
				left: 50%;
				transform: translateX(calc(-50% - 12px));

				bottom: -16px;

				@include viewport(medium) {
					bottom: -24px;
				}

				@include viewport(large) {
					bottom: -40px;
				}
			}
		}

		&--location {
			&.FilterGroup-toggle.LWCHotelStatus-Filter {
				@include viewport(medium) {
					margin-left: 75px;

					@include rtl {
						margin-left: 0;
						margin-right: 25%;
					}
				}

				@include viewport(large) {
					margin-left: 0;

					@include rtl {
						margin-right: 0;
					}
				}
			}
		}

		&--experience {
			padding-right: 30px;

			@include rtl {
				padding-right: 22.5px;
				padding-left: 30px;
			}
		}

		.Button-label {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}
