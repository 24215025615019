.toggledInput {
	position: static;
	max-height: 1em;
	transition: max-height 0.2s ease-out;

	&__toggle {
		position: relative;
		display: inline-block;
		cursor: pointer;
		z-index: 1;
		font-weight: bold;
		padding-right: 15px;
		@include rtl {
			padding-right: 0;
			padding-left: 15px;
		}
	}

	&--open &__toggle {
		font-weight: normal;
	}

	&__action-icon {
		width: 10px;
		height: 10px;
		position: absolute;
		top: 50%;
		right: 0;
		transition: opacity 0.25s ease;
		transform: translateY(-50%);
		@include rtl {
			right: auto;
			left: 0;
		}

		.textInput--error & {
			fill: $error-red;
		}
	}

	&--open &__action-icon {
		opacity: 0;
	}

	.textInput {
		opacity: 0;
		transition: opacity 0.25s ease;
	}

	&--open {
		max-height: calc(80px + 3em);

		.textInput {
			transition-delay: 0;
			opacity: 1;
		}
	}
}
