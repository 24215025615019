.CenterFocusCarousel {
	overflow: hidden;
	margin: auto;
	max-width: 100%;
	&-heading {
		text-align: center;
		margin-bottom: 10px;

		@include viewport(medium) {
			margin-bottom: 30px;
		}

		@include viewport(large) {
			margin-bottom: 20px;
		}
	}
	display: flex;
	flex-direction: column;

	&-mobile {
		display: block;

		@include viewport(medium) {
			display: none;
		}
	}

	&-container {
		&--desktop {
			display: none;

			@include viewport(medium) {
				display: block;
			}
		}
	}

	&-navigation {
		display: flex;
		align-items: center;
		margin: auto;
		margin-top: 10px;
		justify-content: center;

		&--desktop {
			display: none;

			@include viewport(medium) {
				display: flex;
				margin-top: 80px;
			}
		}

		&--mobile {
			display: flex;

			@include viewport(medium) {
				display: none;
			}

			.CenterFocusCarousel-pagination {
				font-size: rem(10, 16);
				&--button {
					font-size: 10px;

					@include rtl {
						font-size: 10px;
					}
				}
			}
		}
	}

	&-pagination {
		display: flex;
		margin: 0 12px;

		&--button {
			font-size: 15px;

			@include rtl {
				font-size: 15px;
			}

			&.button-disabled {
				opacity: 0.5;
			}
		}
	}
}
