.TableList {
	&-container {
		&--dark {
			background-color: $black;
			color: $white;
		}

		&--light {
			background-color: $white;
			color: $black;
		}

		display: block;
	}

	&-item {
		border-top: 1px solid;
		display: block;
		padding: 20px 0 20px;

		@include viewport(large) {
			padding-bottom: 20px;
		}

		@include viewport(xlarge) {
			padding-bottom: 25px;
		}

		&:last-of-type {
			padding-bottom: 0;
		}

		.TableList--verticallyCentered & {
			align-items: center;
		}

		&-title {
			flex: 1;
		}

		&-description {
			flex: 1;
			display: flex;
			flex-direction: column;
			text-align: left;
			width: 100%;
			margin: 0;
			padding-top: 10px;

			@include rtl {
				text-align: right;
			}

			&-text:last-of-type + &-cta {
				margin-top: 20px;

				@include viewport(xlarge) {
					margin-top: 40px;
				}
			}

			&-text {
				&:not(:last-of-type) {
					padding-bottom: 10px;
				}
			}

			&-cta {
				margin-top: 20px;

				.TableList--verticallyCentered & {
					margin: 0;
				}

				.CTA {
					display: inline-block;
				}

				&--onlyText {
					display: inline-block;
					color: $black;

					.TableList-container--dark & {
						color: $white;
					}
				}
			}
		}
	}

	&-item--assets {
		display: flex;
		align-content: center;

		.TableList-item-title {
			text-align: left;
			margin: auto 0;
			padding-right: $gutter_small/2;

			@include rtl {
				text-align: right;
				padding-right: 0;
				padding-left: $gutter_small/2;
			}
		}

		.TableList-item-description {
			text-align: right;
			margin: auto 0;
			max-width: 50%;
			padding-left: $gutter_small/2;

			@include rtl {
				text-align: left;
				padding-left: 0;
				padding-right: $gutter_small/2;
			}

			&-cta {
				margin: 0;

				.CTA {
					.TableList-container--light & {
						color: $white;
					}
				}
			}
		}
	}

	&-item-disclaimer {
		text-align: left;
		display: block;
		padding-bottom: 30px;

		@include viewport(medium) {
			text-align: center;
		}
		@include rtl {
			text-align: right;
		}
	}
}
