$secondary-navigationDividerColor-light: $separatorLight;
$secondary-navigationDividerColor-dark: $separatorDark;
$secondary-navigationBorderColor-light: $black;
$secondary-navigationBorderColor-dark: $gray4;
$secondaryNavHeight: 55px;
$secondaryNavigation-height: 55px;
$secondaryNavigation-height--expanded: 101px;
$secondaryNavigation-verticalPadding: 0;
$secondaryNavigation-verticalPadding--scrolled: 17px;

.SecondaryNavigation {
	display: none;

	@include media($navigationBreakpoint) {
		display: block;
	}

	padding: $secondaryNavigation-verticalPadding 30px;
	background-color: rgba(235, 235, 235, 0.9);
	transition: padding 0.3s ease-in-out;

	.is-Stick & {
		padding: $secondaryNavigation-verticalPadding--scrolled 30px;
	}

	.is-Stick .is-StickExpanded & {
		padding: 0 30px;
	}

	&-bar {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&-links {
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: center;

		.is-Stick & {
			margin-bottom: (-$secondaryNavigation-verticalPadding--scrolled);
		}
		.is-Stick .is-StickExpanded & {
			margin-bottom: 0;
		}

		&Container {
			flex: 1 1 auto;
			margin-left: 30px;
			padding-left: 15px;
			width: 100%;

			@include rtl {
				margin-right: 30px;
				margin-left: 0;
				padding-right: 15px;
				padding-left: 0;
			}

			.Navigation--isPropertyNav & {
				padding-left: 20px;

				@include rtl {
					padding-left: 0;
					padding-right: 25px;
				}
			}

			.Navigation--isPropertyNav.is-Stick & {
				border-left: 1px solid #979797;

				@include rtl {
					padding-left: 0;
					padding-right: 25px;
					border-left: none;
					border-right: 1px solid #979797;
				}
			}

			.SecondaryNavigation-propertyName {
				line-height: 1em;
				letter-spacing: 3px;
			}
		}

		& > .NavigationItem:first-child {
			padding-left: 0;
		}

		&.has-featured {
			.NavigationItem {
				&:first-of-type {
					position: relative;
					padding-right: 20px;

					@include rtl {
						padding-right: 0;
						padding-left: 20px;
					}

					.CTA {
						font-weight: 700;
					}

					&::before {
						content: '';
						position: absolute;
						display: block;
						top: 50%;
						right: 0;
						width: 1px;
						height: 26px;
						transform: translateY(-50%);
						background-color: #999;
					}

					&:only-child {
						.CTA {
							font-weight: 500;
						}
						&::before {
							display: none;
						}
					}

					@include rtl {
						&::before {
							right: initial;
							left: 0;
						}
					}
				}

				&:nth-of-type(2) {
					padding: 0 15px 0 20px;

					@include rtl {
						padding: 0 20px 0 15px;
					}
				}
			}
		}
	}

	.SecondaryNavigation-logo {
		display: block;
		color: $black;

		&Container {
			opacity: 0;
		}

		.Navigation-lg &.BeverlyWilshire::before {
			content: ' ';
			height: 57px;
			width: 100px;
			background-size: contain;
			background-position: center;
			background-image: url('/alt/fshr/design3/images/BEV_logo_dark.png');
		}
	}

	&-pageName {
		position: relative;
		display: block;
		top: 0;
		opacity: 0;
		height: 0;
		line-height: 0.8em;
		letter-spacing: 3px;
		pointer-events: none;

		&--empty {
			height: 18px;
			max-height: 0;
		}

		&--active {
			line-height: 0.8em;
			top: 6px;
		}
	}

	&-item {
		&.multiple-links {
			display: none;

			&.item-0 {
				display: block;
			}
		}
	}

	&-More.single-link {
		display: none;
	}

	&-cta {
		flex-basis: 190px;
		opacity: 0;
		visibility: hidden;

		.is-Stick & {
			opacity: 1;
			visibility: visible;

			@include rtl {
				right: auto;
				left: 30px;
			}
		}

		&--animate {
			opacity: 1;
			visibility: visible;
		}
	}

	&-cartStatus {
		opacity: 0;
		visibility: hidden;

		&.CartStatus-placeholder.with-cta {
			left: unset;
			right: 30px;
		}

		.is-Stick & {
			opacity: 1;
			visibility: visible;
		}

		&--animate {
			opacity: 1;
			visibility: visible;
		}
	}
}

@supports (backdrop-filter: blur(1px)) {
	.SecondaryNavigation {
		background-color: rgba(235, 235, 235, 0.85);
		backdrop-filter: blur(13px);
	}
}
