.Navigation {
	.NavigationCAW {
		display: none;
		position: absolute;
		width: 100%;
		z-index: 1;

		.DateField_container,
		.OccupancyField_container,
		.Dropdown-container select,
		.textInput {
			border: 1px solid $gray3;
		}

		.Submit-container > .form__submit-button {
			padding-top: 0;
			padding-bottom: 0;
		}

		.caw-comp__wrapper {
			.App-wrapper .container .row .cawComponent.horizontal {
				height: 108px;
				padding: 50px 0 18px;
				background-color: rgba(255, 255, 255, 0.85);
				background: linear-gradient(
					180deg,
					rgba(255, 255, 255, 0.65) 0%,
					rgba(255, 255, 255, 0.85) 30%
				);
				box-shadow: 0 25px 51px -30px #000;

				.Navigation-background-filter-fallback {
					background-color: rgba(0, 0, 0, 0.4);
				}

				&.isOffline {
					height: auto;

					.Overlay_caw_wrap {
						justify-content: center;
						align-items: center;
					}

					.Overlay_caw_body {
						margin-bottom: 0;
					}

					.Overlay_caw_title,
					.Overlay_caw_body {
						margin-left: 0;
					}
				}

				&.hasAlert {
					height: auto;
					padding: 15px 132px 15px 141px;

					&.singleProperty {
						padding: 15px 132px 15px 146px;
					}

					.Overlay_caw_wrap,
					.container {
						width: 100%;
					}

					.Overlay_caw_wrap {
						justify-content: space-between;
					}

					.Overlay_caw_button {
						align-self: center;
						top: 0;
						transform: none;
					}
				}
			}

			.DateField_row,
			.caw-popup__wrapper .DateField_row {
				border-bottom: none;
			}
		}

		.hasEliteGuestComponent {
			padding-top: 20px;
			height: auto;
		}
	}

	.InlineCaw {
		display: none;

		@include media($navigationBreakpoint) {
			display: block;
		}
	}

	&.caw-open {
		.Navigation-background {
			border-bottom: none;
		}
	}
}

.Navigation--moreLinkClicked .cawComponent.horizontal,
.Navigation--moreLinkExpanded .cawComponent.horizontal {
	z-index: -1;
}

@supports (backdrop-filter: blur(1px)) {
	.Navigation {
		.NavigationCAW {
			.caw-comp__wrapper .App-wrapper .container .row .cawComponent.horizontal {
				background: rgba(255, 255, 255, 0.75);
				background: linear-gradient(
					180deg,
					rgba(255, 255, 255, 0.55) 0%,
					rgba(255, 255, 255, 0.75) 30%
				);
				backdrop-filter: blur(13px);
				-webkit-backdrop-filter: blur(13px);
			}
		}
	}
}
