.FullBleedVideoOverlay {
	position: relative;
	&-backgroundImage {
		height: calc(100vw * 9 / 16);

		&::after {
			@include gradient2transparent($height: 20%);
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
		}
	}

	&-gallery {
		position: absolute;
		bottom: 20px;
		left: 20px;

		@include viewport(medium) {
			left: 40px;
		}

		@include viewport(large) {
			bottom: 30px;
			left: 30px;
		}

		.Gallery-button::before {
			margin-right: 15px;
		}
	}
}
