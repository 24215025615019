.FindMeetingVenueWizard {
	background-color: $gray4;
	overflow: hidden;

	.flickity-viewport {
		transition: height 0.2s;
	}
	.WizardPage {
		width: 100%;
		padding: 40px 0;

		@include viewport(large) {
			padding: 60px 0;
		}
	}

	&--uninitialized {
		visibility: hidden;
		max-height: 300px;
	}

	&-page {
		display: flex;
		flex-direction: column;
		justify-content: center;
		&--hidden {
			display: none;
		}

		&-heading {
			text-align: center;
		}

		&-buttons {
			margin-top: 50px;

			@include viewport(large) {
				display: flex;
			}
		}

		&-optionalLabel {
			text-align: center;
			margin-top: 30px;

			@include viewport(large) {
				margin-top: 50px;
			}
		}

		&-navigation {
			margin-top: 30px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;

			&-buttons {
				display: flex;
				flex-direction: row;
			}

			&-button {
				margin: 0 15px 30px;
			}

			&-number {
				display: flex;
				flex-direction: row;
				align-items: center;

				&-line {
					@include rtl {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	&-wizardButton {
		margin-top: 5px;
		margin-bottom: 5px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		@include viewport(medium) {
			margin-top: 15px;
			margin-bottom: 15px;
		}

		@include viewport(large) {
			margin-top: 30px;
			margin-bottom: 30px;
		}
		&-button {
			width: 100%;
			height: 100px;
			margin-bottom: 30px;

			@include viewport(large) {
				width: 125px;
				height: 125px;
			}

			@include viewport(xlarge) {
				width: 165px;
				height: 165px;
			}
			background-color: $white;
			transition: opacity 0.5s;
			position: relative;

			&--active {
				&:not(.FindMeetingVenueWizard-wizardButton-button--noBackground) {
					border: 1px solid $black;
				}
			}
			&--inactive {
				opacity: 0.5;
			}

			&-icon {
				color: transparent;
				font-size: 0;
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);

				@include viewport(large) {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				&::before {
					color: $black;
					vertical-align: middle;
					font-size: 3rem;
				}
			}

			&-textOnly {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				width: 100%;

				@include viewport(large) {
					flex-direction: column;
					width: 70%;
				}

				@include viewport(xlarge) {
					width: 60%;
				}

				span {
					padding: 0 10px;

					@include viewport(large) {
						padding: 0;
					}
				}
			}

			&--noBackground {
				background-color: $gray4;
				height: 165px;

				@include viewport(medium) {
					height: 115px;
				}

				@include viewport(large) {
					height: 125px;
				}

				@include viewport(xlarge) {
					height: 165px;
				}

				.FindMeetingVenueWizard-wizardButton-button-icon {
					font-size: 0;
					color: transparent;
					transition: color 0.5s;
					left: 50%;
					transform: translate(-50%, -50%);

					@include viewport(medium) {
						left: 5px;
						transform: translateY(-50%);
					}

					@include viewport(large) {
						left: 50%;
						transform: translate(-50%, -50%);
					}

					&::before {
						color: $gray2;
						vertical-align: top;
						font-size: 7rem;

						@include viewport(medium) {
							font-size: 5rem;
						}

						@include viewport(xlarge) {
							font-size: 6rem;
						}
					}
				}

				&.FindMeetingVenueWizard-wizardButton-button--active {
					.FindMeetingVenueWizard-wizardButton-button-icon {
						color: $black;
					}
				}
			}
		}

		&-label {
			vertical-align: middle;
			&-container {
				&.mobile {
					display: block;
					position: absolute;
					text-align: left;
					width: 140px;
					top: 50%;
					left: 40%;
					transform: translateY(-50%);

					@include viewport(large) {
						display: none;
					}
				}
				&.desktop {
					display: none;
					text-align: center;
					margin: auto;
					width: 100%;
					position: relative;

					@include viewport(large) {
						display: block;
					}
				}

				@include viewport(xlarge) {
					width: 165px;
				}
			}

			&--noBackground {
				&.mobile {
					display: none;

					@include viewport(medium) {
						display: block;
					}

					@include viewport(large) {
						display: none;
					}
				}
				&.desktop {
					display: block;

					@include viewport(medium) {
						display: none;
					}

					@include viewport(large) {
						display: block;
					}
				}
			}
		}

		&-text {
			&--italic {
				font-style: italic;
				background: $white;
			}
			&--centerLine {
				width: 33%;

				@include viewport(large) {
					width: 100%;
				}
				text-align: center;
				border-bottom: 1px solid $gray3;
				line-height: 0.1em;

				span {
					padding: 0 15px;
				}
			}

			&--column {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
