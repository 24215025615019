.ExperiencesPanel {
	&-heading {
		text-align: center;
		margin: 0 0 40px;

		@include viewport(medium) {
			margin: 0 0 60px;
		}

		@include viewport(large) {
			margin: 0 0 70px;
		}
	}

	&-item {
		border-bottom: 1px solid $separatorLight;
		margin-bottom: 80px;
		padding-bottom: 40px;

		.BodySection--dark & {
			border-color: $separatorDark;
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}

		@include viewport(medium) {
			margin-bottom: 60px;
		}

		@include viewport(large) {
			display: flex;
			justify-content: space-between;
			padding-bottom: 30px;
			margin-bottom: 70px;

			&:nth-child(even) {
				flex-direction: row;
			}

			&:nth-child(odd) {
				flex-direction: row-reverse;
			}
		}
	}

	&-itemImage {
		margin: 0 0 30px;

		@include viewport(large) {
			margin: 0;
			flex: 0 0 56.7%;
		}
	}

	&-itemDetail {
		@include viewport(large) {
			margin: 0;
			flex: 0 0 39.6%;
		}
	}

	&-itemHeading {
		margin: 0 0 30px;
	}

	&-itemHeadingText {
		margin: 0 0 15px;
	}

	&-itemDescription {
		margin: 0;
	}

	&-featureList {
		margin: 0 0 40px;
	}

	&-feature {
		display: flex;
		margin-bottom: 20px;
		align-items: center;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-featureIcon {
		font-size: rem(26, 16);
		text-align: center;
		flex-basis: 35px;
		margin: 0 14px 0 0;

		@include rtl {
			margin: 0 0 0 14px;
		}

		&::before {
			display: block;
		}
	}

	&-featureText {
		line-height: rem(18, 16);
	}

	&-itemCta {
		display: inline-block;

		.BodySection--dark & {
			@include grayHover('dark');
			color: $white;
		}
	}
}
