.LoadingIndicator {
	&--dots-flashing {
		$animation-time: 0.5s;

		position: relative;
		display: flex;
		flex-direction: row;
		gap: 6px;

		&__dot {
			width: 5px;
			height: 5px;
			border-radius: 1000px;
			background-color: var(--color-button-primary-pressed);
			transform-origin: 50% 50%;
			animation: loading-indicator-dot-flashing $animation-time infinite linear alternate;
			animation-delay: calc($animation-time / 2);

			&:nth-child(1) {
				animation-delay: 0s;
			}

			&:nth-child(3) {
				animation-delay: $animation-time;
			}
		}
	}
}

@keyframes loading-indicator-dot-flashing {
	0% {
		opacity: 1;
		transform: scale(1.2);
	}
	50% {
		opacity: 0.2;
		transform: scale(1);
	}
	100% {
		transform: scale(1);
		opacity: 0.2;
	}
}
