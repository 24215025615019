.caw-comp__wrapper,
.caw-popup__wrapper .destinationPicker {
	&-container--takeover {
		height: auto;
		&::before {
			height: auto;
		}
	}
}
.destinationPicker {
	position: relative;

	&__nav {
		position: absolute;
		opacity: 0;
		list-style-type: none;
		margin: 0;
		padding: 10px 0 25px;
		pointer-events: none;

		&--active {
			position: static;
			opacity: 1;
			pointer-events: auto;
		}

		&-item a {
			@include link('underline', 'dark');
			@include viewport(medium) {
				padding: 0 calc((100% - 690px) / 2 + 58px);
			}
			@include media($navigationBreakpoint) {
				padding: 0 $gutter_medium * 0.5;
			}
		}
	}

	&__results-container {
		position: absolute;
		width: calc(100% + 80px);
		top: 88px;
		left: -40px;
		background: $gray6;
		z-index: 2;
		opacity: 0;
		transition: all ease-in-out;
		transform: translateY(30px);
		display: none;
		@include media($navigationBreakpoint) {
			display: block;
			left: auto;
			width: 100%;
			top: 58px;
			transform: translateY(0);
		}

		&--active {
			opacity: 1;
			transition: all ease-in-out;
			transform: translateY(0);
		}
	}

	&__results {
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
		background: $gray6;
		margin-top: -12px;
	}

	&__takeover &__results-container {
		display: block;
	}

	&__result {
		display: block;
		width: 100%;
		padding: 12px 40px;
		text-align: left;
		border-radius: 0;
		@include media($navigationBreakpoint) {
			padding: 12px 20px;
		}

		&--focus,
		&:focus,
		&:hover {
			color: $white;
			background: $black;

			.destinationPicker__userInput {
				color: $gray2;
			}
		}
		@include rtl {
			text-align: right;
		}
	}

	hr {
		margin-top: 0;
		margin-bottom: 30px;
		border: 0;
		height: 1px;
		background: #333;
		@include viewport(medium) {
			margin-left: auto;
			margin-right: auto;
			max-width: 690px;
		}
		@include media($navigationBreakpoint) {
			margin-top: 20px;
			max-width: 930px;
		}
		@include viewport(xlarge) {
			max-width: 1530px;
		}
	}

	&__results-container hr {
		margin: 0 auto;
		width: calc(100% - 80px);
		@include viewport(medium) {
			margin: 0 40px;
		}
		@include media($navigationBreakpoint) {
			margin: 0 20px;
			width: auto;
		}
	}

	&__userInput {
		color: #707070;
	}

	&__viewAllHeadline {
		display: none;
		@include media($navigationBreakpoint) {
			display: block;
			margin: 120px auto 0;
			padding: 0 $gutter_medium * 0.5;
			width: 930px;
		}
		@include viewport(xlarge) {
			width: 1530px;
		}
	}

	&__viewAll {
		padding: 20px 40px;
		font-weight: bold;
		width: 100%;
		text-align: left;
		background: $gray6;
		@include media($navigationBreakpoint) {
			padding: 20px;
		}
		@include rtl {
			text-align: right;
		}

		&--active,
		&:focus,
		&:hover {
			color: $white;
			background: $black;

			svg {
				path {
					stroke: $white;
				}

				g,
				polygon {
					fill: $white;
				}
			}
		}
	}

	&__viewAllText {
		position: relative;
		display: inline-block;
		padding-right: 20px;
		@include rtl {
			padding-right: 0;
			padding-left: 20px;
		}
	}

	&__arrowIcon {
		width: 13px;
		height: 11px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		@include rtl {
			right: auto;
			left: 0;
			transform: scaleX(-1) translateY(-50%);
		}
	}

	&__selectAll {
		position: fixed;
		z-index: 200;
		background: $black;
		color: $white;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		overflow: auto;
		opacity: 0;
		transition: opacity ease-in-out;
		transform: translate3d(0, 0, 0);

		&--active {
			opacity: 1;
			transition: opacity ease-in-out;
			transform: translate3d(0, 0, 0);
		}
	}

	&__region {
		margin-bottom: $gutter_large;
		@include media($navigationBreakpoint) {
			width: percentage(1 * 0.2);
			float: left;
			@include rtl {
				float: right;
			}
		}
	}

	&__regions {
		@include media($navigationBreakpoint) {
			margin: 0 auto;
			width: 930px;
		}
		@include viewport(xlarge) {
			width: 1530px;
		}
	}

	&__region-title {
		padding: 0 $gutter_medium;
		@include viewport(medium) {
			padding: 0 calc((100% - 690px) / 2 + 58px);
		}
		@include media($navigationBreakpoint) {
			padding: 0 $gutter_medium * 0.5;
		}
	}

	&__property-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__property-select {
		display: block;
		width: 100%;
		padding: 12px $gutter_medium;
		text-align: left;
		border-radius: 0;

		@include viewport(medium) {
			/*(100% - container size)/2 + one column width*/
			padding: 12px calc((100% - 690px) / 2 + 58px);
		}
		@include media($navigationBreakpoint) {
			padding: 12px $gutter_medium * 0.5;

			&:active,
			&:focus,
			&:hover {
				color: $black;
				background: $white;
			}
		}
		@include rtl {
			text-align: right;
		}
	}

	&__back {
		position: relative;
		padding: 25px $gutter_medium;
		display: block;
		left: $gutter_medium * 0.5;
		@include rtl {
			left: auto;
			right: 28px;
		}
		@include viewport(medium) {
			left: calc((100% - 690px) / 2);
			@include rtl {
				left: auto;
				right: calc((100% - 690px) / 2);
			}
		}
		@include media($navigationBreakpoint) {
			position: absolute;
			left: calc((100% - 930px) / 2);
			top: 24px;
			padding: 20px $gutter_medium;
			@include rtl {
				left: auto;
				right: calc((100% - 930px) / 2);
			}
		}
		@include viewport(xlarge) {
			left: 40px;
			@include rtl {
				left: auto;
				right: 40px;
			}
		}

		&--takeover {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			width: 150px;
			height: 51px;
			z-index: 100;
			@include rtl {
				right: 0;
			}
			@include media($navigationBreakpoint) {
				display: none;
			}
		}
	}

	&__back-icon {
		position: absolute;
		width: 10px;
		height: 20px;
		left: 0;
		top: 50%;

		transform: translateY(-50%);
		@include rtl {
			left: auto;
			right: 0;
			transform: translateY(-50%) scaleX(-1);
		}

		&--takeover {
			left: 20px;
			top: 43px;
			@include rtl {
				left: auto;
				right: 10px;
				transform: scaleX(-1) translateY(-50%);
			}
		}
	}

	&-container {
		position: relative;

		&::before {
			content: '';
			opacity: 0;
			display: block;
			position: fixed;
			width: 0;
			height: 0;
			top: 0;
			right: 0;
			background: $white;
			transition: opacity 175ms ease-in-out;
			@include media($navigationBreakpoint) {
				display: none;
				transition: none;
			}
		}

		.select__label {
			@include rtl {
				text-align: right;
				left: auto;
				right: 0;
			}
		}
	}

	&-container--takeover {
		z-index: 5000;
		position: fixed;
		width: 100%;
		height: 100%;
		top: 12px;
		right: 0;
		left: 0;
		padding: 40px;

		&::before {
			opacity: 1;
			width: 100%;
			height: 100%;
		}
		@include media($navigationBreakpoint) {
			position: static;
			padding: 0;
			z-index: 0;

			&::before {
				display: none;
				transition: none;
			}
		}
	}
}
