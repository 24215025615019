.TitleAndCards {
	&-titleContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 30px;

		@include viewport(medium) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&--light {
		color: $black;
	}

	&--dark {
		color: $white;
	}

	&-title {
		text-align: center;

		margin: 0;
		margin-bottom: 20px;

		@include viewport(medium) {
			margin-bottom: 0;
			text-align: left;
		}
	}

	&-cta {
		text-align: center;

		@include viewport(medium) {
			text-align: right;
		}

		.CTA--underlined--light--checkmark::before,
		.CTA--underlined--dark--checkmark::before {
			top: 2px;
		}

		@include rtl {
			.CTA--underlined--light--checkmark::before,
			.CTA--underlined--dark--checkmark::before {
				top: 6px;
			}
		}
	}

	&-cardsContainer {
		display: block;
	}
}
