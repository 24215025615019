.UGCGrid {
	padding: 0 0 30px;

	@include viewport(medium) {
		width: 450px;
	}

	@include viewport(large) {
		width: 930px;
	}
	&-list {
		padding: 0 45px;
		overflow: hidden;

		@include viewport(medium) {
			padding: 0;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: 30px;
		}
	}
	&-item {
		width: 100%;
		margin-right: 15px;

		@include viewport(medium) {
			width: 210px;
			max-width: 210px;
			margin: 0;
			flex: 0 0 50%;
		}

		@include viewport(large) {
			flex-basis: 25%;
		}

		.Image,
		.Collage-figure {
			display: block;
			line-height: 0;
		}
		.Attribution {
			.Attribution-author {
				overflow-wrap: break-word;
			}
			&.facebook,
			&.instagram {
				display: flex;
				.Attribution-author {
					max-width: calc(100% - 26px);
				}
				&::before {
					margin-right: 12px;
					padding-top: 7px;
				}

				@include rtl {
					&::before {
						margin-right: 0;
						margin-left: 12px;
					}
				}
			}
		}
	}

	&-heading {
		text-align: center;
		padding: 0 15px;

		@include viewport(medium) {
			padding: 0;
		}

		@include viewport(large) {
			width: 83.33333%;
			margin: auto;
		}
		.Heading {
			.Heading-title {
				margin-bottom: 30px;
			}
			.Heading-text {
				margin: 0;
			}
			:last-child {
				margin-bottom: 40px;
			}
		}
	}
	&-title {
		text-transform: uppercase;
	}

	&--light {
		.UGCGrid-title::after {
			background-color: $black;
		}
	}

	&--dark {
		.UGCGrid-title::after {
			background-color: $white;
		}
	}
}
