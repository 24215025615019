$navigationBreakpointMin: 1100px;
$navigationBreakpointMax: 1099px;

.InteractiveHero {
	&-backButton,
	&-list {
		display: none;
	}

	.Hero-headingContainer {
		@include viewport(medium) {
			bottom: 110px;
		}

		@include viewport(xlarge) {
			bottom: 80px;
		}
	}

	.BackgroundImage {
		transition: background-image 1s ease-in-out;

		@include viewport(large) {
			height: auto;
			min-height: calc(100vh - 300px);
			max-height: none;
		}

		@include viewport(xlarge) {
			height: auto;
			min-height: calc(100vh - 300px);
			max-height: none;
		}

		&::after {
			background: linear-gradient(to top, transparentize($black, 1) 0%, $black 100%);
			top: 0;
		}

		@media only screen and (min-width: $navigationBreakpointMin) {
			&::after {
				display: none;
			}
		}

		.InteractiveHero-variants {
			.BackgroundImage {
				&::after {
					background: linear-gradient(to top, transparentize($black, 1) 0%, $black 100%);
					top: 0;
					bottom: auto;
					position: absolute;
					display: block;
					content: '';
					width: 100%;
					height: 60%;
					z-index: 0;
				}
				.Variant--flickity {
					position: relative;
					z-index: 1;
				}
			}
		}
	}

	&-map {
		padding-bottom: 250px;
		padding-top: 40px;
		text-align: center;

		svg {
			width: auto;
			height: calc(88vh - 155px);
			min-height: 400px;
			max-width: 95vw;
			max-height: calc(68vh - 200px);

			a {
				circle {
					position: relative;
					stroke: transparent;
					border-radius: 9999px;
					stroke-width: 70;
				}

				&:hover circle.circle-hover {
					animation: sonar 1s linear infinite;
					stroke: #118761;
				}

				@keyframes sonar {
					from {
						stroke-width: 10;
						opacity: 0.6;
					}
					to {
						stroke-width: 120;
						opacity: 0;
					}
				}
			}
		}
	}

	&--showing-variant {
		.BackgroundImage {
			@include viewport(medium) {
				min-height: 910px;
			}

			@include viewport(large) {
				min-height: 910px;
			}

			.Heading-title::after {
				display: none;
			}
		}

		.InteractiveHero {
			&-backButton {
				display: block;
				width: 990px;
				margin: 0 auto;
				position: relative;

				a {
					z-index: 2;
					left: 0;
					top: 53px;
					padding: 0 5px 5px 0;
					display: inline-block;
				}

				@media only screen and (max-width: $navigationBreakpointMax) {
					display: none;
				}
			}

			&-heading {
				//display: none;

				@media only screen and (max-width: 1160px) {
					display: block;
				}
			}
		}

		@media only screen and (max-width: $navigationBreakpointMax) {
			.BackgroundImage {
				height: auto !important;
				min-height: none !important;
				padding-bottom: 100px;
			}
		}
	}

	.Hero + .InlineCaw {
		margin: -60px 80px 0; // prevent overlap with video pause button
		position: relative;
		z-index: 1;

		@media only screen and (max-width: 1160px) {
			display: none;
		}

		@media only screen and (min-width: 1161px) and (max-width: 1265px) {
			display: block;
		}
	}

	@media screen and (min-width: 1701px) {
		.caw-comp__wrapper .App-wrapper__row .container {
			max-width: 930px;
		}
	}

	@media only screen and (min-width: $navigationBreakpointMin) {
		.caw-comp__wrapper {
			.ToggleCount {
				max-width: 187px;
			}
		}
	}

	@media only screen and (max-width: $navigationBreakpointMax) {
		.Hero .BackgroundImage {
			height: auto !important;
			max-height: none;
			padding-bottom: 100px;
		}

		.InteractiveHero {
			&-map {
				display: none;
			}

			&-list {
				display: block;
				width: 85%;
				margin: 0 auto;
				position: relative;
				z-index: 2;
				.Variant-container {
					border-top: 1px solid #fff;

					.InteractiveHero--mobile-trigger {
						display: block;
						padding: 20px;
						text-align: center;
						font-weight: normal;
					}

					&:last-child {
						border-bottom: 1px solid #fff;
					}

					.Variant-content-container {
						display: none;

						.CtaBlock {
							&-title {
								@include typography(h3);
								font-size: 12px;
							}
						}

						.CTA {
							transform: translateX(-50%);
							left: 50%;
							margin: 20px auto;
							position: relative;
						}
					}

					&-active {
						background-color: white;
						.InteractiveHero--mobile-trigger {
							color: #000;
							font-size: 0.8em;
							font-weight: 600;
						}
						.Variant-content-container {
							padding: 30px;
							display: block;

							.Image-ratioContainer {
								display: none;
							}

							.Image-img {
								height: 100%;
							}
						}
					}
				}
			}
		}

		.Hero .Hero-headingContainer {
			position: relative;
			bottom: auto;
			width: 100%;

			.Heading {
				color: #fff;
				text-align: center;
				padding-top: 30px;
				margin-bottom: 30px;

				&-eyebrow,
				&-title::after {
					display: none;
				}
			}
		}
	}
}
