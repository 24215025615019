.Feature {
	&-heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 15px;
	}

	&-icon {
		display: inline-flex;
		align-self: center;
		width: 25px;
		margin-right: 15px;
		line-height: 1em;

		.Feature--light & {
			color: $black;
		}

		.Feature--dark & {
			color: $white;
		}
	}

	&-cta {
		display: inline-flex;
		align-self: center;
		align-items: center;
		margin-left: 40px;

		.Feature-icon + & {
			margin-left: 0;
		}

		&--noLink {
			margin-top: 0;
			margin-bottom: 0;

			.Feature--light & {
				@include link('text', 'light');
				cursor: initial;
			}

			.Feature--dark & {
				@include link('text', 'dark');
				cursor: initial;
			}

			&:hover {
				color: $black;
			}
		}
	}

	&-content {
		padding-left: 40px;
	}

	&-text,
	&-subtext {
		margin: 0 0 10px;
	}

	&-text {
		@include typography(c2);
		text-transform: none;
		letter-spacing: 2.5px;
	}

	&-subtext {
		color: #595959;
		font: italic 400 rem(12, 16) / 16px $sansFont;
		letter-spacing: 2.5px;
		text-transform: none;

		.Feature--dark & {
			color: #999;
		}
	}
}
