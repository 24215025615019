.StandardHero {
	.hotelSearchBar-input {
		margin-top: 30px;
		.textInput__field:valid {
			border: 1px solid #d8d8d8;
		}
	}
	.hotelSearchBar-results {
		z-index: 1;
		position: absolute;
		border: 1px solid #d8d8d8;
		border-top: none;
	}
	&--yellowOrange,
	&--lightBlueGreen,
	&--pinkRed,
	&--light,
	&--black,
	&--greenGreen {
		.hotelSearchBar-input .textInput__label {
			color: black;
		}
	}

	&--hasOverlayTextBox {
		.StandardHero-hero {
			z-index: 1;
		}
	}
	&-OverlayTextBox {
		position: relative;
		width: 100%;
		background-color: $white;
		&--dark {
			background-color: $black;
		}

		&-heading {
			width: calc(100% - 30px);
			margin: auto;
			position: relative;
			margin-top: -140px;
			z-index: 1;
			flex-direction: column;
			display: flex;
			align-items: center;
			gap: 20px;
			padding: 30px 15px;
			background-color: $white;
			color: $black;
			box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.15);

			@include viewport(medium) {
				width: 100%;
				padding: 30px;
				margin-top: -100px;
			}
			@include viewport(large) {
				width: calc(100% - 140px);
				padding: 30px 50px;
				margin-top: -130px;
			}
		}

		.HeadingTextCta {
			&--dark {
				background-color: $gray1;
				color: $white;
				.HeadingTextCta-title.Heading-title {
					color: $white;
				}
			}
			&-heading {
				margin: 0;
				.Heading-title {
					margin: 0 0 15px;
					color: $black;
					text-align: center;
					font-feature-settings: 'liga' off, 'clig' off;

					/* Heading/H2 */
					font-family: 'Helvetica Neue', sans-serif;
					font-size: 24px;
					font-style: normal;
					font-weight: 200;
					line-height: 31px; /* 129.167% */
					letter-spacing: 4px;
					text-transform: uppercase;
				}
				.Heading-text {
					@include viewport(large) {
						max-width: 640px;
					}
					@include viewport(xlarge) {
						max-width: 1055px;
					}
				}
			}
			&-cta {
				margin: 0;
			}
		}
	}
}
