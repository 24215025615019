.BackgroundImage {
	position: relative;
	z-index: 0;

	&-picture {
		position: absolute;
		z-index: -1;
		width: 100%;
		top: 0;
		left: 0;
		height: 100%;
		padding: 0;
		margin: 0;
	}

	&-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
