.GeneralInterestForm {
	a {
		color: black;
		.BodySection--dark & {
			color: $white;
		}
	}
	&-Section {
		margin-bottom: 50px;
	}

	&-Header {
		margin-top: 0;
		margin-bottom: 30px;
		text-align: center;
	}
	&-Header-intro {
		margin-top: 0;
		margin-bottom: 40px;
		text-align: center;
		p {
			margin: 0;
		}
	}
	&-RadioButtons {
		margin-top: 15px;
		.RadioButtons-label {
			font-size: rem(20, 16);
		}
		.RadioButtons-button {
			margin: 0;
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
	&-RadioButtonsLabel {
		line-height: 1.5em;
		display: block;
	}

	.ContactForm {
		padding-top: 75px;

		.CTA-light-on-light {
			border-color: $black;
		}

		.ContactForm-SubmitCTA {
			width: 175px;
			min-width: 175px;
			max-width: 175px;
			&:disabled {
				background: $gray2;
				cursor: default;
				border: 1px solid $gray2;
			}
			&:disabled:hover {
				color: $white;
			}
		}
	}

	.hidden {
		display: none;
	}
	.LegalAgreement {
		margin-left: $gutter_small / 2;
		margin-right: $gutter_small / 2;

		@include viewport(medium) {
			margin-left: $gutter_medium / 2;
			margin-right: $gutter_medium / 2;
		}
	}
	.LegalDisclaimer {
		margin-bottom: 20px;
	}
}
