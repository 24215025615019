.TruncatedList {
	position: relative;
	overflow: hidden;

	&-hint {
		height: 120px + 40px;
		position: absolute;
		bottom: 0;
		width: 100%;
		display: block;

		&::before {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				to bottom,
				transparentize($white, 1) 0,
				transparentize($white, 0) 85%,
				$white 100%
			);
		}

		&--dark {
			&.TruncatedList-hint::before {
				background: linear-gradient(
					to bottom,
					transparentize($black, 1) 0,
					transparentize($black, 0) 85%,
					$black 100%
				);
			}
		}

		&--hidden {
			display: none;
		}

		&-toggle {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);

			&::after {
				@include icon('icon-plus_sign');

				padding-left: 10px;
				vertical-align: middle;
			}

			@include rtl {
				&::after {
					padding-right: 10px;
				}
			}
		}
	}
}
