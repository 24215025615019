.CheckoutHeader {
	&-lg {
		display: none;

		@include viewport(large) {
			display: block;
		}
	}

	&-sm {
		@include viewport(large) {
			display: none;
		}
	}

	height: 60px;
	width: 100%;
	max-width: 100vw;

	@include viewport(large) {
		height: auto;
	}

	&-bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 30px;
		min-height: 52px;

		@include viewport(large) {
			min-height: 80px;
		}
	}

	&-logo {
		margin: 0 15px;
		color: $white;
		font-size: 27px;
		line-height: 0;
		font-weight: bold;

		&::before {
			@include icon('icon-tree');
			display: inline-block;
			line-height: 1;
		}

		@include viewport(large) {
			font-size: 38px;
		}
	}

	&-brgLink {
		color: #fff;
		width: 85%;
		text-decoration: none;
		padding-top: 5px;

		& .CheckoutHeader-icon-icon {
			margin: 0 8px 0 0;
		}

		& .CheckoutHeader-icon-text {
			font-size: 12px;
			font-weight: 700;
			letter-spacing: 2.1px;
			vertical-align: text-top;
		}

		& .CheckoutHeader-BrgAdditionalText {
			font-size: 10px;
			font-weight: 400;
			letter-spacing: 1.5px;
			vertical-align: middle;
			text-transform: none;
		}
	}

	&-linksContainer {
		min-width: 100px;
		display: flex;
		white-space: nowrap;
		align-items: baseline;
		color: #fff;

		& .CTA,
		.currency-dropdown select {
			color: inherit;
			display: inline-block;
			font-size: 12px;
			letter-spacing: 2.1px;
			vertical-align: middle;
			font-weight: 700;
		}
		& .currency-dropdown select {
			top: -1px;
			position: relative;
		}
	}

	&-background {
		background-color: $black;
	}
}
