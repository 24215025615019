.Accordion-header {
	button.Accordion {
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		align-items: flex-start;
		width: 100%;
		list-style: none;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		text-align: left;
		border-bottom: 1px solid #d8d8d8;
		cursor: pointer;
		transition: 0.3s;
		white-space: nowrap;
		&.active {
			border-bottom: none;
		}
	}

	.Accordion-item {
		display: flex;
		flex-direction: row;
	}

	span.label {
		font-size: 12px;
		text-align: left;
		line-height: 18px;
		font-family: Helvetica;
		color: black;
		width: 192px;
		margin: 36px auto 36px 21.43px;
		letter-spacing: 3px;
		text-transform: uppercase;
		@include viewport(medium) {
			display: flex;
			flex-direction: row;
		}
	}
	.link-expand {
		border-bottom: 1px solid black;
		margin-right: 10px;
		&:focus,
		&:hover {
			border-color: $whover;
			color: $whover;
		}
	}

	.statusContainer {
		@include viewport(medium) {
			margin-left: 20px;
		}
	}
	button .leftIcon.icon {
		font-size: 30px;
		margin-top: 34.34px;
		margin-bottom: 30.34px;
	}

	button.text {
		font-size: 10px;
		letter-spacing: 3px;
		color: black;
		text-transform: uppercase;
		@include viewport(medium) {
			display: none;
		}
		@include viewport(small) {
			display: none;
		}
		@include viewport(large) {
			display: contents;
		}
	}

	span.icon-collapse {
		&::before {
			font-size: 6px;
		}
	}

	span.icon-caret {
		&::before {
			font-size: 6px;
		}
	}

	section.panel {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		max-height: 0;
		overflow: hidden;
		transition: all 0.5s;
		font-size: 12px;
		margin-left: 30px;
		margin-right: 30px;
		h1,
		h2,
		h3 {
			margin-top: 0;
			padding: 0;
			text-decoration: underline;
			@include typography(b1);
			u {
				@include typography(b1);
			}
		}
		u {
			text-decoration: underline;
			font-weight: normal;
		}
		ul {
			list-style: disc;
			margin-block-start: 1em;
			margin-block-end: 1em;
			margin-inline-start: 0px;
			margin-inline-end: 0px;
			padding-inline-start: 40px;
		}
		h4 {
			@include typography(h4);
			text-decoration: none;
		}
		a {
			color: black;
			text-decoration: none;
			border-bottom: 1px solid black;
			&:focus,
			&:hover {
				border-color: $whover;
				color: $whover;
			}
		}
		p {
			margin-top: 0;
		}
	}

	section.open-panel {
		border-bottom: 1px solid #d8d8d8;
	}
}
