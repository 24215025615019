.FeaturedRentalDestinations {
	display: none;

	&-container {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin-bottom: 0;
		overflow: hidden;

		@include viewport(medium) {
			margin-bottom: -60px;
		}

		&::before {
			content: none;
		}

		&--noTruncated {
			margin-bottom: -40px;

			@include viewport(medium) {
				margin-bottom: -60px;
			}
		}
	}

	&-ctaMoreWrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 160px;

		&::before {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				to bottom,
				transparentize($white, 1) 0,
				transparentize($white, 0) 85%,
				$white 100%
			);
		}

		@include viewport(medium) {
			display: none;
		}

		&--hidden {
			display: none;
		}
	}

	&-ctaBtn {
		position: absolute;
		height: 39px;
		bottom: 0;
		right: 50%;
		transform: translateX(50%);

		&--more {
			position: relative;
			width: 100%;
			margin-right: 15px;

			&::after {
				@include icon('icon-plus_sign');
				font-size: 8px;
				position: absolute;
				padding-left: 7px;
			}

			@include rtl {
				margin-right: 0;
				margin-left: 15px;

				&::after {
					padding-left: 0;
					padding-right: 7px;
				}
			}
		}
	}

	&-buttonsTab {
		display: none;

		@include viewport(large) {
			display: block;
			margin-bottom: 100px;
		}
	}

	&-buttons {
		border-top: 1px solid $separatorLight;
		border-bottom: 1px solid $separatorLight;
		height: 80px;
	}

	&-buttonsWrap {
		display: flex;
		max-width: 640px;
		margin: 0 auto;
		height: 100%;
		justify-content: space-around;
	}

	&-dropdownContainer {
		margin-bottom: 60px;

		@include viewport(medium) {
			margin-bottom: 80px;
		}

		@include viewport(large) {
			display: none;
			margin-bottom: 0;
		}
	}

	&-dropdown {
		position: relative;
		height: 80px;
		padding: 22px 0;
		border-top: 1px solid $separatorLight;
		border-bottom: 1px solid $separatorLight;
	}

	&-selectContainer {
		position: relative;

		&::after {
			@include icon('icon-caret');
			pointer-events: none;
			position: absolute;
			top: 7px;
			right: 7px;
			font-size: 5px;
		}

		@include rtl {
			&::after {
				left: 7px;
				right: auto;
			}
		}

		@include viewport(medium) {
			&::after {
				right: 15px;
			}

			@include rtl {
				&::after {
					left: 15px;
					right: auto;
				}
			}
		}
	}

	&-select {
		width: 100%;
		background: transparent;
		border: none;
		display: block;
		appearance: none;
		padding-top: 5px;
	}

	&-buttonContainer {
		display: flex;
	}

	&-button {
		@include grayHover($theme: 'light');
		text-align: center;
		background: none;
		border: none;
		color: $black;
		padding: 0;
		position: relative;

		&--active {
			font-weight: bold;

			&::after {
				content: '';
				position: absolute;
				background-color: $black;
				height: 4px;
				width: 100%;
				bottom: 0;
				left: 0;
			}

			&:hover,
			&:active,
			&:focus {
				color: $black;
			}
		}
	}

	&-content {
		@include viewport(large) {
			border-top: 1px solid $separatorLight;
			border-bottom: 1px solid $separatorLight;
		}
	}

	.DestinationCard {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include viewport(medium) {
			margin-bottom: 4px;
		}

		&-container,
		&-ctaContainer {
			display: block;
		}

		&-textContainer {
			margin: 20px 0 25px;
		}

		&-cta {
			display: block;
			padding: 20px 0;
			border-top: 1px solid $separatorLight;

			.CTA {
				position: relative;
				display: block;

				&::after {
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}

				@include rtl {
					&::after {
						right: auto;
						left: 0;
					}
				}
			}

			&--secondaryText {
				display: inline-block;
				color: $gray2;
			}
		}

		&-allRentalsCta {
			margin-bottom: 60px;

			@include viewport(medium) {
				margin-bottom: 0;
			}
		}

		&-ctaMissing {
			display: block;
			height: 56px;
		}

		&-ctaMissingRentals {
			margin-bottom: 4px;

			@include viewport(medium) {
				margin-bottom: 0;
			}
		}

		&--spacing {
			margin-bottom: 60px;
		}
	}
}
