.SubscribeBody {
	.SubscriptionContainer {
		@include viewport(medium) {
			margin-top: 25px;
		}
	}

	.SubscriptionForm {
		.Intro {
			margin-bottom: 50px;

			p {
				margin: 0;
			}
		}

		.formElement-label {
			@include typography('c4');
		}
	}
}
