.text {
	&-error {
		color: var(--color-error-text-default, #fef0f0);
		padding: 10px;
		border-radius: 5px;
		&-outline {
			border: 1px solid var(--color-error-border-default, #f63232);
		}
		&-filled-inverse {
			background: var(--color-error-container-default, #fcb1b1);
			color: var(--color-error-text-inverse, #310202);
		}
		&-filled {
			background: var(--color-error-container-subtle-default, #530404);
		}
		&-outline,
		&-filled-inverse,
		&-filled {
			@extend .text-error;
		}
	}
	&-warning {
		color: var(--color-warning-text-default, #fef3e1);
		padding: 10px;
		border-radius: 5px;
		&-outline {
			border: 1px solid var(--color-warning-border-default, #e28b08);
		}
		&-filled-inverse {
			color: var(--color-warning-text-inverse, #2c1b02);
			background: var(--color-warning-container-default, #f9bc61);
		}
		&-filled {
			background: var(--color-warning-container-subtle-default, #452a02);
		}
		&-outline,
		&-filled-inverse,
		&-filled {
			@extend .text-warning;
		}
	}
	&-success {
		color: var(--color-success-text-default, #def7e7);
		padding: 10px;
		border-radius: 5px;
		&-outline {
			border: 1px solid var(--color-success-border-default, #26974c);
		}
		&-filled-inverse {
			color: var(--color-success-text-inverse, #071d0e);
			background: var(--color-success-container-default, #70db94);
		}
		&-filled {
			background: var(--color-success-container-subtle-default, #0c3119);
		}
		&-outline,
		&-filled-inverse,
		&-filled {
			@extend .text-success;
		}
	}
	&-information {
		color: var(--color-security-text-default, #e9f2fb);
		padding: 10px;
		border-radius: 5px;
		&-outline {
			border: 1px solid var(--color-security-border-default, #3a87df);
		}
		&-filled-inverse {
			color: var(--color-security-text-inverse, #05111f);
			background: var(--color-security-container-default, #a7caf1);
		}
		&-filled {
			background: var(--color-security-container-subtle-default, #091d35);
		}
		&-outline,
		&-filled-inverse,
		&-filled {
			@extend .text-information;
		}
	}
}
