.VerticalCard {
	@include component-spacing($small: 60px, $medium: 80px, $large: 50px, $xlarge: 60px);

	&:last-child {
		margin-bottom: 0;
	}

	&-picture {
		display: block;
		margin-bottom: 30px;

		@include viewport(medium) {
			margin-bottom: 0;
		}
	}

	&-title {
		margin: 0 0 10px;
	}

	&-textArea {
		margin: 0;
	}
}
