.OverlayPage {
	&-spacer {
		background-color: transparent;
		display: block;
		height: 65px;
		position: relative;
		width: 100%;

		@include viewport(large) {
			height: 90px;
		}
	}
	&-nav {
		top: 0;
		position: absolute;
		background-color: transparent;
		height: 65px;
		max-width: 100vw;
		width: 100%;
		z-index: 9;

		@include viewport(large) {
			height: 90px;
		}
	}

	&-backButton {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 0 15px;
		margin-top: 5px;
		height: 60px;

		@include viewport(medium) {
			padding: 0 30px;
		}

		@include viewport(large) {
			height: 70px;
			padding: 0 40px;
			margin-top: 20px;
		}

		&.is-stick {
			margin-top: 0;
			position: fixed;
			left: 0px;
			top: 0px;
			background: #ffffff;
			box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
			z-index: 1;
		}

		.CTA {
			position: relative;
			top: 0;
			left: 0;
			padding-left: 24px;

			&::before {
				position: absolute;
				left: 0;
				top: 1px;
				padding-right: 0;
			}

			@include rtl {
				right: 0;
				padding-left: 0;
				padding-right: 24px;

				&::before {
					right: 0;
					padding-left: 0;
				}
			}
		}

		.CartStatus-placeholder {
			position: relative;
			right: 0px;
			top: 0;
		}
	}

	&-Header {
		padding-top: 25px;

		@include viewport(large) {
			padding-top: 40px;
		}

		@include viewport(xlarge) {
			padding-top: 60px;
		}

		.StandardHero {
			padding-top: 0;

			&-title {
				padding-bottom: 0;
			}
		}

		.StandardHero-heading .Heading-title {
			padding-bottom: 0;
		}
	}

	&-Body {
		.BodySection {
			padding-top: 50px;

			@include viewport(medium) {
				padding-top: 80px;
			}

			@include viewport(xlarge) {
				padding-top: 100px;
			}
		}
	}
}
