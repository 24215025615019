.HeadingAndLinks {
	&-heading {
		text-align: center;
		margin-bottom: 60px;

		@include viewport(large) {
			margin-bottom: 80px;
		}

		.Heading-title {
			padding-bottom: 30px;
			margin-bottom: 0;
			position: relative;

			@include viewport(medium) {
				@include typography(h2);
			}

			&::after {
				content: '';
				position: absolute;
				display: block;
				bottom: 15px;
				width: 40px;
				left: calc(50% - 20px); //Half the width of the underline
				border-top: 2px solid;
			}

			+ .Heading-text {
				margin-top: 0;
			}
		}
	}

	&-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&-col {
		@include viewport(medium) {
			padding: 0;
		}
	}

	&-table {
		border-collapse: separate;
		width: 100%;
	}

	&-item {
		position: relative;
		width: 100%;
		display: block;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			border-top: 1px solid;
		}

		@include viewport(medium) {
			display: table-cell;
			padding: 0 $gutter_medium/2;
			width: 50%;

			&::before {
				left: $gutter_medium/2;
				right: $gutter_medium/2;
			}
		}
	}

	.col-md-6:nth-child(odd) {
		@include viewport(medium) {
			clear: both;
		}
	}

	.CTA {
		display: block;
		position: relative;
		padding-right: 37px;
		padding-top: 31px;
		padding-bottom: 31px;

		&::after {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}

		@include rtl {
			padding-right: 0;
			padding-left: 37px;

			&::after {
				right: auto;
				left: 0;
			}
		}
	}
}
