.FullWidthContactBar {
	.ContactBar-container {
		justify-content: center;
		padding: 40px;

		@include viewport(medium) {
			margin: 0;
		}
	}

	.ContactBar-title {
		display: block;
		margin: 15px 0 0 0;

		@include viewport(medium) {
			margin: 0 auto;
		}
	}

	.ContactBar-phone {
		margin-top: 20px;

		@include viewport(medium) {
			margin-top: 0px;
		}
	}

	&-header {
		&:not(:last-child) {
			display: block;
			margin-bottom: 10px;
		}
	}

	&-header-wrapper {
		flex-direction: column;

		@include viewport(medium) {
			text-align: left;
			margin-right: auto;
		}
	}

	.CTA {
		&:not(:last-child) {
			margin: 20px 0 30px 0;
		}

		@include viewport(medium) {
			&:not(:last-child) {
				margin: 0px;
			}
		}
	}
}

@include ie() {
	.FullWidthContactBar .ContactBar-container {
		justify-content: flex-start;
	}
}
