$bg-z-index: 2;
$grad-z-index: 3;
$content-z-index: 4;
.TabbedHero {
	position: relative;
	z-index: 1;
	background-color: black;
	transform: translate3d(0, 0, 0);
	min-height: 100vh;
	@media only screen and (min-width: 1100px) {
		min-height: 85vh;
	}

	.TabbedHero-content {
		z-index: $content-z-index;
		height: 100vh; /* Fallback for browsers that do not support Custom Properties */
		height: calc(
			(var(--vh, 1vh) * 100 - var(--alertheight, 0vh))
		); //mobile browsers use odd values for vh so we need to use innerHeight which we will get in js and set the vh css property
		min-height: 100vh;
		min-height: calc((var(--vh, 1vh) * 100 - var(--alertheight, 0vh)));
		max-height: 100vh;
		max-height: calc((var(--vh, 1vh) * 100 - var(--alertheight, 0vh)));

		@media only screen and (min-width: 1100px) {
			transition: height 1s linear, max-height 1s linear, min-height 1s linear;
			height: 88vh;
			height: calc((var(--vh, 1vh) * 100 - var(--alertheight, 0vh)) * 0.88);
			min-height: 88vh;
			min-height: calc((var(--vh, 1vh) * 100 - var(--alertheight, 0vh)) * 0.88);
			max-height: 88vh;
			max-height: calc((var(--vh, 1vh) * 100 - var(--alertheight, 0vh)) * 0.88);
			transition: none;
		}
		overflow: visible;
		position: relative;
		.Hero {
			height: calc(96% - 285px);

			@include viewport(medium) {
				height: 83%;
			}

			@media only screen and (min-width: 1100px) {
				height: 100%;
			}
			width: 100%;
			z-index: $bg-z-index;
			&::after {
				display: none;
			}
			&::before {
				display: none;
			}
		}
		.Hero .BackgroundImage,
		.Hero-video {
			height: 100%;
			width: 100%;
			z-index: $bg-z-index;
			&::after {
				display: none;
			}
			&::before {
				display: none;
			}
		}
		.Hero-video {
			display: none;

			@media only screen and (min-width: 1100px) {
				display: block;
			}
		}
		.TabbedHero-tab {
			height: 100%;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			transition: opacity 1s ease-out;
			opacity: 0;
			z-index: -1;
			&:first-of-type {
				opacity: 1;
				z-index: 1;
			}
			.TabbedHero-container {
				width: 500px;
				max-width: 100%;
				position: absolute;
				text-align: center;
				bottom: 290px;
				z-index: $content-z-index;
				transition: transform 0.3s linear, bottom 0.3s linear;

				@media screen and (min-width: 500px) {
					left: calc(50% - 250px);
				}

				@include viewport(medium) {
					bottom: 450px;
				}

				@media only screen and (min-width: 1100px) {
					top: 0;
					left: 0;
					bottom: auto;
					max-width: 600px;
					width: 100%;
					margin: 0;
					text-align: left;
					margin-left: 70px;
					padding-top: 160px;

					@include rtl {
						text-align: right;
						margin-left: 0;
						margin-right: 70px;
						left: auto;
						right: 0;
					}
				}
				&::before {
					content: '';
					width: 100vw;
					position: fixed;
					left: 0;
					top: 0;
					display: block;
					height: 0;
					z-index: -1;
					transition: height 0.3s linear;
					background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);

					@include viewport(medium) {
						background: linear-gradient(
							180deg,
							rgba(0, 0, 0, 0.5) 16.15%,
							rgba(0, 0, 0, 0) 100%
						);
					}

					@media only screen and (min-width: 1100px) {
						display: none;
					}
				}
				&.active-search {
					opacity: 1 !important;
					&::before {
						height: 60%;
					}
					.Heading-title {
						font-size: 24px;
						letter-spacing: 4px;
						line-height: 31px;

						@media only screen and (min-width: 1100px) {
							font-size: 40px;
							line-height: 55px;
							letter-spacing: 5px;
						}
					}
				}
				&.scrolled {
					bottom: 269px;

					@include viewport(medium) {
						bottom: 344px;
					}

					@media only screen and (min-width: 1100px) {
						bottom: auto;
					}
				}
				.CTA {
					&:hover {
						border: 1px solid white;
					}
				}
				.TabbedHero-headingContainer {
					position: relative;
					.Heading {
						.Heading-title {
							transition: all 0.3s linear; // for mobile search font changes
							margin: 0 10px 32px;
							text-align: center;
							color: white;

							@media only screen and (min-width: 1100px) {
								margin: 0 0 37px;
								text-align: left;
								font-size: 40px;
								line-height: 55px;
								letter-spacing: 5px;

								@include rtl {
									text-align: right;
								}
							}
							&::after {
								display: none;
							}
						}
					}
				}
			}
			.Video-playButton {
				display: none;
				bottom: 160px;
				right: 2.5%;
				position: absolute;
				z-index: $content-z-index;

				@media only screen and (min-width: 1100px) {
					display: block;
				}
			}
			&::after {
				background: linear-gradient(
					0deg,
					rgba(0, 0, 0, 1) 310px,
					rgba(0, 0, 0, 0.4) 95%,
					rgba(0, 0, 0, 0) 100%
				);
				height: 66%;
				bottom: 0;
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				z-index: $grad-z-index;

				@include viewport(medium) {
					background: linear-gradient(
						180deg,
						rgba(0, 0, 0, 0) 0%,
						rgba(0, 0, 0, 0.5) 20%,
						#000 60%
					);
				}

				@media only screen and (min-width: 1100px) {
					height: 40%;
					width: 100%;
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 95%);
				}
			}
			&::before {
				@media only screen and (min-width: 1100px) {
					background: linear-gradient(
						90deg,
						rgba(0, 0, 0, 0.78) 25%,
						rgba(0, 0, 0, 0.65) 44.27%,
						rgba(0, 0, 0, 0) 95.31%
					);
					bottom: 0;
					content: '';
					display: block;
					position: absolute;
					height: 100%;
					width: 55%;
					z-index: $grad-z-index;
				}
			}

			@include rtl {
				&::before {
					background: linear-gradient(
						270deg,
						rgba(0, 0, 0, 0.78) 25%,
						rgba(0, 0, 0, 0.65) 44.27%,
						rgba(0, 0, 0, 0) 95.31%
					);
				}
			}
		}
	}

	&-icons {
		position: absolute;
		bottom: 85px;
		left: 0;
		display: grid;
		justify-content: center;
		width: calc(100% - 30px);
		margin-left: 15px;
		grid-template-columns: repeat(4, 25%);
		grid-template-rows: repeat(2, 95px);
		z-index: $content-z-index;
		transition: bottom 1s linear;
		margin-bottom: 2px; // for outline on focus

		@media only screen and (min-width: 400px) {
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(2, 95px);
		}

		@include viewport(medium) {
			bottom: 150px;
			grid-template-columns: repeat(4, 165px);
			grid-template-rows: repeat(2, 130px);
			margin-left: 0;
			width: 100%;
		}

		@media only screen and (min-width: 1100px) {
			bottom: 0;
			border-top: 1px solid rgba(216, 216, 216, 0.8);
			grid-template-columns: repeat(7, 1fr);
			grid-template-rows: 130px;
			width: calc(100% - 100px);
			margin-left: 50px;
			transition: none;
		}
		&.scrolled {
			bottom: 45px;

			@media only screen and (min-width: 1100px) {
				bottom: 0;
			}
		}
		.TabbedHero-moreIcon {
			.icon {
				font-size: 40px;
				transform: translateY(-10px);
			}

			@media only screen and (min-width: 1100px) {
				display: none;
			}
		}
		.TabbedHero-icon {
			position: relative;

			@for $i from 1 through 4 {
				&:nth-child(#{$i}) {
					border-bottom: 1px solid #353535;

					@media only screen and (min-width: 1100px) {
						border-bottom: none;
					}
				}
			}
			&::before {
				position: absolute;
				right: 0;
				height: calc(100% - 10px);
				width: 1px;
				border-right: 1px solid #353535;
				bottom: 5px;
				top: 5px;

				@include viewport(medium) {
					bottom: 10px;
					top: 10px;
					height: calc(100% - 20px);
				}

				@media only screen and (min-width: 1100px) {
					display: none;
				}
			}

			@mixin right-border {
				&::before {
					content: '';
				}
			}

			@for $i from 1 through 3 {
				&:nth-child(#{$i}) {
					@include right-border;
				}
			}

			@for $i from 5 through 7 {
				&:nth-child(#{$i}) {
					@include right-border;
				}
			}
			@include rtl {
				&:nth-child(1) {
					&::before {
						content: none;
					}
				}
				&:nth-child(4) {
					@include right-border;
				}
				&:nth-child(5) {
					&::before {
						content: none;
					}
				}
				&:nth-child(8) {
					@include right-border;
				}
			}
			.TabbedHero-icon-icon {
				display: flex;
				justify-content: center;
				padding-top: 25px;
				font-size: 24px;
				line-height: 24px;
				padding-bottom: 8px;

				@include viewport(medium) {
					padding-top: 30px;
					font-size: 32px;
					line-height: 32px;
					padding-bottom: 10px;
				}

				@media only screen and (min-width: 1100px) {
					padding-top: 34px;
					font-size: 32px;
					line-height: 32px;
					padding-bottom: 10px;
				}
			}
			.TabbedHero-icon-text {
				text-align: center;
				max-width: 120px;
				overflow: hidden;
				white-space: normal;
				text-overflow: ellipsis;
				display: block;
				margin: auto;
				font-size: 8px;
				letter-spacing: 1.5px;
				line-height: 12px;

				@include viewport(medium) {
					@include typography(c4);
				}
			}
			&.active {
				@media only screen and (min-width: 1100px) {
					.TabbedHero-icon-text {
						font-weight: bold;
					}
				}
			}
			.CTA {
				width: 100%;
				height: 100%;
				color: white;
				padding: 0 3px;
				text-decoration: none;
				display: block;

				@media only screen and (min-width: 1100px) {
					&:hover,
					&:focus,
					&:focus-visible {
						.TabbedHero-icon-text {
							font-weight: bold;
						}
					}
				}
				&:focus-visible {
					outline: 1px solid white;
				}
			}
		}
		.TabbedHero-activeRect {
			display: none;

			@media only screen and (min-width: 1100px) {
				display: block;
				position: absolute;
				left: 0%;
				top: 0%;
				background: #fff;
				border: 1px solid #d8d8d8;
				box-sizing: border-box;
				width: calc(100% / 7);
				height: 7px;
				top: -8px;
				transition: left 0.5s linear;
			}
		}
	}
	&-logoContainer {
		display: none;
		position: absolute;
		top: 70px;
		padding-left: 70px;
		z-index: $content-z-index + 1;

		@include rtl {
			padding-left: 0;
			padding-right: 70px;
		}

		@media only screen and (min-width: 1100px) {
			height: 55px;
			width: 100%;
			display: block;
			&::after {
				content: '';
				width: 100%;
				position: absolute;
				left: 0;
				top: -70px;
				display: block;
				height: 100px;
				background: linear-gradient(180deg, #000 19.85%, rgba(0, 0, 0, 0) 100%);
				opacity: 0.7;
				z-index: -1;
			}
			.TabbedHero-logo {
				width: 100px;
				margin: 0;
			}
		}
	}
	.TabbedHero-scroll {
		display: block;
		height: 40px;
		width: 40px;
		background-color: #3f3f3f;
		border-radius: 50%;
		position: absolute;
		left: calc(50% - 15px);
		bottom: 30px;
		z-index: $content-z-index;

		button {
			font-size: 9px;
			width: 40px;
			height: 40px;
			color: white;
			&::before {
				display: block;
				position: relative;
				top: 1px;
				line-height: 40px;
			}
		}
		.Button-label {
			width: 0;
			height: 0;
			display: block;
			overflow: hidden;
		}

		@include viewport(medium) {
			height: 50px;
			width: 50px;
			left: calc(50% - 25px);
			bottom: 60px;
			button {
				font-size: 12px;
				width: 50px;
				height: 50px;
				&::before {
					line-height: 50px;
				}
			}
		}

		@media only screen and (min-width: 1100px) {
			display: none;
		}
	}
	.TabbedHero-scroll-large {
		display: none;
		z-index: $content-z-index;
		position: relative;
		bottom: 0;
		left: 0;
		padding-left: 71px;
		color: white;
		width: 100%;
		height: 12vh; /* Fallback for browsers that do not support Custom Properties */
		height: calc((var(--vh, 1vh) * 100 - var(--alertheight, 0vh)) * 0.12);
		min-height: 90px;
		background-color: black;
		overflow: hidden;
		z-index: $bg-z-index;

		@include rtl {
			padding-left: 0;
			padding-right: 71px;
		}

		@media only screen and (min-width: 1100px) {
			display: block;
		}
		.rectangle {
			position: absolute;
			width: 2px;
			height: 25px;
			left: 53px;
			top: 15px;
			background: white;
			z-index: $content-z-index;

			@include rtl {
				left: auto;
				right: 53px;
			}
		}
		span {
			display: inline-block;
			position: relative;
			top: 11px;
		}
	}

	//Themes

	@mixin lllTabbedHero($color) {
		.TabbedHero-headingContainer .Heading-title {
			color: $color !important;
			font-family: Saol Display;
			font-weight: 300;
			letter-spacing: 2px !important;
			font-size: 40px !important;
			line-height: 35px !important;

			@include viewport(medium) {
				font-size: 60px !important;
				line-height: 55px !important;
			}

			i {
				margin-right: 3px;
				@include viewport(medium) {
					margin-right: 5px;
				}
				@include rtl {
					margin-right: 0;
					margin-left: 3px;
					@include viewport(medium) {
						margin-left: 5px;
					}
				}
			}
		}
	}

	&-tab {
		&--greenPink {
			@include lllTabbedHero($lll-pink);
		}
		&--greenGreen {
			@include lllTabbedHero($lll-green);
		}
		&--blueGreen {
			@include lllTabbedHero($lll-lightgreen);
		}
		&--pinkRed {
			@include lllTabbedHero($lll-red);
		}
		&--lightBlueGreen {
			@include lllTabbedHero($lll-green);
		}
		&--yellowOrange {
			@include lllTabbedHero($lll-orange);
		}
		&--white {
			@include lllTabbedHero($white);
		}
		&--black {
			@include lllTabbedHero($black);
		}
		&--lightOrange {
			@include lllTabbedHero($lll-lightorange);
		}
		&--yellow {
			@include lllTabbedHero($lll-yellow);
		}
		&--lightYellow {
			@include lllTabbedHero($lll-lightyellow);
		}
		&--pastelGreen {
			@include lllTabbedHero($lll-pastelgreen);
		}
		&--blue {
			@include lllTabbedHero($lll-blue);
		}
		&--lightBlue {
			@include lllTabbedHero($lll-lightblue);
		}
	}
}
.Navigation-bodySpacer--hasTabbedHero {
	display: none;
}
.Navigation {
	&--hasHero.Navigation--hasTabbedHero {
		+ .Navigation-bodySpacer--hasHeroClass {
			display: none;
		}
		.Navigation-background {
			background-color: transparent;
			backdrop-filter: none;
			.LanguageToggle {
				z-index: auto;
			}
			.Navigation-background-blur {
				backdrop-filter: none;
			}
			.Navigation-bar {
				transform: translateY(-121px);
				background: rgba(0, 0, 0, 0.85);
				backdrop-filter: blur(13px);
				border-bottom: 1px solid white;
			}
			&:focus-within {
				.Navigation-topBar {
					background: rgba(0, 0, 0, 0.85);
					backdrop-filter: blur(13px);
					height: 100%;
				}
				.Navigation-bar {
					transform: translate(0, 0) !important;
				}
			}
			.Navigation-topBar {
				position: relative;
				z-index: 1;
			}
		}
		&.is-Stick {
			.Navigation-background {
				transform: translate(0, 0);
			}
			.Navigation-topBar {
				background: rgba(0, 0, 0, 0.85);
				backdrop-filter: blur(13px);
			}
		}
		&:not(.is-Stick) {
			height: 34px !important;
			.Navigation-background {
				height: 34px !important;
			}
		}
		.Navigation-backgroundGradient {
			display: none;
			&::before {
				display: none;
			}

			&::after {
				display: none;
			}
		}
		.NavigationCAW {
			transform: translateY(0px);
		}
		.Navigation-sm {
			display: none;
			&.is-Stick {
				display: flex;
			}
			&.Navigation--atTop {
				display: flex;
				background: transparent;
				border: 0;
				top: 0;
				position: absolute;
				&::after {
					content: '';
					z-index: 1;
					width: 100vw;
					position: absolute;
					left: 0;
					top: 0;
					transform: translateY(-2px);
					display: block;
					height: 110px;
					background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);

					@include viewport(medium) {
						height: 150px;
						background: linear-gradient(
							180deg,
							rgba(0, 0, 0, 0.5) 16.15%,
							rgba(0, 0, 0, 0) 100%
						);
					}
				}
				.Navigation-logo {
					color: white;
				}
				.Navigation-menuButton::before {
					color: white;
				}
				.Navigation-menuButton {
					color: $white;
					padding-top: 6px;
					align-self: flex-start;
					z-index: 2;
				}
				> a {
					padding-top: 18px;
					align-self: flex-start;
					z-index: 2;
				}
				.Navigation-sticky-footer,
				.Navigation-sticky-footer-gradient {
					transform: translateY(100%);
					&:focus-visible,
					&:focus-within {
						transform: translateY(0);
					}
				}
			}
			transition: border 0.7s ease-in-out, background-color 0.7s ease-in-out;
			.Navigation-sticky-footer,
			.Navigation-sticky-footer-gradient {
				transition: transform 0.7s ease-in-out;
			}
		}
	}
}
