.TextMobileCarousel {
	background-color: $gray4;
	padding-bottom: 100px;

	.Card-body {
		margin: 10px;
	}
	.Card-title {
		display: block;
		margin-top: 35px;
		text-transform: capitalize;
		font-weight: 100;
		font-size: 22px;
		letter-spacing: 1px;
	}

	td.side-text {
		text-transform: none;
		padding-left: 50px;

		p {
			position: relative;
			top: 100px;
			letter-spacing: 1px;
			font-size: 30px;
			font-weight: 100;
			text-transform: none;
			position: relative;
			top: 100px;
			color: $black;
			max-width: 400px;
			min-width: 300px;
			width: auto;

			@include viewport(medium) {
				max-width: 400px;
			}

			@include viewport(large) {
				max-width: 500px;
			}
		}
	}

	div.side-text {
		text-transform: none;
		font-weight: 100;
		font-size: 20px;
		letter-spacing: 1px;
		color: $black;
		max-width: 300px;
		width: 100%;
		text-align: center;
		margin: 0 auto;

		@include viewport(medium) {
			max-width: 400px;
		}

		@include viewport(large) {
			max-width: 500px;
		}
	}

	.Card-body .Card-description {
		font-weight: 100;
		font-size: 16px;
		letter-spacing: 1px;
	}

	.Card-description {
		letter-spacing: 0;
		min-height: 75px;
		margin-bottom: 10px;
	}

	.Card-directions {
		letter-spacing: 3px;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 12px;
	}
	.Card-time {
		letter-spacing: 3px;
		text-transform: uppercase;
		font-size: 12px;
	}

	.TextMobileCarousel-heading .Heading .Heading-title {
		font-weight: 100;
		color: $black;
		margin-left: 50%;
		margin-right: 50%;
		text-transform: capitalize;
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.Card-body .Heading .Heading-title {
		font-weight: 100;
		color: $black;
	}

	ul.TextMobileCarousel-Cardpanel-carousel {
		display: block;
		min-height: 440px;

		@include viewport(large) {
			display: none;
		}

		.flickity-page-dots {
			position: absolute;
			display: block;
			width: 100%;
			bottom: 0;
			padding: 0;
			margin: 0;
			list-style: none;
			text-align: center;
			pointer-events: none;
			line-height: 1;

			.dot.is-selected {
				background: $black;
				width: 8px;
				height: 8px;
				opacity: 1;
				margin: -1px 3px;
			}
		}
	}

	.carousel {
		display: block;

		@include viewport(large) {
			display: none;
		}
	}

	.Image-ratioContainer .Image-img {
		width: 100%;
		max-height: 200px;
		height: 200px;
	}

	&-parent {
		overflow: hidden;

		@include viewport(small) {
			overflow: visible;
		}
	}
	&-parent .cardpanel-carousel {
		display: block;
	}
	&-parent .Card-container {
		width: 310px;
	}
	&-heading {
		@include component-spacing($small: 0, $medium: 0, $large: 40px, $xlarge: 60px);
		text-align: center;

		.Heading-title {
			margin: 0;
		}
	}

	&-item {
		width: calc(#{get - columns-vw(10, string)});
		min-width: 310px;
		margin: 0 7.5px;
		display: flex; // Used to align all column heights
		background-color: $gray4;
		float: left;

		@include viewport(medium) {
			width: get-columns-fixed-unit(6, medium);
			margin: 0 15px;
		}

		@include viewport(large) {
			width: get-columns-fixed-unit(4, large);
		}

		@include viewport(xlarge) {
			width: get-columns-fixed-unit(4, xlarge);
		}
	}

	&-parent {
		overflow: hidden;
	}

	&-row {
		position: relative;

		.flickity-viewport {
			left: calc(#{get-columns-vw(1, string)} + 15px);

			@include viewport(medium) {
				left: 0;
			}
		}

		.flickity-prev-next-button::before {
			content: '';
			position: absolute;
			top: 50%;
			height: 100%;
			right: 50%;
			transform: translateX(50%);
		}

		.flickity-prev-next-button.previous {
			@include grayHover('light');
			display: none;

			&::before {
				@include icon('icon-chevron_left');
			}

			@include rtl {
				&::before {
					@include icon('icon-chevron_right');
				}
			}

			@include viewport(medium) {
				display: block;
				left: -30px;
				transform: translateX(50%);

				@include rtl {
					left: auto;
					right: 0;
				}
			}

			@include viewport(large) {
				left: -30px;

				@include rtl {
					left: auto;
					right: 0;
				}
			}

			.BodySection--dark & {
				@include grayHover('dark');
				color: $white;
			}
		}

		.flickity-prev-next-button.next {
			@include grayHover('light');
			display: none;

			&::before {
				@include icon('icon-chevron_right');
			}

			@include rtl {
				&::before {
					@include icon('icon-chevron_left');
				}
			}

			@include viewport(medium) {
				display: block;
				right: 0;
				transform: translateX(50%);

				@include rtl {
					right: auto;
					left: -30px;
				}
			}

			@include viewport(large) {
				left: auto;
				right: 0;

				@include rtl {
					left: -30px;
					right: auto;
				}
			}

			.BodySection--dark & {
				@include grayHover('dark');
				color: $white;
			}
		}
	}
}

.BodySection .TextMobileCarousel .Card-description {
	margin-bottom: 10px;
}

.TextMobileCarousel-card.placeholder {
	visibility: hidden;
}
.TextMobileCarousel-card {
	background-color: $gray4;
	margin-right: 15px;
	float: left;
	border-color: #979797;
	color: $black;
	border-width: 1px;
	min-height: 390px;
	border-radius: 3px;
	width: 310px;
	border-style: solid;
	overflow: hidden;
}

table.col-sm-12.TextMobileCarousel-row {
	position: relative;
	left: -1100px;
	display: none;

	@include viewport(large) {
		display: block;
	}
}

.cardpanel-container {
	padding-left: 0;
	padding-right: 0;
}
