.BestRateGuaranteeForm {
	.ContactForm {
		padding-top: 0;
	}

	&-Title {
		text-align: center;
		margin-bottom: 30px;
	}

	&-IntroCopy {
		text-align: center;
		margin-bottom: 65px;
		font-size: rem(20, 16);
		letter-spacing: em(0.56, 18);
	}

	&-Heading {
		margin-bottom: 42px;
		&--title {
			text-align: center;
			color: #000;
		}
	}

	&-Section {
		margin-bottom: 15px;
	}

	&-GrayLine {
		border-bottom: 1px solid $gray3;
		&--top {
			margin-bottom: 27px;
		}

		&--bottom {
			margin-top: 8px;
			margin-bottom: 35px;
		}
	}

	&-ThankYou {
		text-transform: none;
		font-size: rem(22, 16);
		line-height: em(30, 22);
		letter-spacing: em(0.85, 22);
		font-weight: 200;

		&--active {
			.BestRateGuaranteeForm {
				&-Title {
					margin-bottom: 80px;
				}

				&-Text--container {
					display: none;
				}

				&-form--container {
					padding-bottom: 0;
				}
			}
		}
	}

	&-ButtonCTA {
		width: 100%;
		max-width: 379px;
		float: none;
		margin-top: 37px;

		&--container {
			display: flex;
			justify-content: center;
		}
	}

	&-SuccessCTA {
		margin-top: 68px;
	}

	&-TermsAndConditions {
		text-transform: none;
		font-weight: bold;
		padding-bottom: 0;
	}
}
