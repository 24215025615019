$cellPadding: 20px;

.Chart {
	position: relative;

	@include viewport(large) {
		margin: 0 40px;
	}

	&.container {
		@include viewport(large) {
			max-width: $containerWidth-xl;
		}
	}

	&--overflowingLeft::before {
		content: '';
		width: 100px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
		pointer-events: none;
		z-index: 1;
	}

	&--dark.Chart--overflowingLeft::before {
		background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
	}

	&--overflowingRight::after {
		content: '';
		width: 100px;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
		pointer-events: none;
		z-index: 1;
	}

	&--dark.Chart--overflowingRight::after {
		background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
	}

	&-container {
		position: relative;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: none;
	}

	&-header {
		& > .Chart-row:last-child {
			border-bottom: 1px solid $black;
		}
	}

	&-table {
		border-collapse: collapse;
	}

	&-row {
		background-color: $white;

		&--superHeader {
			background-color: $ac-grey-3;
		}

		&--header {
			background-color: $ac-grey-2;
		}

		tbody > &:nth-child(even) {
			background-color: #f2f2f2;
		}

		.ChartCell {
			border-left: 1px solid $gray3;
			color: $black;

			&:nth-child(1) {
				border-left: none;
			}
		}
	}
}

.ChartCell {
	padding: $cellPadding;
	text-align: center;
	font-size: 15px;
	line-height: 17px;

	&--header {
		padding: $cellPadding;

		@include sansRegular;
		font-size: rem(10, 16);
		letter-spacing: 3px;
		line-height: em(18, 12);

		@include i18nFonts;
		text-transform: uppercase;
	}

	&--image {
		.ChartCell-image {
			margin-bottom: 30px;

			.Image-picture {
				width: auto;
				height: auto;
			}
		}
	}

	&--tooltip {
		padding: 0;

		.ChartCell-tooltipIcon {
			font-style: normal;
			cursor: pointer;
		}

		.ChartCell-text {
			display: block;
			position: relative;
			padding: $cellPadding;
		}

		.WarningModal {
			&-text {
				text-transform: none;
			}

			&-content {
				left: 0;
				right: 0;
			}
		}
	}
}
