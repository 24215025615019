.CardColumns {
	&-heading {
		.Heading-title {
			@include component-spacing($small: 40px, $medium: 60px, $large: 40px, $xlarge: 60px);

			text-align: center;
			margin-top: 0;
		}
	}

	&-list {
		@include viewport(large) {
			display: flex;
		}

		&-item {
			@include viewport(large) {
				display: flex;
			}

			@include viewport(xlarge) {
				// One card
				&.col-lg-10 {
					display: block;
				}
			}
		}
	}

	&-card--no-eyebrow {
		.Card-heading {
			$_eyebrowLineHeight: 0.75em;

			display: block;
			margin-top: calc(30px + #{$_eyebrowLineHeight});

			@include viewport(medium) {
				margin-top: calc(43px + #{$_eyebrowLineHeight});
			}

			@include viewport(large) {
				// One card
				.col-lg-10 & {
					margin-top: calc(87px + #{$_eyebrowLineHeight});
				}
			}

			@include viewport(xlarge) {
				margin-top: calc(49px + #{$_eyebrowLineHeight});

				// Two cards
				.col-lg-5 & {
					margin-top: calc(52px + #{$_eyebrowLineHeight});
				}

				// One card
				.col-lg-10 & {
					margin-top: calc(72px + #{$_eyebrowLineHeight});
				}
			}
		}
	}

	&-card {
		color: $white;
		margin-bottom: 40px;
		padding: 6px;
		background-color: $black;

		@include viewport(medium) {
			margin-bottom: 60px;
		}

		@include viewport(large) {
			display: flex;
			flex-basis: 100%;
			margin-bottom: 0;
		}

		@include viewport(xlarge) {
			// One card
			.col-lg-10 & {
				display: block;
			}
		}

		.BodySection--dark & {
			background-color: $gray4;
			color: $black;
		}

		.Card {
			&-container {
				border: 1px solid $separatorDark;
				padding: 34px 24px;
				text-align: center;
				min-height: 262px;

				@include viewport(medium) {
					padding: 34px 114px 29px;
				}

				@include viewport(large) {
					position: relative;
					padding: 41px 24px;
					width: 100%;

					// One card
					.col-lg-10 & {
						padding: 38px 24px;
					}

					// Two cards
					.col-lg-5 & {
						padding: 38px 30px;
					}
				}

				@include viewport(xlarge) {
					&,
					/* One card*/
					.col-lg-10 &,
					.col-lg-5 & {
						padding: 74px 24px;
					}
				}
			}

			&-heading {
				margin-left: auto;
				margin-right: auto;

				@include viewport(large) {
					// One card
					.col-lg-10 & {
						width: 59%;
					}
				}

				@include viewport(xlarge) {
					// One card
					.col-lg-10 & {
						width: 35.67%;
					}

					// Two cards
					.col-lg-5 & {
						width: 56.3%;
					}
				}

				.Heading {
					&-eyebrow {
						display: block;
						margin-bottom: 30px;

						@include viewport(medium) {
							margin-bottom: 40px;
						}

						@include viewport(large) {
							// One card
							.col-lg-10 & {
								margin-bottom: 87px;
							}
						}

						@include viewport(xlarge) {
							margin-bottom: 49px;

							// Two cards
							.col-lg-5 & {
								margin-bottom: 52px;
							}

							// One card
							.col-lg-10 & {
								margin-bottom: 72px;
							}
						}
					}

					&-title {
						position: relative;
						margin-top: 0;
						margin-bottom: 36px;

						&::after {
							content: '';
							position: absolute;
							border-bottom: 1px solid currentColor;
							width: 30px;
							bottom: -20px;
							left: 50%;
							transform: translateX(-50%);

							@include viewport(medium) {
								bottom: -21px;
							}

							@include viewport(large) {
								bottom: -24px;

								// Two cards
								.col-lg-5 & {
									bottom: -21px;
								}
							}

							@include viewport(xlarge) {
								bottom: -32px;
								border-width: 2px;
								width: 40px;
								left: 50%;
								transform: translateX(-50%);

								// One card
								.col-lg-10 & {
									bottom: -22px;
								}

								// Two cards
								.col-lg-5 & {
									bottom: -30px;
								}
							}
						}

						@include viewport(large) {
							margin-bottom: 42px;

							// Two cards
							.col-lg-5 & {
								margin-bottom: 40px;
							}
						}

						@include viewport(xlarge) {
							margin-bottom: 62px;

							// One card
							.col-lg-10 & {
								margin-bottom: 37px;
							}

							// Two cards
							.col-lg-5 & {
								margin-bottom: 65px;
							}
						}
					}

					&-text {
						margin-top: 0;

						&:lang(ja) {
							font-size: 1rem;
						}

						@include component-spacing(
							$small: 50px,
							$medium: 42px,
							$large: 66px,
							$xlarge: 76px
						);

						@include viewport(large) {
							// One card
							.col-lg-10 & {
								margin-bottom: 94px;
							}

							// Two cards
							.col-lg-5 & {
								margin-bottom: 113px;
							}
						}

						@include viewport(xlarge) {
							margin-bottom: 117px;

							// One card
							.col-lg-10 & {
								margin-bottom: 78px;
							}

							// Two cards
							.col-lg-5 & {
								margin-bottom: 45px;
							}
						}
					}
				}
			}

			&-cta {
				@include viewport(large) {
					position: absolute;
					transform: translateX(-50%);
					left: 50%;
					bottom: 34px;
				}

				@include viewport(xlarge) {
					bottom: 84px;

					// One card
					.col-lg-10 & {
						bottom: 40px;
					}

					// Two cards
					.col-lg-5 & {
						bottom: 43px;
					}
				}

				.BodySection--dark & {
					@include grayHover('light');
					color: $black;
					border-color: $black;
				}
			}
		}
	}
}
