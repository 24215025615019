.Banner {
	background-color: $black;
	border: 0 solid $gray1;
	color: $white;
	display: none;
	padding-bottom: 15px;
	padding-top: 15px;
	position: relative;
	text-align: center;

	&--shown {
		display: block;
	}

	&-closeButton {
		font-size: 13px;
		position: absolute;
		right: 0;
		top: -6px;

		@include rtl {
			left: 0;
			right: auto;
		}
	}

	.CtaBlock {
		@include typography(b3);

		/* Helps centering text */
		&::before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 25px;
		}

		&-cta {
			@include grayHover('dark');
			color: $white;
			vertical-align: bottom;

			@include rtl {
				vertical-align: baseline;
			}
		}

		&-text {
			display: inline;
			margin: 0;
			vertical-align: bottom;
			margin-right: 10px;

			@include rtl {
				margin-left: 10px;
				margin-right: 0;
				vertical-align: baseline;
			}
		}
	}
}
