.LandscapeCardsList {
	&-card-listing {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: var(--spacing-300, 30px);

		@include viewport(large) {
			gap: var(--spacing-500, 50px);
		}
	}

	.Heading-title {
		margin: 0;
		align-self: stretch;
	}

	&-cards {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: var(--container-padding-large, 20px);
		align-self: stretch;

		@include viewport(large) {
			gap: var(--spacing-400, 40px);
		}
	}

	&-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: var(--container-padding-large, 20px);
		width: 100%;

		@include viewport(large) {
			gap: var(--spacing-400, 40px);
		}
	}

	&-line {
		width: 100%;
		height: 1px;
		background: var(--color-border-subtle-default, rgba(77, 77, 77, 0.15));
	}
}
