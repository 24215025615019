$transition: 0.5s linear;

.HasBulletPointCard {
	.AnimatedCardCarouselBackground {
		@include viewport(medium) {
			&-BackgroundImage {
				display: block;
				position: absolute !important;
				width: 100%;
				height: 100%;
				top: 0;
				&::after {
					height: 100%;
					width: 100%;
					top: 0;
					content: '';
					display: block;
					position: absolute;
					pointer-events: none;
					background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.74) 0%,
						rgba(0, 0, 0, 0) 25%,
						rgba(0, 0, 0, 0) 75%,
						rgba(0, 0, 0, 0.74) 100%
					);
				}

				transition: opacity 0.5s linear;
				opacity: 0;
				&--active {
					opacity: 1;
				}
			}
		}

		&-Card {
			min-height: 0;

			@include viewport(medium) {
				&--active {
					min-height: 500px;
				}
			}
		}
	}

	.CenterFocusCarousel {
		padding-bottom: 36.5px;
		&-navigation--desktop {
			margin-top: 16.5px;
		}
	}

	&.AnimatedCardCarouselBackground .CenterFocusCarousel .flickity-viewport {
		margin-left: calc((100% - 310px) / 2);

		@include rtl {
			margin-left: 0;
			margin-right: calc((100% - 310px) / 2);
		}

		@include viewport(medium) {
			margin-left: 0;

			@include rtl {
				margin-right: 0;
			}
		}
	}
}

.BulletPointCard {
	@include viewport(medium) {
		min-height: 500px;
	}

	&.AnimatedCardCarousel--slide {
		width: 310px;
	}

	&.AnimatedCardCarouselBackground--slide {
		@include viewport(medium) {
			&.animated {
				transition: width $transition, margin $transition, height $transition;
			}

			&.CenterFocusCarousel--slide {
				width: 410px;
				&.is-selected {
					width: 450px;
					margin-left: -5px;

					@include rtl {
						margin-right: -5px;
					}

					.AnimatedCardCarouselBackground-Card {
						box-shadow: 0 0 20px 0 change-color($black, $alpha: 0.2);
					}
				}
			}
		}
	}
	.AnimatedCardCarouselBackground {
		&-Card {
			border: none;
			&-icon {
				font-size: 36px;
				line-height: 36px;
				height: 36px;

				@include viewport(medium) {
					font-size: 50px;
					line-height: 50px;
					height: 50px;
				}
			}
			&-bulletedList {
				padding: 20px 15px 30px;

				@include viewport(medium) {
					padding: 45px 45px 60px;
				}
				.BulletedList {
					&-heading {
						.Heading-title {
							margin: 0;
							margin-top: 20px;
						}
					}
					&-list {
						columns: 1 auto;
						margin-top: 30px;
					}
					&-item {
						text-align: left;
						padding-bottom: 0;

						@include rtl {
							text-align: right;
						}
					}
				}
			}

			&--active {
				background-color: change-color($white, $alpha: 0.95);
				backdrop-filter: blur(10px);
				width: 100%;
				transition: none;
			}

			&--inactive {
				display: none;
				transition: none;

				@include viewport(medium) {
					width: 100%;
					display: block;
					padding: 0;
					color: $black;
					background-color: $white;
					border: none;
					opacity: 0.8;

					.AnimatedCardCarouselBackground-Card {
						&-bulletedList {
							height: calc(100% - 36px);
							overflow: hidden;
						}
						&-icon {
							@include viewport(medium) {
								font-size: 42px;
								line-height: 42px;
								height: 42px;
							}
						}
					}
				}
			}

			&.AnimatedCardCarousel-Card {
				@include viewport(medium) {
					height: calc(100% - 46px);
					margin-top: 23px;
				}
			}
		}
	}
}
