.OfferCard {
	&--hidden {
		display: none;
	}

	&-container {
		padding: 0 40px 40px;
		overflow: hidden;
		transition: max-height 0.3s ease-out;

		@include viewport(medium) {
			padding: 0 60px 40px;
		}

		@include viewport(large) {
			padding: 0 60px 40px;
		}

		@include viewport(xlarge) {
			padding: 0 130px 40px;
		}

		&--light {
			box-shadow: 0 -1px 0 0 $black, 0 -5px 0 0 $white, 0 -7px 0 0 $black, 0 1px 0 0 $black,
				0 5px 0 0 $white, 0 7px 0 0 $black;
		}

		&--dark {
			box-shadow: 0 -1px 0 0 $white, 0 -5px 0 0 $black, 0 -7px 0 0 $white, 0 1px 0 0 $white,
				0 5px 0 0 $black, 0 7px 0 0 $white;
		}
	}

	&-wrap {
		display: block;
		padding: 40px 0;

		@include clearfix;

		@include viewport(large) {
			padding: 40px 0;
		}
	}

	&-details {
		transition: opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}

	&-eyebrow {
		display: block;
		margin-bottom: 10px;
	}

	&-heading {
		margin-bottom: 30px;

		@include viewport(medium) {
			margin-bottom: 0;
		}

		&-ctas {
			@include viewport(medium) {
				margin-bottom: 40px;
			}
		}

		&-title {
			display: block;
			padding-bottom: 0;
			margin-bottom: 20px;
			border: 0;
		}

		&-description {
			@include viewport(medium) {
				margin-bottom: 0;
			}
		}
	}

	&-picture {
		display: block;
		font-size: 0;
		line-height: 0;
		margin-bottom: 30px;

		// force container min-height while image gets loaded
		&::before {
			content: '';
			display: block;
			padding-top: calc(100% * (9 / 16));
			float: left;
		}

		@include viewport(medium) {
			margin-bottom: 0;
			margin-top: -23px;
		}

		img {
			width: 100%;
		}
	}

	&-ctas-col {
		@include viewport(medium) {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
	}

	&-ctas {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		@include viewport(medium) {
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}

		&--staked {
			flex-direction: row;
			justify-content: flex-start;

			.OfferCard-cta-details {
				margin-bottom: 0;
				margin-right: 25px;

				@include rtl {
					margin-right: 0;
					margin-left: 25px;
				}
			}
		}

		&--hidden-sm {
			display: none;

			@include viewport(medium) {
				display: flex;
			}
		}

		&--hidden-md {
			@include viewport(medium) {
				display: none;
			}
		}
	}

	&-cta {
		&-details {
			margin-right: 25px;

			@include rtl {
				margin-right: 0;
				margin-left: 25px;
			}

			@include viewport(medium) {
				margin-right: 0;
				margin-bottom: 20px;
			}

			&::after {
				@include icon('icon-plus_sign');

				padding-left: 10px;
			}

			@include rtl {
				&::after {
					padding-left: 0;
					padding-right: 10px;
				}
			}

			&--expanded {
				&::after {
					@include icon('icon-less_sign');
				}
			}
		}

		&-offers {
			align-self: center;
		}
	}

	&-checks {
		border-top: 1px solid $separatorLight;

		.OfferCard-container--dark & {
			border-color: $separatorDark;
		}

		&-item {
			padding: 20px 0;

			@include viewport(xlarge) {
				padding: 30px 0;
			}

			&:not(:last-child) {
				border-bottom: 1px solid $separatorLight;
			}

			.OfferCard-container--dark & {
				border-color: $separatorDark;
			}

			&-group {
				&:not(:last-child) {
					padding-bottom: 20px;

					@include viewport(medium) {
						padding-bottom: 0;
					}
				}

				&:nth-child(2n-1) {
					@include viewport(medium) {
						margin-right: 25px;

						@include rtl {
							margin-right: 0;
							margin-left: 25px;
						}
					}
				}
			}

			span {
				display: block;
				@include viewport(medium) {
					display: inline-block;
				}
			}
			.OfferCard-checks-item-date {
				display: block;
				padding: 10px 0;
			}
			.OfferCard-checks-item-date::before {
				content: '\2022';
				color: #000000;
				display: inline-block;
				width: 1em;
			}
			.OfferCard-checks-item-disclaimer {
				text-transform: none;
				text-align: left;
				@include rtl {
					text-align: right;
				}
			}
		}
	}

	&-inclusions {
		&:last-child .BulletedList-body {
			border-bottom: 1px solid;
			margin-bottom: 30px;
		}

		.BulletedList {
			padding: 0;

			&-separator,
			&-body {
				.OfferCard-container--light & {
					border-color: $separatorLight;
				}

				.OfferCard-container--dark & {
					border-color: $separatorDark;
				}
			}
		}

		&-list {
			padding: 0;

			@include viewport(large) {
				.row > [class*='col-'] {
					width: 100%;
					margin: 0;
				}
			}

			.BulletedList {
				&-list {
					@include viewport(medium) {
						columns: auto 2;
						column-gap: 30px;
					}
				}

				&-separator {
					width: 100%;
					margin: 0;
				}

				&-separator,
				&-body {
					.OfferCard-container--light & {
						border-color: $separatorLight;
					}

					.OfferCard-container--dark & {
						border-color: $separatorDark;
					}
				}

				&-body {
					// border-bottom: 1px solid;
					// margin-bottom: 30px;

					@include viewport(medium) {
						padding: 40px 0;
					}

					@include viewport(xlarge) {
						padding: 60px 0;
					}
				}
			}
		}
	}

	&-more-details-cta {
		margin-top: 30px;
	}
}
