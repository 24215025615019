.NavigationItem {
	// Mobile Navigation Overlay
	&--overlay {
		margin-bottom: 0;
		padding-bottom: 25px;

		.CTA {
			font-size: 14px;
			line-height: 1em;
			font-family: $sansFont;
			letter-spacing: 2.1px;
			font-weight: normal;
		}

		.duplicated-by-secondary-nav {
			display: none !important;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}

	&--mainLink {
		text-align: left;
		position: relative;
		white-space: nowrap;
		font-size: 0;

		@include rtl {
			text-align: right;
		}

		&:not(:last-child) {
			padding-right: 35px;

			@include rtl {
				padding-right: 0;
				padding-left: 35px;
			}
		}

		.Navigation-overflowLinks & {
			margin-bottom: 17px;
			padding-right: 0;
			padding-left: 0;
			white-space: normal;

			&:last-child {
				margin-bottom: 0;
			}

			&:not(:last-child) {
				@include rtl {
					padding-right: 0;
					padding-left: 0;
				}
			}

			.CTA {
				&:hover {
					color: $bhover;
					border-color: $bhover;
				}
			}
		}

		.CTA {
			display: inline-block;
			vertical-align: middle;
			color: $white;
			font-size: 12px;
			letter-spacing: 2.1px;

			&:hover {
				color: $whover;
				border-color: $whover;
			}

			@include wls {
				font-family: 'Work Sans';
			}
		}
	}

	&--secondaryNavigation {
		display: inline-block;
		padding: 0 15px;

		.CTA {
			display: inline-block;
			padding: 20px 0;
			color: $black;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 2.45px;
			vertical-align: middle;

			&:hover {
				color: $whover;
				border-color: $whover;
			}

			&.CTA--underlined {
				&--light {
					border-bottom: 4px solid $black;
				}

				&--dark {
					border-bottom: 4px solid $gray4;
				}

				&--light,
				&--dark {
					padding: 20px 0 16px;
				}
			}
		}
	}

	&-link {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.Navigation-overflowLinks & {
			.CTA {
				color: $black;
			}

			&--active {
				display: block;
			}

			&--active .CTA {
				display: inline;
				border-bottom-color: $black;
				text-decoration: none;
			}
		}

		&--active {
			.CTA {
				border-bottom: 1px solid #fff;
			}

			.SecondaryNavigation & {
				.CTA {
					padding-bottom: 15px;
					border-bottom: 5px solid #666;
				}
			}

			.NavigationOverlay & {
				.CTA {
					font-weight: bold;
				}
			}

			// Active subLink
			.NavigationOverlay .NavigationItem-subLinks & {
				a {
					font-weight: bold;
				}

				&::before {
					display: none;
				}
			}
		}

		&--activeCategory {
			// Active mainLink/category
			.NavigationOverlay &::before {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				left: 0;
				border-top: 9px solid transparent;
				border-bottom: 9px solid transparent;
				border-left: 9px solid $black;
			}

			@include rtl {
				.NavigationOverlay &::before {
					left: auto;
					right: 0;
					border-left: none;
					border-right: 9px solid $black;
				}
			}
		}
	}

	&-subLinksButton {
		line-height: 14px;

		&::before {
			@include icon('icon-less_sign');
			font-size: 12px;
			position: relative;
			top: 2px;

			.NavigationItem--collapsed & {
				@include icon('icon-plus_sign');
			}
		}
	}

	&-subLinks {
		padding: 25px 0 0 35px;
		height: auto;
		visibility: visible;
		opacity: 1;
		transition: opacity 0.4s ease-in, padding-left 0.3s ease-out;

		@include rtl {
			padding: 25px 35px 0 0;
		}

		.NavigationItem--collapsed & {
			padding: 0;
			height: 0;
			visibility: hidden;
			opacity: 0;
		}
	}

	&-subLink {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		.CTA {
			font-family: $sansFont;
			letter-spacing: 2.33px;
			color: $black;
			font-weight: lighter;
		}
	}

	.CTA {
		&--underlined--dark,
		&--underlined--light {
			border: none;
			text-decoration-line: underline;
			text-decoration: underline;
			padding-bottom: 0;
		}

		&:lang(ar) {
			letter-spacing: 0;
		}
	}
}
