html {
	box-sizing: border-box;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;

	&::before {
		content: 'small';
		display: none;
		visibility: hidden;
	}

	@include viewport(medium) {
		&::before {
			content: 'medium';
		}
	}

	@include viewport(large) {
		&::before {
			content: 'large';
		}
	}

	@include viewport(xlarge) {
		&::before {
			content: 'xlarge';
		}
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

address {
	font-style: normal;
}

blockquote {
	margin: 0;
}

body {
	@include rtl {
		direction: rtl;
	}
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	border-radius: 0;
	font: inherit;
	cursor: pointer;
}

input {
	border-radius: 0;
	appearance: none !important;

	&::-ms-clear {
		display: none;
	}
}

figure {
	margin: 0;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

dl {
	margin: 0;
}

table {
	border-spacing: 0;
}

img[data-sizes='auto'] {
	display: block;
}

video[src=''] + .Video-playButton {
	display: none;
}

select::-ms-expand {
	display: none;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	font-size: 0; // To make voiceover read content inside a link in IOS 10.3
}

.hide {
	display: none !important;
}
