.AlignableImage {
	text-align: left;
	display: block;

	&--left {
		[class*='col-'] {
			float: left;
		}
	}

	&--right {
		[class*='col-'] {
			float: right;
		}
	}

	&--center {
		[class*='col-'] {
			margin: 0 auto;
			float: none;
		}
	}

	&-picture {
		display: block;
	}

	&-title {
		padding-bottom: 10px;
		padding-top: 20px;
	}
}
